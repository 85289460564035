import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { array, object, string } from 'yup';

import { useCurrentSession, useTracker } from '../../Hooks';
import {
  FilterCommonFields,
  MetaData,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterSettlementUtils from '../FilterPopup/utils/FilterSettlementUtils';

export type FilterSettlementFields = Pick<
  FilterCommonFields,
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'paymentPartner'
  | 'subMerchantId'
  | 'singleSubMerchantId'
  | 'minAmount'
  | 'maxAmount'
  | 'settlementId'
  | 'utr'
>;

export const defaultSettlementFilters: FilterSettlementFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
};

const {
  duration,
  startDate,
  endDate,
  subMerchantId,
  singleSubMerchantId,
  minAmount,
  maxAmount,
} = commonSchema;
const settlementSchema = object().shape({
  duration,
  startDate,
  endDate,
  paymentPartner: array().of(
    object().shape({ label: string(), value: string() })
  ),
  subMerchantId,
  singleSubMerchantId,
  minAmount,
  maxAmount,
  settlementId: string(),
  utr: string(),
});

const useFilterSettlements = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(false);
  const [settlements, setSettlements] = useState<any[]>([]);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterSettlementFields>({
    defaultValues: defaultSettlementFilters,
    resolver: yupResolver(settlementSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { settlements } =
      (await fetchSettlements({ ...form.getValues(), page: page + 1 })) ?? {};

    setSettlements((prev) => [...prev, ...settlements]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterSettlementUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const fetchSettlements = useCallback(
    async (formData: FilterSettlementFields & { page?: number }) => {
      const { page, ...restFormData } = formData;
      const { filters, filterSection } = FilterSettlementUtils.generateParams(
        restFormData,
        page ? { page } : undefined,
        form.setValue
      );

      const response = await FilterSettlementUtils.getSettlements(filters);

      // check for user token
      if (checkSessionError(response)) return;

      const settlements = response.data?.result?.result ?? [];
      const metaData = response.data?.result?.meta ?? new MetaData();

      const error = response.data?.result?.error?.message;
      const rows = metaData?.total_records;
      const pages = metaData?.total_pages;

      track({
        name: 'Settlement Summary' + TrackEventNames.searchClicked,
        inBuiltOptions: ['merchantId', 'merchantName'],
        additionalOptions: {
          menu_item_name: 'Settlement Summary',
          // @ts-ignore
          filters: filterSection,

          ...(error
            ? { failure_error_message: error }
            : {
                number_of_rows: rows,
                number_of_pages: pages,
              }),
        },
      });

      return { settlements, filterSection, filters, metaData };
    },
    [checkSessionError, track]
  );

  const handleFormSubmit = useCallback(
    async (formData: FilterSettlementFields & { page?: number }) => {
      setLoading(true);
      const { filterSection, settlements, metaData } =
        (await fetchSettlements(formData)) ?? {};

      setSettlements(settlements);
      setMetaData(metaData);
      setFilters(filterSection);
      setLoading(false);
      setFilterModalShown(false);
    },
    [fetchSettlements]
  );

  return {
    filters,
    form,
    loading,
    metaData,
    settlements,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  };
};

export default useFilterSettlements;
