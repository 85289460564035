import { PointOptionsObject, SeriesOptionsType } from 'highcharts';
import { Colors } from '../../constants/color';
import { TransactionSummaryUtil } from './TransactionSummary/TransactionSummaryUtil';

declare module 'highcharts' {
  interface Point {
    currency: string;
    count: number;
  }
  interface PointOptionsObject {
    currency?: string;
    count: number;
  }
}

export type RawData = Omit<PointOptionsObject, 'value'> &
  Record<'amount' | 'count' | 'amount_percentage' | 'count_percentage', number>;

type Filters = Record<'currency' | 'summaryView', string>;

export const formatNumber = (input: number) =>
  Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(input);

export const isLastLevel = (name: string) =>
  /(pending|failed|succeeded)/gi.test(name);

export const formatData = (
  inputData: RawData[],
  filters: Filters
): PointOptionsObject[] => {
  const { currency, summaryView } = filters;
  const isAmount = summaryView === 'amount';

  return inputData
    .map(
      ({
        id,
        name,
        amount,
        count,
        colorValue,
        amount_percentage,
        count_percentage,
        color,
      }) => ({
        id,
        name,
        color,
        colorValue,
        // actual value either amount or count
        count: isAmount ? amount : count,
        // mapping area size according to percentage
        value: isAmount ? amount_percentage : count_percentage,
        // add currency when summaryView = amount
        currency: isAmount ? currency : '',
      })
    )
    .sort((a, b) => b.value - a.value);
};

export const formatTransactionData = (
  inputData: ReturnType<typeof TransactionSummaryUtil.getData>['rawData'],
  filters: Filters
): SeriesOptionsType[] => {
  const { summaryView } = filters;
  const isAmount = summaryView === 'amount';

  const succeeded: PointOptionsObject[] = inputData.map(
    ({ succeeded_amount, succeeded_count, amount, count }) => ({
      y: isAmount ? succeeded_amount : succeeded_count,
      count: isAmount ? amount : count,
    })
  );

  const failed: PointOptionsObject[] = inputData.map(
    ({ failed_amount, failed_count, amount, count }) => ({
      y: isAmount ? failed_amount : failed_count,
      count: isAmount ? amount : count,
    })
  );

  const pending: PointOptionsObject[] = inputData.map(
    ({ pending_amount, pending_count, amount, count }) => ({
      y: isAmount ? pending_amount : pending_count,
      count: isAmount ? amount : count,
    })
  );

  const reversed: PointOptionsObject[] = inputData.map(
    ({ reversed_amount, reversed_count, amount, count }) => ({
      y: isAmount ? reversed_amount : reversed_count,
      count: isAmount ? amount : count,
    })
  );

  const result: SeriesOptionsType[] = [];

  if (succeeded.length) {
    result.push({
      name: 'Succeeded',
      data: succeeded,
      color: Colors.success.light,
      type: 'column',
    });
  }
  if (failed.length) {
    result.push({
      name: 'Failed',
      data: failed,
      color: Colors.failed.light,
      type: 'column',
    });
  }
  if (pending.length) {
    result.push({
      name: 'Pending',
      data: pending,
      color: Colors.pending.light,
      type: 'column',
    });
  }
  if (reversed.length) {
    result.push({
      name: 'Reversed',
      data: reversed,
      color: '#7EA5F8',
      type: 'column',
    });
  }

  return result;
};
