import { Link, Outlet } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { EmptyTableView } from './../EmptyTableView/EmptyTableView';
import {
  Badge,
  BadgeProps,
  Button,
  Collapsible,
  CollapsibleButton,
  Divider,
  Drawer,
  KeyValue,
  OrderLineCard,
  Table,
  TableColumnType,
  TransactionCard,
} from 'nimbbl-quark';
import {
  FiColumns,
  FiEdit,
  FiInfo,
  FiLink,
  FiRepeat,
  FiRotateCcw,
  FiRotateCw,
  FiUser,
} from 'react-icons/fi';
import FilterPopup from '../FilterPopup/FilterPopup';
import { PriceUtil } from '../../Utils';
import { Skeleton, TableCell, TableRow, Tooltip } from '@mui/material';
import {
  PaymentLinkResponse,
  TableMetaResponse,
} from './Create/PaymentLinkResponse';
import { formatDate } from '../Orders/Orders';
import FilterSection from '../FilterSection/FilterSection';
import { cancelPaymentLink, resendPaymentLink } from './Create/utils';
import { useGlobalStore } from '../../Context/GlobalStore';
import { ActionTypes } from '../../Context/reducer';
import { getLink } from '../Transactions/TransactionCardModule';
import { getIssuerBadgeType } from '../../Configs/confgs';
import HeaderComponent from '../Header/Header';
import { useCommonTracker, useCurrentSession } from '../../Hooks';
import { replaceNone } from './Create/Steps';
import useFilterPaymentLink, {
  defaultPaymentLinkFilters,
} from './useFilterPaymentLink';
import FilterSection2 from '../FilterSection/FilterSection2';
import isEqual from 'lodash.isequal';
import FilterPaymentLinkUtils from '../FilterPopup/utils/FilterPaymentLinkUtils';
import FilterPaymentLink from '../FilterPopup/FilterPaymentLink';
import FieldNames from '../../constants/FieldNames';

export const messages = {
  cancel: {
    success: 'Payment link cancelled successfully',
    error: 'Something went wrong. Payment link not cancelled',
  },
  resend: {
    success: 'Payment link successfully sent',
    error: 'Something went wrong. Payment link not sent',
  },
};

const getStatusBadge = (
  status: string
): { type: BadgeProps['type']; text: BadgeProps['text'] } => {
  switch (status) {
    case 'expired':
    case 'cancelled':
      return { type: 'Failed', text: status };
    case 'paid':
      return { type: 'Succeeded', text: status };
    case 'created':
      return { type: 'New', text: status };
    case 'opened':
    case 'sent':
    case 'partially paid':
    default:
      return { type: 'Pending', text: status };
  }
};

const page_size = 20;

const PaymentLink = () => {
  const { dispatch } = useGlobalStore();
  const [selectedLink, setSelectedLink] = useState<PaymentLinkResponse>(
    new PaymentLinkResponse()
  );
  const [isShown, setIsShown] = useState(false);
  const [animatedRight, setAnimateRight] = useState(false);
  const [animatedRightOrderLine, setAnimateRightOrderLine] = useState(false);
  const [animatedRightTransactions, setAnimatedRightTransactions] =
    useState(false);
  const [isOrderItemModalShown, setIsOrderItemModalShown] = useState(false);
  const [isTransactionsShow, setIsTransactions] = useState(false);
  const [copyText, setCopyText] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const tableRef = useRef<any>();

  const { trackRowSelected } = useCommonTracker();

  const {
    filters,
    form,
    loading,
    metaData,
    paymentLinks,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    removeFilter,
  } = useFilterPaymentLink();

  useEffect(() => {
    handleFormSubmit(defaultPaymentLinkFilters);
  }, []);

  useEffect(() => {
    if (!animatedRight) setIsShown(true);
  }, [animatedRight]);

  useEffect(() => {
    if (animatedRightTransactions) setIsTransactions(true);
  }, [animatedRightTransactions]);

  useEffect(() => {
    if (animatedRightOrderLine) setIsOrderItemModalShown(true);
  }, [animatedRightOrderLine]);

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [isShown]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (copyText) {
      timerId = setTimeout(() => setCopyText(''), 2000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [copyText]);

  const renderRowData = (e: any, data: PaymentLinkResponse) => {
    setAnimateRight(true);
    setSelectedLink(data);
    setSelectedLink(data);
    document.querySelector('tr.selected-row')?.classList.remove('selected-row');
    e.currentTarget.closest('tr').classList.add('selected-row');
  };

  const columns: TableColumnType<PaymentLinkResponse>[] = [
    {
      title: FieldNames.paymentLinkId,
      field: 'payment_link_id',
      cellStyle: {
        color: '#0352C9',
        width: '18%',
        textDecoration: 'none',
      },
      sorting: false,
      render: (rowData) => (
        <button
          className='flex'
          style={{ cursor: 'pointer' }}
          onClick={(e) => renderRowData(e, rowData)}>
          {rowData.payment_link_hash}
        </button>
      ),
    },
    {
      title: 'Amount',
      field: 'amount',
      sorting: false,
      render: (rowData) => {
        return PriceUtil.formatCurrency(
          rowData.total_amount,
          (rowData?.currency_id?.name as any) || 'INR'
        );
      },
    },
    {
      title: 'Payer mobile',
      field: 'payer_mobile',
      sorting: false,
      cellStyle: { width: '14%' },
      render: (rowData) => rowData.payer_mobile_number,
    },
    {
      title: 'Payer Name',
      field: 'payer_name',
      sorting: false,
      render: ({ payer_first_name, payer_last_name }) =>
        (payer_first_name || '') + ' ' + (payer_last_name || ''),
    },
    {
      title: 'Expires on',
      field: 'expires_on',
      sorting: false,
      cellStyle: { width: '18%' },
      render: ({ expires_at }) => formatDate(expires_at),
    },
    {
      title: FieldNames.status,
      field: 'status',
      sorting: false,
      cellStyle: { width: '14%' },
      render: ({ status }) => (
        <span className='capitalize'>
          <Badge
            variant='accent'
            containerProps={{ style: { width: 'fit-content' } }}
            {...getStatusBadge(status)}
          />
        </span>
      ),
    },
    {
      title: '',
      field: 'payment_link',
      sorting: false,
      cellStyle: {
        width: '5%',
        textAlign: 'center',
        verticalAlign: 'middle',
      },
      render: (rowData) => {
        return copyText === rowData.payment_link_url ? (
          <div className='relative w-[16px] h-[16px]'>
            <p className='absolute bg-[#222] rounded text-[#fff] w-max px-2 top-[-4px] right-0'>
              Link copied successfully
            </p>
          </div>
        ) : (
          <Tooltip title='Copy payment link url'>
            <button onClick={() => copyTextClipboard(rowData.payment_link_url)}>
              <FiLink color='#045CE1' size={18} />
            </button>
          </Tooltip>
        );
      },
    },
  ];

  const drawerData = useMemo(() => {
    const {
      create_date,
      total_amount,
      expires_at,
      invoice_id,
      callback_url,
      payer_email_address,
      payer_first_name,
      payer_last_name,
      payer_mobile_number,
      partial_payment,
      transaction_details,
      currency_id,
    } = selectedLink;

    const { name: currencyName } = currency_id;
    const { order_id } = transaction_details[0] || {};
    const { order_id: orderId } = order_id || {};

    return [
      {
        label: 'Link details',
        icon: FiInfo,
        values: [
          {
            label: 'amount',
            value: PriceUtil.formatCurrency(total_amount, currencyName as any),
          },
          {
            label: 'partial payment',
            value: partial_payment ? 'Enabled' : 'Disabled',
          },
          {
            label: 'created on',
            value: formatDate(create_date) || create_date,
          },
          { label: 'Expiry date', value: formatDate(expires_at) || expires_at },
          { label: FieldNames.invoiceId, value: invoice_id },
          // { label: 'reminders', value: 'enabled' },
          ...(callback_url
            ? [
                {
                  label: 'callback url',
                  value: callback_url,
                },
              ]
            : []),
          ...(orderId
            ? [
                {
                  label: 'Order id',
                  value: orderId,
                },
              ]
            : []),
        ],
      },
      {
        label: 'Customer details',
        icon: FiUser,
        values: [
          { label: 'first name', value: payer_first_name },
          ...(payer_last_name
            ? [{ label: 'last name', value: payer_last_name }]
            : []),
          ...(payer_email_address
            ? [
                {
                  label: FieldNames.email,
                  value: payer_email_address,
                },
              ]
            : []),
          { label: FieldNames.mobile, value: payer_mobile_number },
        ],
      },
    ];
  }, [selectedLink]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const onClose = () => {
    setAnimateRight(false);
    setAnimateRightOrderLine(false);
    setAnimatedRightTransactions(false);
  };

  const toggleOrderModal = () => {
    setAnimateRightOrderLine(true);
    setAnimatedRightTransactions(false);
  };
  const toggleTransactionModal = () => {
    setAnimateRightOrderLine(false);
    setAnimatedRightTransactions(true);
  };
  const toggleOrderModalClose = () => setAnimateRightOrderLine(false);
  const toggleTransactionModelClose = () => setAnimatedRightTransactions(false);

  const handleFilterModal = () => setFilterModalShown((prev) => !prev);

  const copyTextClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopyText(data);
  };

  const cancelLink = async (id: number) => {
    setButtonLoader(true);
    onClose();
    try {
      const { status, data } = await cancelPaymentLink(id);
      if (data.error) {
        const message = data.error.data.message;
        throw new Error(replaceNone(message));
      }

      if (data?.id === null && status === 200) {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { type: 'success', message: messages.cancel.success },
        });
        await handleFormSubmit(defaultPaymentLinkFilters);
      } else {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { type: 'danger', message: messages.cancel.error },
        });
      }
    } catch (err) {
      const { message } = err as Error;
      if (message) {
        return dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { type: 'danger', message: message },
        });
      }

      dispatch({
        type: ActionTypes.setErrorMessage,
        payload: { type: 'danger', message: messages.cancel.error },
      });
    }

    setButtonLoader(false);
  };

  const resendLink = async (id: number) => {
    setButtonLoader(true);
    try {
      const { status, data } = await resendPaymentLink(id);

      if (data.error) {
        const message = data.error.data.message;
        throw new Error(replaceNone(message));
      }

      if (status === 200) {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { type: 'success', message: messages.resend.success },
        });
      } else {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { type: 'danger', message: messages.resend.error },
        });
      }
    } catch (error) {
      const { message } = error as Error;

      dispatch({
        type: ActionTypes.setErrorMessage,
        payload: { type: 'danger', message: message },
      });
    }
    setButtonLoader(false);
  };

  const isEditable = /(created)/i.test(selectedLink.status);

  return (
    <>
      <Sidebar />
      <div
        style={{ minHeight: 'calc(100vh - 48px)' }}
        className='flex flex-col p-6 body-container ml-60'>
        <HeaderComponent
          // loading={loading}
          title='Payment Links'
          page='payment links'
          handleShowConfigFormModal={handleFilterModal}
        />

        <FilterSection2
          defaultFilters={defaultPaymentLinkFilters}
          filters={filters}
          pageName='payment link'
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterPaymentLinkUtils.generateParams(defaultPaymentLinkFilters)
                .filterSection,
              filters
            )
          }
        />

        <div style={{ overflow: 'auto' }} className='mt-6'>
          {loading ? (
            <>
              <table className='w-full'>
                {Array.from(Array(10).keys()).map((item) => (
                  <TableRow key={item} style={{ width: '100%' }}>
                    <TableCell
                      component='th'
                      scope='row'
                      style={{ width: '20%' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align='right' style={{ width: '20%' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align='right' style={{ width: '20%' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align='right' style={{ width: '20%' }}>
                      <Skeleton />
                    </TableCell>
                    <TableCell align='right' style={{ width: '20%' }}>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                ))}
              </table>
            </>
          ) : paymentLinks.length > 0 ? (
            <Table
              data={paymentLinks}
              columns={columns}
              search={false}
              handleSearchChange={() => ({})}
              selection={true}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    'No records fetched for your search criteria, please change the filters and search again',
                },
              }}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(paymentLinks.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total paymentLinks is still less than total records
                    paymentLinks.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  // getSelectedRow(rows, page + 1);
                  // setCsvData(rows);
                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected('Payment Links', {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: page_size,
                draggable: false,
                showSelectAllCheckbox: true,
              }}
            />
          ) : (
            <EmptyTableView
              headerText='Looks Empty!'
              headerSubText='There are no payment links created for now. Start by clicking the button below to create a new payment link'
              btnLink='/payment-links/create'
              btnText='Create Payment Link'
            />
          )}
        </div>
      </div>
      <Outlet />

      <Drawer
        position='right'
        hide={onClose}
        isShown={isShown}
        style={{ width: '400px' }}
        className={
          animatedRight
            ? 'animation drawerAnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerElement={
          <div>
            <p className='mb-2 text-base'>{selectedLink.payment_link_hash}</p>
            <span className='capitalize'>
              <Badge
                variant='accent'
                containerProps={{ style: { width: 'fit-content' } }}
                {...getStatusBadge(selectedLink.status)}
              />
            </span>
          </div>
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        bodyElement={
          <React.Fragment>
            <div
              className='p-4 no-scroll icon-font'
              style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}>
              {isEditable && (
                <div className='flex justify-between items-center p-2 bg-[#FFF9EA] rounded mb-5'>
                  <span className='text-sm font-medium'>
                    Update payment link
                  </span>
                  <Link
                    to={
                      '/payment-links/create?id=' +
                      selectedLink.payment_link_hash
                    }>
                    <Button
                      variant='text'
                      text='Edit'
                      style={{
                        fontSize: 14,
                        lineHeight: '20px',
                        fontWeight: 500,
                        padding: 8,
                        minHeight: 0,
                        minWidth: 0,
                        backgroundColor: '#E6EFFC',
                        color: ' #033D94',
                      }}
                      iconPosition='left'
                      IconComponent={IconComponent}
                    />
                  </Link>
                </div>
              )}

              <div className='flex items-center'>
                {copyText ? (
                  <div className='relative w-[16px] h-[16px]'>
                    <p className='absolute bg-[#222] rounded text-[#fff] w-max px-2 top-[-4px]'>
                      Link copied successfully
                    </p>
                  </div>
                ) : (
                  <>
                    <Tooltip title='Copy payment link url'>
                      <button
                        onClick={() =>
                          copyTextClipboard(selectedLink.payment_link_url)
                        }>
                        <FiLink size={16} className='' />
                      </button>
                    </Tooltip>
                  </>
                )}
                <Tooltip title='Open payment link in new tab'>
                  <button
                    className='hover:text-[#033d94] ml-2 text-sm font-normal truncate'
                    onClick={() => openLink(selectedLink.payment_link_url)}>
                    {selectedLink.payment_link_url}
                  </button>
                </Tooltip>
              </div>

              <Divider className='mt-4 mb-3' />

              {drawerData.map(({ label, values, icon: Icon }, index) => (
                <Collapsible
                  key={label}
                  open={index === 0 ? false : true}
                  title={label}
                  iconColor='#045CE1'
                  isBorder={true}
                  icon={<Icon size={20} />}
                  style={{ borderBottom: '1px solid #ccc' }}>
                  <div className='grid grid-cols-2 mb-2 gap-x-2 gap-y-4'>
                    {values.map(({ label, value }) => (
                      <KeyValue
                        key={label}
                        label={label}
                        labelStyle={{ textTransform: 'capitalize' }}
                        value={value}
                        valueStyle={{
                          overflowWrap: 'break-word',
                          textTransform: [
                            FieldNames.invoiceId,
                            'callback url',
                            FieldNames.email,
                          ].includes(label)
                            ? 'none'
                            : 'capitalize',
                        }}
                      />
                    ))}
                  </div>
                </Collapsible>
              ))}
              {selectedLink.transaction_details.length > 0 && (
                <div className='mt-4'>
                  <CollapsibleButton
                    text={`Related Transactions (${selectedLink.transaction_details.length})`}
                    iconRight={<FiColumns />}
                    iconLeft={<FiRepeat />}
                    style={{ width: '100%', background: '#F1F4F9' }}
                    iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                    iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                    onClick={toggleTransactionModal}
                  />
                </div>
              )}
              {selectedLink.product_details.length > 0 && (
                <div className='mt-4'>
                  <CollapsibleButton
                    text={`Order line items`}
                    iconRight={<FiColumns />}
                    iconLeft={<FiRepeat />}
                    style={{ width: '100%', background: '#F1F4F9' }}
                    iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                    iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                    onClick={toggleOrderModal}
                  />
                </div>
              )}
              <div className='flex justify-between mt-4'>
                {isEditable && (
                  <>
                    <Button
                      variant='outline'
                      text='Cancel link'
                      themeColor='error'
                      style={{
                        width: '50%',
                        marginRight: 24,
                      }}
                      attributes={{
                        onClick: () => cancelLink(selectedLink.id),
                      }}
                    />
                    <Button
                      variant='primary'
                      text='Resend link'
                      style={{
                        width: '50%',
                      }}
                      attributes={{
                        onClick: () => resendLink(selectedLink.id),
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        }
      />

      <Drawer
        position='right'
        hide={toggleTransactionModelClose}
        isShown={isTransactionsShow}
        style={{ width: '400px' }}
        className={
          animatedRightTransactions
            ? 'animation drawer2AnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerElement={
          <div>
            <h1 className='mb-2 text-base'>Related Transactions</h1>
          </div>
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        bodyElement={
          <div
            className='p-4 no-scroll'
            style={{
              overflowY: 'scroll',
              maxHeight: 'calc(100vh - 100px)',
            }}>
            {selectedLink?.transaction_details?.map((transaction) => {
              const {
                create_date,
                payment_mode,
                status,
                grand_total_amount,
                transaction_id,
                transaction_type,
              } = transaction;

              return (
                <div className='mb-4' key={transaction_id}>
                  <TransactionCard
                    icon={
                      transaction_type === 'payment' ? (
                        <FiRotateCcw />
                      ) : (
                        <FiRotateCw />
                      )
                    }
                    label={transaction_type || 'Payment'}
                    style={{
                      background: '#FAFAFC',
                      color: '#0F1A2A',
                      textTransform: 'capitalize',
                    }}
                    headerRigthContent={
                      <Badge variant='accent' {...getIssuerBadgeType(status)} />
                    }>
                    <div className='flex flex-wrap'>
                      <KeyValue
                        className='w-2/3'
                        value={
                          <a
                            href={getLink(
                              transaction_type,
                              transaction_id,
                              create_date
                            )}
                            target='_blank'
                            rel='noreferrer'>
                            {transaction_id}
                          </a>
                        }
                        styleContainer={{
                          width: '58%',
                          color: '#045CE1',
                        }}
                      />
                      <KeyValue
                        value={formatDate(create_date) || ''}
                        styleContainer={{
                          width: '38%',
                        }}
                      />
                      <KeyValue
                        className='mt-4'
                        label='Payment Method'
                        value={payment_mode}
                        styleContainer={{
                          width: '58%',
                        }}
                      />
                      <KeyValue
                        className='mt-4'
                        label='Transaction Amount'
                        value={PriceUtil.formatCurrency(
                          grand_total_amount || 0,
                          selectedLink.currency_id.name as any
                        )}
                        styleContainer={{
                          width: '38%',
                          float: 'left',
                          marginBottom: '16px',
                        }}
                      />
                    </div>
                  </TransactionCard>
                </div>
              );
            })}
          </div>
        }
      />

      <Drawer
        position='right'
        hide={toggleOrderModalClose}
        isShown={isOrderItemModalShown}
        style={{ width: '400px' }}
        className={
          animatedRightOrderLine
            ? 'animation drawer2AnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerElement={
          <div>
            <h1 className='mb-2 text-base'>Order line items</h1>
          </div>
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        bodyElement={
          <div
            className='p-4 no-scroll'
            style={{
              overflowY: 'scroll',
              maxHeight: 'calc(100vh - 100px)',
            }}>
            {selectedLink?.product_details?.map((product) => {
              const {
                title,
                description,
                quantity,
                rate,
                total_amount,
                image_url,
              } = product;
              return (
                <div key={title} className='mb-4'>
                  <OrderLineCard
                    title={title}
                    description={description}
                    imgUrl={image_url ?? undefined}>
                    <>
                      <div className='flex-1'>
                        <KeyValue label='Quantity' value={quantity} />
                      </div>
                      <div className='flex-1'>
                        <KeyValue label='Rate' value={rate} />
                      </div>
                      <div className='flex-1'>
                        <KeyValue label='Total Amount' value={total_amount} />
                      </div>
                    </>
                  </OrderLineCard>
                </div>
              );
            })}
          </div>
        }
      />

      <FilterPaymentLink
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />
    </>
  );
};

export default PaymentLink;

const IconComponent = () => <FiEdit size={16} className='mr-3' />;
