export const LOGIN = '/';
export const ORDERS = '/orders';
export const API_KEY = '/developer/api-keys';
export const TRANSACTION = '/transactions';
export const SETTLEMENTS = '/settlements';
export const SETTLEMENTSDETAILS = '/settlements-details';
export const OFFERS = '/offers';
export const PAYMENTLINKS = '/payment-links';
export const REFUNDS = '/refunds';
export const BULKDREFUND = '/bulk-refunds';
export const FORGETPASS = '/forgot-password';
export const RESETPASS = '/reset-password';
export const RESETPASSUCESS = '/reset-successfull';
export const ANALYTICS = '/analytics';
export const CREATEPASS = '/create-password';
