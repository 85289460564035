import { CSSProperties, FC, useMemo } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import DateUtils from '../FilterPopup/utils/DateUtils';

interface TagProps {
  label: string;
  value: string;
  onClose?: (text: string) => void;
  style?: CSSProperties;
  showClose?: boolean;
  handleClose: FilterSection2Props['removeFilter'];
}

const Tag = ({
  label,
  value,
  onClose,
  style,
  showClose,
  handleClose,
}: TagProps) => {
  return (
    <p
      style={style}
      className='inline-flex items-center px-2 py-1 text-sm border rounded tag'>
      <span className='capitalize tag-key'>{label}:</span>
      <span className='ml-1'>{value}</span>
      {showClose && (
        <RiCloseLine
          className='w-4 h-4 ml-1 cursor-pointer tag-close'
          onClick={async () => {
            if (handleClose) {
              await handleClose(label);
            }
          }}
        />
      )}
    </p>
  );
};

interface FilterSection2Props {
  defaultFilters: any;
  filters: Record<string, string>;
  totalCount?: number;
  pageName?: string;
  showClearAll?: boolean;
  removeFilter: (filterName?: string) => Promise<void>;
}

const FilterSection2: FC<FilterSection2Props> = (props) => {
  const {
    defaultFilters,
    filters,
    showClearAll,
    totalCount = 0,
    pageName,
    removeFilter,
  } = props;

  const refactoredFilters = useMemo(() => {
    const result = { ...filters };

    if (result.startDate && result.endDate) {
      result['date'] = result.startDate + ' - ' + result.endDate;
      delete result.startDate;
      delete result.endDate;
    }

    return result;
  }, [filters]);

  const filterCount = Object.keys(refactoredFilters).length;
  if (filterCount === 0) return null;

  return (
    <>
      <div className='flex items-center mt-4'>
        <h5 className='flex items-center text-base font-semibold mr-0.5'>
          Filter Applied
          {!!totalCount && (
            <span className='ml-1 text-xs font-normal'>
              (Displaying {totalCount?.toLocaleString('en-IN')}{' '}
              {pageName + (Number(totalCount) > 0 ? 's' : '')})
            </span>
          )}
        </h5>
      </div>
      <div className='flex flex-wrap gap-3 mt-2 FilterSection-body'>
        {Object.entries(refactoredFilters)
          .reverse()
          .map(([key, value]) => {
            if (!value) return null;
            let showClose = true;

            if (/date/i.test(key)) {
              const defaultDate =
                DateUtils.formatDate(defaultFilters.startDate) +
                ' - ' +
                DateUtils.formatDate(defaultFilters.endDate);

              if (defaultDate === value) {
                showClose = false;
              }
            }

            if (/submerchant type/i.test(key)) {
              if (
                value.toLowerCase() === defaultFilters['subMerchantType']?.value
              ) {
                showClose = false;
              }
            }

            if (/currency/i.test(key)) {
              if (value === defaultFilters['currency']?.label) {
                showClose = false;
              }
            }

            return (
              <Tag
                key={key}
                label={key}
                value={value}
                showClose={showClose}
                handleClose={removeFilter}
              />
            );
          })}

        {showClearAll && (
          <button
            className='ml-1 text-sm'
            style={{
              color: '#d83232',
              fontWeight: 500,
            }}
            onClick={async () => {
              await removeFilter('default');
            }}>
            Clear All
          </button>
        )}
      </div>
    </>
  );
};

export default FilterSection2;
