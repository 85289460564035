import axios from "axios";

import {
  FilterOrderFields,
  defaultOrderFilters,
} from "../../Orders/useFilterOrders";
import DateUtils from "./DateUtils";
import getApiEndPoint from "./getApiEndpoint";
import { MetaData } from "../interfaces/CommonFields";
import { daysOptions } from "../constants";
import FieldNames from "../../../constants/FieldNames";
import { UseFormSetValue } from "react-hook-form";

const query =
  "{browser_name,device_name,os_name, merchant_shopfront_domain,available_refundable_amount,amount_before_tax,original_amount_before_tax,tax,original_tax,offer_discount,grand_total_amount,shipping_address_id{address_type, address_1, street, landmark,area, city, state,country,pincode}, referrer_platform_version, referrer_platform, additional_charges, id,order_date,order_id,total_amount,original_total_amount,currency,original_currency,exchange_rate_used,conversion_reason,invoice_id,status,sub_merchant_id{display_name, id, sub_merchant_id, description},order_transac_type,attempts,order_custom_attributes{key,value},user_id{mobile_number,email,country_code,first_name,last_name}, transaction_items{id,payment_mode,status,transaction_id, create_date,transaction_type, refund_done,refund_amount,grand_total_amount, settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount}}}";

// submerchant, submerchant_id, currency,

class FilterOrderUtils {
  static generateParams(
    formData: FilterOrderFields,
    metaData?: Partial<MetaData>,
    updateFormData: UseFormSetValue<FilterOrderFields> = (..._args) => {}
  ) {
    // for filter api
    const filterParams: any[] = [];
    // for filter section
    const filterSection: Record<string, string> = {};

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case "status": {
          const values = formData[filterName].map((item) => item.value);
          // if all is selected then don't send/show status filter
          if (/all/i.test(values.join(","))) break;
          if (values.length === 0) break;

          filterParams.push(["status", "in", values]);

          const value = formData[filterName]
            .map((item) => item.label)
            .join(", ");
          filterSection[FieldNames.status] = value;
          break;
        }
        case "startDate": {
          const startDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams.push([
            "order_date",
            ">=",
            DateUtils.getUTCStringFromDate(startDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case "endDate": {
          let endDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
            updateFormData("endDate", endDate);
          }

          filterParams.push([
            "order_date",
            "<=",
            DateUtils.getUTCStringFromDate(endDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case "orderId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["order_id", "=", value?.trim()]);
          filterSection[FieldNames.orderId] = value ?? "";
          break;
        }
        case "invoiceId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["invoice_id", "=", value?.trim()]);
          filterSection[FieldNames.invoiceId] = value ?? "";
          break;
        }
        case "mobile": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["user_id.mobile_number", "=", value?.trim()]);
          filterSection[FieldNames.mobile] = value ?? "";
          break;
        }
        case "subMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["sub_merchant_id", "in", values]);
          filterSection[FieldNames.submerchantId] = labels;

          break;
        }
        case "singleSubMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          if (value) {
            filterParams.push(["sub_merchant_id", "=", [value.trim()]]);
            filterSection[FieldNames.singleSubMerchantId] = value;
          }

          break;
        }
        case "email": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["user_id.email", "=", value?.trim()]);
          filterSection[FieldNames.email] = value ?? "";
          break;
        }
        case "currency": {
          const value = formData[filterName];
          if (!value) break;

          const { value: code } = value;
          if (!code) break;

          filterParams.push(["currency", "=", code]);
          filterSection[FieldNames.currency] = code;
          break;
        }
        case "minAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["total_amount", ">=", Number(value)]);
          filterSection[FieldNames.minAmount] = value ?? "";
          break;
        }
        case "maxAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["total_amount", "<=", Number(value)]);
          filterSection[FieldNames.maxAmount] = value ?? "";
          break;
        }

        case "customAttributes": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(
            "|",
            ["order_custom_attributes.key", "like", "%" + value + "%"],
            ["order_custom_attributes.value", "like", "%" + value + "%"]
          );
          filterSection[FieldNames.customAttributes] = value ?? "";
          break;
        }
      }
    });

    const { per_page, next, prev, page } = metaData ?? {};

    const filters = {
      params: {
        ...(filterParams.length > 0 && {
          filter: JSON.stringify(filterParams),
        }),
        query: query,
        page: page ?? 1,
        per_page: per_page ?? 60,
        next: next ?? null,
        prev: prev ?? null,
      },
    };

    return {
      filters,
      filterSection,
    };
  }

  static removeParams(
    formData: FilterOrderFields,
    filterName: string
  ): FilterOrderFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case FieldNames.status:
        return { ...result, status: defaultOrderFilters.status };
      case "date":
        return {
          ...result,
          startDate: defaultOrderFilters["startDate"],
          endDate: defaultOrderFilters["endDate"],
        };
      case FieldNames.orderId:
        return { ...result, orderId: undefined };
      case FieldNames.invoiceId:
        return { ...result, invoiceId: undefined };
      case FieldNames.mobile:
        return { ...result, mobile: undefined };
      case FieldNames.submerchantId:
        return { ...result, subMerchantId: [] };
      case FieldNames.singleSubMerchantId:
        return {
          ...result,
          singleSubMerchantId: undefined,
          subMerchantId: [],
        };
      case FieldNames.email:
        return { ...result, email: undefined };
      case FieldNames.currency:
        return { ...result, currency: null };
      case FieldNames.minAmount:
        return { ...result, minAmount: undefined };
      case FieldNames.maxAmount:
        return { ...result, maxAmount: undefined };
      case FieldNames.customAttributes:
        return { ...result, customAttributes: undefined };
      case "default":
      default:
        return { ...defaultOrderFilters };
    }
  }

  static getOrder = (body: object) => axios.post(getApiEndPoint("order"), body);
}

export default FilterOrderUtils;
