enum FieldNames {
  bankName = 'Bank Name',
  bulkRefundId = 'Bulk Refund Id',
  cardNumber = 'Card Number',
  currency = 'Currency',
  customAttributes = 'Custom Attributes',
  email = 'Email',
  invoiceId = 'Invoice Id',
  linkHash = 'Link Hash',
  maxAmount = 'Max Amount',
  minAmount = 'Min Amount',
  mobile = 'Mobile',
  orderId = 'Order Id',
  paymentLinkId = 'Payment Link Id',
  paymentMode = 'Payment Mode',
  paymentPartner = 'Payment Partner',
  refundId = 'Refund Id',
  settlementId = 'Settlement Id',
  singleSubMerchantId = 'Submerchant Id',
  status = 'Status',
  submerchantId = 'Submerchant',
  submerchantType = 'Submerchant Type',
  transactionId = 'Transaction Id',
  upiId = 'UPI Id',
  utr = 'UTR',
  walletName = 'Wallet Name',
}

export default FieldNames;
