export const TransactionModel = 'nimbbl.transaction';

// Fields to included in the excel sheet for the Transactions Page when "Export" button is clicked

// Columns in downloaded excel sheet will be in same order as fields below
export const TransactionFields = [
  // transaction details
  { name: 'transaction_id', label: 'Transaction ID', store: true },
  { name: 'order_id', label: 'Order ID', store: true },
  { name: 'status', label: 'Status', store: true },
  // { name: 'grand_total_amount', label: 'Grand Total Amount', store: true },
  { name: 'total_amount', label: 'Total Amount', store: false },
  { name: 'create_date', label: 'Payment Date', store: true },

  { name: 'user_mobile', label: 'Mobile', store: false },
  { name: 'merchant_id', label: 'Submerchant', store: true },
  {
    name: 'sub_merchant_description',
    label: 'Submerchant Description',
    store: true,
  },
  { name: 'sub_merchant_id', label: 'Submerchant ID', store: true },
  { name: 'user_email', label: 'Email', store: false },
  { name: 'order_invoice_id', label: 'Invoice ID', store: false },
  { name: 'partner_settement_id', label: 'PSP Settlement ID', store: true },
  { name: 'settlement_date', label: 'Settlement Date', store: true },
  { name: 'utr_no', label: 'UTR', store: true },
  { name: 'nimbbl_error_code', label: 'Error Code', store: true },
  {
    name: 'nimbbl_merchant_message',
    label: 'Error Reason',
    store: true,
  },
  { name: 'nimbbl_consumer_message', label: 'Customer Message', store: true },

  // transaction date
  { name: 'payment_mode', label: 'Payment Mode', store: true },
  {
    name: 'psp_generated_txn_id',
    label: 'Psp Generated Id',
    store: true,
  },
  { name: 'payment_partner', label: 'Payment Partner', store: true },
  { name: 'issuer', label: 'Card Issuer', store: true },
  { name: 'network', label: 'Card Network', store: true },
  { name: 'card_type', label: 'Card Type', store: true },
  { name: 'masked_card', label: 'Card Number', store: true },
  { name: 'expiry', label: 'Card Expiry', store: true },
  { name: 'holder_name', label: 'Card Holder Name', store: true },
  { name: 'wallet_name', label: 'Wallet Name', store: true },
  { name: 'vpa_id', label: 'UPI ID', store: true },
  { name: 'vpa_holder', label: 'UPI Account Holder Name', store: true },
  { name: 'vpa_app_name', label: 'UPI App', store: true },
  { name: 'upi_flow', label: 'UPI Flow', store: true },
  { name: 'bank_name', label: 'Bank Name', store: true },
  { name: 'additional_charges', label: 'Convenience Fee', store: true },
  { name: 'currency', label: 'Order Currency', store: true },
  { name: 'settled_amount', label: 'Net Settlement Value', store: true },
  {
    name: 'order_custom_attributes',
    label: 'Custom Attributes',
    store: true,
  },

  {
    name: 'order_id/original_currency',
    label: 'Original Order Currency',
    store: true,
  },
  {
    name: 'order_id/original_total_amount',
    label: 'Original Order Amount',
    store: true,
  },
  { name: 'order_id/offer_discount', label: 'Offer Discount', store: true },
  { name: 'currency', label: 'Transaction Currency', store: true },
  { name: 'total_amount', label: 'Transaction Amount', store: false },
  { name: 'order_id/exchange_rate_used', label: 'Exchange Rate', store: true },

  // { name: 'transaction_type', label: 'Transaction Type', store: true },
  // { name: 'direct_payment_mode', label: 'Sub Payment Mode', store: true },
  // { name: 'offer', label: 'Offer', store: true },

  // custom attributes

  // { name: 'refund_done', label: 'Refund Done', store: true },
  // { name: 'refund_amount', label: 'Refund Amount', store: true },

  //payment details
  // { name: 'order_order_date', label: 'Payment Date', store: true },

  // amount details

  // settlement details

  // more keys

  // { name: 'write_date', label: 'Last Updated on', store: true },
  // { name: 'user_name', label: 'User Name', store: false },
  // { name: 'user_unique_id', label: 'User Id ', store: false },

  // { name: 'refund_arn', label: 'Refund ARN', store: true },
  // {
  //   name: 'orignal_payment_transaction_id',
  //   label: 'Orignal Transactions',
  //   store: true,
  // },
];
