import { GlobalState } from './initialState';

export enum ActionTypes {
  setSession = 'SET_SESSION',
  setCallbackUrl = 'SET_CALLBACK_URL',
  setErrorMessage = 'SET_ERROR_MESSAGE',
  setConfirmDialog = 'SET_CONFIRM_DIALOG',
}

export type Action =
  | {
      type: ActionTypes.setCallbackUrl;
      payload: GlobalState['callbackUrl'];
    }
  | { type: ActionTypes.setSession; payload: GlobalState['isSessionValid'] }
  | {
      type: ActionTypes.setErrorMessage;
      payload: {
        message: GlobalState['error'];
        type: GlobalState['errorType'];
      };
    }
  | {
      type: ActionTypes.setConfirmDialog;
      payload: GlobalState['confirmDialog'];
    };

export const reducer = (state: GlobalState, action: Action): GlobalState => {
  const { type } = action;

  switch (type) {
    case ActionTypes.setCallbackUrl:
      return { ...state, callbackUrl: action.payload };
    case ActionTypes.setSession:
      return { ...state, isSessionValid: action.payload };
    case ActionTypes.setErrorMessage:
      const { message, type } = action.payload;
      return { ...state, error: message, errorType: type };
    case ActionTypes.setConfirmDialog:
      return { ...state, confirmDialog: { ...action.payload } };
    default:
      return state;
  }
};
