import { CSSProperties } from 'react';
import currencies from '../../../constants/currencies.json';
import { Dropdown } from '../../Dropdown';

const currencyOptions = currencies.map((currency) => {
  const { code, name } = currency;
  return { label: `${code} - ${name}`, value: code };
});

interface CurrencyFieldProps {
  placeholder?: string;
  label: string;
  value: { label: string; value: string } | null;
  onChange: (newValue: CurrencyFieldProps['value']) => void;
  containerProps?: {
    className?: string;
    style?: CSSProperties;
  };
}

const CurrencyField = (props: CurrencyFieldProps) => {
  const { containerProps, label, onChange, value } = props;
  const { className, style } = containerProps ?? {};

  return (
    <div className={className} style={style}>
      <label
        htmlFor={label}
        className='inline-block mb-1 text-sm font-semibold text-black-primary'>
        {label}
      </label>
      <Dropdown
        searchable
        id={label}
        options={currencyOptions}
        value={value}
        onChange={onChange}
        placeHolder={`Select ${label}`}
      />
    </div>
  );
};

export default CurrencyField;
