import { FC } from 'react';
import { Dropdown } from '../../Dropdown';
import { settlementEntryTypeOptions } from '../constants';
import { Option } from '../interfaces/CommonFields';

interface SettlementEntryTypeFieldProps {
  value: Option | null;
  setValue: (newValue: SettlementEntryTypeFieldProps['value']) => void;
}

const SettlementEntryType: FC<SettlementEntryTypeFieldProps> = (props) => {
  const { setValue, value } = props;

  return (
    <div className='mt-6 form-input'>
      <div className='mb-1'>
        <label
          htmlFor='status'
          className='text-sm font-semibold text-black-primary'>
          Settlement Entry Type
        </label>
      </div>

      <Dropdown
        id='settlement_entry_type'
        options={settlementEntryTypeOptions}
        value={value}
        onChange={setValue}
      />
    </div>
  );
};

export default SettlementEntryType;
