import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Alert, Button, InputField } from 'nimbbl-quark';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsCheckCircleFill } from 'react-icons/bs';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { FiX } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import logo from './../../images/logo.svg';
import LoginBanner from './../../images/loginBanner.png';
import constants from '../../constants';
import { RESETPASSUCESS } from '../../constants/routes';
import { ISetNewPassFormData, ISetNewPassRes } from '../../interfaces/Login';
import './Styles.css';

const getToken = () => {
  const url_string = window.location.href;
  const url = new URL(url_string);
  const token = url.searchParams.get('token');

  return token;
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .test({
      name: 'special character test',
      message: 'Password can only contain !#%&@ as special characters',
      test: (value) => {
        if (!value) return false;
        const specialCharacterRegex = /\W/g;

        if (specialCharacterRegex.test(value)) {
          const containsOtherSpecialCharacters = (
            value.match(specialCharacterRegex) ?? []
          )
            .map((item) => /^(?=.*[!#%&@])/.test(item))
            .includes(false);

          return !containsOtherSpecialCharacters;
        }

        return !specialCharacterRegex.test(value);
      },
    }),
  // .min(8, 'Minimum 8 characters')
  // .matches(/^(?=.*[a-z])/, 'Small letter')
  // .matches(/^(?=.*\d)/, 'Number')
  // .matches(/^(?=.*[A-Z])/, 'Capital letter')
  // .matches(/^(?=.*[!@#%&])/, 'Symbol'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

const validatePassword = (password: string, isFocused = false) => {
  const result = {
    small: false,
    number: false,
    capital: false,
    symbol: false,
    minLength: false,
  };

  if (!password && !isFocused) {
    result.small = true;
    result.number = true;
    result.capital = true;
    result.symbol = true;
    result.minLength = true;
  }

  if (/^(?=.*[a-z])/.test(password)) result.small = true;
  if (/^(?=.*\d)/.test(password)) result.number = true;
  if (/^(?=.*[A-Z])/.test(password)) result.capital = true;
  if (/^(?=.*[!#%&@])/.test(password)) result.symbol = true;
  if (password.length >= 8) result.minLength = true;

  return result;
};

interface ValidationItemProps {
  label: string;
  checked: boolean;
}

const ValidationItem: FC<ValidationItemProps> = (props) => {
  const { label, checked = false } = props;

  return (
    <div
      style={{ ...(checked && { color: '#219653' }) }}
      className='flex items-center validation-item'>
      <BsCheckCircleFill className='mr-4' />
      <span>{label}</span>
    </div>
  );
};

class PasswordError {
  capital = true;
  minLength = true;
  number = true;
  small = true;
  symbol = true;
}

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState<any>('');
  const [alertClose, setAlertClose] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<PasswordError>(
    new PasswordError()
  );
  const {
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm<ISetNewPassFormData>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { password: '', confirmPassword: '' },
  });

  const handleSetPass = async (data: ISetNewPassFormData) => {
    try {
      const res = await axios.post<ISetNewPassRes>(
        `${constants.API.UPDATE_PASSWORD_API}`,
        {
          params: {
            password: data.password,
            token: getToken(),
          },
        }
      );

      if (res.data.result?.error?.message) {
        setAlertClose(true);
        // console.log(res.data.result.error.message)
        setErrorData(res.data.result?.error?.message);
      }

      if (res.data.result.result?.message) {
        navigate(RESETPASSUCESS);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAlert = () => {
    setAlertClose(false);
  };

  useEffect(() => {
    if (alertClose) {
      setTimeout(() => {
        handleAlert();
      }, 5000);
    }
  }, [alertClose]);

  console.log('form error', errors);
  const { password } = errors;

  const handlePasswordChange = (isFocused = false) =>
    setPasswordError(validatePassword(watch('password'), isFocused));

  const { capital, minLength, number, small, symbol } = passwordError;

  const showPasswordError =
    !password && (!small || !minLength || !number || !capital || !symbol);

  return (
    <div className='flex justify-between h-screen'>
      <div className='alertPosition'>
        {errorData && alertClose && (
          <Alert
            variant={'danger'}
            bannerWithCta={false}
            description={errorData}
            size='md'
            icon={<IoAlertCircleOutline />}
            closeIcon={<FiX />}
            closable={true}
            onClose={() => handleAlert()}></Alert>
        )}
      </div>
      <div className='flex flex-col items-center justify-between w-1/2'>
        <div
          style={{ marginTop: -24 }}
          className='flex flex-col justify-center grow max-w-[360px]'>
          <img
            style={{ width: 115, height: 75 }}
            src={logo}
            alt='logo'
            className='mb-16'
          />
          <h3 className='flex mb-1 text-2xl font-bold text-left justify-items-start'>
            Set New Password
          </h3>
          <p className='mb-6 text-sm text-secondary-10'>
            Your New Password must be different to previously used passwords.
          </p>

          <form
            className='login-form'
            onSubmit={handleSubmit(handleSetPass)}
            noValidate>
            <div className='relative mb-4 '>
              <InputField
                type='password'
                id='password'
                attributes={{
                  ...register('password', {
                    onChange: () => {
                      handlePasswordChange();
                      trigger('password');
                    },
                    onBlur: () => handlePasswordChange(),
                  }),
                  onFocus: () => handlePasswordChange(true),
                  style: {
                    display: 'block',
                    width: '360px',
                  },
                }}
                caption={errors.password?.message}
                captionType='error'
                label='Enter New Password'
              />

              {showPasswordError && (
                <div className='passwordHint'>
                  <ValidationItem label='Small Letter' checked={small} />
                  <ValidationItem label='Number' checked={number} />
                  <ValidationItem label='Capital Letter' checked={capital} />
                  <ValidationItem
                    label='Special Characters (!#%&@)'
                    checked={symbol}
                  />
                  <ValidationItem
                    label='Minimum 8 characters'
                    checked={minLength}
                  />
                </div>
              )}
            </div>

            <InputField
              type='password'
              id='confirm-password'
              attributes={{
                ...register('confirmPassword', {
                  onChange: () => trigger('confirmPassword'),
                }),
                style: { display: 'block', width: '360px' },
              }}
              caption={errors.confirmPassword?.message}
              captionType='error'
              label='Confirm Password'
            />
            <Button
              text='Reset Password'
              type='submit'
              attributes={{
                style: {
                  width: '160px',
                  marginTop: '20px',
                  background: '#045CE0',
                },
              }}
            />
          </form>
        </div>
        <div className='w-full mb-2 text-center text-tertiary-10'>
          <span className='mr-4'>@Nimbbl</span>
          <Link className='mr-4' to='/contact'>
            Contact
          </Link>
          <Link className='mr-4' to='/contact'>
            Privacy Terms
          </Link>
        </div>
      </div>
      <div
        style={{
          width: '50%',
          maxHeight: '100%',
          overflowY: 'hidden',
        }}>
        <img
          style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
          src={LoginBanner}
          alt='banner'
        />
      </div>
    </div>
  );
};

export default ResetPassword;
