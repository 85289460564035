export class ApiKeyResponse {
  access_key = '';
  access_secret = '';
  last_accessed_on = false;
  create_date = '';
  sub_merchant_id: {
    is_sandbox: boolean;
    description: string;
    sub_merchant_id: string;
  } = {
    is_sandbox: true,
    description: '',
    sub_merchant_id: '',
  };
}

export class MetaResponse {
  per_page = 0;
  prev: number | null = null;
  page = 0;
  next: number | null = 0;
  total_pages = 0;
  total_records = 0;
}

class ResponseError {
  message: string = '';
}

export class ResponseResult<T> {
  error?: ResponseError = new ResponseError();
  result?: T[] = [];
  meta?: MetaResponse = new MetaResponse();
}

export class ApiResponse {
  jsonrpc: string = '';
  id: number | null = null;
  result?: ResponseResult<ApiKeyResponse> = new ResponseResult();
}
