import { Button, ModalV2 } from 'nimbbl-quark';
import { MdClose } from 'react-icons/md';

import FailureIcon from '../../../images/Failure Icon.svg';
import SuccessIcon from '../../../images/Success Icon.svg';
import { FiRotateCcw } from 'react-icons/fi';
import { FC, useEffect } from 'react';

export interface BulkRefundResultInterface {
  fileName: string;
  message: string;
  subMerchant: string;
  open: boolean;
  onClose: () => void;
  handleRetry?: () => void;
}

export const BulkRefundSuccess: FC<BulkRefundResultInterface> = (props) => {
  const { fileName, message, subMerchant, open, onClose } = props;

  useEffect(() => {
    if (open) {
      const timerId = setTimeout(() => {
        clearTimeout(timerId);
        onClose();
      }, 5000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [open]);

  return (
    <ModalV2
      isShown={open}
      isBackdrop
      position='center'
      size='sm'
      style={{ maxWidth: '480px' }}
      hide={onClose}
      modalContent={
        <>
          <header className='flex items-start justify-between mb-6'>
            <div>
              <h1 className='text-lg font-semibold text-black'>
                Create Bulk Refund
              </h1>
              <p className='text-xs text-[#6C7F9A]'>
                Your bulk refund order is created.
              </p>
            </div>
            <button onClick={onClose}>
              <MdClose size={24} color='black' />
            </button>
          </header>

          <div className='flex flex-col items-center justify-center'>
            <img src={SuccessIcon} alt='Success Icon' />
            <p className='text-base'>File {fileName} successfully uploaded!</p>
            {subMerchant && <p className='text-base'>for {subMerchant}</p>}
            {message && <p className=''>{message}</p>}
          </div>
        </>
      }
    />
  );
};

export const BulkRefundFailure: FC<BulkRefundResultInterface> = (props) => {
  const { fileName, message, open, onClose, handleRetry } = props;

  return (
    <ModalV2
      isShown={open}
      isBackdrop
      position='center'
      size='sm'
      style={{ maxWidth: '480px' }}
      hide={onClose}
      modalContent={
        <>
          <header className='flex items-start justify-between mb-6'>
            <div>
              <h1 className='text-lg font-semibold text-black'>
                Create Bulk Refund
              </h1>
            </div>
            <button onClick={onClose}>
              <MdClose size={24} color='black' />
            </button>
          </header>

          <div className='flex flex-col items-center justify-center'>
            <img src={FailureIcon} alt='Success Icon' />
            <p className='text-base'>File {fileName} is not uploaded!</p>
            {message && <p className='text-[#D83233]'>{message}</p>}

            <Button
              IconComponent={RetryIcon}
              iconPosition='right'
              classNames='mt-3'
              style={{ marginBottom: -16, paddingLeft: 24, paddingRight: 24 }}
              size='sm'
              text='Retry'
              attributes={{
                onClick: handleRetry,
              }}
            />
          </div>
        </>
      }
    />
  );
};

const RetryIcon = () => <FiRotateCcw style={{ marginLeft: 12 }} size={16} />;
