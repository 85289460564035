export const SettlementModel = 'nimbbl.psp_settlement_summary';

export const SettlementFields = [
  // settlement details
  { name: 'settlement_date', label: 'Settlement Date', store: true },
  { name: 'settlement_id', label: 'Settlement ID', store: true },
  { name: 'utr_ref_no', label: 'UTR', store: true },
  { name: 'sub_merchant_id', label: 'Submerchant', store: true },
  // {
  //   name: 'sub_merchant_description',
  //   label: 'Submerchant',
  //   store: true,
  // },
  { name: 'payment_partner', label: 'Payment Partner', store: true },
  { name: 'settlement_currency', label: 'Settlement Currency', store: true },
  { name: 'settled_amount', label: 'Settlement Amount', store: true },
  { name: 'txn_count', label: 'No of Entries', store: true },
  // EOF

  // { name: 'credit_txn_count', label: 'No of credit entries', store: true },
  // { name: 'credit_amount', label: 'Credit amount', store: true },
  // { name: 'debit_txn_count', label: 'No of debit entries', store: true },
  // { name: 'debit_amount', label: 'Debit amount', store: true },
  // {
  //   name: 'psp_settlement_log_id',
  //   label: 'PSP Settlement Log Id',
  //   store: true,
  // },
  // { name: 'total_txn_count', label: 'No of total entries', store: true },
];
