import axios from 'axios';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { SUB_PAYMENT_MODE } from '../../../constants/api';
import { SummaryViewType } from '../AnalyticsHome';
import {
  AnalyticsResponse,
  SubPaymentModeResponse,
} from '../AnalyticsResponse';
import { formatData, isLastLevel, RawData } from '../sharedUtil';
import { PaymentModeUtil } from './PaymentModeUtil';
import FilterAnalyticUtils from '../../FilterPopup/utils/FilterAnalyticUtils';

interface PaymentModeHookProps {
  filters: {
    [key: string]: any;
    currency: string;
    summaryView: SummaryViewType;
  };
  paymentModes: AnalyticsResponse['payment_mode'];
}

export const usePaymentMode = (props: PaymentModeHookProps) => {
  const { filters, paymentModes } = props;
  const [loading, setLoading] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');
  const [subMethods, setSubMethods] = useState<Record<string, RawData[]>>({});

  const fetchSubMethods = useMemo(
    () =>
      debounce(async () => {
        console.log('fetching Data');
        setLoading(true);

        const url = `${SUB_PAYMENT_MODE}?session_id=${window.localStorage.getItem(
          'nimbbl_session_id'
        )}`;

        if (selectedCell.length === 0) {
          console.warn('empty payment mode');
          setLoading(false);
          return;
        }

        const { currency, summaryView, ...restFilters } = filters;
        const { filters: appliedFilters } = FilterAnalyticUtils.generateParams(
          restFilters as any
        );

        const body = { ...appliedFilters, payment_mode: selectedCell };

        const response = await axios.post<SubPaymentModeResponse>(url, body);
        const data = PaymentModeUtil.getSubModes(
          response.data.result,
          selectedCell
        );
        setSubMethods(data);

        setLoading(false);
      }, 300),
    [filters, selectedCell]
  );

  useEffect(() => {
    return () => {
      fetchSubMethods.cancel();
    };
  }, [fetchSubMethods]);

  const paymentData = useMemo(() => {
    const data = PaymentModeUtil.getAllModes(paymentModes, selectedCell);
    if (data.length > 0) {
      return formatData(data, filters);
    }

    if (subMethods[selectedCell]) {
      return formatData(subMethods[selectedCell], filters);
    }

    fetchSubMethods();
    return [];
  }, [filters, paymentModes, selectedCell, subMethods, fetchSubMethods]);

  const getOptions = () => {
    const newOption = PaymentModeUtil.getOptions(
      paymentData,
      selectedCell.length === 0
    );
    if (newOption.series) {
      newOption.series[0].events = {
        click: function (e) {
          // @ts-ignore
          const id = e.point.id;
          if (isLastLevel(id)) return;
          setSelectedCell(id);
        },
      };
    }
    return newOption;
  };

  return {
    paymentData,
    options: getOptions(),
    loading,
    setLoading,
    selectedCell,
    setSelectedCell,
  };
};
