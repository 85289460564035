import Sidebar from '../Sidebar/Sidebar';
import { useState, useEffect, useRef } from 'react';
import {
  Collapsible,
  CollapsibleButton,
  Drawer,
  KeyValue,
  Table,
  TableColumnType,
} from 'nimbbl-quark';
import { FiInfo, FiExternalLink } from 'react-icons/fi';
import { BsCheck2Circle } from 'react-icons/bs';
import { RiFileCopy2Line } from 'react-icons/ri';
import {
  APIResponse,
  OrderRes,
  SettlementTable,
} from '../../interfaces/ApiResponse';
import { IStateData } from '../../interfaces/StateData';
import axios from 'axios';
import {
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';

import * as api from '../../Apis';
import FilterPopup from '../FilterPopup/FilterPopup';
import constants from '../../constants';

import FilterSection from '../FilterSection/FilterSection';
import { formateDateToDDMonYYYY } from '../../Apis';
import { useCommonTracker, useCsvExport, useCurrentSession } from '../../Hooks';
import HeaderComponent from '../Header/Header';
import { PriceUtil } from '../../Utils';
import { BiRupee } from 'react-icons/bi';
import { DrawerTableRow, TableDivider } from '../Orders/Orders';
import { HandlePaymentPartner } from '../../Utils/PaymentPartnerHandling';
import useFilterSettlements, {
  defaultSettlementFilters,
} from './useFilterSettlements';
import FilterSection2 from '../FilterSection/FilterSection2';
import isEqual from 'lodash.isequal';
import FilterSettlementUtils from '../FilterPopup/utils/FilterSettlementUtils';
import FilterSettlements from '../FilterPopup/FilterSettlements';
import FieldNames from '../../constants/FieldNames';

class SingleSettlement {
  'txn_count' = 0;
  'credit_amount' = 0;
  'debit_amount' = 0;
  'credit_txn_count' = 0;
  'debit_txn_count' = 0;
  'settlement_date' = '';
  'settlement_id' = '';
  'settlement_currency': { name: string } = {
    name: '',
  };
  'payment_partner' = '';
  'total_txn_count' = 0;
  'settled_amount' = 0;
  'utr_ref_no' = '';
  'sub_merchant_id': {
    id: number;
    display_name: string;
    sub_merchant_id: string;
  } = {
    id: 0,
    display_name: '',
    sub_merchant_id: '',
  };
}

const rowsPerPage = 60;

const Settlements = () => {
  const { trackItemClicked, trackPageRendered, trackRowSelected } =
    useCommonTracker();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [singleSettlementData, setSingleSettlementData] =
    useState<SingleSettlement>(new SingleSettlement());
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [isDebitModalShown, setIsDebitModalShown] = useState<boolean>(false);
  const [creditData, setCreditData] = useState<any>({});
  const [debitData, setDebitData] = useState<any>({});
  const [csvData, setCsvData] = useState<any>([]);
  const [copyText, setCopyText] = useState<boolean>(false);
  const [collapsibleDataLoader, setCollapsibleDataLoader] =
    useState<boolean>(false);
  // Filter related stuffs
  const [animatedRight, setAnimateRight] = useState<boolean>(false);
  const [animatedRightCredit, setAnimateRightCredit] = useState<boolean>(false);
  const [animatedRightDebit, setAnimateRightDebit] = useState<boolean>(false);
  // filter v2
  const {
    filters,
    form,
    loading,
    metaData,
    settlements,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    removeFilter,
  } = useFilterSettlements();

  const { checkSessionError, hasSession, setSessionExpired } =
    useCurrentSession();
  const { exportAll, loading: csvLoading } = useCsvExport(
    'Settlement',
    form.watch()
  );

  useEffect(() => {
    handleFormSubmit(defaultSettlementFilters);
  }, []);

  useEffect(() => {
    trackPageRendered('Settlement', {
      ...filters,
      ...metaData,
    });

    return () => {
      trackPageRendered.cancel();
    };
  }, [filters, metaData]);

  useEffect(() => {
    if (animatedRightCredit) setIsModalShown(true);
  }, [animatedRightCredit]);

  useEffect(() => {
    if (animatedRightDebit) setIsDebitModalShown(true);
  }, [animatedRightDebit]);

  const getSingleSettlement = async (id: string) => {
    setCollapsibleDataLoader(true);
    try {
      const token = window.localStorage.getItem('nimbbl_session_id');

      if (!hasSession()) {
        return setSessionExpired();
      }

      const res = await axios.post<APIResponse<SingleSettlement>>(
        `${constants.API.SETTLEMENT_SUMMARY_API}?session_id=${token}`,
        {
          params: {
            // filter: `[[\"partner_settement_id\",\"=\",\"${id}\"]]`,
            filter: `[["id","=","${id}"]]`,
            // query: '{*}',
            query:
              '{txn_count,credit_amount,debit_amount,credit_txn_count,debit_txn_count,settlement_date,settlement_id,settlement_currency{name},payment_partner,total_txn_count,settled_amount,utr_ref_no,sub_merchant_id{id, display_name,sub_merchant_id}}',
          },
        }
      );

      if (checkSessionError(res)) {
        return;
      }

      if (res.data) {
        setSingleSettlementData(res.data.result.result[0]);
        setCollapsibleDataLoader(false);
      } else {
        setSingleSettlementData(new SingleSettlement());
        setCollapsibleDataLoader(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCreditData = async (id: number) => {
    try {
      const token = window.localStorage.getItem('nimbbl_session_id');

      if (!hasSession()) {
        return setSessionExpired();
      }

      const res = await axios.post<APIResponse<OrderRes>>(
        `${constants.API.SETTLEMENT_API}?session_id=${token}`,
        {
          params: {
            filter: `[[\"partner_settement_id\",\"=\",\"${id}\"],[\"transaction_type\",\"=\",[\"payment\"]]]`,
            query:
              '{transaction_id{transaction_id, status},create_date,total_amount,fee_amount,tax_amount, settled_amount}',
            sort_by: 'desc',
            type: 'csv',
          },
        }
      );

      if (checkSessionError(res)) {
        return;
      }

      if (res.data) {
        console.log(res.data);
        setCreditData(res.data.result.result);
      } else {
        setCreditData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDebitData = async (id: number) => {
    try {
      const token = window.localStorage.getItem('nimbbl_session_id');

      if (!hasSession()) {
        return setSessionExpired();
      }

      const res = await axios.post<APIResponse<OrderRes>>(
        `${constants.API.SETTLEMENT_API}?session_id=${token}`,
        {
          params: {
            filter: `[[\"partner_settement_id\",\"=\",\"${id}\"],[\"transaction_type\",\"in\",[\"full-refund\",\"partial-refund\"]]]`,
            query:
              '{transaction_id{transaction_id,status, refund_amount},create_date,total_amount,fee_amount,tax_amount}',
            sort_by: 'desc',
            type: 'csv',
          },
        }
      );
      if (checkSessionError(res)) {
        return;
      }

      if (res.data) {
        console.log(res.data);
        setDebitData(res.data.result.result);
      } else {
        setDebitData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedRow = (data: [], currentPage: number) => {
    const sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];

    setCsvData(sliceData);
  };

  const getSelectedExport = () => {
    const settlementIds = csvData?.map((item: any) => item.settlement_id);

    exportAll(settlementIds, 'settlement_id');
  };

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [isShown]);

  useEffect(() => {
    if (!animatedRight) setIsShown(true);
  }, [animatedRight]);

  const onClose = () => {
    // setIsShown(false);
    setAnimateRight(false);
    // setIsModalShown(false);
    setAnimateRightCredit(false);
    // setIsDebitModalShown(false);
    setAnimateRightDebit(false);
  };

  const getSettlementLink = (
    link: string,
    dateString: string
    // sub: { id: string; display_name: string }
  ) => {
    window.localStorage.setItem('psp_date', dateString);
    window.open(
      // &sub_name=${sub.display_name}&subid=${sub.id}
      `/settlements-details?psp_id=${link}`,
      '_blank'
    );
  };

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [isShown]);

  const renderRowData = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowData: SettlementTable
  ) => {
    setAnimateRight(true);
    trackItemClicked('Settlement', {
      settlement_id: rowData.settlement_id,
    });
    document.querySelector('tr.selected-row')?.classList.remove('selected-row');
    e.currentTarget.closest('tr')?.classList.add('selected-row');
    await getSingleSettlement(rowData.id);
  };

  const columns: TableColumnType<SettlementTable>[] = [
    {
      title: 'psp settlement id',
      field: 'settlement_id',
      cellStyle: {
        color: '#0352C9',
        // fontWeight: 'bold',
        textDecoration: 'none',
      },
      sorting: false,
      render: (rowData) => (
        <a
          style={{ cursor: 'pointer' }}
          onClick={(e) => renderRowData(e, rowData)}
          target='_blank'
          rel='noreferrer'>
          {rowData.settlement_id}
        </a>
      ),
    },
    {
      title: 'settlement date',
      field: 'settlement_date',
      sorting: false,
      render: (rowData) => (
        <span style={{ width: '110px' }}>
          {formateDateToDDMonYYYY(rowData.settlement_date)}
        </span>
      ),
    },
    {
      title: 'settlement amount',
      field: 'settled_amount',
      sorting: false,
      render: (rowData) =>
        PriceUtil.formatCurrency(
          rowData?.settled_amount || 0,
          rowData?.settlement_currency.name as any
        ),
    },
    {
      title: 'NUMBER OF ENTRIES',
      field: 'txn_count',
      sorting: false,
      render: (rowData) =>
        rowData.txn_count && rowData.txn_count.toLocaleString(),
    },
    {
      title: 'Submerchant name',
      field: 'sub_merchant_id.description',
      sorting: false,
      render: (rowData) => rowData.sub_merchant_id.description,
    },
    {
      title: 'PSP',
      field: 'payment_partner',
      sorting: false,
      render: (rowData) =>
        HandlePaymentPartner.getPaymentPartner(rowData.payment_partner),
    },
    {
      title: FieldNames.utr,
      field: 'utr_ref_no',
      sorting: false,
      render: (rowData) => rowData.utr_ref_no,
    },
  ];

  let settlementObj: any = {
    settlement_summary: [
      {
        settlement_date: new Date(
          singleSettlementData?.settlement_date
        ).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
      },
      {
        PSP: HandlePaymentPartner.getPaymentPartner(
          singleSettlementData?.payment_partner
        ),
      },
      {
        total_amount_settled: PriceUtil.formatCurrency(
          singleSettlementData?.settled_amount || 0,
          singleSettlementData.settlement_currency.name as any
        ),
      },
      { no_of_entries: singleSettlementData?.txn_count },
      { utr: singleSettlementData?.utr_ref_no },
      {
        submerchant_name: singleSettlementData?.sub_merchant_id?.display_name,
      },
      {
        submerchant_id: singleSettlementData?.sub_merchant_id?.sub_merchant_id,
      },
    ],
  };

  const toggle = () => {
    setAnimateRightCredit(true);
    setAnimateRightDebit(false);
    if (creditModal?.current) {
      creditModal.current.scrollTop = 0;
    }
  };

  // const toggleClose = () => {
  //   setAnimateRightCredit(false);
  // };

  // const toggleDebit = () => {
  //   setAnimateRightDebit(true);
  //   setAnimateRightCredit(false);
  //   if (debitModal?.current) {
  //     debitModal.current.scrollTop = 0;
  //   }
  //   // setIsModalShown(false);
  // };

  // const toggleDebitClose = () => {
  //   setAnimateRightDebit(false);
  // };

  // const getDebitButton = (data: string) => {
  //   return (
  //     <CollapsibleButton
  //       text={data}
  //       iconRight={<FiColumns />}
  //       iconLeft={<FiRepeat />}
  //       style={{ width: '100%', background: '#F1F4F9' }}
  //       iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
  //       iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
  //       onClick={toggleDebit}></CollapsibleButton>
  //   );
  // };

  // const getCreditButton = (data: string) => {
  //   return (
  //     <CollapsibleButton
  //       text={data}
  //       iconRight={<FiColumns />}
  //       iconLeft={<FiRepeat />}
  //       style={{ width: '100%', background: '#F1F4F9' }}
  //       iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
  //       iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
  //       onClick={toggle}></CollapsibleButton>
  //   );
  // };

  const creditModal: any = useRef(null);
  const debitModal: any = useRef(null);

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  const copyTextClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopyText(true);
  };

  const skeletonArray = Array(10).fill('');

  const tableRef = useRef<any>();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllExportExport = () => {
    exportAll();
    clearSelection();
  };

  return (
    <>
      <Sidebar />
      <div className='p-4 body-container ml-60'>
        <HeaderComponent
          loading={csvLoading}
          page='settlements'
          title={
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontSize: '19px' }}>Settlement Summary </span>
              <Tooltip
                title='Settlements are being displayed for payment and refund transaction types only'
                arrow>
                <IconButton size='small'>
                  <FiInfo />
                </IconButton>
              </Tooltip>
            </div>
          }
          total={metaData?.total_records}
          handleExport={getSelectedExport}
          handleExportAll={getAllExportExport}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          selectedRows={csvData.length}
        />
        <FilterSection2
          defaultFilters={defaultSettlementFilters}
          filters={filters}
          pageName='settlement'
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterSettlementUtils.generateParams(defaultSettlementFilters)
                .filterSection,
              filters
            )
          }
        />
        {loading ? (
          <div className='w-full'>
            <table className='w-full'>
              {skeletonArray.map((item, index) => (
                <TableRow key={index} style={{ width: '100%' }}>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}{' '}
            </table>
          </div>
        ) : (
          <div style={{ overflow: 'auto' }} className='mt-6'>
            <Table
              data={settlements}
              columns={columns}
              // filtering
              search={false}
              handleSearchChange={() => ({})}
              selection={true}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    'No records fetched for your search criteria, please change the filters and search again',
                },
              }}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(settlements.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total orders is still less than total records
                    settlements.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  getSelectedRow(rows, page + 1);
                  setCsvData(rows);
                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected('Settlements', {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: 20,
                draggable: false,
              }}
            />
          </div>
        )}
      </div>

      <div className='slide'>
        <Drawer
          position='right'
          hide={onClose}
          isShown={isShown}
          style={{ width: '400px' }}
          className={
            animatedRight
              ? 'animation drawerAnimationRightShow'
              : 'animation drawerAnimationRightHide'
          }
          headerElement={
            <div>
              <p className='text-sm font-bold'>
                {singleSettlementData?.sub_merchant_id?.display_name}
              </p>
              <p className='relative mt-2 text-sm' style={{ display: 'flex' }}>
                <span>
                  {singleSettlementData && singleSettlementData?.settlement_id}
                </span>
                {!copyText ? (
                  <span
                    className='ml-3 text-lg cursor-pointer text-blue-40'
                    title={`Copy ${FieldNames.settlementId}`}
                    onClick={() =>
                      copyTextClipboard(singleSettlementData?.settlement_id)
                    }>
                    {' '}
                    <RiFileCopy2Line />
                  </span>
                ) : (
                  <span
                    style={{
                      position: 'absolute',
                      width: 170,
                      top: 0,
                      right: '-115%',
                    }}
                    className='copyToClipboard'>
                    copied to clipboard
                  </span>
                )}
              </p>
            </div>
          }
          headerStyle={{
            background: '#FAFAFC',
            borderBottom: '1px solid #CCC',
          }}
          bodyElement={
            <>
              <div
                className='p-4 no-scroll icon-font'
                style={{
                  overflowY: 'scroll',
                  maxHeight: 'calc(100vh - 100px)',
                }}>
                <Collapsible
                  open={false}
                  title={
                    <h6 className='capitalize font-weight-bold'>
                      Settlement Summary
                    </h6>
                  }
                  iconColor='#0352C9'
                  isBorder={true}
                  icon={<BsCheck2Circle />}
                  style={{ borderBottom: '1px solid #ccc' }}>
                  <div className='p-4 rounded-lg bg-blue-10 icon-font'>
                    {collapsibleDataLoader ? (
                      <>
                        <div className='flex'>
                          <Skeleton
                            variant='rectangular'
                            width={'47%'}
                            height={40}
                            style={{ marginRight: '2%' }}
                          />
                          <Skeleton
                            variant='rectangular'
                            width={'47%'}
                            height={40}
                          />
                        </div>
                        <div className='flex mt-3'>
                          <Skeleton
                            variant='rectangular'
                            width={'47%'}
                            height={40}
                            style={{ marginRight: '2%' }}
                          />
                          <Skeleton
                            variant='rectangular'
                            width={'47%'}
                            height={40}
                          />
                        </div>
                      </>
                    ) : (
                      settlementObj.settlement_summary.map(
                        (item: any, index: number) => {
                          let pointer = index + 1;
                          // eslint-disable-next-line no-mixed-operators
                          let val: any =
                            (Object.values(item) && Object.values(item)[0]) ||
                            '';
                          let lab: any =
                            Object.keys(item) &&
                            Object.keys(item)[0].replace(/\_/g, ' ');
                          // if (
                          //   lab.includes('amount') ||
                          //   lab.includes('tax') ||
                          //   lab.includes('fees')
                          // ) {
                          //   val = val;
                          // }
                          if (val === '') return;
                          if (lab.includes('utr')) {
                            lab = lab.replace('utr', 'UTR');
                          }
                          if (lab.includes('id')) {
                            lab = lab.replace('id', 'ID');
                          }
                          let msg = [];
                          msg.push(
                            <KeyValue
                              className={
                                pointer % 2 === 0
                                  ? 'keyContainerWoMargin'
                                  : 'keyContainer'
                              }
                              label={lab}
                              labelStyle={{ textTransform: 'capitalize' }}
                              value={val}
                              valueStyle={{ overflowWrap: 'break-word' }}
                            />
                          );
                          if (pointer % 2 === 0) {
                            msg.push(<div className='clear-both'></div>);
                          }
                          if (
                            settlementObj.settlement_summary.length === pointer
                          ) {
                            msg.push(<div className='clear-both'></div>);
                          }

                          return msg;
                        }
                      )
                    )}
                  </div>
                </Collapsible>
                <Collapsible
                  title='Settlement Amount'
                  isBorder
                  icon={<BiRupee />}
                  style={{ borderBottom: '1px solid #ccc' }}
                  iconColor='#045CE1'>
                  <div className='p-4 rounded-lg bg-blue-10'>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <DrawerTableRow
                          label='Total Credit Amount'
                          info={`Number of Credit Entries: ${singleSettlementData?.credit_txn_count}`}
                          value={PriceUtil.formatCurrency(
                            singleSettlementData?.credit_amount,
                            singleSettlementData.settlement_currency.name as any
                          )}
                        />

                        <DrawerTableRow
                          label='Total Debit Amount'
                          info={`Number of Debit Entries: ${singleSettlementData?.debit_txn_count}`}
                          value={
                            // '- ' +
                            PriceUtil.formatCurrency(
                              singleSettlementData?.debit_amount,
                              singleSettlementData.settlement_currency
                                .name as any
                            )
                          }
                        />

                        <tr>
                          <td colSpan={2}>
                            <TableDivider />
                          </td>
                        </tr>

                        <DrawerTableRow
                          label='Settled Amount'
                          value={PriceUtil.formatCurrency(
                            singleSettlementData?.settled_amount,
                            singleSettlementData.settlement_currency.name as any
                          )}
                        />
                      </tbody>
                    </table>
                  </div>
                </Collapsible>

                <div className='mt-4'>
                  <CollapsibleButton
                    text='Settlement Details'
                    iconRight={<FiExternalLink />}
                    style={{ width: '100%', background: '#F1F4F9' }}
                    iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                    iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                    onClick={() => {
                      getSettlementLink(
                        singleSettlementData.settlement_id,
                        singleSettlementData.settlement_date
                        // singleSettlementData.sub_merchant_id
                      );
                    }}
                  />
                </div>
              </div>
            </>
          }
        />
      </div>

      <FilterSettlements
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />

      {/* <Drawer
        position='right'
        hide={toggleClose}
        isShown={isModalShown}
        style={{ width: '400px' }}
        className={animatedRightCredit ? "animation drawer2AnimationRightShow" : "animation drawerAnimationRightHide"}
        headerElement={
          <div>
            <h1 className="mb-2 text-base">Credit</h1>
        </div>
        }
        headerStyle= {{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <div className='p-4 icon-font no-scroll' ref={creditModal} style={{overflowY:'scroll', maxHeight: 'calc(100vh - 100px)'}}>
            {creditData && creditData.length > 0 && creditData.map((data: any) => {
              return <TransactionCard 
                        icon={<FiRotateCcw />} 
                        label={data?.transaction_id?.transaction_id} 
                        headerRigthContent={<span className='capitalize'><Badge variant='filled' type={data?.transaction_id?.status} text={`₹  ${formatAmount(data?.settled_amount)}`}></Badge></span>}
                        style={{background: '#FAFAFC', color:'#0F1A2A', fontSize: '14px'}} >
                          <>
                            <Divider></Divider>
                            <div className='mt-2'>
                            <KeyValue value={data.create_date.replace(',', ', ')} label={'Transaction Date'} styleContainer={{ width: '48%', float: 'left', marginBottom: '16px', marginRight: '2%', overflowWrap: 'break-word' }}></KeyValue>  
                            <KeyValue value={'Payment'} label={'Settlement Type'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word'}}></KeyValue> 
                            <div className='clear-both'></div> 

                            <KeyValue value={`₹ ${data.total_amount.toFixed(2)}`} label={'Transaction Amount'} styleContainer={{ width: '48%', float: 'left', marginBottom: '16px', marginRight: '2%', overflowWrap: 'break-word' }}></KeyValue>  
                            <KeyValue value={`₹ ${data.fee_amount.toFixed(2)}`} label={'PSP Fee'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word'}}></KeyValue> 
                            <div className='clear-both'></div> 
                            <KeyValue value={`₹ ${data.tax_amount.toFixed(2)}`} label={'Tax on PSP Fee'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word'}}></KeyValue> 
                            <div className='clear-both'></div> 
                            </div>
                            
                          </>
                      </TransactionCard>
            })

            }
        </div>
      } /> */}

      {/* <Drawer
        position='right'
        hide={toggleDebitClose}
        isShown={isDebitModalShown}
        style={{ width: '400px' }}
        className={animatedRightDebit ? "animation drawer2AnimationRightShow" : "animation drawerAnimationRightHide"}
        headerElement={
          <div>
            <h1 className="mb-2 text-base">Debit</h1>
        </div>
        }
        headerStyle= {{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <div className='p-4 icon-font no-scroll'  ref={debitModal} style={{overflowY:'scroll', maxHeight: 'calc(100vh - 100px)'}}>
            {debitData && debitData.length > 0 && debitData.map((data: any) => {
              console.log(data)
              return <TransactionCard 
                        icon={<FiRotateCcw />} 
                        label={data.transaction_id?.transaction_id} 
                        headerRigthContent={<span className='capitalize'><Badge variant='filled' type="Failed" text={`₹ ${formatAmount(data?.transaction_id?.refund_amount)}`}></Badge></span>}
                        style={{background: '#FAFAFC', color:'#0F1A2A', fontSize: '14px'}} >
                          <>
                            <Divider></Divider>
                            <div className='mt-2'>
                            <KeyValue value={data.create_date.replace(',', ', ')} label={'Transaction Date'} styleContainer={{ width: '48%', float: 'left', marginBottom: '16px', marginRight: '2%', overflowWrap: 'break-word' }}></KeyValue>  
                            <KeyValue value={'refund'} label={'Settlement Type'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word', textTransform: 'capitalize'}}></KeyValue> 
                            <div className='clear-both'></div> 

                            <KeyValue value={`₹ ${data.total_amount.toFixed(2)}`} label={'Refund Amount'} styleContainer={{ width: '48%', float: 'left', marginBottom: '16px', marginRight: '2%', overflowWrap: 'break-word' }}></KeyValue>  
                            <KeyValue value={`₹ ${data.fee_amount.toFixed(2)}`} label={'PSP Fee'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word'}}></KeyValue>  
                            <div className='clear-both'></div> 
                            <KeyValue value={`₹ ${data.tax_amount.toFixed(2)}`} label={'Tax on PSP Fee'} styleContainer={{ width: '50%', float: 'left', marginBottom: '16px', overflowWrap: 'break-word'}}></KeyValue> 
                            <div className='clear-both'></div> 
                            </div>
                            
                          </>
                      </TransactionCard>
            })

            }
        </div>
      } /> */}
    </>
  );
};

export default Settlements;
