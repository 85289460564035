import {
  Autocomplete,
  AutocompleteCloseReason,
  Box,
  ButtonBase,
  ClickAwayListener,
  InputBase,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import React, { useState } from 'react';
import { RiArrowDownSLine, RiSearchLine } from 'react-icons/ri';
import { Option } from '.';
import { PopperContainer } from './StyledComponents';
import './Dropdown.css';

const PopperComponent = (props: any) => {
  const { disablePortal, anchorEl, open, ...others } = props;

  return <div {...others} />;
};

interface ComboBoxProps<T extends Option> {
  id: string;
  placeHolder?: string;
  options: T[];
  value: T | null;
  onChange: (value: T) => void;
  onInputChange?: (value: string) => void;
}

export const ComboBox = <T extends Option>(props: ComboBoxProps<T>) => {
  const { value, id, onChange, options, placeHolder, onInputChange } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleClose = () => {
    if (anchorElement) anchorElement.focus();
    setAnchorElement(null);
  };

  return (
    <>
      <ButtonBase
        id={id}
        disableRipple
        onClick={handleOpen}
        // sx={ValueContainerStyles}
        className='Value-Container'>
        <span
          style={{
            color: value?.label ? '#000' : '#6b7280',
          }}>
          {value?.label ?? placeHolder}
        </span>
        <RiArrowDownSLine
          color='#6C7F9A'
          size={20}
          style={{ marginLeft: 'auto' }}
        />
      </ButtonBase>
      <PopperContainer
        id='dropDown'
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: ({ placement }: { placement: string }) =>
                placement === 'top' ? [0, 3] : [0, 2],
            },
          },
        ]}
        style={{ ...(anchorElement && { width: anchorElement.offsetWidth }) }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Autocomplete
            open
            id={`search ${id}`}
            disablePortal
            autoHighlight
            fullWidth
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            size='small'
            options={options}
            noOptionsText='No options available'
            onInputChange={(e, newValue) => {
              if (onInputChange) {
                onInputChange(newValue);
              }
            }}
            onChange={(_e, value) => {
              if (value && typeof value === 'object') {
                onChange(value);
              }
              handleClose();
            }}
            onClose={(
              _event: React.ChangeEvent<{}>,
              reason: AutocompleteCloseReason
            ) => {
              if (reason === 'escape') {
                handleClose();
              }
            }}
            // getOptionLabel={(option) =>
            //   typeof option === "string" ? option : option.label
            // }
            PopperComponent={PopperComponent}
            componentsProps={{
              clearIndicator: {
                disableRipple: true,
                disableFocusRipple: true,
                disableTouchRipple: true,
                size: 'small',
                className: 'Clear-Icon',
              },
              paper: {
                elevation: 0,
              },
              popper: {
                className: 'Popper',
                sx: {
                  position: 'static',
                  '& .MuiAutocomplete-listbox': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    '&::-webkit-scrollbar': {
                      width: 6,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'inherit',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.1)',
                      borderRadius: '4px',
                    },
                  },
                },
              },
            }}
            filterOptions={(options, { inputValue }) =>
              matchSorter(options, inputValue, {
                keys: ['label', 'value'],
              })
            }
            renderOption={(props, option) => (
              <Box
                component='li'
                {...props}
                key={option.value}
                sx={{
                  p: '8px !important',
                  ...(value?.value === option.value && {
                    backgroundColor: '#1976d21f !important',
                  }),
                }}>
                <span
                  style={{
                    fontSize: 14,
                    lineHeight: '20px',
                    fontWeight: 'normal',
                    marginLeft: 8,
                  }}>
                  {option.label}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <InputBase
                autoFocus
                fullWidth={params.fullWidth}
                size={params.size}
                id={params.id}
                disabled={params.disabled}
                {...params.InputProps}
                sx={{
                  borderBottom: '1px solid #CBD4E1',
                  px: 1,
                  backgroundColor: '#fff',
                }}
                className='dropdown'
                inputProps={{
                  ...params.inputProps,
                  className: 'Popper-InputField',
                }}
                startAdornment={
                  <RiSearchLine
                    size={20}
                    style={{ marginBottom: 4 }}
                    color='gray'
                  />
                }
                placeholder={'Search...'}
              />
            )}
          />
        </ClickAwayListener>
      </PopperContainer>
    </>
  );
};
