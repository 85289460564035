// default width for filter popup in pixels
export const defaultFilterWidth = 440;

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colourOptions: readonly ColourOption[] = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export const statusOptions = [
  { label: "All", value: "all" },
  { label: "New", value: "new" },
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
  { label: "Lapsed", value: "lapsed" },
];

export const transactionStatusOptions = [
  { label: "All", value: "all" },
  { label: "Failed", value: "failed" },
  { label: "Pending", value: "pending" },
  { label: "Succeeded", value: "succeeded" },
  { label: "Reversed", value: "reversed" },
  { label: "Reversing", value: "reversing" },
];

// currently only theses statuses are allowed on dashboard-charts api
export const analyticsStatusOptions = [
  { label: "All", value: "all", hide: true },
  { label: "Failed", value: "failed" },
  { label: "Pending", value: "pending" },
  { label: "Succeeded", value: "succeeded" },
];

export const refundStatusOptions = [
  { label: "All", value: "all" },
  { label: "Failed", value: "failed" },
  { label: "Pending", value: "pending" },
  { label: "Succeeded", value: "succeeded" },
];

export const bulkRefundStatusOptions = [
  { label: "All", value: "all" },
  { label: "Queued", value: "queued" },
  { label: "Processing", value: "processing" },
  { label: "Processed", value: "processed" },
  { label: "Failed", value: "failed" },
];

export const paymentLinkStatusOptions = [
  { label: "All", value: "all" },
  { label: "Created", value: "created" },
  { label: "Opened", value: "opened" },
  { label: "Sent", value: "sent" },
  { label: "Paid", value: "paid" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Expired", value: "expired" },
];

export const settlementEntryTypeOptions = [
  { label: "All", value: "all" },
  { label: "Credit", value: "Credit" },
  { label: "Debit", value: "Debit" },
];

export const daysOptions = [
  { label: "Today", value: 1 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 14 days", value: 14 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 3 months", value: 90 },
  { label: "Last 6 months", value: 180 },
  { label: "Last 12 months", value: 365 },
  { label: "Custom", value: 0 },
];

export const daysGraphOptions = [
  { label: "Last 7 days", value: "7days" },
  { label: "Last 15 days", value: "15days" },
  { label: "Last 30 days", value: "30days" },
  { label: "Last 60 days", value: "60days" },
  { label: "Last 3 months", value: "90days" },
  { label: "Last 4 months", value: "120days" },
  { label: "Last 6 months", value: "180days" },
  { label: "Last 12 months", value: "365days" },
  // { label: 'Last 18 months', value: '445days' },
  // { label: 'Last 24 months', value: '730days' },
];

export const transactionOptions = [
  { label: "All", value: "all", hide: true },
  { label: "Refund", value: "full-refund" },
  { label: "Partial Refund", value: "partial-refund" },
  { label: "Payment", value: "payment" },
];

export const paymentPartnerOptions = [
  { label: "All", value: "", hide: true },
  { label: "Cashfree", value: "Cashfree" },
  { label: "CC Avenue", value: "ccavenue" },
  { label: "Direct", value: "Direct" },
  { label: "Easebuzz", value: "Easebuzz" },
  { label: "Freecharge S2S", value: "FreechargeS2S" },
  { label: "HDFC MPGS", value: "HdfcMpgs" },
  { label: "Ingenico", value: "Ingenico" },
  { label: "Payglocal", value: "Payglocal" },
  { label: "PayU", value: "Payu" },
  { label: "PineLabs", value: "Pinelabs" },
  { label: "Razorpay", value: "Razorpay" },
  { label: "SPay", value: "SPay" },
  { label: "Paytm S2S", value: "PaytmS2S" },
  { label: "Billdesk", value: "Billdesk" },
  { label: "Paytm Router", value: "Paytm Router" },
  { label: "Bajaj Pay PG", value: "Bajaj Pay PG" },
  //   { label: 'Freecharge', value: 'Freecharge'},
];

export const paymentModeOptions = [
  { label: "All", value: "", hide: true },
  { label: "Credit Card", value: "Credit Card" },
  { label: "Debit Card", value: "Debit Card" },
  { label: "Prepaid Card", value: "Prepaid Card" },
  { label: "UPI", value: "UPI" },
  { label: "Net Banking", value: "Netbanking" },
  { label: "Wallet", value: "Wallet" },
  { label: "Ola Money", value: "Olamoney" },
  { label: "Simpl", value: "Simpl" },
  { label: "Lazypay", value: "Lazypay" },
  { label: "ICICI PayLater", value: "ICICI PayLater" },
  { label: "Cred", value: "Cred" },
  { label: "Google Pay", value: "Google Pay" },
  { label: "Freecharge", value: "Freecharge" },
  { label: "PayTm", value: "PayTM" },
];

export const walletOptions = [
  {
    value: "PayPal",
    label: "PayPal",
  },
  {
    value: "PhonePe Switch",
    label: "PhonePe Switch",
  },
  {
    value: "Billdesk",
    label: "Billdesk",
  },
  {
    value: "Airtel Money",
    label: "Airtel Money",
  },
  {
    value: "ItzCash",
    label: "ItzCash",
  },
  {
    value: "Yesbank",
    label: "Yesbank",
  },
  {
    value: "PayZapp",
    label: "PayZapp",
  },
  {
    value: "Freecharge",
    label: "Freecharge",
  },
  {
    value: "Olamoney",
    label: "Olamoney",
  },
  {
    value: "Jio Money",
    label: "Jio Money",
  },
  {
    value: "PhonePe",
    label: "PhonePe",
  },
  {
    value: "Amazon Pay",
    label: "Amazon Pay",
  },
  {
    value: "Paytm",
    label: "Paytm",
  },
  {
    value: "TWID",
    label: "TWID",
  },
  {
    value: "TPAY",
    label: "TPAY",
  },
  {
    value: "Mobikwik",
    label: "Mobikwik",
  },
  {
    value: "Citrus Wallet",
    label: "Citrus Wallet",
  },
  {
    value: "Amex easy click",
    label: "Amex easy click",
  },
  {
    value: "Paycash",
    label: "Paycash",
  },
  {
    value: "Citibank",
    label: "Citibank",
  },
  {
    value: "Oxigen",
    label: "Oxigen",
  },
];

export const netBankingOptions = [
  {
    value: "Yes Bank - Corporate Netbanking",
    label: "Yes Bank - Corporate Netbanking",
  },
  {
    value: "Yes Bank",
    label: "Yes Bank",
  },
  {
    value: "Vijaya Bank",
    label: "Vijaya Bank",
  },
  {
    value: "Varachha Co-operative Bank Limited",
    label: "Varachha Co-operative Bank Limited",
  },
  {
    value: "Utkarsh Small Finance Bank",
    label: "Utkarsh Small Finance Bank",
  },
  {
    value: "United Bank of India",
    label: "United Bank of India",
  },
  {
    value: "Union Bank of India",
    label: "Union Bank of India",
  },
  {
    value: "Union Bank of India - Corporate Netbanking",
    label: "Union Bank of India - Corporate Netbanking",
  },
  {
    value: "UCO Bank",
    label: "UCO Bank",
  },
  {
    value: "The Surat Peopleâ€™s Co-operative Bank Limited",
    label: "The Surat Peopleâ€™s Co-operative Bank Limited",
  },
  {
    value: "The Nainital Bank",
    label: "The Nainital Bank",
  },
  {
    value: "The Kalupur Commercial Co-Operative Bank",
    label: "The Kalupur Commercial Co-Operative Bank",
  },
  {
    value: "The Bharat Co-op. Bank Ltd",
    label: "The Bharat Co-op. Bank Ltd",
  },
  {
    value: "Thane Janata Sahakari Bank",
    label: "Thane Janata Sahakari Bank",
  },
  {
    value: "Tamilnadu State Apex Co-operative Bank",
    label: "Tamilnadu State Apex Co-operative Bank",
  },
  {
    value: "Tamilnadu Mercantile Bank",
    label: "Tamilnadu Mercantile Bank",
  },
  {
    value: "Tamil Nadu State Co-operative Bank",
    label: "Tamil Nadu State Co-operative Bank",
  },
  {
    value: "Syndicate Bank",
    label: "Syndicate Bank",
  },
  {
    value: "Suryoday Small Finance Bank",
    label: "Suryoday Small Finance Bank",
  },
  {
    value: "State Bank of Travancore",
    label: "State Bank of Travancore",
  },
  {
    value: "State Bank of Patiala",
    label: "State Bank of Patiala",
  },
  {
    value: "State Bank of Mysore",
    label: "State Bank of Mysore",
  },
  {
    value: "State Bank of India Corporate",
    label: "State Bank of India Corporate",
  },
  {
    value: "State Bank of India",
    label: "State Bank of India",
  },
  {
    value: "State Bank of Hyderabad",
    label: "State Bank of Hyderabad",
  },
  {
    value: "State Bank of Bikaner and Jaipur",
    label: "State Bank of Bikaner and Jaipur",
  },
  {
    value: "Standard Chartered Bank",
    label: "Standard Chartered Bank",
  },
  {
    value: "South Indian Bank",
    label: "South Indian Bank",
  },
  {
    value: "Shivalik Bank",
    label: "Shivalik Bank",
  },
  {
    value: "Shamrao Vithal Co-operative Bank",
    label: "Shamrao Vithal Co-operative Bank",
  },
  {
    value: "Shamrao Vithal Bank - Corporate Netbanking",
    label: "Shamrao Vithal Bank - Corporate Netbanking",
  },
  {
    value: "Saraswat Co-operative Bank",
    label: "Saraswat Co-operative Bank",
  },
  {
    value: "RBL Bank",
    label: "RBL Bank",
  },
  {
    value: "RBL Bank - Corporate Netbanking",
    label: "RBL Bank - Corporate Netbanking",
  },
  {
    value: "Punjab National Bank - Retail Netbanking",
    label: "Punjab National Bank - Retail Netbanking",
  },
  {
    value: "Punjab National Bank - Corporate Netbanking",
    label: "Punjab National Bank - Corporate Netbanking",
  },
  {
    value: "Punjab & Sind Bank",
    label: "Punjab & Sind Bank",
  },
  {
    value: "Punjab And Maharashtra Co-operative Bank limited",
    label: "Punjab And Maharashtra Co-operative Bank limited",
  },
  {
    value: "Oriental Bank Of Commerce",
    label: "Oriental Bank Of Commerce",
  },
  {
    value: "NSDL Payments Bank",
    label: "NSDL Payments Bank",
  },
  {
    value: "North East Small Finance Bank",
    label: "North East Small Finance Bank",
  },
  {
    value: "NKGSB Co-operative Bank",
    label: "NKGSB Co-operative Bank",
  },
  {
    value: "Mehsana Urban Bank",
    label: "Mehsana Urban Bank",
  },
  {
    value: "Lakshmi Vilas Bank - Retail Netbanking",
    label: "Lakshmi Vilas Bank - Retail Netbanking",
  },
  {
    value: "Lakshmi Vilas Bank - Corporate Netbanking",
    label: "Lakshmi Vilas Bank - Corporate Netbanking",
  },
  {
    value: "Kotak Mahindra Bank",
    label: "Kotak Mahindra Bank",
  },
  {
    value: "Karur Vysya Bank",
    label: "Karur Vysya Bank",
  },
  {
    value: "Karur Vysya - Corporate Netbanking",
    label: "Karur Vysya - Corporate Netbanking",
  },
  {
    value: "Karnataka Bank",
    label: "Karnataka Bank",
  },
  {
    value: "Kalyan Janata Sahakari Bank",
    label: "Kalyan Janata Sahakari Bank",
  },
  {
    value: "Janata Sahakari Bank Pune",
    label: "Janata Sahakari Bank Pune",
  },
  {
    value: "Jana Small Finance Bank",
    label: "Jana Small Finance Bank",
  },
  {
    value: "Jammu and Kashmir Bank",
    label: "Jammu and Kashmir Bank",
  },
  {
    value: "IndusInd Bank",
    label: "IndusInd Bank",
  },
  {
    value: "Indian Overseas Bank - Corporate Netbanking",
    label: "Indian Overseas Bank - Corporate Netbanking",
  },
  {
    value: "Indian Overseas Bank",
    label: "Indian Overseas Bank",
  },
  {
    value: "Indian Bank",
    label: "Indian Bank",
  },
  {
    value: "IDFC Bank",
    label: "IDFC Bank",
  },
  {
    value: "IDBI",
    label: "IDBI",
  },
  {
    value: "ICICI Corp. Netbanking",
    label: "ICICI Corp. Netbanking",
  },
  {
    value: "ICICI Bank",
    label: "ICICI Bank",
  },
  {
    value: "HSBC",
    label: "HSBC",
  },
  {
    value: "HDFC - Corporate Netbanking",
    label: "HDFC - Corporate Netbanking",
  },
  {
    value: "HDFC Bank",
    label: "HDFC Bank",
  },
  {
    value: "Gujarat State Co-operative Bank Limited",
    label: "Gujarat State Co-operative Bank Limited",
  },
  {
    value: "Fincare Small Finance Bank",
    label: "Fincare Small Finance Bank",
  },
  {
    value: "Federal Bank",
    label: "Federal Bank",
  },
  {
    value: "ESAF Small Finance Bank",
    label: "ESAF Small Finance Bank",
  },
  {
    value: "Equitas Small Finance Bank",
    label: "Equitas Small Finance Bank",
  },
  {
    value: "Dhanlaxmi Bank - Corporate Netbanking",
    label: "Dhanlaxmi Bank - Corporate Netbanking",
  },
  {
    value: "Dhanlaxmi Bank",
    label: "Dhanlaxmi Bank",
  },
  {
    value: "Deutsche Bank",
    label: "Deutsche Bank",
  },
  {
    value: "Dena Bank",
    label: "Dena Bank",
  },
  {
    value: "DCB Bank",
    label: "DCB Bank",
  },
  {
    value: "DCB Bank - Corporate Netbanking",
    label: "DCB Bank - Corporate Netbanking",
  },
  {
    value: "Development Bank of Singapore",
    label: "Development Bank of Singapore",
  },
  {
    value: "Cosmos Co-operative Bank",
    label: "Cosmos Co-operative Bank",
  },
  {
    value: "Corporation Bank",
    label: "Corporation Bank",
  },
  {
    value: "City Union Bank - Corporate Netbanking",
    label: "City Union Bank - Corporate Netbanking",
  },
  {
    value: "City Union Bank",
    label: "City Union Bank",
  },
  {
    value: "Axis Bank",
    label: "Axis Bank",
  },
];

export const subMerchantTypeOptions = [
  { value: "live", label: "Live" },
  { value: "sandbox", label: "SandBox" },
];
