import { MenuItem, Select as MuiSelect } from '@mui/material';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useMemo, CSSProperties } from 'react';
import { Option } from '.';

interface SelectProps<T extends Option> {
  id: string;
  placeHolder?: string;
  options: T[];
  value: T | null;
  onChange: (value: T) => void;
}

export const Select = <T extends Option>(props: SelectProps<T>) => {
  const { id, options, value, onChange, placeHolder } = props;

  const formattedOptions = useMemo(() => {
    const result: Record<string, Option> = {};
    options.forEach(({ label, value }) => (result[value] = { label, value }));
    return result;
  }, [options]);

  return (
    <MuiSelect
      labelId={id}
      id={id}
      fullWidth
      IconComponent={IconComponent}
      sx={{
        '& > div': {
          padding: 1.5,
        },
        '&:hover': {
          '& > fieldset': {
            border: '1px solid #2563eb !important',
          },
        },
        '&:focus-within': {
          '& > fieldset': {
            borderRadius: '4px',
          },
        },
        '& > fieldset': {
          border: '1px solid #033d94',
        },
      }}
      MenuProps={{
        disablePortal: true,
        disableScrollLock: true,
        sx: {
          marginTop: '1px',
        },
        MenuListProps: {
          sx: {
            py: 0.5,
            maxHeight: 300,
            overflowY: 'auto',

            '&::-webkit-scrollbar': {
              width: 6,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#CBD4E1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: '4px',
            },
          },
        },
        PaperProps: {
          elevation: 1,
          style: {
            maxWidth: 0,
            borderRadius: '4px',
          },
        },
      }}
      inputProps={{
        id,
      }}
      value={value?.value ?? undefined}
      onChange={(e) => onChange(formattedOptions[e.target.value] as T)}>
      {options.map(({ label, value }) => (
        <MenuItem
          key={value}
          value={value}
          style={{
            padding: '8px 12px',
            display: 'block',
            fontSize: 14,
            lineHeight: '20px',
          }}
          className='world'>
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

const IconComponent = () => (
  <RiArrowDownSLine size={20} style={iconComponentStyles} />
);

const iconComponentStyles: CSSProperties = {
  userSelect: 'none',
  display: 'inline-block',
  fill: 'currentColor',
  WebkitFlexShrink: 0,
  msFlexPositive: 0,
  WebkitTransition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  width: '1em',
  height: '1em',
  flexShrink: 0,
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  fontSize: '1.5rem',
  position: 'absolute',
  right: 7,
  top: 'calc(50% - .5em)',
  pointerEvents: 'none',
  color: 'rgba(0, 0, 0, 0.54)',
};
