export const BulkRefundModel = 'nimbbl.bulk_refund';

export const BulkRefundFields = [
  { name: 'create_date', label: 'Created At', store: true },
  { name: 'bulk_refund_id', label: 'Bulk Refund ID', store: true },
  { name: 'total_count', label: 'Total Count', store: true },
  { name: 'processed_time', label: 'Processed Time', store: true },
  { name: 'success_count', label: 'Success Count', store: true },
  { name: 'failed_count', label: 'Failed Count', store: true },
  { name: 'description', label: 'Description', store: true },
  { name: 'sub_merchant_id', label: 'Sub Merchant', store: true },
];
