interface TransactionDetailsType {
  transaction_id: string;
  payment_mode: string;
  create_date: string;
  order_id: { order_id: string };
  refund_amount: number;
  transaction_type: string;
  grand_total_amount: string;
  status: string;
}

interface ProductDetailsType {
  title: string;
  description: string;
  rate: number;
  quantity: number;
  total_amount: number;
  payment_link_id: string;
  image_url?: string;
  tax: number;
}

export class PaymentLinkResponse {
  id = 0;
  payer_first_name = '';
  payer_last_name = '';
  payer_mobile_number = '';
  payer_email_address = '';
  currency_id = { id: 0, name: '' };
  total_amount = 0;
  expires_at = '';
  create_date = '';
  partial_payment = true;
  late_payment_charges = 0;
  invoice_id = '';
  description = '';
  status = '';
  callback_url = '';
  notification_channel_email = true;
  notification_channel_sms = true;
  payment_link_url = '';
  payment_link_hash = '';
  sent_at = '';
  paid_amount = 0;
  pending_amount = 0;
  notification_scheduled_at: string | false = false;
  sub_merchant_id: {
    sub_merchant_id: string;
    display_name: string;
    id: number;
  } = {
    sub_merchant_id: '',
    display_name: '',
    id: 0,
  };
  terms_and_conditions: string | null = null;
  transaction_details: TransactionDetailsType[] = [];
  product_details: ProductDetailsType[] = [];
}

export class TableMetaResponse {
  next: number | null = null;
  page = 0;
  per_page = 0;
  prev: number | null = null;
  total_pages = 0;
  total_records = 0;
}
