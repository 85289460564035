import { object, string } from 'yup';

import {
  FilterCommonFields,
  MetaData,
  commonSchema,
  defaultCommonFilters,
} from '../../FilterPopup/interfaces/CommonFields';
import { useCurrentSession } from '../../../Hooks';
import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FilterBulkRefundUtils from '../../FilterPopup/utils/FilterBulkRefundUtils';

export type FilterBulkRefundFields = Pick<
  FilterCommonFields,
  | 'status'
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'bulkRefundId'
  | 'subMerchantId'
  | 'singleSubMerchantId'
>;

export const defaultBulkRefundFilters: FilterBulkRefundFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
};

const {
  status,
  startDate,
  endDate,
  duration,
  subMerchantId,
  singleSubMerchantId,
} = commonSchema;

const bulkRefundSchema = object().shape({
  status,
  startDate,
  endDate,
  duration,
  bulkRefundId: string(),
  subMerchantId,
  singleSubMerchantId,
});

export class BulkRefundTransaction {
  create_date = '';
  bulk_refund_id = '';
  status: 'queued' | 'processing' | 'processed' | 'failed' | 'created' =
    'queued';
  total_count = 0;
  success_count = 0;
  failed_count = 0;
  processed_time = '';
  report_file_url = '';
  uploaded_file_url = '';
  sub_merchant_id = {
    sub_merchant_id: '',
    description: '',
    id: 0,
  };
}

const useFilterBulkRefund = () => {
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(false);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [transactions, setTransactions] = useState<BulkRefundTransaction[]>([]);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterBulkRefundFields>({
    defaultValues: defaultBulkRefundFilters,
    resolver: yupResolver(bulkRefundSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { transactions } =
      (await fetchTransactions({ ...form.getValues(), page: page + 1 })) ?? {};

    setTransactions((prev) => [...prev, ...transactions]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterBulkRefundUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const fetchTransactions = useCallback(
    async (formData: FilterBulkRefundFields & { page?: number }) => {
      const { page, ...restFormData } = formData;
      const { filters, filterSection } = FilterBulkRefundUtils.generateParams(
        restFormData,
        page ? { page } : undefined,
        form.setValue
      );

      const response = await FilterBulkRefundUtils.getBulkRefundTransactions(
        filters
      );

      // check for user token
      if (checkSessionError(response)) return;

      const transactions = response.data?.result?.result ?? [];
      const metaData = response.data?.result?.meta ?? new MetaData();

      // const error = response.data?.result?.error?.message;
      // const rows = metaData?.total_records;
      // const pages = metaData?.total_pages;

      return { filterSection, transactions, metaData };
    },
    [checkSessionError]
  );

  const handleFormSubmit = useCallback(
    async (formData: FilterBulkRefundFields & { page?: number }) => {
      setLoading(true);
      const { filterSection, transactions, metaData } =
        (await fetchTransactions(formData)) ?? {};

      setTransactions(transactions);
      setMetaData(metaData);
      setFilters(filterSection);
      setLoading(false);
      setFilterModalShown(false);
    },
    [fetchTransactions]
  );

  return {
    filters,
    form,
    loading,
    transactions,
    page,
    metaData,
    isFilterModalShown,
    fetchNextPage,
    setFilterModalShown,
    handleFormSubmit,
    setLoading,
    removeFilter,
  };
};

export default useFilterBulkRefund;
