import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Button, Header } from "nimbbl-quark";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiDownload, FiFilter, FiPlus } from "react-icons/fi";
import { CircularProgress, Tooltip } from "@mui/material";
import { useTracker } from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";
import { Link, useLocation } from "react-router-dom";
import { BULKDREFUND } from "../../constants/routes";
import constants from "../../constants";

const exportLimit = 20000;
const exportErrorMessage =
  "Cannot export the file as the total number of rows to export is more than " +
  exportLimit.toLocaleString();

export const HeaderComponent = (data: any) => {
  const {
    loading,
    page = "",
    title = "",
    total = 0,
    type,
    selectedRows = 0,
  } = data;
  const { track } = useTracker();
  const location = useLocation();
  const { pathname } = location;

  const handleFaqs = () => {
    track({
      name: title + " " + TrackEventNames.helpClicked,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { menu_item_name: page },
    });

    window.open(
      "https://nimbbl.biz/frequently-asked-questions#onboardingChecklist",
      "_blank"
    );
  };

  const handleFilter = () => {
    track({
      name: title + " " + TrackEventNames.filterClicked,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { menu_item_name: page },
    });
    data.handleShowConfigFormModal();
  };

  const isRowSelected = selectedRows > 0;
  const isCountAboveLimit = total > exportLimit;
  const isExportDisabled = (isCountAboveLimit || loading) && !isRowSelected;
  const tooltipTitle = (
    <span className="text-sm font-normal">{exportErrorMessage}</span>
  );

  const isPaymentLink = pathname === constants.ROUTES.PAYMENTLINKS;

  return (
    <>
      <Header
        style={{}}
        leftHeaderContent={<>{data.title}</>}
        leftBodyContent={<></>}
        rightContent={
          <div className="flex div-right">
            {type !== "analytics" && (
              <Button
                variant="outline"
                IconComponent={AiOutlineQuestionCircle}
                iconPosition="center"
                classNames="mr-2 header-button"
                style={{ fontSize: "24px" }}
                attributes={{
                  onClick: () => handleFaqs(),
                }}
              />
            )}
            <Button
              IconComponent={FiFilter}
              iconPosition="center"
              variant="outline"
              classNames="mr-2 header-button"
              style={{ fontSize: "24px" }}
              attributes={{
                onClick: handleFilter,
              }}
            />
            {isPaymentLink && (
              <Link to="/payment-links/create">
                <Button
                  text="Create Payment Link"
                  iconPosition="left"
                  IconComponent={FiPlus}
                  size="lg"
                />
              </Link>
            )}

            {type !== "analytics" && !isPaymentLink && (
              <div
                className="text-white rounded-[6px] font-normal"
                style={{
                  // backgroundColor: '#fff',
                  border: "1px solid var(--color-primary-60)",
                  opacity: isExportDisabled ? 0.4 : 1,
                  position: "relative",
                  color: "var(--color-primary-60)",
                }}
              >
                <Menu
                  direction="bottom"
                  align="end"
                  offsetY={8}
                  offsetX={36}
                  menuStyle={{
                    marginTop: "18px",
                    marginLeft: "-33px",
                    fontSize: "14px",
                    padding: "-2px",
                  }}
                  menuButton={
                    <Tooltip
                      title={
                        isCountAboveLimit && !isRowSelected ? tooltipTitle : ""
                      }
                      arrow
                    >
                      <MenuButton
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: 12,
                          paddingRight: 12,
                        }}
                        disabled={isExportDisabled}
                      >
                        {loading ? (
                          <CircularProgress sx={{ color: "#fff" }} size={24} />
                        ) : (
                          <FiDownload color="inherit" size={24} />
                        )}
                      </MenuButton>
                    </Tooltip>
                  }
                  transition
                >
                  <Tooltip
                    placement="top"
                    title={isCountAboveLimit ? tooltipTitle : ""}
                    arrow
                  >
                    <MenuItem
                      disabled={isCountAboveLimit}
                      onClick={() => {
                        data.handleExportAll();
                      }}
                    >
                      Export All
                    </MenuItem>
                  </Tooltip>
                  <MenuItem
                    onClick={() => data?.handleExport()}
                    style={{
                      opacity: isRowSelected ? "1" : "0.4",
                      cursor: isRowSelected ? "" : "not-allowed",
                      pointerEvents: isRowSelected ? "auto" : "none",
                    }}
                  >
                    Export Selected ({isRowSelected ? selectedRows : "0"})
                  </MenuItem>
                </Menu>
              </div>
            )}

            {data?.showBulkRefund && (
              <Button
                classNames="ml-2"
                text="Create Bulk Refund"
                iconPosition="left"
                IconComponent={FiPlus}
                size="lg"
                attributes={{
                  onClick: data?.openUpload,
                }}
              />
            )}
          </div>
        }
      />
    </>
  );
};

export default HeaderComponent;
