import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  Alert,
  Badge,
  Button,
  InputField,
  KeyValue,
  ModalV2,
  TransactionCard,
} from "nimbbl-quark";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FiRotateCcw, FiRotateCw, FiX } from "react-icons/fi";
import { IRefundRequest, IRefundRes } from "../../Apis/interface";
import constants from "../../constants";
import * as yup from "yup";
import { IoAlertCircleOutline } from "react-icons/io5";
import { BsCheck2Circle } from "react-icons/bs";
import ReactDOM from "react-dom";
import { getIssuerBadgeType } from "../../Configs/confgs";
import {
  segmentConfirmationRefundCancelRender,
  segmentConfirmationRefundRender,
} from "../../Apis/segment";
import { formatDate } from "../Orders/Orders";
import { PriceUtil } from "../../Utils";
import { useTracker } from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Please enter valid Refund amount")
    .required("Amount is required"),
  // comment: yup.string().required('Comment is required'),
});

export const getLink = (
  transactionType: string,
  transaction_id: string,
  tdate: string
) => {
  let d = new Date(tdate);
  d.setDate(d.getDate() - 1);
  window.localStorage.setItem("transaction_date", d.toString());
  if (transactionType === "payment") {
    return `transactions?tid=${transaction_id}`;
  } else {
    return `refunds?tid=${transaction_id}`;
  }
};

class AlertType {
  type: "success" | "danger" = "danger";
  message: string = "";
}

const TransactionCardModule = (value: any) => {
  const { currency } = value;
  const { original_currency } = value;
  const { track } = useTracker();
  const [alert, setAlert] = useState<AlertType>(new AlertType());

  const data = value.transaction_data;
  const amount = value.available_refundable_amount;
  const transactionCardType = value.transactionCardType;
  const [isRefundModal, setIsRefundModal] = useState<boolean>(false);
  const [refundResData, setRefundResData] = useState<any>({});
  const [issueTransactionData, setIssueTransactionData] = useState<any>([]);
  const [showRefundAlert, setShowRefundAlert] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<string | null>("");
  const getRefundTransaction = (data: any) => {
    // segmentIssueRefundRender(data)
    setIssueTransactionData(data);
    setIsRefundModal(true);
  };

  const hideRefundModal = () => {
    setIsRefundModal(false);
    segmentConfirmationRefundCancelRender(issueTransactionData?.transaction_id);
  };

  const handleAlert = () => {
    setShowRefundAlert(false);
  };

  const handleRefundSubmit = async (data: IRefundRequest) => {
    const user_id = window.localStorage.getItem("nimbbl_user_id");
    const token = window.localStorage.getItem("nimbbl_session_id");
    try {
      const res = await axios.post<IRefundRes>(
        `${constants.API.INITIATE_REFUND_API}?session_id=${token}`,
        {
          params: {
            amount: data.amount,
            comment: data.comment,
            transaction_id: issueTransactionData?.transaction_id,
            user_id: user_id,
          },
        }
      );
      setRefundResData(res.data);
      segmentConfirmationRefundRender(
        res.data,
        issueTransactionData?.transaction_id
      );

      // @ts-ignore
      const refund_id = res?.data?.result?.result?.res_id;
      track({
        name: TrackEventNames.confirmationRendered,
        inBuiltOptions: ["merchantId", "merchantName"],
        additionalOptions: {
          ...(refund_id && { refund_id }),
          transaction_id: value.transaction_data?.transaction_id,
        },
      });

      if (res.data?.result?.status === "failed") {
        throw new Error(res?.data?.result?.m_message);
      }

      track({
        name: TrackEventNames.issueRefundSelected,
        inBuiltOptions: ["merchantId", "merchantName"],
        additionalOptions: {
          menu_item_name: "Transactions",
          transaction_id: value.transaction_data?.transaction_id,
          transaction_amount: String(amount),
          refund_amount: (data.amount || 0).toString(),
          payment_mode: value?.transaction_data?.payment_mode,
        },
      });

      if (res.data?.result?.status === "success") {
        setAlert({
          type: "success",
          message: res.data?.result?.m_message,
        });

        setShowRefundAlert(true);
      }
    } catch (error) {
      const { message } = error as Error;

      setAlert({ type: "danger", message });
      setShowRefundAlert(true);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IRefundRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return { amount };
    }, [amount]),
  });

  useEffect(() => {
    const hideModal = () => setIsRefundModal(false);

    let timerId: NodeJS.Timeout;
    if (showRefundAlert) {
      if (alert.type === "success") {
        setTimeout(() => {
          hideModal();
        }, 1500);

        return;
      }

      timerId = setTimeout(() => {
        handleAlert();
      }, 5000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [showRefundAlert, alert, setIsRefundModal]);

  useEffect(() => {
    let is_admin = window.localStorage.getItem("nimbbl_admin");
    let is_admin_internal = window.localStorage.getItem("internal_admin");
    if (is_admin_internal) {
      is_admin = "true";
    }
    setIsAdmin(is_admin);
  }, []);

  const getLink = (
    transactionType: string,
    transaction_id: string,
    tdate: string
  ) => {
    let d = new Date(tdate);
    d.setDate(d.getDate() - 1);
    window.localStorage.setItem("transaction_date", d.toString());
    if (transactionType === "payment") {
      return `transactions?tid=${transaction_id}`;
    } else {
      return `refunds?tid=${transaction_id}`;
    }
  };

  const isFullRefund = data.transaction_type === "full-refund";
  const isPartialRefund = data.transaction_type === "partial-refund";

  const transactionAmount = useMemo(() => {
    const { transaction_type, refund_amount } = value.transaction_data;

    switch (transaction_type.toLowerCase()) {
      case "payment":
        return value?.order_amount;
      case "full-refund":
      case "partial-refund":
        return refund_amount;
      default:
        return 0;
    }
  }, [value]);

  return (
    <>
      <TransactionCard
        icon={
          ["partial-refund", "full-refund"].includes(data.transaction_type) ? (
            <FiRotateCcw />
          ) : (
            <FiRotateCw />
          )
        }
        label={
          isFullRefund
            ? "Full Refund"
            : isPartialRefund
            ? "Partial refund"
            : data.transaction_type
        }
        headerRigthContent={
          <Badge variant="accent" {...getIssuerBadgeType(data?.status)}></Badge>
        }
        style={{
          background: "#FAFAFC",
          color: "#0F1A2A",
          textTransform: "capitalize",
        }}
        children={
          <div>
            <KeyValue
              value={
                <a
                  href={getLink(
                    data.transaction_type,
                    data.transaction_id,
                    data.create_date
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.transaction_id}
                </a>
              }
              styleContainer={{
                width: "58%",
                float: "left",
                marginBottom: "16px",
                marginRight: "2%",
                overflowWrap: "break-word",
                color: "#045CE1",
                textTransform: "initial",
              }}
            ></KeyValue>

            <KeyValue
              value={data && formatDate(data.create_date)}
              styleContainer={{
                width: "38%",
                float: "left",
                marginBottom: "16px",
              }}
            ></KeyValue>
            <div className="clear-both"></div>
            <KeyValue
              label="Payment Method"
              value={data.payment_mode}
              styleContainer={{
                width: "58%",
                float: "left",
                marginBottom: "16px",
                marginRight: "2%",
              }}
            />

            {transactionCardType === "orders" && (
              <KeyValue
                label="Transaction Amount"
                value={PriceUtil.formatCurrency(transactionAmount, currency)}
                styleContainer={{
                  width: "38%",
                  float: "left",
                  marginBottom: "16px",
                }}
              />
            )}
            {transactionCardType === "transactions" && (
              <KeyValue
                label="Refund Amount"
                value={PriceUtil.formatCurrency(data.refund_amount, currency)}
                styleContainer={{
                  width: "38%",
                  float: "left",
                  marginBottom: "16px",
                }}
              ></KeyValue>
            )}
            {transactionCardType === "refunds" && (
              <KeyValue
                label="Refund Amount"
                value={PriceUtil.formatCurrency(data.refund_amount, currency)}
                styleContainer={{
                  width: "38%",
                  float: "left",
                  marginBottom: "16px",
                }}
              ></KeyValue>
            )}
            <div className="clear-both"></div>
            {data?.status &&
              data?.status.toLowerCase() === "succeeded" &&
              data.transaction_type !== "full-refund" &&
              data.transaction_type !== "partial-refund" &&
              isAdmin === "true" &&
              transactionCardType !== "refunds" &&
              value.isRefundType !== "full-refund" && (
                <>
                  <div
                    className="mb-2"
                    style={{ borderBottom: "1px solid #CBD4E1" }}
                  ></div>
                  <div className="flex items-center justify-center">
                    <Button
                      text="Issue Refund"
                      variant="link"
                      classNames="text-menu-20"
                      size="sm"
                      attributes={{
                        onClick: () => getRefundTransaction(data),
                        disabled: isFullRefund ? true : false,
                        ...(isFullRefund && {
                          style: {
                            color: "#94a3b8",
                            cursor: "not-allowed",
                          },
                        }),
                      }}
                    ></Button>
                  </div>
                </>
              )}
          </div>
        }
      />

      {showRefundAlert &&
        ReactDOM.createPortal(
          <div className="alertPosition" style={{ zIndex: "999" }}>
            <Alert
              variant={alert.type}
              onClose={() => handleAlert()}
              bannerWithCta={false}
              description={alert.message}
              size="md"
              icon={
                alert.type === "success" ? (
                  <BsCheck2Circle />
                ) : (
                  <IoAlertCircleOutline />
                )
              }
              closeIcon={<FiX />}
              closable={true}
            ></Alert>
          </div>,
          document.body
        )}

      <ModalV2
        isShown={isRefundModal}
        isBackdrop
        position="center"
        hide={hideRefundModal}
        size="sm"
        style={{ maxWidth: "352px" }}
        modalContent={
          <>
            <div
              className="flex items-center justify-between mb-4 text-lg"
              style={{ color: "#333333" }}
            >
              <h5 className="text-lg font-bold">Issue refund</h5>
              {/* <RiCloseFill className='w-6 h-6 cursor-pointer' onClick={hideRefundModal}/> */}
            </div>
            <form
              className="login-form"
              onSubmit={handleSubmit(handleRefundSubmit)}
              noValidate
            >
              <InputField
                prefix={original_currency ? original_currency : currency}
                label="Refund Amount"
                prefixClassName="rounded-l-lg h-12 w-12 pr-2 ml-[1px] bg-[#FAFAFC] border-r-[1px] border-r-[#CBD4E1] flex items-center justify-center font-medium text-sm"
                type="text"
                id="amount"
                attributes={{
                  type: "text",
                  ...register("amount"),
                  style: {
                    overflow: "hidden",
                    display: "block",
                    width: "100%",
                    // marginBottom: errors.amount?.message ? "" : "16px",
                    paddingLeft: "60px",
                    fontWeight: "normal",
                  },
                  // defaultValue: amount,
                }}
                caption={errors.amount?.message}
                captionType="error"
              />
              <InputField
                type="text"
                id="comment"
                attributes={{
                  type: "text",
                  ...register("comment"),
                  style: {
                    display: "block",
                    width: "100%",
                    fontWeight: "normal",
                  },
                }}
                caption={errors.comment?.message}
                captionType="error"
                label="Add Notes"
              />

              <div className="flex items-center justify-between mt-6">
                <Button
                  text="Cancel"
                  attributes={{
                    style: {
                      width: "140px",
                      background: "#FFFFFF",
                      color: "#045CE0",
                      border: "1px solid #045CE0",
                      fontWeight: "normal",
                      fontSize: "14px",
                      height: "40px",
                    },
                    onClick: () => hideRefundModal(),
                  }}
                />
                <Button
                  text="Issue Refund"
                  variant="primary"
                  type="submit"
                  attributes={{
                    style: {
                      width: "150px",
                      background: "#045CE0",
                      fontWeight: "normal",
                      fontSize: "14px",
                      height: "40px",
                    },
                  }}
                ></Button>
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default TransactionCardModule;
