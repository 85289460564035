export const isNavItemVisited = (pathName: string, key: string): boolean => {
  const arr = pathName.split('/');
  for (let i = 0; i < arr.length; i++) {
    const el = arr[i].trim().toLowerCase();
    if (el === key.trim().toLowerCase()) {
      return true;
    }
  }
  return false;
};
