const defaultCurrency = 'INR';
// add more currencies as per requirements
type currencyType = 'INR' | 'USD' | 'GBP';

interface ConvertParameters {
  amount: string | number;
  conversionRate: string | number;
  currentCurrency: currencyType;
  targetCurrency: currencyType;
}

const formatWithSpace = (amount: string): string => {
  // inserting space between currencySymbol and amount
  return amount.substring(0, 1) + ' ' + amount.substring(1);
  // replace the line above if know a better way to insert space in-between string characters
};

export class PriceUtil {
  static defaultCurrencyValue = '0 INR';

  // this is a generic method to format number to different currency format
  static formatCurrency(
    amount: number | string,
    amountCurrency?: currencyType
  ) {
    const currency = !!amountCurrency ? amountCurrency : defaultCurrency;

    const formattingOptions = {
      stringType: 'en-IN',
      minimumIntegerDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
    };

    switch (currency) {
      case 'GBP':
        formattingOptions.stringType = 'en-UK';
        break;
      case 'USD':
        formattingOptions.stringType = 'en-US';
        break;
      case 'INR':
      default:
        break;
    }

    const { stringType, ...restOptions } = formattingOptions;
    const formattedAmount = Number(amount).toLocaleString(
      stringType,
      restOptions
    );

    // return formattedAmount;
    return formattedAmount
      .replace(currency, '')
      .trim()
      .concat(' ' + currency);
  }

  // this is a specific method to format numbers in INR
  static formatCurrencyInINR(
    amount: number | string,
    showCode: boolean = false
  ) {
    const amountInINR = Number(amount).toLocaleString('en-IN', {
      minimumIntegerDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR',
      ...(showCode && { currencyDisplay: 'code' }),
    });

    return showCode
      ? amountInINR.replace('INR', '').concat(' INR')
      : formatWithSpace(amountInINR);
  }

  // converts amount from one currency to another
  // default targetCurrency is INR
  static convert(data: ConvertParameters) {
    const { amount, conversionRate, targetCurrency = defaultCurrency } = data;
    const newAmount = +amount * +conversionRate;

    return PriceUtil.formatCurrency(newAmount, targetCurrency);
  }
}
