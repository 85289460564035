import React, { useState } from 'react';
import { NavItem, Sidebar as LeftSidebar } from 'nimbbl-quark';
import routerPaths from '../../router/router';
import { RiLogoutCircleLine, RiSwapBoxLine } from 'react-icons/ri';
import { BsBoxes } from 'react-icons/bs';
import { TbCurrencyRupee } from 'react-icons/tb';
import { BiDetail } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNavItemVisited } from './Sidebar.utils';
import {
  FiArchive,
  FiFileText,
  FiLink,
  FiRotateCcw,
  FiRotateCw,
} from 'react-icons/fi';
import { AiOutlineBarChart } from 'react-icons/ai';
import { useCommonTracker } from '../../Hooks';
import { IoKeySharp } from 'react-icons/io5';
import { MdOutlineLocalOffer } from 'react-icons/md';

const SidebarLogoSVG = (
  <svg
    width='125'
    height='23'
    viewBox='0 0 125 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M19.8026 0L0 11.4479V22.9146L19.8026 11.3865V0Z' fill='#282828' />
    <path
      d='M19.5518 11.5336V23.0003L39.3543 11.4705V0.0839844L19.5518 11.5336Z'
      fill='#282828'
    />
    <path
      d='M54.1703 19.7812V13.895C54.1703 12.8708 53.513 12.0394 52.5178 12.0394C51.4543 12.0394 50.7526 12.7222 50.7526 13.895V19.7846H46.4541V8.26153H50.7304V9.8611H50.7748C51.1763 9.31903 51.6977 8.87717 52.2983 8.57006C52.8989 8.26296 53.5624 8.09894 54.2369 8.09082C57.4292 8.09082 58.4688 10.7608 58.4688 13.1149V19.7726L54.1703 19.7812Z'
      fill='#282828'
    />
    <path
      d='M60.4659 6.69093C60.2549 6.48884 60.0874 6.24572 59.9738 5.97651C59.8601 5.7073 59.8028 5.4177 59.8053 5.1255C59.8088 4.53803 60.0438 3.97563 60.4592 3.56021C60.8746 3.1448 61.437 2.90983 62.0245 2.90625C62.3159 2.90625 62.6045 2.96365 62.8738 3.07518C63.143 3.18671 63.3877 3.35018 63.5938 3.55626C63.7998 3.76233 63.9633 4.00697 64.0748 4.27623C64.1864 4.54548 64.2438 4.83407 64.2438 5.1255C64.2406 5.71201 64.0054 6.27342 63.5895 6.68703C63.1737 7.10064 62.611 7.33281 62.0245 7.33281C61.4405 7.33364 60.88 7.1028 60.4659 6.69093ZM64.1499 8.26148V19.7794H59.8053V8.26148H64.1499Z'
      fill='#282828'
    />
    <path
      d='M96.6948 18.1306C96.1995 18.7412 95.5708 19.23 94.8569 19.5594C94.1431 19.8888 93.3632 20.05 92.5772 20.0306C91.9161 20.0459 91.2612 19.8992 90.6697 19.6035C90.0782 19.3077 89.5679 18.8718 89.1835 18.3337H89.0913V19.7814H84.8149V3.59277H89.1374V9.14602H89.1835C89.5835 8.60922 90.107 8.17674 90.7096 7.88522C91.3123 7.5937 91.9763 7.45173 92.6455 7.47134C93.4169 7.45389 94.1822 7.61185 94.8836 7.9333C95.5851 8.25474 96.2044 8.73129 96.6948 9.32698C97.7543 10.5506 98.3262 12.1215 98.3012 13.7399C98.3141 15.3495 97.7434 16.9093 96.6948 18.1306ZM89.7946 11.9969C89.3445 12.4607 89.0927 13.0816 89.0927 13.7279C89.0927 14.3742 89.3445 14.9951 89.7946 15.4589C90.0143 15.6904 90.2791 15.8742 90.5728 15.999C90.8665 16.1238 91.1826 16.187 91.5017 16.1845C92.1418 16.1676 92.75 15.9015 93.1968 15.4428C93.6435 14.9841 93.8935 14.3691 93.8935 13.7288C93.8935 13.0885 93.6435 12.4735 93.1968 12.0148C92.75 11.5561 92.1418 11.29 91.5017 11.2731C91.1828 11.2707 90.8668 11.3337 90.5732 11.4582C90.2796 11.5827 90.0146 11.766 89.7946 11.9969Z'
      fill='#282828'
    />
    <path
      d='M110.621 18.1306C110.126 18.7412 109.497 19.23 108.783 19.5594C108.068 19.8888 107.288 20.05 106.502 20.0306C105.841 20.0453 105.187 19.8984 104.596 19.6027C104.004 19.307 103.495 18.8714 103.11 18.3337H103.019V19.7814H98.7432V3.59277H103.066V9.14602H103.11C103.51 8.60943 104.034 8.1771 104.636 7.88561C105.239 7.59411 105.903 7.45202 106.572 7.47134C107.343 7.45414 108.109 7.61221 108.81 7.93364C109.511 8.25507 110.131 8.73148 110.621 9.32698C111.681 10.5506 112.253 12.1215 112.228 13.7399C112.241 15.3495 111.67 16.9093 110.621 18.1306ZM103.719 11.9969C103.269 12.4607 103.018 13.0816 103.018 13.7279C103.018 14.3742 103.269 14.9951 103.719 15.4589C103.938 15.6893 104.201 15.8725 104.493 15.9973C104.785 16.122 105.099 16.1858 105.416 16.1845C106.056 16.1676 106.665 15.9015 107.111 15.4428C107.558 14.9841 107.808 14.3691 107.808 13.7288C107.808 13.0885 107.558 12.4735 107.111 12.0148C106.665 11.5561 106.056 11.29 105.416 11.2731C105.099 11.2721 104.786 11.3358 104.494 11.4603C104.203 11.5847 103.94 11.7673 103.721 11.9969H103.719Z'
      fill='#282828'
    />
    <path
      d='M117.576 19.7814H113.231V2.90137H117.576V19.7814Z'
      fill='#282828'
    />
    <path
      d='M119.681 19.2603C119.434 19.024 119.238 18.7395 119.106 18.4244C118.973 18.1093 118.907 17.7704 118.911 17.4286C118.902 17.0758 118.966 16.725 119.099 16.3978C119.231 16.0707 119.429 15.7743 119.681 15.5269C119.923 15.2762 120.213 15.0769 120.534 14.9407C120.854 14.8045 121.199 14.7344 121.548 14.7344C121.896 14.7344 122.241 14.8045 122.562 14.9407C122.882 15.0769 123.172 15.2762 123.414 15.5269C123.666 15.7743 123.864 16.0707 123.997 16.3978C124.129 16.725 124.193 17.0758 124.184 17.4286C124.188 17.7704 124.122 18.1093 123.989 18.4244C123.857 18.7395 123.661 19.024 123.414 19.2603C122.918 19.7535 122.247 20.0303 121.548 20.0303C120.848 20.0303 120.177 19.7535 119.681 19.2603Z'
      fill='#282828'
    />
    <path
      d='M79.6474 8.10156C78.239 8.10156 77.0321 8.66491 76.0061 9.7899C75.383 8.66491 74.3775 8.10156 72.9487 8.10156C72.3502 8.10849 71.7614 8.2537 71.2284 8.52585C70.6953 8.798 70.2324 9.18973 69.8758 9.6704H69.8622V8.26203H65.5142V19.78H69.8724V13.2297C69.8724 12.185 70.4358 11.6012 71.4208 11.6012C72.3051 11.6012 72.8087 12.325 72.8087 13.2093V19.7817H76.6309V13.2127C76.6309 12.1679 77.1926 11.6046 78.1793 11.6046C79.0636 11.6046 79.5672 12.3079 79.5672 13.1939V19.7851H83.3877V12.5657C83.3877 10.4813 82.5631 8.10156 79.6474 8.10156Z'
      fill='#282828'
    />
  </svg>
);

function Sidebar() {
  let is_admin = window.localStorage.getItem('nimbbl_admin');
  const [isSettlementShow, setIsSettlementShow] = useState<boolean>(true);
  const [isRefundShow, setIsRefundShow] = useState<boolean>(true);
  const [isDeveloperSettingShow, setIsDeveloperSettingShow] =
    useState<boolean>(true);
  let is_internal_admin = window.localStorage.getItem('internal_admin');
  const location = useLocation();
  const navigate = useNavigate();
  const { trackMenuItemClicked, trackLogout } = useCommonTracker();

  const navItems: NavItem[] = [
    {
      id: 1,
      text: 'Orders',
      Icon: FiArchive,
      visited: isNavItemVisited(location.pathname, 'orders'),
      attributes: {
        onClick: () => {
          navigate(routerPaths.orders);
          trackMenuItemClicked('Orders');
        },
      },
    },
    {
      id: 2,
      text: 'Transactions',
      Icon: FiRotateCw,
      visited: isNavItemVisited(location.pathname, 'transactions'),
      attributes: {
        onClick: () => {
          navigate(routerPaths.transactions);
          trackMenuItemClicked('Transactions');
        },
      },
    },
    {
      id: 3,
      text: 'Refunds',
      Icon: FiRotateCcw,
      secondary: true,
      toggle: isRefundShow,
      link: routerPaths.refunds,
      // visited: isNavItemVisited(location.pathname, 'refunds'),
      attributes: {
        onClick: () => {
          setIsRefundShow(!isRefundShow);
          navigate(routerPaths.refunds);
          trackMenuItemClicked('Refunds Details');
        },
      },
      children: [
        {
          id: 3.1,
          text: 'Refund Details',
          Icon: BiDetail,
          visited: isNavItemVisited(location.pathname, 'refunds'),
          attributes: {
            onClick: () => {
              navigate(routerPaths.refunds);
              trackMenuItemClicked('Refunds');
            },
          },
        },
        {
          id: 3.2,
          text: 'Bulk Refund',
          Icon: BsBoxes,
          visited: isNavItemVisited(location.pathname, 'bulk-refunds'),
          attributes: {
            onClick: () => {
              navigate(routerPaths.bulkRefund);
              trackMenuItemClicked('Bulk Refund');
            },
          },
        },
      ],
    },
    {
      id: 4,
      text: 'Settlements',
      Icon: TbCurrencyRupee,
      secondary: true,
      // visited: isNavItemVisited(location.pathname, 'settlements'),
      toggle: isSettlementShow,
      link: routerPaths.settlements,
      attributes: {
        onClick: () => {
          setIsSettlementShow(!isSettlementShow);
          navigate(routerPaths.settlements);
          trackMenuItemClicked('Settlements');
        },
      },
      children: [
        {
          text: 'Settlement Summary',
          id: 4.1,
          Icon: FiFileText,
          visited: isNavItemVisited(location.pathname, 'settlements'),
          attributes: {
            onClick: () => {
              navigate(routerPaths.settlements);
              trackMenuItemClicked('Settlement');
            },
          },
        },
        {
          text: 'Settlement Details',
          id: 4.2,
          Icon: BiDetail,
          visited: isNavItemVisited(location.pathname, 'settlements-details'),
          attributes: {
            onClick: () => {
              navigate(routerPaths.settlementDetails);
              trackMenuItemClicked('Settlement Details');
            },
          },
        },
      ],
    },
    {
      id: 6,
      text: 'Analytics',
      secondary: true,
      Icon: AiOutlineBarChart,
      visited: isNavItemVisited(location.pathname, 'analytics'),
      attributes: {
        onClick: () => {
          navigate(routerPaths.analytics);
          trackMenuItemClicked('Analytics');
        },
      },
    },
    // {
    //   id: 8,
    //   text: 'Settlement Details',
    //   Icon: BiDetail,
    //   visited: isNavItemVisited(location.pathname, 'settlements-details'),
    //   attributes: {
    //     onClick: () => { navigate(`${routerPaths.settlementDetails}/${partner_settement_id}` ); segmentMenuItemClicked()}
    //   },
    // },
    // {
    //   id: 5,
    //   text: 'Customers',
    //   Icon: RiSettingsLine,
    // },
    // {
    //   id: 6,
    //   text: 'Customise Checkout',
    //   Icon: RiCoupon2Line,
    // },
    // {
    //   id: 'offers',
    //   text: 'Offers and Coupons',
    //   Icon: MdOutlineLocalOffer,
    //   visited: isNavItemVisited(location.pathname, 'offers'),
    //   attributes: {
    //     onClick: () => {
    //       navigate(routerPaths.offers);
    //       trackMenuItemClicked('Offers and Coupons');
    //     },
    //   },
    // },
    {
      id: 9,
      text: 'Payment Links',
      Icon: FiLink,
      visited: isNavItemVisited(location.pathname, 'payment-links'),
      attributes: {
        onClick: () => {
          navigate(routerPaths.paymentLink);
          trackMenuItemClicked('Payment Link');
        },
      },
    },
    {
      id: 8,
      text: 'Developer Settings',
      Icon: RiSwapBoxLine,
      secondary: true,
      toggle: isDeveloperSettingShow,
      link: routerPaths.apiKeys,
      attributes: {
        onClick: () => {
          setIsSettlementShow(!isDeveloperSettingShow);
          // setIsSettlementShow(!isDeveloperSettingShow);
          navigate(routerPaths.apiKeys);
          trackMenuItemClicked('API Keys');
        },
      },
      children: [
        {
          text: 'API Keys',
          id: '7.1',
          Icon: IoKeySharp,
          visited: isNavItemVisited(location.pathname, 'api-keys'),
          attributes: {
            onClick: () => {
              navigate(routerPaths.apiKeys);
              trackMenuItemClicked('API Keys');
            },
          },
        },
        // {
        //   text: 'Webhooks',
        //   id: '7.2',
        //   Icon: RiAddCircleFill,
        //   visited: isNavItemVisited(location.pathname, 'configs'),
        //   attributes: {
        //   //   onClick: () => navigate(routerPaths.configs),
        //   },
        // },
      ],
    },
    {
      id: 5,
      text: 'Log Out',
      Icon: RiLogoutCircleLine,
      secondary: true,
      attributes: {
        onClick: () => {
          window.localStorage.removeItem('nimbbl_session_id');
          trackLogout();
          // segmentLogout();
          navigate(routerPaths.home);
        },
      },
    },
  ];

  if (is_admin === 'false' && is_internal_admin === 'false') {
    let index = navItems.findIndex(function (o) {
      return o.id === 7;
    });
    if (index !== -1) navItems.splice(index, 1);
  }

  // if(!partner_settement_id){
  //   console.log(navItems)
  //   let index = navItems.findIndex(function(o){
  //     return o.id === 8;
  //   })
  //   if (index !== -1) navItems.splice(index, 1);
  // }

  return (
    <div className='fixed sidebar-container'>
      <LeftSidebar
        navItems={navItems}
        profilePhotoUrl='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
        logo={SidebarLogoSVG}
        // isDivider={false}
      />
    </div>
  );
}

export default React.memo(Sidebar);
