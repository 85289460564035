import { FC } from 'react';

const FilterContainer: FC = (props) => {
  const { children } = props;

  return (
    <div className='h-full px-4 pt-0 overflow-y-auto no-scroll'>{children}</div>
  );
};

export default FilterContainer;
