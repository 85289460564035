import { Button } from 'nimbbl-quark';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import NoOffers from './../../images/No Offers.svg';

export const EmptyTableView = (props: {
  headerText: string;
  headerSubText: string;
  btnLink: string;
  btnText: string;
}) => {
  const { headerText, headerSubText, btnLink, btnText } = props;
  return (
    <div
      style={{ height: '100%' }}
      className='flex flex-col items-center justify-center grow'>
      <img src={NoOffers} alt='No offers found' />
      <h3 className='mb-2 text-base font-semibold'>{headerText}</h3>
      <p className='text-sm font-normal'>{headerSubText}</p>
      <Link to={btnLink}>
        <Button
          text={btnText}
          iconPosition='left'
          IconComponent={FiPlus}
          size='lg'
          classNames='mt-10'
        />
      </Link>
    </div>
  );
};
