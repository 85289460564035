import { FiLock } from 'react-icons/fi';

export const LockContainer = () => (
  <div
    className='flex flex-col items-center justify-center py-12 mt-4 justify-items-center'
    style={{ background: '#F1F4F9' }}>
    <FiLock className='w-8 h-8' style={{ color: '#6C7F9A' }} />
    <p className='mt-5 text-base font-semibold' style={{ color: '#0F1A2A' }}>
      Production Keys locked
    </p>
    <p className='text-sm m1-1' style={{ color: '#6C7F9A' }}>
      Please contact our team on{' '}
      <a style={{ color: '#0352C9' }} href='mailto:help@nimbbl.biz'>
        help@nimbbl.biz
      </a>{' '}
      to enquire about the status of your Production Keys
    </p>
  </div>
);
