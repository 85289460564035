import axios, { AxiosResponse } from 'axios';
import {
  CANCEL_PAYMENT_LINK,
  CREATE_PAYMENT_LINK,
  PAYMENT_LINK,
  RESEND_PAYMENT_LINK,
  UPDATE_PAYMENT_LINK,
} from '../../../constants/api';
import {
  LinkDetails,
  LinkCustomerDetails,
} from '../../../interfaces/PaymentLinks';
import { PaymentLinkResponse } from './PaymentLinkResponse';
import moment from 'moment';

export const formatResponse = (
  response: PaymentLinkResponse | undefined | null
) => {
  if (!response) {
    return {
      linkDetails: new LinkDetails(),
      customerDetails: new LinkCustomerDetails(),
    };
  }

  const {
    id: payment_link_id,
    callback_url,
    product_details,
    total_amount,
    currency_id,
    description,
    expires_at,
    invoice_id,
    payer_first_name,
    payer_last_name,
    payer_mobile_number,
    payer_email_address,
    notification_channel_email,
    notification_channel_sms,
    sub_merchant_id,
    notification_scheduled_at,
    terms_and_conditions,
  } = response;

  const { display_name, id } = sub_merchant_id;
  const scheduleDate = notification_scheduled_at
    ? moment(notification_scheduled_at, 'DD MMM YYYY, hh:mm:ss')
    : null;

  const linkDetails: LinkDetails = {
    sub_merchant_id: { label: display_name, value: String(id) },
    total_amount: total_amount.toFixed(2),
    currency: { label: currency_id.name, value: currency_id.name },
    expires_at: moment(expires_at, 'DD MMM YYYY').toDate(),
    show_scheduled_at: Boolean(notification_scheduled_at),
    notification_scheduled_at: scheduleDate?.toDate() || undefined,
    description,
    callback_url,
    invoice_id,
    ...(terms_and_conditions && { terms_and_conditions }),
    product_details: product_details.map((product) => ({
      defaultExpanded: false,
      title: product.title,
      amount_before_tax: product.rate?.toFixed(2),
      description: product.description,
      image_url: product.image_url || undefined,
      isImageUrl: Boolean(product.image_url),
      quantity: product.quantity,
      tax: product.tax?.toFixed(2),
    })),
  };

  const customerDetails: LinkCustomerDetails = {
    payment_link_id,
    payer_first_name,
    payer_last_name,
    payer_mobile_number,
    payer_email_address,
    notification_channel_email,
    notification_channel_sms,
  };

  return { linkDetails, customerDetails };
};

export const getPaymentLinkById = async (id: string) => {
  const token = window.localStorage.getItem('nimbbl_session_id');
  const url = PAYMENT_LINK + '?session_id=' + token;

  const res = await axios.post(url, { link_hash: id });
  return res.data;
};

export const createPaymentLink = async (body: any, isEdit = false) => {
  const token = window.localStorage.getItem('nimbbl_session_id');
  const baseUrl = isEdit ? UPDATE_PAYMENT_LINK : CREATE_PAYMENT_LINK;
  const url = baseUrl + '?session_id=' + token;
  let res: AxiosResponse<any, any>;
  if (isEdit) {
    try {
      res = await axios.put(url, body);
      return res;
    } catch {
      const errorRes = { status: 400, data: { data: { error: 'error' } } };
      return errorRes;
    }
  } else {
    try {
      res = await axios.post(url, body);
      return res;
    } catch {
      const errorRes = { status: 400, data: { data: { error: 'error' } } };
      return errorRes;
    }
  }
};

export const cancelPaymentLink = async (id: number) => {
  const token = window.localStorage.getItem('nimbbl_session_id');
  const url = CANCEL_PAYMENT_LINK + '?session_id=' + token;
  const res = await axios.post(url, { payment_link_id: id });
  return res;
};

export const resendPaymentLink = async (id: number) => {
  const token = window.localStorage.getItem('nimbbl_session_id');
  const url = RESEND_PAYMENT_LINK + '?session_id=' + token;
  const res = await axios.post(url, { payment_link_id: id });
  return res;
};
