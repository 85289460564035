import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  CSSProperties,
} from 'react';
import Select from 'react-select';

import { ISubMerchantsRetrieveRequest } from '../../Apis/interface';
import { getSubmerchantById } from '../../Apis';
import debounce from 'lodash.debounce';
import { Dropdown } from '../Dropdown';

interface IOption {
  label: string;
  value: string | number;
}

interface Option {
  label: string;
  value: number;
  groupName: 'Live' | 'Sandbox';
}

interface IGroupOption {
  label: string;
  options: IOption[];
}

export interface ISubmerchantItem {
  label?: string;
  value?: number;
}

const getSubmerchantOptions = async (
  search = '',
  subMerchantId: boolean,
  subMerchantType: string | undefined
) => {
  let rr: ISubMerchantsRetrieveRequest = {
    order_by: 'id',
    sort_by: 'desc',
    page: 1,
    per_page: 10,
    total_pages: 10,
    next: null,
    prev: null,
    search,
  };

  if (subMerchantType) {
    rr.is_sandbox = subMerchantType === 'Live' ? false : true;
  }

  try {
    const subMerchantSandboxOption: IOption[] = [];
    const subMerchantLiveOptionList: IOption[] = [];
    const res = await getSubmerchantById(rr);
    const data = res.data.result.result;

    if (subMerchantType) {
      const options = data.map((item: any) => ({
        label: item.description,
        value: subMerchantId ? item.sub_merchant_id : item.id,
        groupName: subMerchantType,
      }));

      return { options };
    }

    // data.forEach((item: any) => {
    //   if (item.is_sandbox) {
    //     subMerchantSandboxOption.push({
    //       label: item.description,
    //       value: subMerchantId ? item.sub_merchant_id : item.id,
    //       // value: item.sub_merchant_id,
    //     });
    //   } else {
    //     subMerchantLiveOptionList.push({
    //       label: item.description,
    //       value: subMerchantId ? item.sub_merchant_id : item.id,
    //       // value: item.sub_merchant_id,
    //     });
    //   }
    // });

    // const groupOptions = [
    //   {
    //     label: 'Live',
    //     options: subMerchantLiveOptionList,
    //   },
    //   {
    //     label: 'Sandbox',
    //     options: subMerchantSandboxOption,
    //   },
    // ];

    const options: Array<Option> = data.map((item: any) => ({
      label: item.description,
      value: subMerchantId ? item.sub_merchant_id : item.id,
      groupName: item.is_sandbox ? 'Sandbox' : 'Live',
    }));

    return { options };
  } catch (err) {
    console.error(err);
  }
  return { options: [] };
};

interface ISubMerchantDropdown {
  className?: string;
  onChange?: (selectedOption: any) => void;
  onInputChange?: any;
  attributes?: object;
  value?: any;
  subMerchantId?: boolean;
  isMulti?: boolean;
  subMerchantType?: string;
  caption?: string;
  captionType?: string;
  captionStyles?: CSSProperties;
  captionClassName?: string;
  placeHolder?: string;
}

const SubMerchantDropdown = ({
  className = '',
  onChange,
  onInputChange,
  placeHolder,
  value,
  attributes,
  isMulti = true,
  subMerchantId = true,
  subMerchantType,
  caption,
  captionStyles,
  captionType,
  captionClassName,
}: ISubMerchantDropdown) => {
  const [subMerchantOptions, setSubMerchantOptions] = useState<Option[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const updateSubMerchantOptions = useCallback(
    async (search = '') => {
      const { options } = await getSubmerchantOptions(
        search,
        subMerchantId,
        subMerchantType
      );
      // if (subMerchantType) {
      //   const newList = list?.filter(
      //     (item: any) => item.label === subMerchantType
      //   )[0];
      //   console.log(newList?.options, subMerchantType);
      //   list = newList?.options;
      // }

      setSubMerchantOptions(options || []);
    },
    [subMerchantId, subMerchantType]
  );

  useEffect(() => {
    updateSubMerchantOptions();
  }, [updateSubMerchantOptions]);

  const handleChange = (selectedOption: any) => {
    onChange && onChange(selectedOption);
  };

  const memoizedCallback = useMemo(
    () =>
      debounce((value: string) => {
        console.log('value', value);
        if (!isLoading) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        updateSubMerchantOptions(value);
        onInputChange && onInputChange(value);
      }, 600),
    [isLoading, onInputChange, updateSubMerchantOptions]
  );

  const color = captionType === 'error' ? 'rgb(216,50,50)' : '#6C7F9A';

  const sortedOptions = useMemo(() => {
    const sortOptions = (a: Option, b: Option) => {
      return a.groupName.localeCompare(b.groupName);
    };

    return subMerchantOptions.sort(sortOptions);
  }, [subMerchantOptions]);

  return (
    <>
      {/* <Select 
        className={`multi-select ${className}`}
        placeholder='Enter Submerchant Name'
        isMulti={isMulti}
        isSearchable
        options={subMerchantOptions}
        formatGroupLabel={formatSubMerchantOptions}
        onInputChange={memoizedCallback}
        value={value}
        onChange={handleChange}
        {...attributes}
        // cacheOptions
        // defaultOptions
      /> */}
      {/* @ts-ignore */}
      <Dropdown
        placeHolder='Search by Submerchant Name'
        id='submerchant-dropdown'
        value={value}
        onInputChange={memoizedCallback}
        onChange={handleChange}
        options={sortedOptions}
        {...(!isMulti && { searchable: true })}
        {...(isMulti && {
          multiple: true,
          groupBy: (option) => option.groupName,
        })}
      />
      <p
        style={{
          fontWeight: 300,
          fontSize: 14,
          color,
          ...captionStyles,
        }}
        className={captionClassName}>
        {caption}
      </p>
    </>
  );
};

export default React.memo(SubMerchantDropdown);
