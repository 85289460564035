import { FC } from 'react';

interface FilterHeadingProps {
  title: string;
}

const FilterHeading: FC<FilterHeadingProps> = (props) => {
  const { title } = props;

  return <h1 className='text-lg font-bold'>{title}</h1>;
};

export default FilterHeading;
