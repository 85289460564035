import { useMemo, useState } from 'react';
import { SummaryViewType } from '../AnalyticsHome';
import { AnalyticsResponse } from '../AnalyticsResponse';
import { formatData, isLastLevel } from '../sharedUtil';
import { PaymentPartnerUtil } from './PaymentPartnerUtil';

interface PaymentPartnerHookProps {
  filters: {
    currency: string;
    summaryView: SummaryViewType;
  };
  paymentPartners: AnalyticsResponse['payment_partner'];
}

export const usePaymentPartner = (props: PaymentPartnerHookProps) => {
  const { filters, paymentPartners } = props;
  const [selectedCell, setSelectedCell] = useState('');

  const rawData = useMemo(
    () => PaymentPartnerUtil.getData(paymentPartners),
    [paymentPartners]
  );

  const paymentData = useMemo(
    () => formatData(rawData[selectedCell], filters),
    [rawData, selectedCell, filters]
  );

  const getOptions = () => {
    const newOption = PaymentPartnerUtil.getOptions(paymentData);
    if (newOption.series) {
      newOption.series[0].events = {
        click: function (e) {
          // @ts-ignore
          const id = e.point.id;
          if (isLastLevel(id)) return;
          setSelectedCell(id);
        },
      };
    }
    return newOption;
  };

  return {
    paymentData,
    options: getOptions(),
    selectedCell,
    setSelectedCell,
  };
};
