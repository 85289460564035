import { reset } from '@amplitude/analytics-browser';
import { TrackEventNames } from '../constants/trackEventNames';
import { useTracker } from './useTracker';

import debounce from 'lodash.debounce';

type AnyObject = Record<string, any>;

// this file contains tracker functions which are used in multiple files
export const useCommonTracker = () => {
  const { track } = useTracker();

  const trackClearFiltersClicked = (menu_item_name: string) => {
    track({
      name: TrackEventNames.clearFilterClicked,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: { menu_item_name },
    });
  };

  const trackFilterFieldsEntered = (pageName: string, data: AnyObject) => {
    track({
      name: pageName + TrackEventNames.filterFieldsEntered,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        ...data,
        ...scanFilters(data),
        menu_item_name: pageName,
      },
    });
  };

  const trackItemClicked = (pageName: string, data: AnyObject) => {
    track({
      name: pageName + ' ' + TrackEventNames.itemClicked,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: { ...data },
    });
  };

  const trackLogout = () => {
    // removing amplitude userId
    reset();

    track({
      name: TrackEventNames.logout,
      inBuiltOptions: ['merchantId', 'merchantName', 'merchantEmail'],
    });
  };

  const trackMenuItemClicked = (item: string) => {
    track({
      name: TrackEventNames.menuItemClicked,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: { menu_item_name: item },
    });
  };

  const trackPageExported = (page: string, data: AnyObject) => {
    track({
      name: page + ' ' + TrackEventNames.exported,
      inBuiltOptions: ['merchantId', 'merchantName'],

      additionalOptions: { ...data },
    });
  };

  const trackPageRendered = debounce((page: string, data: AnyObject) => {
    const isException = /(api)|(sign in)/i.test(page);
    const isSign = /sign in/.test(page);

    const { days } = data || {};
    const { total_pages = 0, total_records = 0, per_page = 0 } = data || {};

    let additionalOptions = {};

    // track will not run if default_duration, per_page, total_records & total_pages are not present
    if (!isException) {
      if (!days) return;
      if (!days.label) return;
      if (!per_page && per_page !== 0) return;
      if (!total_pages && total_pages !== 0) return;
      if (!total_records && total_records !== 0) return;

      additionalOptions = {
        number_of_rows: per_page,
        number_of_pages: total_pages,
        count_of_records: total_records,
        default_duration: days.label,
      };
    }

    track({
      name: page + ' ' + TrackEventNames.pageRendered,
      ...(!isSign && { inBuiltOptions: ['merchantId', 'merchantName'] }),
      additionalOptions,
      type: 'pageView',
    });
  }, 1500);

  const trackPopupRendered = (name: string, data: AnyObject) => {
    track({
      name: name + TrackEventNames.popupRendered,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: { ...data },
    });
  };

  const trackRowSelected = (page: string, data: AnyObject) => {
    track({
      name: page + ' ' + TrackEventNames.pageSelected,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: { ...data },
    });
  };

  return {
    trackClearFiltersClicked,
    trackFilterFieldsEntered,
    trackItemClicked,
    trackLogout,
    trackMenuItemClicked,
    trackPageExported,
    trackPageRendered,
    trackPopupRendered,
    trackRowSelected,
  };
};

export const scanFilters = (filters: Object) => {
  const result = {} as AnyObject;

  Object.entries(filters).forEach(([key, value]) => {
    if (!Boolean(value)) return;
    if (Array.isArray(value) && value.length === 0) return;

    switch (key) {
      case 'days':
        result[key] = value?.label || value;
        break;
      case 'end_date':
        result['to_datetime'] = value;
        break;
      case 'start_date':
        result['from_datetime'] = value;
        break;
      default:
        result[key] = value;
    }
  });

  return result;
};
