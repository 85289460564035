import { object, string } from 'yup';
import {
  FilterCommonFields,
  MetaData,
  Option,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';
import { useCurrentSession, useTracker } from '../../Hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterPaymentLinkUtils from '../FilterPopup/utils/FilterPaymentLinkUtils';

export type FilterPaymentLinkFields = Pick<
  FilterCommonFields,
  | 'status'
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'linkHash'
  | 'orderId'
  | 'invoiceId'
  | 'mobile'
  | 'maxAmount'
  | 'minAmount'
> & { subMerchantId?: Option | null };

export const defaultPaymentLinkFilters: FilterPaymentLinkFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
};

const paymentLinkSchema = object().shape({
  status: commonSchema.status,
  duration: commonSchema.duration,
  startDate: commonSchema.startDate,
  endDate: commonSchema.endDate,
  linkHash: string(),
  orderId: commonSchema.orderId,
  invoiceId: commonSchema.invoiceId,
  mobile: commonSchema.mobile,
  subMerchantId: commonSchema.subMerchantId,
  maxAmount: commonSchema.maxAmount,
  minAmount: commonSchema.minAmount,
});

const useFilterPaymentLink = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(true);
  const [paymentLinks, setPaymentLinks] = useState<any[]>([]);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterPaymentLinkFields>({
    defaultValues: defaultPaymentLinkFilters,
    resolver: yupResolver(paymentLinkSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { paymentLinks } =
      (await fetchPaymentLinks({ ...form.getValues(), page: page + 1 })) ?? {};

    setPaymentLinks((prev) => [...prev, ...paymentLinks]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterPaymentLinkUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const handleFormSubmit = async (
    formData: FilterPaymentLinkFields & { page?: number }
  ) => {
    setLoading(true);
    const { filterSection, paymentLinks, metaData } =
      (await fetchPaymentLinks(formData)) ?? {};

    setPaymentLinks(paymentLinks);
    setMetaData(metaData);
    setFilters(filterSection);
    setLoading(false);
    setFilterModalShown(false);
  };

  const fetchPaymentLinks = async (
    formData: FilterPaymentLinkFields & { page?: number }
  ) => {
    const { page, ...restFormData } = formData;
    const { filters, filterSection } = FilterPaymentLinkUtils.generateParams(
      restFormData,
      page ? { page } : undefined
    );

    const response = await FilterPaymentLinkUtils.getPaymentLinks(filters);

    // check for user token
    if (checkSessionError(response)) return;

    console.log('payment', response);

    const paymentLinks = response.data?.result?.result ?? [];
    const metaData = response.data?.result?.meta ?? new MetaData();

    const error = response.data?.result?.error?.message;
    const rows = metaData?.total_records;
    const pages = metaData?.total_pages;

    track({
      name: 'Payment Links' + TrackEventNames.searchClicked,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Payment Links',
        // @ts-ignore
        filters: filterSection,

        ...(error
          ? { failure_error_message: error }
          : {
              number_of_rows: rows,
              number_of_pages: pages,
            }),
      },
    });

    return { paymentLinks, filterSection, filters, metaData };
  };

  return {
    filters,
    form,
    loading,
    metaData,
    paymentLinks,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  };
};

export default useFilterPaymentLink;
