import { Alert, Button, InputField } from 'nimbbl-quark';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from './../../images/logo.svg';
import mailIcon from './../../images/mail.svg';
import LoginBanner from './../../images/loginBanner.png';
import { IForgetPassFormData, IForgetPassRes } from '../../interfaces/Login';
import axios from 'axios';
import './Styles.css';
import { FiX } from 'react-icons/fi';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { BsCheck2Circle } from 'react-icons/bs';
import constants from '../../constants';
import { useTracker } from '../../Hooks';
import { TrackEventNames } from '../../constants/trackEventNames';

export const ForgetPassword = () => {
  const [resultData, setResultData] = useState<IForgetPassRes>();
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [saveMailData, setSaveMailData] = useState<any>({});
  const [resendMail, setResendMail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertClose, setAlertClose] = useState<boolean>(false);
  const { track } = useTracker();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email('Email is invalid')
          .required('Email is required'),
      }),
    []
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<IForgetPassFormData>({
    resolver: yupResolver(validationSchema),
  });

  const { email } = watch();

  const handleLogin = async (data: IForgetPassFormData) => {
    try {
      const res = await axios.post<IForgetPassRes>(
        `${constants.API.RESET_PASSWORD_API}`,
        {
          params: {
            email_id: data.email,
          },
        }
      );
      setResultData(res.data);
      setAlertClose(true);
      const { error } = res?.data?.result || {};
      const { message = '' } = error || {};

      if (message) {
        track({
          name: TrackEventNames.sendPasswordResetLinkFailed,
          additionalOptions: {
            merchant_login_email: data.email,
            failure_error_message: message,
          },
        });
      }

      if (res.data.result.result?.email) {
        setMailSent(true);
        setSaveMailData(res.data.result.result);
        track({
          name: TrackEventNames.sendPasswordResetLinkSuccess,
          additionalOptions: { merchant_login_email: data.email },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAlert = () => {
    setAlertClose(false);
  };

  useEffect(() => {
    if (alertClose) {
      setTimeout(() => {
        handleAlert();
      }, 5000);
    }
  }, [alertClose, mailSent, resendMail]);

  const handleResend = async () => {
    setLoading(true);
    try {
      const res = await axios.post<IForgetPassRes>(
        `${constants.API.RESET_PASSWORD_API}`,
        {
          params: {
            email_id: saveMailData.email,
          },
        }
      );

      setResultData(res.data);
      setLoading(false);
      setAlertClose(true);
      if (res.data.result.result.email) {
        setResendMail(true);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
    track({
      name: TrackEventNames.resendPasswordResetEmail,
      additionalOptions: {
        merchant_login_email: saveMailData.email,
      },
    });
  };

  return (
    <div className='flex justify-between'>
      <div className='alertPosition'>
        {resultData && resultData?.result.error && alertClose && (
          <Alert
            variant={'danger'}
            onClose={() => handleAlert()}
            bannerWithCta={false}
            description={resultData?.result.error.message}
            size='md'
            icon={<IoAlertCircleOutline />}
            closeIcon={<FiX />}
            closable={true}></Alert>
        )}
      </div>
      {resendMail && alertClose && (
        <div className='alertPosition'>
          <Alert
            variant={'success'}
            onClose={() => handleAlert()}
            bannerWithCta={false}
            description={'Mail resend successfully!'}
            size='md'
            icon={<BsCheck2Circle />}
            closeIcon={<FiX />}
            closable={true}></Alert>
        </div>
      )}
      {mailSent && alertClose && (
        <div className='alertPosition'>
          <Alert
            variant={'success'}
            onClose={() => handleAlert()}
            bannerWithCta={false}
            description={saveMailData?.message}
            size='md'
            icon={<BsCheck2Circle />}
            closeIcon={<FiX />}
            closable={true}></Alert>
        </div>
      )}

      <div className='relative flex justify-center flex-1 mt-8'>
        <div className='flex-col justify-start'>
          <img src={logo} alt='logo' className='mb-16' />
          {!mailSent && (
            <>
              <h3 className='flex mb-2 font-bold text-left justify-items-start'>
                Forgot Password?
              </h3>
              <p className='mb-6 text-sm text-secondary-10'>
                No worries, we&apos;ll send you instructions
              </p>
              <form
                className='flex flex-col login-form'
                onSubmit={handleSubmit(handleLogin)}
                noValidate>
                <InputField
                  type='text'
                  id='email'
                  attributes={{
                    type: 'email',
                    ...register('email'),
                    style: {
                      display: 'block',
                      width: '360px',
                      marginBottom: '16px',
                    },
                  }}
                  caption={errors.email?.message}
                  captionType='error'
                  label='Registered Email Address'
                />

                <Button
                  text='Send Password Reset Link'
                  type='submit'
                  attributes={{
                    style: {
                      marginLeft: 'auto',
                      width: '226px',
                      marginTop: '20px',
                      background: '#045CE0',
                      fontWeight: 'normal',
                    },
                    onClick: () => {
                      track({
                        name: TrackEventNames.sendPasswordResetLink,
                        additionalOptions: {
                          merchant_login_email: email,
                        },
                      });
                    },
                  }}
                />

                <Link
                  to={'/'}
                  className='backBtn'
                  style={{ display: 'inline', marginLeft: 'auto' }}
                  onClick={() => track({ name: TrackEventNames.backToLogin })}>
                  Back to Log In
                </Link>
              </form>
            </>
          )}
          {mailSent && (
            <>
              <img src={mailIcon} alt='mail' className='mb-4' />
              <h3 className='flex mb-2 font-bold text-left justify-items-start'>
                Check your email
              </h3>
              <p className='mb-6 text-md text-secondary-10'>
                We sent a password reset link to <br />
                {saveMailData.email}
              </p>
              <p className='mb-6 text-md text-secondary-10'>
                Didn&apos;t receive the email?{' '}
                <Link
                  to={'#'}
                  onClick={() => handleResend()}
                  className='text-blue-40'>
                  {loading ? 'sending...' : 'Click here to resend'}
                </Link>
              </p>

              <Link
                to={'/'}
                className='backBtn'
                onClick={() => track({ name: TrackEventNames.backToLogin })}>
                Back to Log In
              </Link>
            </>
          )}
        </div>
        <div className='w-full text-center sm:absolute bottom-12 text-tertiary-10'>
          <span className='mr-4'>@Nimbbl</span>
          <Link className='mr-4' to='/contact'>
            Contact
          </Link>
          <Link className='mr-4' to='/contact'>
            Privacy Terms
          </Link>
        </div>
      </div>
      <div className='flex items-center justify-center flex-1'>
        <img src={LoginBanner} alt='banner' />
      </div>
    </div>
  );
};

export default ForgetPassword;
