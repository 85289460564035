import moment from 'moment';
import { Button, InputField, Divider } from 'nimbbl-quark';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useCreateLinkContext } from '..';
import { createPaymentLink } from '../utils';
import { LinkCustomerDetails } from '../../../../interfaces/PaymentLinks';
import { useGlobalStore } from '../../../../Context/GlobalStore';
import { ActionTypes } from '../../../../Context/reducer';
import { Collapse } from '@mui/material';
import { FormRow } from './FormRow';
import { FieldUtil } from '../../../../Utils';

const regexPattern = {
  subMerchant: /sub merchant link page not created/i,
  mobile: /valid 10 digit mobile number/i,
  invoice: /invoice_id/i,
};

const message = {
  create: {
    success: 'Payment link created successfully.',
    error: 'Something went wrong. Payment link not created.',
  },
  update: {
    success: 'Payment link updated successfully',
    error: 'Something went wrong. Payment link not updated.',
  },
  miscellaneous: {
    subMerchant: 'Sub Merchant Link page is not created for ',
    mobile: 'Please enter a valid 10-digit mobile number',
    invoice: 'Duplicate invoice id exits. Please enter a different invoice id.',
  },
};

const formatDate = (inputDate: Date | null): string | null => {
  // format date in: YYYY-MM-DD hh:mm:ss
  if (!inputDate) return null;

  return moment(inputDate).format('YYYY-MM-DD HH:mm:ss');
};

// TODO: remove this is a temporary fix for keyword "None" coming in error messages
// used in PaymentLink.tsx
export const replaceNone = (message: string): string =>
  message.replace(/none/i, '');

export const CustomerDetails = () => {
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();
  const [searchParams] = useSearchParams();
  const { linkDetailsForm, customerDetailsForm, isEdit } =
    useCreateLinkContext();
  const { getValues } = linkDetailsForm;
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = customerDetailsForm;
  console.log('errors', errors);

  const handleFormSubmit = async (customerDetails: LinkCustomerDetails) => {
    const linkDetails = getValues();
    const {
      sub_merchant_id,
      currency,
      total_amount,
      expires_at,
      product_details,
      terms_and_conditions,
      description,
      show_scheduled_at,
      notification_scheduled_at,
      ...restLinkDetails
    } = linkDetails;

    const { payment_link_id, ...restCustomerDetails } = customerDetails;

    const body = {
      ...restLinkDetails,
      ...restCustomerDetails,
      ...(isEdit && payment_link_id && { payment_link_id }),
      total_amount: parseFloat(total_amount),
      terms_and_conditions,
      // product_details,
      description,
      currency: currency?.value,
      sub_merchant_id: sub_merchant_id?.value,
      ...(expires_at && { expires_at: formatDate(expires_at) }),
      ...(show_scheduled_at &&
        notification_scheduled_at && {
          notification_scheduled_at: formatDate(notification_scheduled_at),
        }),
      product_details: product_details.map((product) => {
        const {
          amount_before_tax,
          isImageUrl,
          tax,
          title,
          description,
          image,
          image_url,
          quantity,
        } = product;

        return {
          rate: parseFloat(amount_before_tax),
          image_url: isImageUrl ? image_url : image,
          currency: currency?.value,
          quantity,
          total_amount:
            parseFloat(tax) + quantity * parseFloat(amount_before_tax),
          description,
          tax: parseFloat(tax),
          title,
        };
      }),
    };

    const data: any = await createPaymentLink(body, isEdit);
    console.log(data);
    if (data.status === 200 && !data.data.error) {
      if (isEdit) {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: message.update.success, type: 'success' },
        });
      } else {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: message.create.success, type: 'success' },
        });
      }
      navigate('/payment-links');
    } else {
      const errorMessage = data.data.error.data.message || '';

      if (errorMessage) {
        if (regexPattern.mobile.test(errorMessage)) {
          return dispatch({
            type: ActionTypes.setErrorMessage,
            payload: { message: message.miscellaneous.mobile, type: 'danger' },
          });
        } else if (regexPattern.invoice.test(errorMessage)) {
          return dispatch({
            type: ActionTypes.setErrorMessage,
            payload: { message: message.miscellaneous.invoice, type: 'danger' },
          });
        } else {
          return dispatch({
            type: ActionTypes.setErrorMessage,
            payload: { message: replaceNone(errorMessage), type: 'danger' },
          });
        }
      }

      if (isEdit) {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: message.update.error, type: 'danger' },
        });
      } else {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: message.create.error, type: 'danger' },
        });
      }
    }
  };

  const linkId = searchParams.get('id');
  const linkDetailsUrl =
    '/payment-links/create' + (linkId ? `?id=${linkId}` : '');
  const showEmail = watch('notification_channel_email');

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormRow
        required
        id='payer_first_name'
        label='Name'
        value={
          <div className='flex'>
            <InputField
              id='payer_first_name'
              style={{ marginTop: -4, marginRight: 16 }}
              caption={errors?.payer_first_name?.message}
              captionType='error'
              attributes={{
                placeholder: 'First Name',
                onKeyDown: (e) => {
                  const value = e.key;

                  if (
                    value !== FieldUtil.BACKSPACE_KEY_CODE &&
                    value !== FieldUtil.ENTER_KEY_CODE
                  ) {
                    if (!FieldUtil.checkIsValidName(value)) {
                      e.preventDefault();
                    }
                  }
                },
                ...register('payer_first_name', {
                  onBlur: (e) => {
                    setValue(
                      'payer_first_name',
                      e.target.value.replace(/\p{Emoji_Presentation}/gu, '')
                    );
                  },
                }),
              }}
            />
            <InputField
              id='payer_last_name'
              style={{ marginTop: -4 }}
              caption={errors?.payer_last_name?.message}
              captionType='error'
              attributes={{
                placeholder: 'Last Name',
                onKeyDown: (e) => {
                  const value = e.key;

                  if (
                    value !== FieldUtil.BACKSPACE_KEY_CODE &&
                    value !== FieldUtil.ENTER_KEY_CODE
                  ) {
                    if (!FieldUtil.checkIsValidName(value)) {
                      e.preventDefault();
                    }
                  }
                },
                ...register('payer_last_name', {
                  onBlur: (e) => {
                    setValue(
                      'payer_last_name',
                      e.target.value.replace(/\p{Emoji_Presentation}/gu, '')
                    );
                  },
                }),
              }}
            />
          </div>
        }
      />

      <FormRow
        required
        id='mobileNumber'
        label='Mobile Number'
        value={
          <InputField
            id='mobile_number'
            style={{ marginTop: -4 }}
            caption={errors?.payer_mobile_number?.message}
            captionType='error'
            attributes={{
              type: 'tel',
              placeholder: 'Enter mobile number',
              onKeyDown: (e) => {
                const value = e.key;

                if (
                  value !== FieldUtil.BACKSPACE_KEY_CODE &&
                  value !== FieldUtil.ENTER_KEY_CODE
                ) {
                  if (!FieldUtil.checkIsNumeric(value)) {
                    e.preventDefault();
                  }
                }
              },
              ...register('payer_mobile_number'),
            }}
          />
        }
      />

      <Collapse in={showEmail}>
        <FormRow
          required
          id='email'
          label='Email ID'
          // captionText='(optional)'
          value={
            <InputField
              id='email'
              style={{ marginTop: -4 }}
              caption={errors?.payer_email_address?.message}
              captionType='error'
              attributes={{
                placeholder: 'Enter email address',
                ...register('payer_email_address'),
              }}
            />
          }
        />
      </Collapse>

      <FormRow
        id='sendLink'
        label='Send Link to'
        value={
          <div className='flex items-center'>
            <input
              {...register('notification_channel_sms')}
              type='checkbox'
              id='notification_channel_sms'
              className='mr-2 rounded'
            />
            <label htmlFor='notification_channel_sms' className='mr-7'>
              SMS
            </label>

            <input
              {...register('notification_channel_email')}
              type='checkbox'
              id='notification_channel_email'
              className='mr-2 rounded'
            />
            <label htmlFor='notification_channel_email'>Email</label>
          </div>
        }
      />

      <Divider />

      <div className='flex justify-between my-6'>
        <Link to='/payment-links'>
          <Button
            variant='text'
            text='Cancel'
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              backgroundColor: '#E6EFFC',
              fontSize: 14,
              lineHeight: '20px',
              fontWeight: 500,
            }}
            attributes={{
              type: 'button',
            }}
          />
        </Link>

        <div className='flex'>
          <Link to={linkDetailsUrl}>
            <Button
              iconPosition='left'
              variant='text'
              text=' Previous'
              classNames='mr-3'
              style={{
                paddingLeft: 24,
                paddingRight: 24,
                backgroundColor: '#E6EFFC',
                fontSize: 14,
                lineHeight: '20px',
                fontWeight: 500,
              }}
              IconComponent={BackArrow}
            />
          </Link>
          <Button
            type='submit'
            variant='primary'
            text={`${isEdit ? 'Update' : 'Create'} payment link`}
            iconPosition='right'
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              fontSize: 14,
              lineHeight: '20px',
              fontWeight: 500,
            }}
          />
        </div>
      </div>
    </form>
  );
};

const BackArrow = () => <BiArrowBack className='mr-2' size={22} />;
