import { AxiosResponse } from 'axios';
import { useUnAuthenticatedRoutes } from '../Components/SessionExpiredModal/SessionExpiredModal';
import { Constants } from '../constants/miscellaneous';
import { useGlobalStore } from '../Context/GlobalStore';
import { ActionTypes } from '../Context/reducer';
import { useCurrentLocation } from './useCurrentLocation';

export const useCurrentSession = () => {
  const pathname = useCurrentLocation();
  const { dispatch } = useGlobalStore();
  const isUnAuthenticatedRoute = useUnAuthenticatedRoutes();
  const { sessionExpiredCode, sessionExpiredMessage } = Constants;

  const checkSessionError = (response: AxiosResponse<any, any>) => {
    const { data } = response;
    const { error = {} } = data;
    const { code, message } = error || { code: 0, message: '' };
    if (code === sessionExpiredCode && message === sessionExpiredMessage) {
      setSessionExpired();
      return true;
    }
    return false;
  };

  const hasSession = (): boolean => {
    const sessionId = localStorage.getItem('nimbbl_session_id');
    if (sessionId) {
      return true;
    }
    return false;
  };

  const setSessionExpired = () => {
    console.error('User Session Expired. Please Login');
    // delete localStorage session id(if any)
    localStorage.removeItem('nimbbl_session_id');
    // show session expired modal
    // dispatch({ type: ActionTypes.setCallbackUrl, payload: pathname });
    dispatch({ type: ActionTypes.setSession, payload: false });
    if (!isUnAuthenticatedRoute) {
      dispatch({ type: ActionTypes.setCallbackUrl, payload: pathname });
    }
  };

  return {
    hasSession,
    setSessionExpired,
    checkSessionError,
  };
};
