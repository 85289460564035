import { array, boolean, date, number, object, SchemaOf, string } from 'yup';
import {
  LinkCustomerDetails,
  LinkDetails,
  LinkProductDetails,
} from '../../../interfaces/PaymentLinks';

const checkAmount = (fieldName: string) =>
  string()
    .label(fieldName)
    .max(13, '${label} cannot be more than 10-digits')
    .matches(/^\d+\.?\d*$/, '${label} should be a number');

const getTodayDate = () => {
  const newDate = new Date();
  return newDate;
};

const ProductDetailsValidation: SchemaOf<LinkProductDetails> = object().shape({
  description: string().max(80).label('Description'),
  quantity: number()
    .min(1)
    .integer()
    .max(99_999, '${label} cannot be more than 5-digits')
    .required()
    .label('Quantity')
    .typeError('Quantity must be a number'),
  title: string().required().max(40).label('Name'),
  amount_before_tax: checkAmount('Price')
    .required()
    .test(
      'is-greater-than-0',
      '${label} should be greater than 0',
      (value) => parseFloat(value || '0') !== 0
    ),
  tax: checkAmount('Tax').required(),
  image: string().label('Image'),
  isImageUrl: boolean().required(),
  image_url: string().url().label('Image url'),
  defaultExpanded: boolean().required(),
  // .when('isImageUrl', { is: true, then: string().url().required() }),
});

export const LinkDetailsValidation: SchemaOf<LinkDetails> = object().shape({
  sub_merchant_id: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable()
    .required()
    .label('Submerchant'),
  currency: object()
    .shape({
      label: string(),
      value: string(),
    })
    .nullable()
    .required()
    .label('Currency'),
  total_amount: checkAmount('Amount')
    .required()
    .test(
      'is-greater-than-0',
      '${label} should be greater than 0',
      (value) => parseFloat(value || '0') !== 0
    ),
  // .when('product_details', (products, schema) => {
  //   if (Array.isArray(products) && products.length > 0) {
  //     const minValue = products.reduce(
  //       (value, { quantity, amount_before_tax, tax }) =>
  //         value + quantity * amount_before_tax + tax,
  //       0
  //     );
  //     return schema.min(minValue);
  //   }
  //   return schema;
  // }),
  callback_url: string().url().label('Callback URL'),
  description: string().label('Link Description'),
  expires_at: date()
    .nullable(true)
    .label('Expiry Date')
    .min(getTodayDate(), 'Expiry Date cannot be in the past'),
  invoice_id: string().required().label('Invoice'),
  terms_and_conditions: string().label('Terms & Condition'),
  show_scheduled_at: boolean().required(),
  notification_scheduled_at: date()
    .label('Schedule notification')
    .when('show_scheduled_at', {
      is: true,
      then: date()
        .min(new Date(), 'Schedule notification cannot be in the past')
        .typeError('Schedule notification should be valid date')
        .required(
          'Date & Time is a required field to schedule the Payment Link'
        ),
    }),
  product_details: array().of(ProductDetailsValidation),
});

export const LinkCustomerValidation: SchemaOf<LinkCustomerDetails> =
  object().shape({
    payment_link_id: number(),
    payer_first_name: string().label('First Name').required(),
    payer_last_name: string().label('Last Name'),
    payer_mobile_number: string().length(10).required().label('Mobile Number'),
    payer_email_address: string()
      .email()
      .label('Email ID')
      .when('notification_channel_email', (showEmail, schema) => {
        if (showEmail) {
          return schema.required();
        }
        return schema;
      }),
    notification_channel_email: boolean().required(),
    notification_channel_sms: boolean().required(),
  });
