export const Colors = {
  pending: { light: '#fcd34d', main: '#FF9800', dark: '#e5b800' },
  success: { light: '#4ade80', main: '#4CAF50', dark: '#00632b' },
  failed: { light: '#f87171', main: '#ef5350', dark: '#b01212' },
  info: { light: '#93c5fd', main: '', dark: '#7EA5F8' },
} as const;

export const BlueColors = [
  '#E6EFFC',
  '#D3E2F8',
  '#C0D5F4',
  '#ADC8EF',
  '#9ABBEB',
  '#87AEE7',
  '#75A1E3',
  '#6293DE',
  '#4F86DA',
  '#3C79D6',
  '#296CD2',
  '#165FCD',
  '#0352C9',
];

export const PurpleColors = [
  '#F0E7FC',
  '#E6D6FA',
  '#DCC5F8',
  '#D1B5F6',
  '#C7A4F4',
  '#BD93F2',
  '#B382F1',
  '#A871EF',
  '#9E60ED',
  '#9450EB',
  '#8A3FE9',
  '#7F2EE7',
  '#751DE5',
];

export const getColor = (colorArray: string[], index: number): string => {
  const length = colorArray.length - 1;
  if (index <= length) {
    return colorArray[index];
  }
  return getColor(colorArray, index - length);
};

// #FCCC75 #219653 #D83232
