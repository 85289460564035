import axios, { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useGlobalStore } from '../Context/GlobalStore';
import { ActionTypes } from '../Context/reducer';
import { Page } from '../interfaces/StateData';
import { CsvExportUtil, FileUtil } from '../Utils';
import { useCommonTracker } from './useCommonTracker';

type AnyObject = { [key: string]: any };
const errorMessage = {
  timeout: 'Request timeout. Requested file cannot be downloaded.',
  default: 'Something went wrong. Requested file cannot be downloaded.',
};

export const useCsvExport = (page: Page, filter: AnyObject) => {
  const [loading, setLoading] = useState(false);
  const { trackPageExported } = useCommonTracker();
  const { dispatch } = useGlobalStore();

  // const tmpe = PageMap[page];
  // const idList = { []: [] };

  if (page === 'Settlement') {
    page = 'Settlement Summary';
  }

  const data = useMemo(
    () => ({
      // required fields otherwise api throw error
      ids: false,
      import_compat: false,

      ...CsvExportUtil.getFields(page),
      domain: CsvExportUtil.generateFilterParams(filter, page),
    }),
    [filter, page]
  );

  const exportAll = async (ids: string[] = [], keyName: string = '') => {
    const token = CsvExportUtil.getToken();
    const body = { ...data };

    if (ids.length > 0 && keyName) {
      body.domain = [[keyName, 'in', ids]];
    }
    CsvExportUtil.setSpecialFilters(page, body.domain);

    const formData = new FormData();
    formData.append('token', token);
    formData.append('data', JSON.stringify(body));
    console.log('formData', body);

    const id_list =
      page === 'Refunds'
        ? { refund_id_list: ids }
        : page === 'Settlement'
        ? { settlement_id_list: ids }
        : null;

    try {
      setLoading(true);
      const response = await axios.post(CsvExportUtil.url + token, formData, {
        responseType: 'blob',
        timeout: 180000,
      });
      const fileName = FileUtil.getExportName(page) + '.xlsx';
      FileUtil.download(response.data, fileName);

      trackPageExported(page, {
        filters: {
          from_datetime: filter.start_date,
          to_datetime: filter.end_date,
          ...filter,
        },
        ...(!!id_list && id_list),
      });
    } catch (err) {
      console.log(err);
      const { code } = err as AxiosError;
      // show alert
      if (code === '504') {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: errorMessage.timeout, type: 'danger' },
        });
      } else {
        dispatch({
          type: ActionTypes.setErrorMessage,
          payload: { message: errorMessage.default, type: 'danger' },
        });
      }
    }

    setLoading(false);
  };

  return { exportAll, loading };
};
