export const RefundModel = 'nimbbl.transaction';

//     { label: 'net_settlement_value', key: 'net_settlement_value' },

export const RefundFields = [
  // refund details
  {
    name: 'orignal_payment_transaction_id',
    label: 'Transaction ID',
    store: true,
  },
  { name: 'total_amount', label: 'Total Amount', store: false },
  { name: 'refund_amount', label: 'Refund Value', store: true },
  { name: 'create_date', label: 'Refund Initiated Date', store: true },
  { name: 'transaction_id', label: 'Refund ID', store: true },
  { name: 'order_id', label: 'Order ID', store: true },
  { name: 'status', label: 'Status', store: true },
  { name: 'payment_partner', label: 'Payment Partner', store: true },
  { name: 'payment_mode', label: 'Payment Mode', store: true },
  { name: 'user_email', label: 'Email', store: false },
  { name: 'user_mobile', label: 'Mobile', store: false },
  { name: 'refund_arn', label: 'Refund ARN', store: true },
  { name: 'partner_settement_id', label: 'PSP Settlement ID', store: true },
  { name: 'settlement_date', label: 'Settlement Date', store: true },
  { name: 'utr_no', label: 'UTR No', store: true },
  { name: 'currency', label: 'Order Currency', store: true },
  { name: 'settled_amount', label: 'Net Settlement Value', store: true },
  {
    name: 'order_custom_attributes',
    label: 'Custom Attributes',
    store: true,
  },

  { name: 'currency', label: 'Refund Currency', store: true },
  { name: 'order_id/currency', label: 'Order Currency', store: true },
  { name: 'order_id/total_amount', label: 'Order Amount', store: true },
  {
    name: 'order_id/total_refundable_amount',
    label: 'Refundable Amount',
    store: false,
  },
  { name: 'order_id/refunded_amount', label: 'Refunded Amount', store: true },
  { name: 'currency', label: 'Refundable Currency', store: true },

  // {
  //   name: 'order_id/refundable_currency',
  //   label: 'Refundable Currency',
  //   store: true,
  // },

  // EOF

  // { name: 'order_id', label: 'Order ID', store: true },
  // {
  //   name: 'psp_generated_txn_id',
  //   label: 'Psp Generated Transaction Id',
  //   store: true,
  // },
  // { name: 'transaction_type', label: 'Transaction Type', store: true },
  // { name: 'merchant_id', label: 'Merchant', store: true },
  // { name: 'sub_merchant_id', label: 'Sub Merchant', store: true },
  // { name: 'order_invoice_id', label: 'Invoice Id', store: false },

  // // amount details
  // { name: 'refund_done', label: 'Refund Done', store: true },
  // { name: 'grand_total_amount', label: 'Grand Total Amount', store: true },
  // { name: 'offer', label: 'Offer', store: true },
  // { name: 'offer_discount', label: 'Offer Discount', store: true },
  // { name: 'additional_charges', label: 'Convenience Fee', store: true },

  // settlement details

  // { name: 'write_date', label: 'Last Updated on', store: true },
  // { name: 'user_name', label: 'User Name', store: false },
  // { name: 'user_unique_id', label: 'User Id ', store: false },
];
