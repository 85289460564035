import { FC } from 'react';
import { InputField } from 'nimbbl-quark';
import FieldNames from '../../../constants/FieldNames';

interface AmountFieldProps {
  appendLabel?: string;
  minValue: string;
  onMinChange: (value: string) => void;
  maxValue: string;
  onMaxChange: (value: string) => void;
  error?: string;
}

const AmountField: FC<AmountFieldProps> = (props) => {
  const { appendLabel, error, maxValue, minValue, onMaxChange, onMinChange } =
    props;

  return (
    <div className='mt-6 form-input'>
      <div className='mb-1'>
        <label className='text-sm font-semibold text-black-primary'>
          {appendLabel && appendLabel + ' '}Amount (Min/Max)
        </label>
      </div>
      <div className='flex'>
        <InputField
          type='text'
          id='min'
          attributes={{
            value: minValue,
            onChange: (e) => onMinChange(e.currentTarget.value),
          }}
          placeholder={`Enter ${FieldNames.minAmount}`}
        />
        <div className='min-w-2'></div>
        <InputField
          type='text'
          id='max'
          attributes={{
            value: maxValue,
            onChange: (e) => onMaxChange(e.currentTarget.value),
          }}
          placeholder={`Enter ${FieldNames.maxAmount}`}
        />
      </div>

      {error && (
        <div>
          <p className='pt-1 font-light text-error-40'>{error}</p>
        </div>
      )}
    </div>
  );
};

export default AmountField;
