import { array, date, number, object, string } from 'yup';

import { daysOptions, statusOptions } from '../constants';
import DateUtils from '../utils/DateUtils';

export type Option = { label: string; value: string };

export interface FilterCommonFields {
  status: Option[];
  duration: (typeof daysOptions)[number];
  startDate: Date | null;
  endDate: Date | null;
  orderId?: string;
  invoiceId?: string;
  mobile?: string;
  email?: string;
  settlementType?: Option[];
  settlementEntryType?: Option | null;
  subMerchantId?: Option[];
  singleSubMerchantId?: string;
  currency?: Option | null;
  subMerchantType?: Option | null;
  minAmount?: string;
  maxAmount?: string;
  customAttributes?: string;
  refundId?: string;
  transactionId?: string;
  paymentMode?: Option[] | null;
  paymentPartner?: Option[] | null;
  cardNo?: string;
  bankName?: Option[] | null;
  walletName?: Option[] | null;
  upiId?: string;
  settlementId?: string;
  utr?: string;
  linkHash?: string;
  bulkRefundId?: string;
}

export class MetaData {
  next?: number | null = null;
  page: number | null = null;
  per_page: number | null = null;
  prev?: number | null = null;
  total_pages: number | null = null;
  total_records: number | null = null;
}

export const defaultCommonFilters = {
  status: [statusOptions[0]],
  duration: daysOptions[1],
  startDate: DateUtils.getDefaultStartDate(),
  endDate: new Date(),
};

export const commonSchema = {
  status: array().of(object().shape({ label: string(), value: string() })),
  duration: object().shape({ label: string(), value: number() }),
  startDate: date().nullable(),
  endDate: date().nullable(),
  orderId: string(),
  invoiceId: string(),
  mobile: string(),
  subMerchantId: array().of(
    object().shape({ label: string(), value: string() })
  ),
  singleSubMerchantId: string(),
  email: string().email().label('Filter by email'),
  currency: object().nullable().shape({ label: string(), value: string() }),
  minAmount: string()
    .notRequired()
    .test('min-amt-positive', 'Amount should be positive', (minAmount) =>
      !minAmount ? true : Number(minAmount) >= 0 || false
    )
    .when('maxAmount', {
      is: (maxAmount: string, _schema: any) => {
        if (maxAmount) {
          return true;
        }
        return false;
      },
      then: string().test(
        'min-amt-max',
        'Min amount should be less than max amount',
        (minAmount, schema) =>
          minAmount ? Number(minAmount) < Number(schema.parent.maxAmount) : true
      ),
    }),
  maxAmount: string().test(
    'max-amt-positive',
    'Amount should be positive',
    (maxAmount) => (!maxAmount ? true : Number(maxAmount) >= 0 || false)
  ),
  customAttributes: string(),
};
