import { PointOptionsObject, Options } from 'highcharts';
import { Colors, PurpleColors } from '../../../constants/color';
import { AnalyticsResponse } from '../AnalyticsResponse';
import { formatNumber, RawData } from '../sharedUtil';

export class PaymentPartnerUtil {
  static getData(inputData: AnalyticsResponse['payment_partner']) {
    const result: Record<string, RawData[]> = {};
    result[''] = [];

    Object.entries(inputData).forEach(([paymentPartner, values], index) => {
      const {
        amount,
        amount_percentage,
        count,
        count_percentage,
        failed_count,
        failed_amount,
        pending_count,
        pending_amount,
        succeeded_count,
        succeeded_amount,
      } = values;

      result[''].push({
        id: paymentPartner,
        name: paymentPartner,
        amount,
        count,
        colorValue: index,
        amount_percentage,
        count_percentage,
      });

      result[paymentPartner] = [
        {
          id: 'Pending',
          name: 'Pending',
          color: Colors.pending.light,
          amount: pending_amount,
          count: pending_count,
          amount_percentage: (pending_amount / amount) * 100,
          count_percentage: (pending_count / count) * 100,
        },
        {
          id: 'Failed',
          name: 'Failed',
          color: Colors.failed.light,
          amount: failed_amount,
          count: failed_count,
          amount_percentage: (failed_amount / amount) * 100,
          count_percentage: (failed_count / count) * 100,
        },
        {
          id: 'Succeeded',
          name: 'Succeeded',
          color: Colors.success.light,
          amount: succeeded_amount,
          count: succeeded_count,
          amount_percentage: (succeeded_amount / amount) * 100,
          count_percentage: (succeeded_count / count) * 100,
        },
      ];
    });

    return result;
  }

  static getOptions(data: PointOptionsObject[]): Options {
    return {
      colorAxis: {
        minColor: PurpleColors.at(-1),
        maxColor: PurpleColors[0],
      },
      lang: {
        thousandsSep: ',',
      },
      legend: { enabled: false },
      series: [
        {
          opacity: 1,
          states: { hover: { enabled: false } },
          name: 'Payment Modes',
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          layoutStartingDirection: 'horizontal',
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'middle',
            style: {
              fontSize: '12px',
              textOutline: 'none',
            },
          },
          data,
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        useHTML: true,
        borderRadius: 4,
        followPointer: true,
        // pointFormat:
        // '{point.name}: <strong>{point.count:,0.2f} {point.currency}</strong>',
        pointFormatter: function () {
          return `${this.name}: <strong>${formatNumber(this.count)} ${
            this.currency
          }</strong>`;
        },
      },
    };
  }
}
