import axios from 'axios';
import getApiEndPoint from './getApiEndpoint';
import {
  FilterAnalyticsFields,
  defaultAnalyticsFilters,
} from '../../Analytics/useFilterAnalytics';
import DateUtils from './DateUtils';
import { daysOptions } from '../constants';
import FieldNames from '../../../constants/FieldNames';

class FilterAnalyticUtils {
  static generateParams(formData: FilterAnalyticsFields) {
    // for filter api
    const filterParams: Record<string, string | null> = {};
    // for filter section
    const filterSection: Record<string, string> = {};

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case 'status': {
          const values = formData[filterName].map((item) => item.value);
          // if all is selected then don't send/show status filter
          if (/all/i.test(values.join(','))) break;
          if (values.length === 0) break;

          filterParams[filterName] = values.join(',');

          const value = formData[filterName]
            .map((item) => item.label)
            .join(', ');
          filterSection[FieldNames.status] = value;
          break;
        }
        case 'startDate': {
          const startDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams['start_date'] =
            DateUtils.getStringFromDate(startDate, {
              removeSeconds: true,
            })?.replaceAll('/', '-') ?? null;

          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case 'endDate': {
          let endDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
          }

          filterParams['end_date'] =
            DateUtils.getStringFromDate(endDate, {
              removeSeconds: true,
            })?.replaceAll('/', '-') ?? null;

          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case 'paymentMode': {
          const formValue = formData[filterName];
          if (!formValue) break;
          if (!Array.isArray(formValue)) break;
          if (formValue.length === 0) break;

          const values = formValue.map((item) => item.value);
          if (values.length === 0) break;

          filterParams['payment_mode'] = values.join(',');

          const value = formValue.map((item) => item.label).join(', ');
          filterSection[FieldNames.paymentMode] = value;
          break;
        }
        case 'paymentPartner': {
          const formValue = formData[filterName];
          if (!formValue) break;
          if (!Array.isArray(formValue)) break;
          if (formValue.length === 0) break;

          const values = formValue.map((item) => item.value);
          if (values.length === 0) break;

          filterParams['payment_partner'] = values.join(',');

          const value = formValue.map((item) => item.label).join(', ');
          filterSection[FieldNames.paymentPartner] = value;
          break;
        }
        case 'subMerchantId': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const { label, value } = formValue;
          if (!value) break;

          filterParams['sub_merchant_id'] = value;
          filterSection[FieldNames.submerchantId] = label;
          break;
        }
        case 'subMerchantType': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const { label, value } = formValue;
          if (!value) break;

          filterParams['sub_merchant_type'] = value;
          filterSection[FieldNames.submerchantType] = label;
          break;
        }
        case 'currency': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const { label, value } = formValue;
          if (!value) break;

          filterParams['currency'] = value;
          filterSection[FieldNames.currency] = label;
          break;
        }
      }
    });

    return { filters: filterParams, filterSection };
  }

  static removeParams(
    formData: FilterAnalyticsFields,
    filterName: string
  ): FilterAnalyticsFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case FieldNames.status:
        return { ...result, status: defaultAnalyticsFilters.status };
      case 'date':
        return {
          ...result,
          startDate: defaultAnalyticsFilters['startDate'],
          endDate: defaultAnalyticsFilters['endDate'],
        };
      case FieldNames.paymentMode:
        return { ...result, paymentMode: undefined };
      case FieldNames.paymentPartner:
        return { ...result, paymentPartner: undefined };
      case FieldNames.submerchantId:
        return { ...result, subMerchantId: null };
      case FieldNames.submerchantType:
        return {
          ...result,
          subMerchantType: defaultAnalyticsFilters.subMerchantType,
        };
      case FieldNames.currency:
        return { ...result, currency: defaultAnalyticsFilters.currency };
      default:
        return { ...defaultAnalyticsFilters };
    }
  }

  static getApiData = (body: object) =>
    axios.post(getApiEndPoint('paymentModeChart'), body);
}

export default FilterAnalyticUtils;
