import { array, object, string } from 'yup';
import {
  FilterCommonFields,
  Option,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';
import { useCurrentSession, useTracker } from '../../Hooks';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterAnalyticUtils from '../FilterPopup/utils/FilterAnalyticUtils';
import { subMerchantTypeOptions } from '../FilterPopup/constants';
import { AnalyticsResponse } from './AnalyticsResponse';

// status, duration, date range,
// payment mode, payment partner, submerchant,
// submerchant type, transaction currency

export type FilterAnalyticsFields = Pick<
  FilterCommonFields,
  | 'status'
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'paymentMode'
  | 'paymentPartner'
  | 'subMerchantType'
  | 'currency'
> & { subMerchantId?: Option | null };

export const defaultAnalyticsFilters: FilterAnalyticsFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
  currency: { label: 'INR - Indian Rupee', value: 'INR' },
  subMerchantType: subMerchantTypeOptions[0],
};

export const analyticSchema = object().shape({
  status: commonSchema.status,
  duration: commonSchema.duration,
  startDate: commonSchema.startDate,
  endDate: commonSchema.endDate,
  paymentMode: array().of(object().shape({ label: string(), value: string() })),
  paymentPartner: array().of(
    object().shape({ label: string(), value: string() })
  ),
  subMerchantId: object().shape({ label: string(), value: string() }),
  subMerchantType: object().shape({ label: string(), value: string() }),
  currency: commonSchema.currency,
});

const useFilterAnalytics = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState<AnalyticsResponse>(
    new AnalyticsResponse()
  );
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const form = useForm<FilterAnalyticsFields>({
    defaultValues: defaultAnalyticsFilters,
    resolver: yupResolver(analyticSchema),
  });

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterAnalyticUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const fetchApiData = useCallback(
    async (formData: FilterAnalyticsFields & { page?: number }) => {
      const { page, ...restFormData } = formData;
      const { filters, filterSection } =
        FilterAnalyticUtils.generateParams(restFormData);

      const response = await FilterAnalyticUtils.getApiData(filters);

      // check for user token
      if (checkSessionError(response)) return;

      const apiData = response.data?.result ?? new AnalyticsResponse();

      const error = response.data?.error?.message;

      track({
        name: 'Transactions' + TrackEventNames.searchClicked,
        inBuiltOptions: ['merchantId', 'merchantName'],
        additionalOptions: {
          menu_item_name: 'Transactions',
          // @ts-ignore
          filters: filterSection,

          ...(error && { failure_error_message: error }),
        },
      });

      return { apiData, filterSection, filters };
    },
    [checkSessionError, track]
  );

  const handleFormSubmit = useCallback(
    async (formData: FilterAnalyticsFields & { page?: number }) => {
      setLoading(true);
      const { filterSection, apiData } = (await fetchApiData(formData)) ?? {};

      setApiData(apiData);
      setFilters(filterSection);
      setLoading(false);
      setFilterModalShown(false);
    },
    [fetchApiData]
  );

  return {
    filters,
    form,
    loading,
    apiData,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    setLoading,
    removeFilter,
  };
};

export default useFilterAnalytics;
