import { CSSProperties, FC } from 'react';

import { Dropdown } from '../../Dropdown';
import { daysOptions } from '../constants';

interface DurationFieldProps {
  label?: string;
  value: (typeof daysOptions)[number];
  setValue: (newValue: DurationFieldProps['value']) => void;
  containerStyles?: CSSProperties;
}

const DurationField: FC<DurationFieldProps> = (props) => {
  const { label, value, setValue, containerStyles } = props;

  return (
    <div className='mt-6 form-input' style={containerStyles}>
      <div className='mb-1'>
        <label
          htmlFor='duration'
          className='text-sm font-semibold text-black-primary'>
          {label ?? 'Duration'}
        </label>
      </div>

      <Dropdown
        id='duration'
        options={daysOptions}
        value={value}
        onChange={setValue}
      />
    </div>
  );
};

export default DurationField;
