import { FC } from 'react';
import { Dropdown } from '../../Dropdown';
import { Option } from '../interfaces/CommonFields';
import { transactionOptions } from '../constants';

interface SettlementTypeFieldProps {
  value: Option[];
  setValue: (newValue: Option[]) => void;
}

const SettlementTypeField: FC<SettlementTypeFieldProps> = (props) => {
  const { setValue, value } = props;

  const handleChange = (selectedTypes: any) => {
    const isAllPresent = selectedTypes.some(
      (status: any) => status.value === 'all'
    );
    const onlyAllIsPresent =
      isAllPresent && value.length === 1 && value[0].value === 'all';

    // when all is present remove other statuses
    if (isAllPresent && !onlyAllIsPresent) {
      const allOption = selectedTypes.filter(
        (status: any) => status.value === 'all'
      );
      setValue(allOption);
      return;
    }

    // keep all statuses except all
    const typesWithoutAll = selectedTypes.filter(
      (status: any) => status.value !== 'all'
    );
    setValue(typesWithoutAll);
  };

  return (
    <div className='mt-6 form-input'>
      <div className='mb-1'>
        <label
          htmlFor='status'
          className='text-sm font-semibold text-black-primary'>
          Settlement Type
        </label>
      </div>

      <Dropdown
        id='settlement_type'
        multiple
        options={transactionOptions}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default SettlementTypeField;
