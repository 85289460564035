import React, { useMemo, useState, useEffect } from "react";
import logo from "./../../images/logo.svg";
import LoginBanner from "./../../images/loginBanner.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { setUserId } from "@amplitude/analytics-browser";
import {
  ILoginFormData,
  ILoginRes,
  IMerchantConf,
} from "../../interfaces/Login";
import axios from "axios";

import constants from "../../constants";
import { NIMBBL_DB } from "../../constants/api";

import { Button, InputField } from "nimbbl-quark";
import { useGlobalStore } from "../../Context/GlobalStore";
import { ActionTypes } from "../../Context/reducer";
import { useCommonTracker, useTracker, setAmplitudeId } from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";

const validationSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const { track } = useTracker();
  const { trackPageRendered } = useCommonTracker();
  const { dispatch } = useGlobalStore();
  const [resultData, setResultData] = useState<ILoginRes>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  useEffect(() => {
    trackPageRendered("Sign In", {});
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      const sessionId = window.localStorage.getItem("nimbbl_session_id");
      if (sessionId) {
        let url = constants.ROUTES.ORDERS;
        if (redirectUrl) {
          url = redirectUrl;
        }
        navigate(url);
      }
    };

    handleFocus();
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [navigate, redirectUrl]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ILoginFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // const [email, password] = watch(['email', 'password']);

  // const debounceSignInFormTrack = useMemo(
  //   () =>
  //     debounce(() => {
  //       if (email || password) {
  //         track({ name: TrackEventNames.signInForm });
  //       }
  //     }, 700),
  //   [email, password, track]
  // );

  // useEffect(() => {
  //   debounceSignInFormTrack();

  //   return () => {
  //     debounceSignInFormTrack.cancel();
  //   };
  // }, [debounceSignInFormTrack]);

  const getMerchantConfig = async (token: string, id: number) => {
    // let id:number = window.localStorage.getItem("nimbbl_user_id")
    try {
      const res = await axios.post<IMerchantConf>(
        `${constants.API.MERCHANT_CONF}?session_id=${token}`,
        {
          params: {
            user_id: id,
          },
        }
      );

      const merchant = {
        id: res.data.result.result.merchant_id,
        name: res.data.result.result.merchant_name,
        unique_merchant_id: res.data.result.result.unique_merchant_id,
      };

      // Event
      window.localStorage.setItem("merchant_name", merchant.name);
      window.localStorage.setItem(
        "merchant_id",
        res.data.result.result.unique_merchant_id
      );
      window.localStorage.setItem("merchant_index_id", merchant.id);
      window.localStorage.setItem(
        "merchant_email",
        res.data.result.result.merchant_email
      );

      track({
        name: TrackEventNames.signedIn,
        additionalOptions: {
          merchant_id: merchant.id,
          merchant_mobile: res.data.result.result.phone,
          merchant_login_first_name: res.data.result.result.login_name,
          merchant_login_email: res.data.result.result.merchant_email,
          merchant_name: merchant.name,
        },
      });

      const user_id = window.localStorage.getItem("merchant_id");
      window?.analytics?.identify(`${user_id}`, {
        name: res.data.result.result.merchant_name,
        email: res.data.result.result.merchant_email,
        phone: res.data.result.result.phone,
        user_id: user_id,
        merchant_id: res.data.result.result.merchant_id,
        login_name: res.data.result.result.merchant_name,
        type: "merchant",
      });
      if (res.data.result.result.user_id) {
        window.localStorage.setItem(
          "nimbbl_admin",
          res.data.result.result.refund_initial_button
        );
      } else {
        window.localStorage.setItem("nimbbl_admin", "false");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async (data: ILoginFormData) => {
    try {
      const res = await axios.post<ILoginRes>(`${constants.API.AUTH_API}`, {
        params: {
          login: data.email,
          password: data.password,
          db: NIMBBL_DB,
        },
      });
      if (res.data.result.error) {
        track({
          name: TrackEventNames.failedSignedIn,
          // inBuiltOptions: ['merchantId', 'merchantName', "merchantEmail"],
          additionalOptions: {
            merchant_login_email: data.email,
            failure_error_message: res.data.result.error.message,
          },
        });
      }

      setResultData(res.data);
      if (res.data?.result?.result?.session_id) {
        const data = {
          isAdmin: res.data.result.result.is_admin,
          sessionId: res.data.result.result.session_id,
          userId: res.data.result.result.uid,
          userName: res.data.result.result.name,
          userEmail: res.data.result.result.username,
        };

        window.localStorage.setItem("internal_admin", data.isAdmin);
        window.localStorage.setItem("nimbbl_session_id", data.sessionId);
        window.localStorage.setItem("nimbbl_user_id", data.userId);
        window.localStorage.setItem("nimbbl_user_name", data.userName);
        window.localStorage.setItem("nimbbl_user_email", data.userEmail);

        getMerchantConfig(data.sessionId, data.userId);
        setAmplitudeId();

        dispatch({ type: ActionTypes.setSession, payload: true });
        dispatch({ type: ActionTypes.setCallbackUrl, payload: null });
        // let url = '/home';
        let url = "/orders";
        if (redirectUrl) {
          url = redirectUrl;
        }
        track({
          name: TrackEventNames.dashboardRendered,
          inBuiltOptions: ["merchantId", "merchantName"],
        });
        navigate(url);
      }
    } catch (err) {
      console.error(err);
      // trackPageRendered('API Keys', {
      //   developer_section_name: 'API_Keys',
      //   sub_merchant_id: subMerchantid,
      // });
      // segmentApiRender(null);
    }
  };

  const handleForgetPass = () => {
    track({ name: TrackEventNames.forgotPassword });
    navigate("/forgot-password");
  };

  return (
    <div className="flex justify-between h-screen">
      <div className="flex flex-col items-center justify-between w-1/2">
        <div className="flex flex-col justify-center grow">
          <img
            style={{ width: 115, height: 75 }}
            src={logo}
            alt="logo"
            className="mb-16"
          />
          <h3 className="flex mb-6 text-2xl font-bold text-left justify-items-start">
            Hello!
          </h3>
          {resultData?.result?.error && (
            <span className="block mb-3 text-error-40">
              {resultData?.result.error.message}
            </span>
          )}
          <form
            className="login-form"
            onSubmit={handleSubmit(handleLogin)}
            noValidate
          >
            <InputField
              type="text"
              id="email"
              attributes={{
                type: "email",
                ...register("email"),
                style: {
                  display: "block",
                  width: "100%",
                  marginBottom: "16px",
                },
              }}
              caption={errors.email?.message}
              captionType="error"
              label="Registered Email Address"
            />
            <InputField
              type="password"
              id="password"
              attributes={{
                ...register("password"),
                style: { display: "block", width: "360px" },
              }}
              caption={errors.password?.message}
              captionType="error"
              label="Password"
            />
            <Button
              text="Log In"
              type="submit"
              attributes={{
                style: {
                  width: "160px",
                  marginTop: "20px",
                  background: "#045CE0",
                },
              }}
            />
            <Button
              variant="link"
              text="Forgot Password?"
              attributes={{
                style: {
                  fontSize: "14px",
                  color: "#94A3B8",
                  display: "block",
                  fontWeight: "normal",
                  paddingLeft: "0px",
                },
                onClick: () => handleForgetPass(),
              }}
            ></Button>
          </form>
        </div>
        <div className="w-full mb-2 text-center text-tertiary-10">
          <span>
            <a
              href="https://nimbbl.biz/"
              target="_blank"
              rel="noreferrer"
              className="mr-4"
            >
              @Nimbbl
            </a>
          </span>
          <a
            href="https://nimbbl.biz/get-in-touch"
            className="mr-4"
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <a
            href="https://nimbbl.biz/about-us"
            className="mr-4"
            target="_blank"
            rel="noreferrer"
          >
            About
          </a>
          <a
            href="https://nimbbl.biz/privacy-policy"
            className="mr-4"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Terms{" "}
          </a>
        </div>
      </div>
      <div
        style={{
          width: "50%",
          maxHeight: "100%",
          overflowY: "hidden",
        }}
      >
        <img
          style={{ objectFit: "contain", width: "100%", height: "auto" }}
          src={LoginBanner}
          alt="banner"
        />
      </div>
    </div>
  );
};

export default Login;
