import { Skeleton, TableCell, TableRow } from '@mui/material';

const skeletonArray = Array.from(Array(10).keys());

export const Loader = () => {
  return (
    <div className='w-full'>
      <table className='w-full'>
        {skeletonArray.map((item) => (
          <TableRow key={item} style={{ width: '100%' }}>
            <TableCell component='th' scope='row' style={{ width: '20%' }}>
              <Skeleton />
            </TableCell>
            <TableCell align='right' style={{ width: '20%' }}>
              <Skeleton />
            </TableCell>
            <TableCell align='right' style={{ width: '20%' }}>
              <Skeleton />
            </TableCell>
            <TableCell align='right' style={{ width: '20%' }}>
              <Skeleton />
            </TableCell>
            <TableCell align='right' style={{ width: '20%' }}>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}{' '}
      </table>
    </div>
  );
};
