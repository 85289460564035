export const SettlementDetailModel = 'nimbbl.payment_settlement_logs';

export const SettlementDetailFields = [
  { name: 'sub_merchant_id', label: 'Submerchant', store: true },
  // {
  //   name: 'sub_merchant_description',
  //   label: 'Submerchant',
  //   store: true,
  // },
  { name: 'partner_settement_id', label: 'PSP Settlement ID', store: true },
  { name: 'settlement_date', label: 'Settlement Date', store: true },
  {
    name: 'transaction_type',
    label: 'Settlement Type',
    store: true,
  },
  { name: 'utr_no', label: 'UTR', store: true },
  { name: 'order_invoice_id', label: 'Invoice', store: false },
  { name: 'order_id', label: 'Order', store: true },
  { name: 'transaction_id', label: 'Transaction ID', store: true },
  { name: 'transaction_date', label: 'Transaction Date', store: false },
  { name: 'payment_partner', label: 'Payment Partner', store: true },
  { name: 'transaction_payment_mode', label: 'Payment Mode', store: false },
  { name: 'fee_amount', label: 'PSP Fee', store: true },
  { name: 'tax_amount', label: 'Tax on PSP Fee', store: true },
  { name: 'transaction_amount', label: 'Transaction Amount', store: true },
  { name: 'settled_amount', label: 'Settlement Amount', store: true },
  { name: 'settlement_currency', label: 'Settlement Currency', store: true },
  {
    name: 'transaction_currency',
    label: 'Transaction Currency',
    store: true,
  },
  {
    name: 'converted_transaction_amount',
    label: 'Converted Transaction Amount',
    store: true,
  },
  {
    name: 'psp_currency_conversion_rate',
    label: 'PSP Currency Conversion Rate',
    store: true,
  },
  {
    name: 'order_custom_attributes',
    label: 'Custom Attributes',
    store: true,
  },
  {
    name: 'original_payment_transaction_id',
    label: 'Related Transaction ID',
    store: true,
  },

  // settlement details

  // { name: 'transaction_type', label: 'Transaction Type', store: false },

  // settlement amount

  // { name: 'psp_settlement_log', label: 'PSP Settlement Log', store: true },
  // {
  //   name: 'psp_settlement_summary',
  //   label: 'PSP Settlement summary',
  //   store: true,
  // },
];
