import { Button, Drawer } from 'nimbbl-quark';
import { FC, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import FilterContainer from './Fields/Container';
import DurationField from './Fields/DurationField';
import FilterHeading from './Fields/Heading';
import StatusField from './Fields/StatusField';
import { daysOptions, defaultFilterWidth } from './constants';
import DateUtils from './utils/DateUtils';
import DateRangeField from './Fields/DateRangeField';
import TextField from './Fields/TextField';
import AmountField from './Fields/AmountField';
import CurrencyField from './Fields/CurrencyField';
import SubMerchantField from './Fields/SubMerchantField';
import { FilterOrderFields } from '../Orders/useFilterOrders';
import { useTracker } from '../../Hooks';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterSubmitButton from './Fields/SubmitButton';
import FieldNames from '../../constants/FieldNames';

interface FilterOrdersProps {
  form: UseFormReturn<FilterOrderFields, object>;
  handleFormSubmit: (formData: FilterOrderFields) => Promise<void>;
  width?: string | number;
  isActive: boolean;
  onHide: () => void;
  removeFilter: (filterName?: string) => Promise<void>;
}

const FilterOrders: FC<FilterOrdersProps> = (props) => {
  const {
    isActive,
    onHide,
    width = defaultFilterWidth,
    form,
    handleFormSubmit,
    removeFilter,
  } = props;

  const { track } = useTracker();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = form;

  const duration = watch('duration');

  useEffect(() => {
    // useEffect to keep duration and dates in sync
    const { value } = duration;
    if (!value) return;

    setValue('startDate', DateUtils.subtractDays(value));

    const customOption = daysOptions[daysOptions.length - 1];
    if (value === customOption.value) return;

    setValue('endDate', new Date());
  }, [duration, setValue]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    setValue('startDate', startDate);
    setValue('endDate', endDate);
    setValue('duration', daysOptions[daysOptions.length - 1]);
  };

  const handleClearAll = async () => {
    await removeFilter('default');
    track({
      name: 'Order ' + TrackEventNames.filterFieldsCleared,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Orders',
        filters: getValues(),
      },
    });
  };

  console.log('filter orders errors', errors);

  return (
    <Drawer
      position='right'
      isShown={isActive}
      style={{ width }}
      hide={onHide}
      headerElement={<FilterHeading title='Filter Orders' />}
      bodyElement={
        <FilterContainer>
          <form
            className='relative filter-form pb-[156px]'
            onSubmit={handleSubmit(handleFormSubmit)}
            noValidate>
            <span
              className='absolute right-0 text-sm cursor-pointer clear-all'
              style={{ color: '#D83232' }}
              onClick={handleClearAll}>
              Clear All
            </span>

            <StatusField
              isOrderType
              value={watch('status')}
              setValue={(statuses) => setValue('status', statuses)}
            />

            <DurationField
              label='Order Duration'
              value={watch('duration')}
              setValue={(newValue) => setValue('duration', newValue)}
            />

            <DateRangeField
              label='Order Date Range'
              value={[watch('startDate'), watch('endDate')]}
              onChange={handleDateChange}
            />

            <TextField
              label={FieldNames.orderId}
              id='order_id'
              placeHolder={`Enter ${FieldNames.orderId}`}
              value={watch('orderId') ?? ''}
              onChange={(orderId) => setValue('orderId', orderId)}
            />

            <TextField
              label={FieldNames.invoiceId}
              id='invoice_id'
              placeHolder={`Enter ${FieldNames.invoiceId}`}
              value={watch('invoiceId') ?? ''}
              onChange={(invoiceId) => setValue('invoiceId', invoiceId)}
            />

            <TextField
              label={FieldNames.mobile}
              id='mobile'
              type='number'
              placeHolder='Enter Mobile Number'
              value={watch('mobile') ?? ''}
              onChange={(mobile) => setValue('mobile', mobile)}
            />

            <SubMerchantField
              isMulti
              subMerchantId
              value={watch('subMerchantId') ?? []}
              onChange={(value) => setValue('subMerchantId', value)}
            />

            <TextField
              label={FieldNames.singleSubMerchantId}
              id='singleMerchant'
              type='text'
              placeHolder={`Enter ${FieldNames.singleSubMerchantId}`}
              value={watch('singleSubMerchantId') ?? ''}
              onChange={(singleSubMerchantId) =>
                setValue('singleSubMerchantId', singleSubMerchantId)
              }
            />

            <TextField
              label={FieldNames.email}
              id='email'
              type='email'
              placeHolder={`Enter ${FieldNames.email}`}
              error={errors.email?.message ?? ''}
              value={watch('email') ?? ''}
              onChange={(email) => setValue('email', email)}
            />

            <CurrencyField
              containerProps={{ className: 'mt-6 form-input' }}
              label='Order Currency'
              value={watch('currency') ?? null}
              onChange={(value) => setValue('currency', value)}
            />

            <AmountField
              maxValue={watch('maxAmount') ?? ''}
              minValue={watch('minAmount') ?? ''}
              onMaxChange={(value) => setValue('maxAmount', value)}
              onMinChange={(value) => setValue('minAmount', value)}
              error={
                errors.minAmount?.message ?? errors.maxAmount?.message ?? ''
              }
            />

            <TextField
              label={FieldNames.customAttributes}
              id='custom_attributes'
              type='text'
              placeHolder={`Enter ${FieldNames.customAttributes}`}
              value={watch('customAttributes') ?? ''}
              onChange={(customAttributes) =>
                setValue('customAttributes', customAttributes)
              }
            />

            <FilterSubmitButton />
          </form>
        </FilterContainer>
      }
    />
  );
};

export default FilterOrders;
