export const removeDateParams = (filterParams: any[], key: string) => {
  const index = filterParams.findIndex((element) => element[0] === key);

  if (index !== -1) {
    filterParams.splice(index, 1);
  }
};

export const removeDataFilter = (
  filterParams: Record<string, string>,
  key: string
) => {
  delete filterParams[key];
};
