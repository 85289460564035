import { array, object, string } from 'yup';
import {
  FilterCommonFields,
  MetaData,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';
import { useCurrentSession, useTracker } from '../../Hooks';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterSettlementDetailUtils from '../FilterPopup/utils/FilterSettlementDetailUtils';
import {
  settlementEntryTypeOptions,
  transactionOptions,
} from '../FilterPopup/constants';

export type FilterSettlementDetailFields = Pick<
  FilterCommonFields,
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'transactionId'
  | 'orderId'
  | 'invoiceId'
  | 'mobile'
  | 'settlementType'
  | 'settlementEntryType'
  | 'paymentMode'
  | 'paymentPartner'
  | 'subMerchantId'
  | 'singleSubMerchantId'
  | 'email'
  | 'currency'
  | 'minAmount'
  | 'maxAmount'
  | 'settlementId'
  | 'customAttributes'
  | 'utr'
>;

const settlementDetailSchema = object().shape({
  duration: commonSchema.duration,
  startDate: commonSchema.startDate,
  endDate: commonSchema.endDate,
  transactionId: string(),
  orderId: commonSchema.orderId,
  invoiceId: commonSchema.invoiceId,
  mobile: commonSchema.mobile,
  settlementType: array().of(
    object().shape({ label: string(), value: string() })
  ),
  settlementEntryType: object().shape({ label: string(), value: string() }),
  paymentMode: array().of(object().shape({ label: string(), value: string() })),
  paymentPartner: array().of(
    object().shape({ label: string(), value: string() })
  ),
  subMerchantId: commonSchema.subMerchantId,
  singleSubMerchantId: commonSchema.singleSubMerchantId,
  email: commonSchema.email,
  currency: commonSchema.currency,
  minAmount: commonSchema.minAmount,
  maxAmount: commonSchema.maxAmount,
  settlementId: string(),
  customAttributes: commonSchema.customAttributes,
  utr: string(),
});

export const defaultSettlementDetailFilters: FilterSettlementDetailFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
  settlementType: [transactionOptions[0]],
  settlementEntryType: settlementEntryTypeOptions[0],
};

const useFilterSettlementDetails = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(false);
  const [settlements, setSettlements] = useState<any[]>([]);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterSettlementDetailFields>({
    defaultValues: defaultSettlementDetailFilters,
    resolver: yupResolver(settlementDetailSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { settlements } =
      (await fetchSettlements({ ...form.getValues(), page: page + 1 })) ?? {};

    setSettlements((prev) => [...prev, ...settlements]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterSettlementDetailUtils.removeParams(
      formData,
      filterName
    );
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const fetchSettlements = useCallback(
    async (formData: FilterSettlementDetailFields & { page?: number }) => {
      const { page, ...restFormData } = formData;
      const { filters, filterSection } =
        FilterSettlementDetailUtils.generateParams(
          restFormData,
          page ? { page } : undefined,
          form.setValue
        );

      const response = await FilterSettlementDetailUtils.getSettlements(
        filters
      );

      // check for user token
      if (checkSessionError(response)) return;

      const settlements = response.data?.result?.result ?? [];
      const metaData = response.data?.result?.meta ?? new MetaData();

      const error = response.data?.result?.error?.message;
      const rows = metaData?.total_records;
      const pages = metaData?.total_pages;

      track({
        name: 'Settlement Details' + TrackEventNames.searchClicked,
        inBuiltOptions: ['merchantId', 'merchantName'],
        additionalOptions: {
          menu_item_name: 'Settlement Details',
          // @ts-ignore
          filters: filterSection,

          ...(error
            ? { failure_error_message: error }
            : {
                number_of_rows: rows,
                number_of_pages: pages,
              }),
        },
      });

      return { settlements, filterSection, filters, metaData };
    },
    [checkSessionError, track]
  );

  const handleFormSubmit = useCallback(
    async (formData: FilterSettlementDetailFields & { page?: number }) => {
      setLoading(true);
      const { filterSection, settlements, metaData } =
        (await fetchSettlements(formData)) ?? {};

      setSettlements(settlements);
      setMetaData(metaData);
      setFilters(filterSection);
      setLoading(false);
      setFilterModalShown(false);
    },
    [fetchSettlements]
  );

  return {
    filters,
    form,
    loading,
    metaData,
    settlements,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  };
};

export default useFilterSettlementDetails;
