class SubPaymentMode {
  amount = 0;
  count = 0;
  amount_percentage = 0;
  count_percentage = 0;

  failed_amount = 0;
  failed_amount_percentage = 0;
  failed_count = 0;
  failed_count_percentage = 0;

  pending_amount = 0;
  pending_amount_percentage = 0;
  pending_count = 0;
  pending_count_percentage = 0;

  succeeded_amount = 0;
  succeeded_amount_percentage = 0;
  succeeded_count = 0;
  succeeded_count_percentage = 0;
}

class PaymentMode {
  amount = 0;
  count = 0;

  amount_percentage = 0;
  count_percentage = 0;

  failed_amount = 0;
  failed_count = 0;

  pending_amount = 0;
  pending_count = 0;

  succeeded_amount = 0;
  succeeded_count = 0;
}

class PaymentPartner {
  amount = 0;
  amount_percentage = 0;

  count = 0;
  count_percentage = 0;

  failed_amount = 0;
  failed_count = 0;

  pending_amount = 0;
  pending_count = 0;

  succeeded_amount = 0;
  succeeded_count = 0;
}

class TransactionSummary {
  amount = 0;
  count = 0;

  amount_percentage = 0;
  count_percentage = 0;

  failed_amount = 0;
  failed_count = 0;

  new_amount = 0;
  new_count = 0;

  pending_amount = 0;
  pending_count = 0;

  succeeded_amount = 0;
  succeeded_count = 0;

  'auto-reversed_amount' = 0;
  'auto-reversed_count' = 0;
}

export class AnalyticsResponse {
  currency: string = 'INR';
  total_transaction_count: number = 0;
  total_transaction_amount: number = 0;
  payment_mode: Record<string, PaymentMode> = {};
  payment_partner: Record<string, PaymentPartner> = {};
  transaction_summary: Record<string, TransactionSummary> = {};
}

export class SubPaymentModeResponse {
  result: Record<string, SubPaymentMode> = {};
}
