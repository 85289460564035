import React, { useState, useEffect, useMemo } from 'react';
import { OrderRes, SettlementDetailsTable } from '../../interfaces/ApiResponse';
import { IStateData } from '../../interfaces/StateData';
import {
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import Sidebar from '../Sidebar/Sidebar';
import {
  Badge,
  BadgeProps,
  Collapsible,
  CollapsibleButton,
  Drawer,
  KeyValue,
  Table,
  TableColumnType,
} from 'nimbbl-quark';
import * as api from '../../Apis';
import HeaderComponent from '../Header/Header';
import FilterPopup from '../FilterPopup/FilterPopup';
import FilterSection from '../FilterSection/FilterSection';
import { FiExternalLink, FiInfo, FiRotateCw } from 'react-icons/fi';
import { formateDateToDDMonYYYY } from '../../Apis';
import { PriceUtil } from '../../Utils';
import { useCommonTracker, useCsvExport, useCurrentSession } from '../../Hooks';
import { DrawerTableRow, TableDivider } from '../Orders/Orders';
import { BiRupee } from 'react-icons/bi';
import { BsCheck2Circle } from 'react-icons/bs';

import '../Login/Styles.css';
import { RiFileCopy2Line } from 'react-icons/ri';
import { HandlePaymentPartner } from '../../Utils/PaymentPartnerHandling';
import FilterSection2 from '../FilterSection/FilterSection2';
import useFilterSettlementDetails, {
  defaultSettlementDetailFilters,
} from './useFilterSettlementDetails';
import isEqual from 'lodash.isequal';
import FilterSettlementDetailUtils from '../FilterPopup/utils/FilterSettlementDetailUtils';
import FilterSettlementDetails from '../FilterPopup/FilterSettlementDetails';
import { useSearchParams } from 'react-router-dom';
import FieldNames from '../../constants/FieldNames';

const rowsPerPage = 60;

type TransactionType = 'full-refund' | 'partial-refund' | 'payment';
const getBadgeProps = (
  transactionType: TransactionType
): { text: string; type: BadgeProps['type'] } => {
  switch (transactionType) {
    case 'full-refund':
      return { text: 'Full Refund', type: 'Completed' };
    case 'partial-refund':
      return { text: 'Partial Refund', type: 'Pending' };
    case 'payment':
      return { text: 'Payment', type: 'New' };
    default:
      return { text: transactionType, type: 'New' };
  }
};

const SettlementDetails = () => {
  const { trackItemClicked, trackPageRendered, trackRowSelected } =
    useCommonTracker();

  const [csvData, setCsvData] = useState<any>([]);
  // settlement details right drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const [animateRight, setAnimateRight] = useState(false);
  const [copyText, setCopyText] = useState<boolean>(false);
  // filter v2
  const {
    filters,
    form,
    loading,
    metaData,
    settlements,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  } = useFilterSettlementDetails();
  const [searchParams] = useSearchParams();

  const { exportAll, loading: csvLoading } = useCsvExport(
    'Settlement Details',
    form.watch()
  );

  useEffect(() => {
    if (!showDrawer) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [showDrawer]);

  useEffect(() => {
    if (!animateRight) setShowDrawer(true);
  }, [animateRight]);

  const renderRowData = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    rowData: any
  ) => {
    setAnimateRight(true);
    setSelectedTransaction(rowData);
    document.querySelector('tr.selected-row')?.classList.remove('selected-row');
    event.currentTarget.closest('tr')?.classList.add('selected-row');
    setFilterModalShown(false);
  };

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  const copyTextClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopyText(true);
  };

  const closeDrawer = () => {
    setAnimateRight(false);
    setSelectedTransaction({});
  };

  useEffect(() => {
    const settlementId = searchParams.get('psp_id');

    if (settlementId) {
      form.setValue('settlementId', settlementId);
      handleFormSubmit(form.getValues());
    } else {
      handleFormSubmit(defaultSettlementDetailFilters);
    }
  }, [searchParams, form]);

  useEffect(() => {
    trackPageRendered('Settlement Detail', { ...metaData, ...filters });

    return () => {
      trackPageRendered.cancel();
    };
  }, [filters, metaData]);

  const skeletonArray = Array(10).fill('');
  const tableRef = React.useRef<any>();
  const { checkSessionError } = useCurrentSession();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllExportExport = () => {
    exportAll();
    clearSelection();
  };

  const openTransactionLink = (id: string, date: string, type: string) => {
    const transactionDate = new Date(date);
    transactionDate.setDate(transactionDate.getDate() - 1);
    window.localStorage.setItem('transaction_date', transactionDate.toString());
    if (type === 'payment') {
      window.open(`/transactions?tid=${id}`, '_blank');
    } else {
      window.open(`/refunds?tid=${id}`, '_blank');
    }
  };

  const columns: TableColumnType<SettlementDetailsTable>[] = [
    {
      title: FieldNames.transactionId,
      field: 'transaction_id.transaction_id',
      cellStyle: {
        color: '#0352C9',
        // fontWeight: 'bold',
        textDecoration: 'none',
      },
      sorting: false,
      render: (rowData: any) => (
        <a
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            renderRowData(e, rowData);
            trackItemClicked('Settlement Details', {
              transaction_id: rowData?.transaction_id?.transaction_id,
            });
          }}>
          {rowData.transaction_id.transaction_id}
        </a>
      ),
    },
    {
      title: 'settlement  date',
      field: 'settlement_date',
      sorting: false,
      render: (rowData) => (
        <span style={{ width: '110px' }}>
          {formateDateToDDMonYYYY(rowData.settlement_date)}
        </span>
      ),
    },
    {
      title: 'settlement type',
      field: 'transaction_type',
      sorting: false,
      render: (rowData) => (
        <span className='capitalize'>{rowData.transaction_type}</span>
      ),
    },
    {
      title: 'Entry type',
      field: 'settlement_entry_type',
      sorting: false,
      render: (rowData) => (
        <span className='capitalize'>{rowData.settlement_entry_type}</span>
      ),
    },
    {
      title: (
        <>
          <span className='mr-1'>transaction amount</span>
          {/* <Tooltip
            title='Transaction amount converted in INR, you can get original transaction currency by downloading the report.'
            arrow>
            <IconButton size='small'>
              <FiInfo size={16} />
            </IconButton>
          </Tooltip> */}
        </>
      ),
      field: 'total_amount',
      sorting: false,
      render: (rowData) => {
        const { transaction_currency, transaction_amount, total_order_amount } =
          rowData;

        const { name: transactionCurrency } = transaction_currency;

        // const isSameCurrency = settlementCurrency === transactionCurrency;

        // const convertedAmount = Boolean(converted_transaction_amount)
        //   ? converted_transaction_amount
        //   : transaction_amount
        //   ? transaction_amount
        //   : total_order_amount;

        const amount = Boolean(transaction_amount)
          ? transaction_amount
          : total_order_amount;

        return PriceUtil.formatCurrency(
          amount || 0.0,
          transactionCurrency as any
        );
      },
    },
    {
      title: 'PSP fee',
      field: 'fee_amount',
      sorting: false,
      render: (rowData) =>
        PriceUtil.formatCurrency(
          rowData?.fee_amount || 0,
          rowData?.settlement_currency?.name as any
        ),
    },
    {
      title: 'tax psp fee',
      field: 'tax_amount',
      sorting: false,
      render: (rowData: any) =>
        PriceUtil.formatCurrency(
          rowData?.tax_amount || 0,
          rowData?.settlement_currency?.name as any
        ),
    },
    {
      title: 'settlement amount',
      field: 'settled_amount',
      sorting: false,
      render: (rowData: any) =>
        PriceUtil.formatCurrency(
          rowData?.settled_amount || 0,
          rowData?.settlement_currency?.name as any
        ),
    },
  ];

  const getSelectedRow = (data: [], currentPage: number) => {
    let sliceData: any[] = [];
    sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];
    setCsvData(sliceData);
  };

  const getSelectedExport = () => {
    const transactionIds = csvData.map(
      (item: any) => item?.transaction_id?.transaction_id
    );
    exportAll(transactionIds, 'transaction_id');
  };

  const transactionData = useMemo(() => {
    const {
      fee_amount,
      transaction_amount, // transaction amount
      total_order_amount,
      tax_amount,
      settled_amount,
      settlement_date,
      payment_partner,
      sub_merchant_id = {},
      // total_order_amount,
      utr_no,
      settlement_currency,
      transaction_currency,
      transaction_id,
      psp_currency_conversion_rate,
      converted_transaction_amount,
      order_id,
    } = selectedTransaction || {};
    const { display_name = '' } = sub_merchant_id || {};
    const { name: settlementCurrency } = settlement_currency || {};
    const { name: transactionCurrency } = transaction_currency || {};
    const { transaction_items = [], order_custom_attributes = [] } =
      (order_id || {}) as {
        transaction_items: any[];
        order_custom_attributes: { key: string; value: string }[];
      };
    const { refund_amount = 0 } = transaction_id || {};

    const summary = [
      {
        label: 'Settlement Date',
        value: new Date(settlement_date).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
      },
      {
        label: 'PSP',
        value: HandlePaymentPartner.getPaymentPartner(payment_partner),
      },
      {
        label: 'Total Amount Settled',
        value: PriceUtil.formatCurrency(settled_amount, settlementCurrency),
      },
      { label: FieldNames.utr, value: utr_no },
      { label: FieldNames.submerchantId, value: display_name },
    ];

    const isCurrencyINR = settlementCurrency === transactionCurrency;

    // const refundAmount = transaction_items.reduce((sum, item) => {
    //   const { status, transaction_type, refund_amount } = item;

    //   if (transaction_type === 'partial-refund' && status === 'succeeded') {
    //     return sum + refund_amount;
    //   }

    //   return sum;
    // }, 0);

    return [
      {
        label: 'Settlement Details',
        children: (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {summary.map(({ label, value }, index) => {
              const isEven = index % 2 === 0;

              return (
                <KeyValue
                  styleContainer={{
                    width: isEven ? '47%' : '50%',
                    marginBottom: 16,
                    marginRight: isEven ? '3%' : 0,
                  }}
                  key={label}
                  label={label}
                  value={value}
                />
              );
            })}
          </div>
        ),
        icon: <BsCheck2Circle />,
      },
      {
        label: 'Settlement Amount',
        icon: <BiRupee />,
        children: (
          <table style={{ width: '100%' }}>
            <tbody>
              <DrawerTableRow
                label='Transaction Amount'
                value={PriceUtil.formatCurrency(
                  transaction_amount ? transaction_amount : total_order_amount,
                  transactionCurrency
                )}
              />

              {!isCurrencyINR && (
                <>
                  <tr>
                    <td colSpan={2}>
                      <TableDivider />
                    </td>
                  </tr>

                  <DrawerTableRow
                    label='Converted Amount'
                    info={`Transaction Amount converted by PSP.\r\n Conversion Rate: ${Number(
                      psp_currency_conversion_rate
                    ).toFixed(2)}`}
                    value={PriceUtil.formatCurrency(
                      converted_transaction_amount,
                      settlementCurrency
                    )}
                  />
                </>
              )}
              <DrawerTableRow
                label='PSP Fees'
                value={
                  '- ' +
                  PriceUtil.formatCurrency(fee_amount, settlementCurrency)
                }
              />
              <DrawerTableRow
                label='Tax'
                value={
                  '- ' +
                  PriceUtil.formatCurrency(tax_amount, settlementCurrency)
                }
              />
              {/* {Boolean(refundAmount) && (
                <DrawerTableRow
                  label='Refunded Amount'
                  value={
                    '- ' +
                    PriceUtil.formatCurrency(refundAmount, settlementCurrency)
                  }
                />
              )} */}

              <tr>
                <td colSpan={2}>
                  <TableDivider />
                </td>
              </tr>

              <DrawerTableRow
                label='Settlement Amount'
                value={PriceUtil.formatCurrency(
                  settled_amount,
                  settlementCurrency
                )}
              />
            </tbody>
          </table>
        ),
      },

      // only render custom attributes when it is present for the current transaction
      ...(order_custom_attributes?.length > 0
        ? [
            {
              label: 'Custom Attributes',
              icon: <FiInfo />,
              children: (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {order_custom_attributes?.map(({ key, value }, index) => {
                    const isEven = index % 2 === 0;

                    return (
                      <KeyValue
                        styleContainer={{
                          width: isEven ? '47%' : '50%',
                          marginBottom: 16,
                          marginRight: isEven ? '3%' : 0,
                        }}
                        key={key}
                        label={key}
                        value={value}
                      />
                    );
                  })}
                </div>
              ),
            },
          ]
        : []),
    ];
  }, [selectedTransaction]);

  console.table('selected', selectedTransaction);

  return (
    <>
      <Sidebar />
      <div className='p-4 mb-8 body-container ml-60'>
        <HeaderComponent
          loading={csvLoading}
          page='settlement details'
          title={
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontSize: '19px' }}>Settlement Details </span>
              <Tooltip
                title='Settlements are being displayed for payment and refund transaction types only'
                arrow>
                <IconButton size='small'>
                  <FiInfo />
                </IconButton>
              </Tooltip>
            </div>
          }
          total={metaData?.total_records}
          handleExport={getSelectedExport}
          handleExportAll={getAllExportExport}
          text={'Export CSV'}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          selectedRows={csvData.length || 0}
        />
        <FilterSection2
          defaultFilters={defaultSettlementDetailFilters}
          filters={filters}
          pageName='settlement detail'
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterSettlementDetailUtils.generateParams(
                defaultSettlementDetailFilters
              ).filterSection,
              filters
            )
          }
        />
        {loading ? (
          <div className='w-full'>
            <table className='w-full'>
              {skeletonArray.map((_item, index) => (
                <TableRow key={index} style={{ width: '100%' }}>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='right' style={{ width: '20%' }}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}{' '}
            </table>
          </div>
        ) : (
          <div style={{ overflow: 'auto' }} className='mt-6'>
            <Table
              data={settlements}
              columns={columns}
              // filtering
              search={false}
              handleSearchChange={() => ({})}
              selection={true}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    'No records fetched for your search criteria, please change the filters and search again',
                },
              }}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(settlements.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total orders is still less than total records
                    settlements.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  getSelectedRow(rows, page + 1);
                  setCsvData(rows);

                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected('Settlement Details', {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: 20,
                draggable: false,
              }}
            />
          </div>
        )}

        <Drawer
          position='right'
          hide={closeDrawer}
          isShown={showDrawer}
          style={{ width: '400px' }}
          className={
            animateRight
              ? 'animation drawerAnimationRightShow'
              : 'animation drawerAnimationRightHide'
          }
          headerStyle={{
            background: '#FAFAFC',
            borderBottom: '1px solid #CCC',
          }}
          headerElement={
            <div>
              <p className='flex mb-2 text-sm'>
                {selectedTransaction.transaction_id?.transaction_id}
                {!copyText ? (
                  <span
                    className='ml-2 text-lg cursor-pointer text-blue-40'
                    title={`Copy ${FieldNames.transactionId}`}
                    onClick={() =>
                      copyTextClipboard(
                        selectedTransaction.transaction_id?.transaction_id
                      )
                    }>
                    {' '}
                    <RiFileCopy2Line />
                  </span>
                ) : (
                  <p className='copyToClipboard'>copied to clipboard</p>
                )}
              </p>
              <Badge
                variant='accent'
                containerProps={{ style: { width: 'fit-content' } }}
                {...getBadgeProps(selectedTransaction?.transaction_type)}
              />
            </div>
          }
          bodyElement={
            <div
              className='p-4 no-scroll icon-font'
              style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}>
              {transactionData.map(({ label, children, icon }, index) => (
                <Collapsible
                  open={index !== 0}
                  key={label}
                  title={label}
                  isBorder
                  style={{ borderBottom: '1px solid #ccc' }}
                  iconColor='#045CE1'
                  icon={icon}>
                  <div className='p-4 rounded-lg bg-blue-10'>{children}</div>
                </Collapsible>
              ))}
              <div className='mt-4'>
                <CollapsibleButton
                  text='Related Transaction'
                  iconRight={<FiExternalLink />}
                  iconLeft={<FiRotateCw />}
                  style={{ width: '100%', background: '#F1F4F9' }}
                  iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                  iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                  onClick={() =>
                    openTransactionLink(
                      selectedTransaction?.transaction_id?.transaction_id,
                      selectedTransaction?.transaction_date,
                      selectedTransaction?.transaction_type
                    )
                  }
                />
              </div>
            </div>
          }
        />

        <FilterSettlementDetails
          form={form}
          handleFormSubmit={handleFormSubmit}
          removeFilter={removeFilter}
          isActive={isFilterModalShown}
          onHide={() => {
            setFilterModalShown(false);
          }}
        />
      </div>
    </>
  );
};

export default SettlementDetails;
