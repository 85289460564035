import axios from 'axios';
import moment from 'moment';

import {
  FilterPaymentLinkFields,
  defaultPaymentLinkFilters,
} from '../../PaymentLinks/useFilterPaymentLink';
import DateUtils from './DateUtils';
import getApiEndPoint from './getApiEndpoint';
import { MetaData } from '../interfaces/CommonFields';
import { daysOptions } from '../constants';
import FieldNames from '../../../constants/FieldNames';

class FilterPaymentLinkUtils {
  static generateParams(
    formData: FilterPaymentLinkFields,
    metaData?: Partial<MetaData>
  ) {
    // for filter api
    const filterParams: Record<string, string | string[] | number | null> = {};
    // for filter section
    const filterSection: Record<string, string> = {};

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case 'status': {
          const values = formData[filterName].map((item) => item.value);
          // if all is selected then don't send/show status filter
          if (/all/i.test(values.join(','))) break;
          if (values.length === 0) break;

          filterParams['status'] = values;

          const value = formData[filterName]
            .map((item) => item.label)
            .join(', ');
          filterSection[FieldNames.status] = value;
          break;
        }
        case 'startDate': {
          const startDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams['start_date'] =
            DateUtils.getStringFromDate(startDate)?.replaceAll('/', '-') ??
            null;

          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case 'endDate': {
          let endDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
          }

          filterParams['end_date'] =
            DateUtils.getStringFromDate(endDate)?.replaceAll('/', '-') ?? null;

          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case 'linkHash': {
          const value = formData[filterName]?.trim();
          if (!value) break;

          filterParams['link_hash'] = value;
          filterSection[FieldNames.linkHash] = value ?? '';
          break;
        }
        case 'orderId': {
          const value = formData[filterName]?.trim();
          if (!value) break;

          filterParams['order_id'] = value;
          filterSection[FieldNames.orderId] = value ?? '';
          break;
        }
        case 'invoiceId': {
          const value = formData[filterName];
          if (!value) break;

          filterParams['invoice_id'] = value;
          filterSection[FieldNames.invoiceId] = value ?? '';
          break;
        }
        case 'mobile': {
          const value = formData[filterName];
          if (!value) break;

          filterParams['mobile_number'] = value;
          filterSection[FieldNames.mobile] = value ?? '';
          break;
        }
        case 'subMerchantId': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const { label, value } = formValue;
          if (!value) break;

          filterParams['sub_merchant_id'] = value;
          filterSection[FieldNames.submerchantId] = label;
          break;
        }
        case 'minAmount': {
          const value = formData[filterName];
          if (!value) break;

          filterParams['minimum_amount'] = +value;
          filterSection['Min Amount'] = value ?? '';
          break;
        }
        case 'maxAmount': {
          const value = formData[filterName];
          if (!value) break;

          filterParams['maximum_amount'] = +value;
          filterSection['Max Amount'] = value ?? '';
          break;
        }
      }
    });

    const { per_page, page } = metaData ?? {};

    const filters = {
      ...filterParams,
      page: page ?? 1,
      page_size: per_page ?? 60,
    };

    return {
      filters,
      filterSection,
    };
  }

  static removeParams(
    formData: FilterPaymentLinkFields,
    filterName: string
  ): FilterPaymentLinkFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case FieldNames.status:
        return { ...result, status: defaultPaymentLinkFilters.status };
      case 'date':
        return {
          ...result,
          startDate: defaultPaymentLinkFilters['startDate'],
          endDate: defaultPaymentLinkFilters['endDate'],
        };
      case FieldNames.linkHash:
        return { ...result, linkHash: undefined };
      case FieldNames.orderId:
        return { ...result, orderId: undefined };
      case FieldNames.invoiceId:
        return { ...result, invoiceId: undefined };
      case FieldNames.mobile:
        return { ...result, mobile: undefined };
      case FieldNames.submerchantId:
        return { ...result, subMerchantId: null };
      case 'Min Amount':
        return { ...result, minAmount: undefined };
      case 'Max Amount':
        return { ...result, maxAmount: undefined };
      default:
        return { ...defaultPaymentLinkFilters };
    }
  }

  static getPaymentLinks = (body: object) =>
    axios.post(getApiEndPoint('paymentLink'), body);
}

export default FilterPaymentLinkUtils;
