import { Button, InputField, Divider } from 'nimbbl-quark';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { MdArrowForward } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import SubMerchantDropdown from '../../../SubMerchantDropdown';
import { LinkDetails as DefaultLinkDetails } from '../../../../interfaces/PaymentLinks';
import { FiPlus } from 'react-icons/fi';
import { useCreateLinkContext } from '..';
import { FieldUtil, PriceUtil } from '../../../../Utils';
import Currencies from '../../../../constants/currencies.json';
import { Collapse, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { FormRow } from './FormRow';
import { DateField } from './DateTimePicker';
import { Dropdown } from '../../../Dropdown';

const currencyOptions = Currencies.map(({ code, name }) => ({
  label: code,
  value: code,
}));

export const inputStyles =
  'border-primary-80 border text-black-primary font-normal rounded w-full py-3 px-3';

export const disabledStyles = {
  backgroundColor: '#F1F4F9',
  borderColor: '#CBD4E1',
  cursor: 'not-allowed',
};

export const LinkDetails = () => {
  const { linkDetailsForm, removeProduct } = useCreateLinkContext();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = linkDetailsForm;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [
    total_amount,
    expires_at,
    notification_scheduled_at,
    products,
    currency,
    showScheduledAt,
  ] = watch([
    'total_amount',
    'expires_at',
    'notification_scheduled_at',
    'product_details',
    'currency',
    'show_scheduled_at',
  ]);

  console.log('errors', errors);

  const linkId = searchParams.get('id');
  const productDetailsUrl =
    '/payment-links/create/product-details' + (linkId ? `?id=${linkId}` : '');
  const customerDetailsUrl =
    '/payment-links/create/customer-details' + (linkId ? `?id=${linkId}` : '');

  const submitLinkDetails = async (_formData: DefaultLinkDetails) => {
    navigate(customerDetailsUrl);
  };

  const hasProducts = products.length > 0;

  useEffect(() => {
    if (products.length > 0) {
      const totalAmount = products
        .reduce(
          (sum, { amount_before_tax, tax, quantity }) =>
            sum + (parseFloat(amount_before_tax) * quantity + parseFloat(tax)),
          0
        )
        .toFixed(2);
      setValue('total_amount', totalAmount);
      // trigger('total_amount', { shouldFocus: true });
    }
  }, [total_amount, products, setValue, trigger]);

  return (
    <form onSubmit={handleSubmit(submitLinkDetails)}>
      <FormRow
        required
        id='submerchant'
        label='Submerchant'
        value={
          <SubMerchantDropdown
            isMulti={false}
            subMerchantId={false}
            placeHolder='Select a Submerchant'
            value={watch('sub_merchant_id')}
            onChange={(selectedValue) => {
              setValue('sub_merchant_id', selectedValue);
              trigger('sub_merchant_id', { shouldFocus: true });
            }}
            caption={errors?.sub_merchant_id?.message as unknown as string}
            captionType='error'
          />
        }
      />

      <Divider className='mb-6' />

      <FormRow
        required
        id='amount'
        label='Amount'
        value={
          <div className='flex'>
            <div className='w-1/3'>
              <Dropdown
                id='currency'
                searchable
                multiple={false}
                options={currencyOptions}
                // @ts-ignore
                value={currency}
                onChange={(newValue) => setValue('currency', newValue)}
              />
            </div>
            <div className='w-full ml-4'>
              <InputField
                id='amount'
                placeholder={`Enter amount in ${currency?.value}`}
                style={{ marginTop: -4 }}
                caption={errors?.total_amount?.message}
                captionType='error'
                attributes={{
                  disabled: hasProducts,
                  style: {
                    marginTop: -4,
                    height: 48,
                    ...(hasProducts && {
                      cursor: 'not-allowed',
                      backgroundColor: '#0000001e',
                    }),
                  },
                  ...register('total_amount', {
                    onBlur: (e) =>
                      setValue(
                        'total_amount',
                        FieldUtil.formatDecimalNumber(e.target.value),
                        { shouldValidate: true }
                      ),
                  }),
                }}
              />
            </div>
          </div>
        }
      />

      <FormRow
        label='Product Details'
        value={
          products.length > 0 ? (
            <div className='text-sm font-medium'>
              {products
                .slice(0, 2)
                .map(({ title, amount_before_tax, tax, quantity }, index) => (
                  <div key={title} className='flex items-center'>
                    <p className='mr-2'>
                      {title} :
                      {PriceUtil.formatCurrency(
                        quantity * parseFloat(amount_before_tax) +
                          parseFloat(tax),
                        currency?.value as any
                      )}
                    </p>
                    <IconButton
                      size='small'
                      onClick={() => removeProduct(index)}>
                      <RiDeleteBin6Line />
                    </IconButton>
                  </div>
                ))}
              <Link
                to={productDetailsUrl}
                className='text-[#045CE1] mt-2 block'>
                View all products
              </Link>
              {errors?.product_details && (
                <p className='text-[#d83232]'>Error in product details</p>
              )}
            </div>
          ) : (
            <Link
              to={productDetailsUrl}
              className='flex items-center text-[#045CE1]'>
              <FiPlus size={18} />
              <span className='ml-2 text-sm font-normal'>
                Add product details
              </span>
            </Link>
          )
        }
      />

      <FormRow
        id='expires_at'
        label='Expiry Date'
        value={
          <DateField
            id='expires_at'
            {...register('expires_at')}
            containerClassName='col-start-4 col-end-9'
            selected={expires_at}
            onChange={(value, _event) => {
              setValue('expires_at', value);
              trigger('expires_at', { shouldFocus: true });
            }}
            showTimeInput
            dateFormat='yyyy-MM-dd, HH:mm'
            placeholderText='Select Date & Time'
            caption={
              errors?.expires_at?.message ||
              (expires_at
                ? undefined
                : 'If no date is selected, the default expiry will be 90 days')
            }
            captionType={errors?.expires_at?.message ? 'error' : 'default'}
          />
        }
      />

      <FormRow
        required
        id='invoice_id'
        label='Invoice ID'
        value={
          <InputField
            id='invoice_id'
            style={{ marginTop: -4 }}
            caption={errors?.invoice_id?.message}
            captionType='error'
            attributes={{
              placeholder: 'Enter a unique Invoice ID',
              ...register('invoice_id', {
                onBlur: (e) => {
                  setValue(
                    'invoice_id',
                    e.target.value.replace(/\p{Emoji_Presentation}/gu, '')
                  );
                },
              }),
            }}
          />
        }
      />

      <FormRow
        id='show_scheduled_at'
        label='Schedule Notification'
        value={
          <>
            <div className='flex items-center'>
              <input
                {...register('show_scheduled_at')}
                type='checkbox'
                id='show_scheduled_at'
                className='mr-2 rounded'
              />
              <label htmlFor='show_scheduled_at' className='text-sm mr-7'>
                Schedule
              </label>
            </div>
            <Collapse in={showScheduledAt}>
              <div className='flex mt-2'>
                <DateField
                  id='notification_scheduled_at'
                  {...register('notification_scheduled_at')}
                  containerClassName='w-full'
                  selected={notification_scheduled_at}
                  onChange={(value, _event) => {
                    if (value) {
                      setValue('notification_scheduled_at', value);
                      trigger('notification_scheduled_at', {
                        shouldFocus: true,
                      });
                    }
                  }}
                  showTimeInput
                  dateFormat='yyyy-MM-dd, HH:mm'
                  placeholderText='Select Date & Time'
                  caption={errors?.notification_scheduled_at?.message}
                  captionType={'error'}
                />
              </div>
            </Collapse>
          </>
        }
      />

      <FormRow
        id='description'
        label='Link Description'
        value={
          <>
            {/* <textarea
            {...register('description')}
            id='linkDescription'
            rows={3}
            className={`col-start-4 col-end-9 ${inputStyles}`}
            placeholder='Enter a description...'
          /> */}

            <textarea
              id='description'
              placeholder='Enter link description...'
              className='w-full px-3 py-3 font-normal border rounded border-primary-80 text-black-primary'
              rows={3}
              {...register('description')}
            />
            {errors?.description?.message && (
              <p
                style={{
                  fontWeight: 300,
                  fontSize: 14,
                  color: 'rgb(216,50,50)',
                }}>
                {errors?.description?.message}
              </p>
            )}
          </>
        }
      />

      <FormRow
        id='callback_url'
        label='Callback URL'
        value={
          <InputField
            id='callback_uRL'
            placeholder='Enter a valid URL'
            style={{ marginTop: -4 }}
            caption={
              errors?.callback_url?.message ||
              'Customer would be redirected to the Callback URL after payment is complete'
            }
            captionType={errors?.callback_url?.message ? 'error' : 'text'}
            attributes={{
              ...register('callback_url'),
            }}
          />
        }
      />

      <FormRow
        id='terms_and_conditions'
        label='Terms and conditions'
        value={
          <>
            {/* <TextField
            id='terms_and_conditions'
            {...register('terms_and_conditions')}
            // rows={6}
            placeholder='Enter terms and conditions...'
            captionType='error'
            caption={errors?.terms_and_conditions?.message}
            captionStyles={{ marginBottom: 4 }}
          /> */}
            <textarea
              id='terms_and_conditions'
              placeholder='Enter terms and conditions...'
              className='w-full px-3 py-3 font-normal border rounded border-primary-80 text-black-primary'
              rows={3}
              {...register('terms_and_conditions')}
            />
            {errors?.terms_and_conditions?.message && (
              <p
                style={{
                  fontWeight: 300,
                  fontSize: 14,
                  color: 'rgb(216,50,50)',
                }}>
                {errors?.terms_and_conditions?.message}
              </p>
            )}
          </>
        }
      />

      <Divider />

      <div className='flex justify-end my-6'>
        <Button
          type='submit'
          variant='primary'
          text='Next 1/2'
          iconPosition='right'
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
          }}
          IconComponent={() => <MdArrowForward className='ml-2' size={22} />}
        />
      </div>
    </form>
  );
};
