import axios from 'axios';
import {
  Badge,
  Collapsible,
  CollapsibleButton,
  Drawer,
  KeyValue,
  Table,
  TableColumnType,
} from 'nimbbl-quark';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BsCheck2Circle } from 'react-icons/bs';
import { FiColumns, FiInfo, FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import { getIssuerBadgeType } from '../../../Configs/confgs';
import {
  APIResponse,
  OrderRes,
  RefundTable,
} from '../../../interfaces/ApiResponse';
import HeaderComponent from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';

import constants from '../../../constants';
import TransactionCardModule from '../../Transactions/TransactionCardModule';
import { RiFileCopy2Line } from 'react-icons/ri';
import { formateDateToDDMonYYYY } from '../../../Apis';
import { formatDate } from '../../Orders/Orders';
import { PriceUtil } from '../../../Utils';
import {
  useCommonTracker,
  useCsvExport,
  useCurrentSession,
  useTracker,
} from '../../../Hooks';
import { TrackEventNames } from '../../../constants/trackEventNames';
import { HandlePaymentPartner } from '../../../Utils/PaymentPartnerHandling';
import useFilterRefund, { defaultRefundFilters } from './useFilterRefund';
import FilterSection2 from '../../FilterSection/FilterSection2';
import isEqual from 'lodash.isequal';
import FilterRefundUtils from '../../FilterPopup/utils/FilterRefundUtils';
import FilterRefunds from '../../FilterPopup/FilterRefunds';
import { useSearchParams } from 'react-router-dom';
import RefundTabs from '../RefundTabs';
import TableSkeleton from '../../TableSkeleton/TableSkeleton';
import FieldNames from '../../../constants/FieldNames';

const SingleRefund = () => {
  const { track } = useTracker();
  const {
    trackItemClicked,
    trackPageRendered,
    trackPopupRendered,
    trackRowSelected,
  } = useCommonTracker();

  const [singleRefundData, setSingleRefundData] = useState<any>({});
  const [refundDetails, setRefundDetails] = useState<any>({});
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>([]);
  const [copyText, setCopyText] = useState<boolean>(false);

  const { checkSessionError, hasSession, setSessionExpired } =
    useCurrentSession();
  const [animatedRight, setAnimateRight] = useState<boolean>(false);
  const [animatedRightRelatedTransac, setAnimateRightRelatedTransac] =
    useState<boolean>(false);

  // filter v2
  const {
    filters,
    form,
    loading,
    metaData,
    transactions,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    removeFilter,
  } = useFilterRefund();
  const [searchParams] = useSearchParams();

  const { exportAll, loading: csvLoading } = useCsvExport(
    'Refunds',
    form.watch()
  );

  useEffect(() => {
    trackPageRendered('Refunds', {
      ...filters,
      ...metaData,
    });

    return () => {
      trackPageRendered.cancel();
    };
  }, [filters, metaData]);

  useEffect(() => {
    const tid = searchParams.get('tid');
    const bulkRefundId = searchParams.get('bulk_refund_id');

    if (tid) {
      form.setValue('transactionId', tid);
      handleFormSubmit(form.getValues());
    } else if (bulkRefundId) {
      form.setValue('bulkRefundId', bulkRefundId);
      handleFormSubmit(form.getValues());
    } else {
      handleFormSubmit(defaultRefundFilters);
    }
  }, [searchParams, form]);

  useEffect(() => {
    if (animatedRightRelatedTransac) setIsModalShown(true);
  }, [animatedRightRelatedTransac]);

  const getSingleRefund = async (id: number) => {
    try {
      const token = window.localStorage.getItem('nimbbl_session_id');

      if (!hasSession()) {
        return setSessionExpired();
      }

      const res = await axios.post<APIResponse<OrderRes>>(
        `${constants.API.ORDER_API}?session_id=${token}`,
        {
          params: {
            filter: `[["transaction_items.id","=","${id}"]]`,
            query:
              '{status,bulk_refund_id, create_date,order_id,total_amount,user_id{email,mobile_number},id,transaction_items{id,status,transaction_type,transaction_id,transaction_type,refund_amount,payment_mode,create_date,settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount}}}',
          },
        }
      );

      if (checkSessionError(res)) {
        return;
      }

      if (res.data) {
        setSingleRefundData(res.data.result.result[0]);
      } else {
        setSingleRefundData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedRow = (data: [], currentPage: number) => {
    let sliceData: any[] = [];
    sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];

    setCsvData(sliceData);
  };

  const getSelectedExport = () => {
    const transactionIds: string[] = csvData.map(
      (item: any) => item.transaction_id
    );

    exportAll(transactionIds, 'transaction_id');
  };

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [isShown]);

  useEffect(() => {
    if (!animatedRight) setIsShown(true);
  }, [animatedRight]);

  const renderRowData = (e: any, data: any) => {
    trackItemClicked('Refund', {
      transaction_id: data?.orignal_payment_transaction_id?.transaction_id,
      refund_id: data?.transaction_id,
    });
    setAnimateRight(true);
    setRefundDetails(data);
    getSingleRefund(data.id);
    document.querySelector('tr.selected-row')?.classList.remove('selected-row');
    e.currentTarget.closest('tr').classList.add('selected-row');

    setFilterModalShown(false);
    trackPopupRendered('Refund', {
      refund_id: data?.transaction_id,
      transaction_id: data?.orignal_payment_transaction_id?.transaction_id,
    });
  };

  const onClose = () => {
    setAnimateRight(false);
    setAnimateRightRelatedTransac(false);
    track({
      name: TrackEventNames.refundClosed,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        refund_id: refundDetails?.transaction_id,
        transaction_id:
          refundDetails?.orignal_payment_transaction_id?.transaction_id,
      },
    });
  };

  const columns: TableColumnType<RefundTable>[] = [
    {
      title: FieldNames.refundId,
      field: 'refund_id',
      sorting: false,
      cellStyle: {
        color: '#0352C9',
        // fontWeight: 'bold',
        textDecoration: 'none',
      },
      render: (rowData) => (
        <a
          style={{ cursor: 'pointer' }}
          onClick={(e) => renderRowData(e, rowData)}>
          {rowData.transaction_id}
        </a>
      ),
    },
    {
      title: FieldNames.transactionId,
      field: 'transaction_id',
      sorting: false,
      render: (rowData) =>
        rowData.orignal_payment_transaction_id.transaction_id,
    },
    {
      title: 'refund initiated date',
      field: 'refund_date',
      sorting: false,
      render: (rowData) => (
        <span style={{ width: '110px' }}>
          {formatDate(rowData.create_date)}
        </span>
      ),
    },
    {
      title: 'txn amount',
      field: 'txn_amount',
      sorting: false,
      render: (rowData) => (
        <span>
          {PriceUtil.formatCurrency(
            rowData?.order_id?.grand_total_amount || 0,
            rowData?.order_id?.currency as any
          )}
        </span>
      ),
    },
    {
      title: 'refund amount',
      field: 'refund_amount',
      sorting: false,
      render: (rowData) => (
        <span>
          {PriceUtil.formatCurrency(
            rowData.refund_amount,
            rowData?.order_id?.currency as any
          )}
        </span>
      ),
    },
    {
      cellStyle: {
        maxWidth: 180,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      title: 'invoice',
      field: 'invoice',
      sorting: false,
      render: (rowData) => rowData.order_id.invoice_id,
    },
    {
      title: FieldNames.status,
      field: 'status',
      sorting: false,
      render: (rowData) => (
        <span className='capitalize'>
          <Badge
            variant='accent'
            {...getIssuerBadgeType(rowData.status)}></Badge>
        </span>
      ),
    },
  ];

  let refundDetailsObj: any = {
    refund_details: [
      {
        transaction_amount: PriceUtil.formatCurrency(
          refundDetails?.order_id?.grand_total_amount,
          refundDetails?.order_id?.currency
        ),
      },
      {
        refund_amount: PriceUtil.formatCurrency(
          refundDetails?.refund_amount,
          refundDetails?.order_id?.currency
        ),
      },
      {
        payment_partner: HandlePaymentPartner.getPaymentPartner(
          refundDetails?.payment_partner
        ),
      },
      { payment_mode: refundDetails?.payment_mode },
      {
        email: refundDetails?.user_id?.email
          ? refundDetails?.user_id?.email
          : '--',
      },
      {
        mobile: refundDetails?.user_id?.mobile_number
          ? refundDetails?.user_id?.mobile_number
          : '--',
      },
      { refund_initiated_date: formatDate(refundDetails?.create_date) },
      {
        ...(Boolean(refundDetails?.bulk_refund_id?.bulk_refund_id) && {
          bulk_refund_id: refundDetails?.bulk_refund_id?.bulk_refund_id,
        }),
      },
      { submerchant: refundDetails?.sub_merchant_id?.description },
      { submerchant_id: refundDetails?.sub_merchant_id?.sub_merchant_id },
      { refund_arn: refundDetails?.refund_arn },
    ],
    settlement_details: [
      {
        psp_settlement_id: refundDetails?.settlement_log?.partner_settement_id,
      },
      {
        settlement_amount: PriceUtil.formatCurrency(
          refundDetails?.settlement_log?.partner_settement_id
            ? refundDetails?.settlement_log?.settled_amount
            : 0,
          refundDetails?.settlement_log?.settled_currency?.name as any
        ),
      },
      {
        settlement_date: formateDateToDDMonYYYY(
          refundDetails?.settlement_log?.settlement_date
        ),
      },
      { utr_no: refundDetails?.settlement_log?.utr_no },
    ],
  };

  if (refundDetails?.order_id?.order_custom_attributes?.length > 0) {
    Object.assign(refundDetailsObj, {
      custom_attributes: refundDetails?.order_id?.order_custom_attributes,
    });
  }

  const toggle = () => {
    setAnimateRightRelatedTransac(true);
    track({
      name: TrackEventNames.refundRelatedTransactionOpened,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        refund_id: refundDetails?.transaction_id,
        transaction_id:
          refundDetails?.orignal_payment_transaction_id?.transaction_id,
      },
    });
  };

  const toggleClose = () => {
    setAnimateRightRelatedTransac(false);
  };

  let icon = [<FiRotateCcw />, <BsCheck2Circle />, <FiInfo />];

  const getKeyValueObjMap = (dataArray: [], data: string) => {
    let msgNull = [];
    if (
      data === 'settlement_details' &&
      singleRefundData?.transaction_items &&
      singleRefundData?.transaction_items.length &&
      !singleRefundData.transaction_items[0]?.settlement_log
        .partner_settement_id
    ) {
      msgNull.push(<span className='text-sm'>Transaction is not settled</span>);
      return msgNull;
    }

    if (data === 'custom_attributes') {
      return dataArray?.map((item, index) => {
        const { key, value } = item;

        return (
          <KeyValue
            styleContainer={{
              width: '47%',
              marginBottom: 16,
              marginLeft: index % 2 === 0 ? '3%' : 0,
            }}
            label={key}
            labelStyle={{ textTransform: 'capitalize' }}
            value={value}
            valueStyle={{ overflowWrap: 'break-word' }}
          />
        );
      });
    }

    return dataArray.map((item: any, index: number) => {
      let pointer = index + 1;
      let val: any = (Object.values(item) && Object.values(item)[0]) || '';
      let lab: any =
        Object.keys(item) && Object.keys(item)[0]?.replace(/_/g, ' ');

      if (val === '') return <div className='clear-both'></div>;
      if (lab.includes('email')) {
        val = <span className='lowercase'>{val}</span>;
      }
      if (lab.includes('id')) {
        lab = lab?.replace('id', 'ID');
      }
      let msg = [];
      msg.push(
        <KeyValue
          className={
            pointer % 2 === 0 ? 'keyContainerWoMargin' : 'keyContainer'
          }
          label={lab}
          labelStyle={{ textTransform: 'capitalize' }}
          value={val}
          valueStyle={{ overflowWrap: 'break-word' }}
        />
      );
      if (pointer % 2 === 0) {
        msg.push(<div className='clear-both'></div>);
      }
      if (dataArray.length === pointer) {
        msg.push(<div className='clear-both'></div>);
      }

      return msg;
    });
  };

  const getSuccessCard = (data: any) => {
    if (data.transaction_type !== 'full-refund') {
      return (
        <TransactionCardModule
          transaction_data={data}
          order_amount={refundDetails?.order_id?.grand_total_amount}
          currency={refundDetails?.order_id?.currency}
          transactionCardType='refunds'
        />
      );
    }
  };

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  const copyTextClipboard = (data: any) => {
    navigator.clipboard.writeText(data?.transaction_id);
    setCopyText(true);
    track({
      name: TrackEventNames.refundIDCopied,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        transaction_id: data?.transaction_id,
        refund_id: data?.transaction_id,
      },
    });
  };

  const tableRef = useRef<any>();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllExport = () => {
    exportAll();
    clearSelection();
  };

  const relatedTransaction = useMemo(() => {
    const { transaction_id } = refundDetails;
    const { transaction_items = [] } = singleRefundData || {};

    return transaction_items.filter((item: any) => {
      if (['partial-refund', 'full-refund'].includes(item.transaction_type)) {
        return item.transaction_id !== transaction_id;
      }
      return true;
    });
  }, [singleRefundData, refundDetails]);

  return (
    <>
      <Sidebar />
      <div className='p-4 mb-8 body-container ml-60'>
        <HeaderComponent
          loading={csvLoading}
          page='Refund'
          title='Refund Details'
          total={metaData?.total_records}
          handleExport={getSelectedExport}
          handleExportAll={getAllExport}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          selectedRows={csvData.length}
        />

        <FilterSection2
          defaultFilters={defaultRefundFilters}
          filters={filters}
          pageName='refund'
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterRefundUtils.generateParams(defaultRefundFilters)
                .filterSection,
              filters
            )
          }
        />

        {loading ? (
          <TableSkeleton />
        ) : (
          <div style={{ overflow: 'auto' }} className='mt-6'>
            <Table
              data={transactions}
              columns={columns}
              // filtering
              search={false}
              handleSearchChange={() => {}}
              selection={true}
              // handleSelectionChange={(data) => getSelectedRow(data)}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(transactions.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total transactions is still less than total records
                    transactions.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  getSelectedRow(rows, page + 1);
                  setCsvData(rows);
                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected('Refunds', {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: 20,
                draggable: false,
              }}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    'No records fetched for your search criteria, please change the filters and search again',
                },
              }}
            />
          </div>
        )}
      </div>

      <Drawer
        position='right'
        hide={onClose}
        isShown={isShown}
        style={{ width: '400px' }}
        className={
          animatedRight
            ? 'animation drawerAnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerElement={
          <div>
            <div className='flex items-center justify-start mb-2 text-base'>
              {refundDetails && refundDetails?.transaction_id}{' '}
              {!copyText ? (
                <span
                  className='ml-4 text-lg cursor-pointer text-blue-40'
                  title={`Copy ${FieldNames.refundId}`}
                  onClick={() => copyTextClipboard(refundDetails)}>
                  {' '}
                  <RiFileCopy2Line />
                </span>
              ) : (
                <p className='copyToClipboard'>copied to clipboard</p>
              )}
            </div>
            <span className='capitalize'>
              <Badge
                variant='accent'
                {...getIssuerBadgeType(refundDetails.status)}></Badge>
            </span>
          </div>
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        bodyElement={
          <>
            <div
              className='p-4 no-scroll icon-font'
              style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}>
              {Object.keys(refundDetailsObj).map((data: any, index: number) => {
                return (
                  <Collapsible
                    open={index === 0 ? false : true}
                    title={
                      <h6 className='capitalize font-weight-bold'>
                        {data?.replace(/\_/g, ' ')}
                      </h6>
                    }
                    iconColor='#0352C9'
                    isBorder={true}
                    icon={icon[index]}
                    style={{ borderBottom: '1px solid #ccc' }}>
                    <div
                      className='p-4 rounded-lg bg-blue-10'
                      style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {getKeyValueObjMap(refundDetailsObj[data], data)}
                    </div>
                  </Collapsible>
                );
              })}
              {relatedTransaction.length > 0 && (
                <div className='mt-4'>
                  <CollapsibleButton
                    text={`Related Transactions`}
                    iconRight={<FiColumns />}
                    iconLeft={<FiRotateCw />}
                    style={{ width: '100%', background: '#F1F4F9' }}
                    iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                    iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                    onClick={toggle}></CollapsibleButton>
                </div>
              )}
            </div>
          </>
        }
      />

      <FilterRefunds
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />

      <Drawer
        position='right'
        hide={toggleClose}
        isShown={isModalShown}
        style={{ width: '400px' }}
        className={
          animatedRightRelatedTransac
            ? 'animation drawer2AnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerElement={
          <div>
            <h1 className='mb-2 text-base'>Related Transactions</h1>
            {/* <Badge type={getIssuerBadgeType(orderData.status)} ></Badge> */}
          </div>
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        bodyElement={
          <div
            className='p-4 no-scroll'
            style={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}>
            {relatedTransaction?.length > 0 &&
              relatedTransaction.map((data: any) => {
                return getSuccessCard(data);
              })}
          </div>
        }
      />
    </>
  );
};

export default SingleRefund;
