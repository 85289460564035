import React, { useEffect, useMemo, useState } from "react";
import constants from "../../constants";
import * as yup from "yup";
import { IRefundRequest, IRefundRes } from "../../Apis/interface";
import axios from "axios";
import { segmentConfirmationRefundRender } from "../../Apis/segment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, InputField, ModalV2 } from "nimbbl-quark";
import { IoAlertCircleOutline } from "react-icons/io5";
import { BsCheck2Circle } from "react-icons/bs";
import ReactDOM from "react-dom";
import { FiX } from "react-icons/fi";
import { useTracker } from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Please enter valid Refund amount")
    .required("Amount is required"),
  // comment: yup.string().required('Comment is required'),
});

class AlertType {
  type: "success" | "danger" = "danger";
  message: string = "";
}

export const RefundModal = (value: any) => {
  let data = value.transaction_data;

  const amount = data?.order_id?.available_refundable_amount;
  const original_currency = data?.order_id?.original_currency;
  const currency = data?.order_id?.currency;
  const { track } = useTracker();
  const [alert, setAlert] = useState<AlertType>(new AlertType());
  const [showRefundAlert, setShowRefundAlert] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<string | null>("");

  const { hideModal } = value;

  useEffect(() => {
    track({
      name: TrackEventNames.issueRefundRendered,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: {
        transaction_id: value.transaction_data?.transaction_id,
      },
    });
  }, [value.transaction_data?.transaction_id]);

  const handleAlert = () => {
    setShowRefundAlert(false);
  };

  const handleRefundSubmit = async (data: IRefundRequest) => {
    const user_id = window.localStorage.getItem("nimbbl_user_id");
    const token = window.localStorage.getItem("nimbbl_session_id");
    try {
      const res = await axios.post<IRefundRes>(
        `${constants.API.INITIATE_REFUND_API}?session_id=${token}`,
        {
          params: {
            amount: data.amount,
            comment: data.comment,
            transaction_id: value.transaction_data?.transaction_id,
            user_id: user_id,
          },
        }
      );

      // @ts-ignore
      const refund_id = res?.data?.result?.result?.res_id;
      track({
        name: TrackEventNames.confirmationRendered,
        inBuiltOptions: ["merchantId", "merchantName"],
        additionalOptions: {
          ...(refund_id && { refund_id }),
          transaction_id: value.transaction_data?.transaction_id,
        },
      });

      if (res.data?.result?.status === "failed") {
        throw new Error(res?.data?.result?.m_message);
      }

      track({
        name: TrackEventNames.issueRefundSelected,
        inBuiltOptions: ["merchantId", "merchantName"],
        additionalOptions: {
          menu_item_name: "Transactions",
          transaction_id: value.transaction_data?.transaction_id,
          transaction_amount: String(amount),
          refund_amount: (data.amount || 0).toString(),
          payment_mode: value?.transaction_data?.payment_mode,
        },
      });

      if (res.data?.result?.status === "success") {
        setAlert({
          type: "success",
          message: res.data?.result?.m_message,
        });

        setShowRefundAlert(true);
      }
    } catch (error) {
      const { message } = error as Error;

      setAlert({ type: "danger", message });
      setShowRefundAlert(true);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IRefundRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return { amount };
    }, [amount]),
  });

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (showRefundAlert) {
      if (alert.type === "success") {
        setTimeout(() => {
          hideModal();
        }, 1500);

        return;
      }

      timerId = setTimeout(() => {
        handleAlert();
      }, 5000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [
    showRefundAlert,
    track,
    alert,
    value.transaction_data?.transaction_id,
    hideModal,
  ]);

  useEffect(() => {
    let is_admin = window.localStorage.getItem("nimbbl_admin");
    let is_admin_internal = window.localStorage.getItem("internal_admin");
    if (is_admin_internal) {
      is_admin = "true";
    }
    setIsAdmin(is_admin);
  }, []);

  return (
    <div>
      {showRefundAlert &&
        ReactDOM.createPortal(
          <div className="alertPosition" style={{ zIndex: "999" }}>
            <Alert
              variant={alert.type}
              onClose={() => handleAlert()}
              bannerWithCta={false}
              description={alert.message}
              size="md"
              icon={
                alert.type === "success" ? (
                  <BsCheck2Circle />
                ) : (
                  <IoAlertCircleOutline />
                )
              }
              closeIcon={<FiX />}
              closable={true}
            />
          </div>,
          document.body
        )}

      <ModalV2
        isShown={true}
        isBackdrop
        position="center"
        hide={value.setRefund}
        size="sm"
        style={{ maxWidth: "352px" }}
        modalContent={
          <>
            <div
              className="flex items-center justify-between mb-4 text-lg"
              style={{ color: "#333333" }}
            >
              <h5 className="text-lg font-bold">Issue refund</h5>
              {/* <RiCloseFill className='w-6 h-6 cursor-pointer' onClick={hideRefundModal}/> */}
            </div>
            <form
              className="login-form"
              onSubmit={handleSubmit(handleRefundSubmit)}
              noValidate
            >
              <InputField
                type="text"
                prefix={original_currency ? original_currency : currency}
                // label="Refund Amount"
                prefixClassName="rounded-l-lg h-12 w-12 pr-2 ml-[1px] bg-[#FAFAFC] border-r-[1px] border-r-[#CBD4E1] flex items-center justify-center font-medium text-sm"
                id="amount"
                attributes={{
                  type: "text",
                  ...register("amount"),

                  style: {
                    overflow: "hidden",
                    display: "block",
                    width: "100%",
                    // marginBottom: "16px",
                    paddingLeft: "60px",
                    fontWeight: "normal",
                  },
                  // defaultValue: amount,
                }}
                caption={errors.amount?.message}
                captionType="error"
                label="Refund Amount"
              />
              <InputField
                type="text"
                id="comment"
                attributes={{
                  type: "text",
                  ...register("comment"),
                  style: {
                    display: "block",
                    width: "100%",
                    fontWeight: "normal",
                  },
                }}
                caption={errors.comment?.message}
                captionType="error"
                label="Add Notes"
              />

              <div className="flex items-center justify-between mt-6">
                <Button
                  text="Cancel"
                  attributes={{
                    style: {
                      width: "140px",
                      background: "#FFFFFF",
                      color: "#045CE0",
                      border: "1px solid #045CE0",
                      fontWeight: "normal",
                      fontSize: "14px",
                      height: "40px",
                    },
                    onClick: () => {
                      track({
                        name: TrackEventNames.confirmationClicked,
                        inBuiltOptions: ["merchantId", "merchantName"],
                        additionalOptions: {
                          transaction_id:
                            value.transaction_data?.transaction_id,
                          refund_confirmation: "Cancel",
                        },
                      });
                      hideModal();
                    },
                  }}
                />
                <Button
                  text="Issue Refund"
                  variant="primary"
                  type="submit"
                  attributes={{
                    style: {
                      width: "150px",
                      background: "#045CE0",
                      fontWeight: "normal",
                      fontSize: "14px",
                      height: "40px",
                    },
                    onClick: () => {
                      track({
                        name: TrackEventNames.confirmationClicked,
                        inBuiltOptions: ["merchantId", "merchantName"],
                        additionalOptions: {
                          transaction_id:
                            value.transaction_data?.transaction_id,
                          refund_confirmation: "Yes",
                        },
                      });
                    },
                  }}
                ></Button>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};
