import {
  Autocomplete,
  AutocompleteCloseReason,
  ButtonBase,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputBase,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import React, { useState, KeyboardEvent, useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import {
  RiArrowDownSLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiSearchLine,
} from 'react-icons/ri';
import { Option } from '.';
import { PopperContainer } from './StyledComponents';
import './Dropdown.css';

interface MultiSelectProps<T extends Option> {
  id: string;
  placeHolder?: string;
  options: T[];
  value: T[];
  onChange: (value: T[]) => void;
  onInputChange?: (value: string) => void;
  groupBy?: (option: T) => string;
}

const optionType = 'multi';

const PopperComponent = (props: any) => {
  const { disablePortal, anchorEl, open, ...others } = props;

  return <div {...others} />;
};

export const MultiSelect = <T extends Option>(props: MultiSelectProps<T>) => {
  const { value, id, onChange, options, placeHolder, groupBy, onInputChange } =
    props;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  // const [searchValue, setSearchValue] = useState('');

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleClose = (e?: MouseEvent | TouchEvent) => {
    if (e) {
      if (e.target) {
        const isMulti =
          // @ts-ignore
          e.target?.getAttribute('data-option-type') === optionType;
        if (isMulti) {
          return;
        }
      }
    }

    if (anchorElement) anchorElement.focus();
    setAnchorElement(null);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: T[],
    reason: AutocompleteCloseReason
  ) => {
    if (
      event.type === 'keydown' &&
      (event as KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    onChange(value);
  };

  const getLabel = () => {
    if (!value) return '';

    if (value?.length === 0) return placeHolder;
    if (value?.length === 1) return value[0].label;
    return value[0].label + `, +${value?.length - 1} more`;
  };

  // const memoOptions = useMemo(() => {
  //   if (!searchValue) return options;

  //   return matchSorter(options, searchValue, {
  //     keys: ['label', 'value'],
  //     baseSort: (a, b) => {
  //       const aGroupName = a.item.groupName;
  //       const bGroupName = b.item.groupName;
  //       if (aGroupName && bGroupName) {
  //         return aGroupName.localeCompare(bGroupName);
  //       }
  //       return a.index > b.index ? -1 : 1;
  //     },
  //   });
  // }, [options, searchValue]);

  return (
    <>
      <ButtonBase
        className='Value-Container'
        id={id}
        disableRipple
        onClick={handleOpen}>
        <span
          className='font-normal'
          style={{ color: value?.length > 0 ? '#000' : 'rgba(0, 0, 0, .5)' }}>
          {getLabel()}
        </span>
        <span className='flex' style={{ marginLeft: 'auto', color: '#6C7F9A' }}>
          {value.length > 0 ? (
            <MdClose
              color='inherit'
              size={20}
              onClick={() => {
                onChange([]);
                // @ts-ignore
                handleClose();
              }}
            />
          ) : (
            <RiArrowDownSLine color='inherit' size={20} />
          )}
        </span>
      </ButtonBase>
      <PopperContainer
        id='dropDown'
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: ({ placement }: { placement: string }) =>
                placement === 'top' ? [0, 3] : [0, 2],
            },
          },
        ]}
        style={{ ...(anchorElement && { width: anchorElement.offsetWidth }) }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Autocomplete
            open
            multiple
            id={`search ${id}`}
            freeSolo
            autoHighlight
            fullWidth
            selectOnFocus
            handleHomeEndKeys
            disableCloseOnSelect
            disableClearable
            // loading={loading}
            size='small'
            value={value}
            options={options}
            noOptionsText='No options available'
            {...(groupBy && { groupBy })}
            // groupBy={(option) => option?.groupName || ''}
            onInputChange={(e, newValue) => {
              if (onInputChange) {
                onInputChange(newValue);
              }
            }}
            // @ts-ignore
            onChange={handleChange}
            onClose={(
              _event: React.ChangeEvent<{}>,
              reason: AutocompleteCloseReason
            ) => {
              if (reason === 'escape') {
                handleClose();
              }
            }}
            PopperComponent={PopperComponent}
            componentsProps={{
              clearIndicator: {
                disableRipple: true,
                disableFocusRipple: true,
                disableTouchRipple: true,
                size: 'small',
                className: 'Clear-Icon',
              },
              paper: {
                elevation: 0,
              },
              popper: {
                disablePortal: true,
                sx: {
                  position: 'static',
                  '& .MuiAutocomplete-listbox': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    '&::-webkit-scrollbar': {
                      width: 6,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'inherit',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.1)',
                      borderRadius: '4px',
                    },
                  },
                },
              },
            }}
            filterOptions={(options, { inputValue }) => {
              console.log('search', inputValue, options);
              if (!inputValue) return options;

              return matchSorter(options, inputValue, {
                keys: ['label', 'value'],
                baseSort: (a, b) => {
                  const aGroupName = a.item.groupName;
                  const bGroupName = b.item.groupName;
                  if (aGroupName && bGroupName) {
                    return aGroupName.localeCompare(bGroupName);
                  }
                  return a.index > b.index ? -1 : 1;
                },
              });
            }}
            // renderGroup={(params) => (
            //   <li key={params.key}>
            //     <div
            //       style={{
            //         position: 'sticky',
            //         top: '0px',
            //         padding: '10px 8px',
            //         fontSize: 14,
            //         lineHeight: '20px',
            //         fontWeight: 500,
            //         backgroundColor: '#fff',
            //         color: 'rgba(0, 0, 0, .6)',
            //       }}>
            //       {params.group}
            //     </div>
            //     <ul style={{ padding: 0, zIndex: 0 }}>{params.children}</ul>
            //   </li>
            // )}
            renderTags={() => null}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option, { selected }) => {
              return (
                <li
                  {...props}
                  data-option-type={optionType}
                  key={option.value}
                  style={{ padding: '8px !important' }}>
                  <Checkbox
                    style={{ padding: 4 }}
                    size='small'
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    icon={<RiCheckboxBlankLine size={18} color='#CBD4E1' />}
                    checkedIcon={<RiCheckboxFill size={18} />}
                    checked={selected}
                    inputProps={{
                      // @ts-ignore
                      'data-option-type': optionType,
                    }}
                  />
                  <span
                    data-option-type={optionType}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 'normal',
                      marginLeft: 8,
                    }}>
                    {option.label}
                  </span>
                </li>
              );
            }}
            loadingText={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CircularProgress size={24} style={{ color: '#045CE0' }} />
              </div>
            }
            renderInput={(params) => (
              <InputBase
                autoFocus
                fullWidth={params.fullWidth}
                size={params.size}
                id={params.id}
                disabled={params.disabled}
                {...params.InputProps}
                sx={{
                  borderBottom: '1px solid #CBD4E1',
                  px: 1,
                  backgroundColor: '#fff',
                }}
                className='dropdown'
                inputProps={{
                  ...params.inputProps,
                  className: 'Popper-InputField',
                }}
                startAdornment={
                  <RiSearchLine
                    size={20}
                    style={{ marginBottom: 4 }}
                    color='gray'
                  />
                }
                endAdornment={
                  // @ts-ignore
                  <IconButton size='small' onClick={handleClose}>
                    <MdClose size={20} />
                  </IconButton>
                }
                placeholder='Search...'
              />
            )}
          />
        </ClickAwayListener>
      </PopperContainer>
    </>
  );
};
