import { Skeleton } from '@mui/material';
import { Fragment } from 'react';

export const EmptyContainer = ({ message }: { message: string }) => (
  <div className='border mt-6 border-[#94A3B8] rounded h-[400px] flex justify-center items-center  hover:bg-[#e6effc]'>
    <p className='text-base'>{message}</p>
  </div>
);

const Heading = ({ className }: { className?: string }) => (
  <Skeleton
    className={`${className} rounded`}
    variant='rectangular'
    width={240}
    height={40}
  />
);

const randomHeights = [130, 300, 250, 100, 180, 150, 200, 280, 160];

export const AnalyticsSkeleton = () => {
  return (
    <div>
      <Heading className='mt-6' />

      <div className='flex items-end justify-around w-full my-10'>
        {randomHeights.map((height) => (
          <Skeleton
            className='rounded'
            key={height}
            variant='rectangular'
            width={60}
            height={height}
          />
        ))}
      </div>

      {[1, 2].map((item) => (
        <Fragment key={item}>
          <Heading />
          <Skeleton
            variant='rectangular'
            className='mt-6 mb-10 rounded'
            width='100%'
            height={360}
          />
        </Fragment>
      ))}
    </div>
  );
};
