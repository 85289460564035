import axios from 'axios';
import { Button, ModalV2 } from 'nimbbl-quark';
import { FC, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { MdClose } from 'react-icons/md';
import { FaRegTrashCan } from 'react-icons/fa6';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { LuFileText } from 'react-icons/lu';

import File_XLS from '../../../images/File_XLS.svg';
import { CircularProgress } from '@mui/material';
import getApiEndPoint from '../../FilterPopup/utils/getApiEndpoint';
import SubMerchantDropdown from '../../SubMerchantDropdown';
import { Option } from '../../FilterPopup/interfaces/CommonFields';
import {
  BulkRefundFailure,
  BulkRefundResultInterface,
  BulkRefundSuccess,
} from './BulkRefundResult';
import {
  FilterBulkRefundFields,
  defaultBulkRefundFilters,
} from './useFilterBulkRefund';

// file size in kb
const maxFileSize = 1024;
const sampleFileLink =
  'https://docs.google.com/spreadsheets/d/16uci6ti6uzod6GyZhPW3gv3-AGyMVdZOs69cXczcH9s/export?format=xlsx';

const getSizeInKB = (size: number) => (size / 1024).toFixed(2);

const sizeValidator = (file: File) => {
  const fileSizeInKB = +getSizeInKB(file.size);

  if (fileSizeInKB > maxFileSize) {
    return {
      code: 'file-too-large',
      message: 'Selected file size is greater than 1 MB.',
    };
  }

  return null;
};

interface BulkUploadModalInterface {
  open: boolean;
  openUpload: () => void;
  onClose: () => void;
  handleFormSubmit: (
    formData: FilterBulkRefundFields & {
      page?: number;
    }
  ) => Promise<void>;
}

const BulkUploadModal: FC<BulkUploadModalInterface> = (props) => {
  const { onClose, openUpload, open, handleFormSubmit } = props;

  const [subMerchant, setSubMerchant] = useState<Option | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState<'success' | 'failed' | null>(
    null
  );
  const [uploadMessage, setUploadMessage] = useState('');

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls'],
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    validator: sizeValidator,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const file = acceptedFiles[0];
      if (file) setSelectedFile(file);
      else setSelectedFile(null);
    },
  });

  const handleClose = () => {
    console.log('called 1');
    onClose();
    setSelectedFile(null);
    setSubMerchant(null);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setLoading(true);
    const url = getApiEndPoint('bulkRefundUpload');
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('sub_merchant_id', subMerchant?.value ?? '');

    try {
      const res = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('res', res);

      const errorMessage = res.data?.result?.error?.message || '';
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      const message = res.data?.result?.message;

      setLoading(false);
      onClose();
      setUploadResult('success');
      setUploadMessage(message);
    } catch (error) {
      const { message } = error as Error;
      console.log('error', message);

      setLoading(false);
      onClose();
      setUploadResult('failed');
      setUploadMessage(message);
    }
  };

  const clearFile = () => setSelectedFile(null);

  const fileError = fileRejections.reduce((result, { file, errors }) => {
    const { message, code } = errors[0];

    if (code === ErrorCode.FileInvalidType) {
      return result + 'File type must be .xlsx, .xls or .csv';
    }

    return result + message;
  }, '');

  const isSubmitDisabled = !subMerchant || !selectedFile;
  const showSuccess = uploadResult === 'success';
  const showFailed = uploadResult === 'failed';

  const resultProps: Pick<
    BulkRefundResultInterface,
    'fileName' | 'message' | 'subMerchant' | 'onClose' | 'handleRetry'
  > = {
    fileName: selectedFile?.name ?? '',
    subMerchant: subMerchant?.label ?? '',
    message: uploadMessage,
    onClose: () => {
      setUploadResult(null);
      handleClose();
      handleFormSubmit({ ...defaultBulkRefundFilters });
    },
    handleRetry: () => {
      setUploadResult(null);
      openUpload();
    },
  };

  return (
    <>
      <ModalV2
        isShown={open}
        isBackdrop
        position='center'
        size='sm'
        style={{ maxWidth: '480px' }}
        hide={handleClose}
        modalContent={
          <>
            <header className='flex items-start justify-between mb-6'>
              <div>
                <h1 className='text-lg font-semibold text-black'>
                  Create Bulk Refund
                </h1>
                <p className='text-xs text-[#6C7F9A]'>
                  {loading
                    ? 'Please wait while the files are getting uploaded.'
                    : 'Add files to initiate bulk refund.'}
                </p>
              </div>
              <button onClick={handleClose}>
                <MdClose size={24} color='black' />
              </button>
            </header>

            {loading ? (
              <div className='flex flex-col items-center justify-center'>
                <CircularProgress size={80} thickness={2.5} />

                <p className='mt-6 text-base text-black'>
                  Uploading file {selectedFile?.name}
                </p>
                {subMerchant?.label && (
                  <p className='text-base text-black'>
                    for {subMerchant?.label}
                  </p>
                )}

                <p className='flex mt-9'>
                  <FiAlertCircle size={20} className='mr-2' />
                  Please do not close the window when the file is being
                  uploaded.
                </p>
              </div>
            ) : (
              <>
                <div className='mb-4'>
                  <div className='mb-1'>
                    <label
                      htmlFor='submerchant-dropdown'
                      className='text-sm font-semibold text-black-primary'>
                      Submerchant
                    </label>
                  </div>
                  <SubMerchantDropdown
                    className='z-20'
                    isMulti={false}
                    value={subMerchant}
                    subMerchantId={false}
                    onChange={(value) => setSubMerchant(value)}
                  />
                </div>

                {selectedFile ? (
                  <section>
                    <div className='py-3 px-2 bg-[#ECF0FD] rounded flex justify-between items-center border'>
                      <div className='flex'>
                        <img
                          src={File_XLS}
                          alt='.xls file icon'
                          style={{
                            width: '100%',
                            height: 'auto',
                            marginRight: 8,
                          }}
                        />
                        <div className='flex flex-col justify-between'>
                          <p className='mb-2 truncate'>{selectedFile?.name}</p>
                          <div>
                            <span>{getSizeInKB(selectedFile?.size)} KB</span>
                            <span className='mx-1'>&#x2022;</span>
                            <span className='text-[#219653]'>Selected</span>
                          </div>
                        </div>
                      </div>
                      <button className='mr-1'>
                        <FaRegTrashCan
                          size={26}
                          color='#D83232'
                          onClick={clearFile}
                        />
                      </button>
                    </div>
                    <button
                      className='mt-2 underline'
                      style={{ color: 'var(--color-primary-60)' }}
                      {...getRootProps()}>
                      <input {...getInputProps()} />
                      Choose a different file
                    </button>
                    {fileError && (
                      <p
                        className='mt-1'
                        style={{ color: 'var(--rs-red-500)' }}>
                        {fileError}
                      </p>
                    )}
                  </section>
                ) : (
                  <section>
                    <div
                      className='py-3 bg-[#ECF0FD] rounded flex flex-col justify-center items-center border border-dashed cursor-pointer'
                      {...getRootProps()}>
                      <input {...getInputProps()} />
                      <LuFileText size={24} />
                      <p className='my-1 text-sm font-medium text-black'>
                        Drag and drop here or{' '}
                        <span
                          style={{
                            color: 'var(--color-primary-60)',
                          }}>
                          Browse files
                        </span>
                      </p>
                      <p>Supported formats : .CSV, .XLS </p>
                    </div>
                    {fileError && (
                      <p
                        className='mt-1'
                        style={{ color: 'var(--rs-red-500)' }}>
                        {fileError}
                      </p>
                    )}
                    <div className='flex justify-between mt-1'>
                      <p>Rows allowed: 1-2000</p>
                      <p>Maximum file size : 1MB</p>
                    </div>
                  </section>
                )}

                <section className='mt-6'>
                  <a
                    href={sampleFileLink}
                    target='_blank'
                    rel='noreferrer'
                    className='flex items-center py-2 text-sm font-medium w-fit'
                    style={{
                      color: 'var(--color-primary-60)',
                    }}>
                    <FiDownload size={20} style={{ marginRight: 12 }} />
                    Download Sample File
                  </a>
                  <p>
                    Download the excel to get the sample sheet to upload the
                    data.
                  </p>
                </section>
              </>
            )}
          </>
        }
        modalFooter={
          loading ? (
            <></>
          ) : (
            <footer className='flex justify-end w-full'>
              <Button
                style={{ paddingLeft: 32, paddingRight: 32 }}
                text='Cancel'
                variant='outline'
                attributes={{
                  onClick: handleClose,
                }}
              />
              <Button
                style={{
                  marginLeft: 24,
                  paddingLeft: 32,
                  paddingRight: 32,
                  ...(isSubmitDisabled && { opacity: 0.5 }),
                }}
                text='Continue'
                attributes={{
                  disabled: isSubmitDisabled,
                  onClick: handleUpload,
                }}
              />
            </footer>
          )
        }
      />

      <BulkRefundSuccess open={showSuccess} {...resultProps} />
      <BulkRefundFailure open={showFailed} {...resultProps} />
    </>
  );
};

export default BulkUploadModal;
