export const { REACT_APP_API_ENDPOINT, REACT_APP_DB_NAME } = process.env;
// console.log(SEGMENT_ID)
// export const segment_id : any = SEGMENT_ID;

export const AUTH_API = `${REACT_APP_API_ENDPOINT}/auth/`;
export const UPDATE_PASSWORD_API = `${REACT_APP_API_ENDPOINT}/update_password/`;
export const RESET_PASSWORD_API = `${REACT_APP_API_ENDPOINT}/reset_password/`;
export const ORDER_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.order`;
export const ORDER_LINE_ITEM_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.order_line_item`;
export const SUBMERCHANT_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.sub_merchant`;
export const API_KEYS_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.auth_principal`;
export const TRANSACTION_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.transaction`;
export const REFUNDS_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.transaction`;
export const BULK_REFUNDS_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.bulk_refund`;
export const BULK_REFUNDS_UPLOAD_API = `${REACT_APP_API_ENDPOINT}/api/v2/dashboard/bulk_refund`;
export const SETTLEMENT_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.payment_settlement_logs`;
export const INITIATE_REFUND_API = `${REACT_APP_API_ENDPOINT}/api/v2/dashboard/refund`;
export const MERCHANT_CONF = `${REACT_APP_API_ENDPOINT}/merchant_info`;
export const SETTLEMENT_SUMMARY_API = `${REACT_APP_API_ENDPOINT}/api/read/many/nimbbl.psp_settlement_summary`;
export const GROUPBY_API = `${REACT_APP_API_ENDPOINT}/api/read_group/many/nimbbl.transaction`;
export const PAYMENT_MODE = `${REACT_APP_API_ENDPOINT}/api/dashboard-charts/transaction`;
export const SUB_PAYMENT_MODE = `${REACT_APP_API_ENDPOINT}/api/dashboard-charts/transaction/sub-payment-mode`;
export const PAYMENT_LINK = `${REACT_APP_API_ENDPOINT}/api/dashboard/payment-link`;
export const CREATE_PAYMENT_LINK = `${REACT_APP_API_ENDPOINT}/api/dashboard/payment-link/create/`;
export const UPDATE_PAYMENT_LINK = `${REACT_APP_API_ENDPOINT}/api/dashboard/payment-link/update`;
export const CANCEL_PAYMENT_LINK = `${REACT_APP_API_ENDPOINT}/api/dashboard/payment-link/cancel`;
export const RESEND_PAYMENT_LINK = `${REACT_APP_API_ENDPOINT}/api/dashboard/payment-link/send-notification`;

export const NIMBBL_DB = `${REACT_APP_DB_NAME}`;
