import moment from 'moment';

interface Options {
  removeSeconds: boolean;
}

class DateUtils {
  static getDefaultStartDate() {
    // default startDate = currentDate - 7 days
    const date = new Date();
    date.setDate(date.getDate() - 7);

    return date;
  }

  static subtractDays(value: number) {
    const date = new Date();
    date.setDate(date.getDate() - value);
    date.setHours(0, 0, 0);

    return date;
  }

  static getStringFromDate(date: Date | null, options?: Options) {
    if (!date) return null;

    const { removeSeconds } = options ?? {};

    let result =
      date.getFullYear() +
      '/' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2);

    if (!removeSeconds) {
      result += ':' + ('0' + date.getUTCSeconds()).slice(-2);
    }

    return result;
  }

  static getUTCStringFromDate(date: Date | null) {
    if (!date) return null;

    return (
      date.getUTCFullYear() +
      '/' +
      ('0' + (date.getUTCMonth() + 1)).slice(-2) +
      '/' +
      ('0' + date.getUTCDate()).slice(-2) +
      ' ' +
      ('0' + date.getUTCHours()).slice(-2) +
      ':' +
      ('0' + date.getUTCMinutes()).slice(-2) +
      ':' +
      ('0' + date.getUTCSeconds()).slice(-2)
    );
  }

  static formatDate(date: Date | null) {
    if (!date) return '';

    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }
}

export default DateUtils;
