import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import moment from 'moment';
import { FC } from 'react';

import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';

const commonProps: DateTimePickerProps<moment.Moment> = {
  ampm: false,
  timeSteps: { hours: 1, minutes: 1, seconds: 1 },
  format: 'DD/MM/YYYY HH:mm:ss',
  views: ['year', 'month', 'day', 'hours', 'minutes', 'seconds'],
  slotProps: {
    inputAdornment: {
      sx: {
        marginLeft: 0,
        marginRight: 1,
        '& > button': {
          padding: 0.5,
          '& > svg': {
            fontSize: 22,
          },
        },
      },
    },
    textField: {
      InputProps: {
        sx: {
          paddingRight: 0,
          padding: '8px',
          '& > input:focus': { boxShadow: 'none' },
        },
      },
      inputProps: {
        style: {
          fontSize: 16,
        },
      },
    },
  },
};

interface DateRangeFieldProps {
  label: string;
  value: [Date | null, Date | null];
  onChange: (value: DateRangeFieldProps['value']) => void;
}

const oldDate = new Date();
oldDate.setFullYear(oldDate.getFullYear() - 1);

const DateRangeField: FC<DateRangeFieldProps> = (props) => {
  const { label, value, onChange } = props;
  const [startDate, endDate] = value;

  const onDateChange = (dates: [Date | null, Date | null], _: any) => {
    let [start, end] = dates;
    onChange([start, end]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='en-gb'>
      <div className='mt-6 form-input'>
        <div className='mb-1'>
          <label className='text-sm font-semibold text-black-primary'>
            {label}
          </label>
        </div>

        <div className='flex gap-x-3'>
          <DateTimePicker
            {...commonProps}
            value={moment(startDate)}
            onChange={(value) => onChange([value?.toDate() ?? null, endDate])}
          />
          <DateTimePicker
            {...commonProps}
            value={moment(endDate)}
            onChange={(value) => onChange([startDate, value?.toDate() ?? null])}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DateRangeField;
