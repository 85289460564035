import { Breadcrumbs } from '@mui/material';
import classnames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { emptyMessage, SummaryViewType } from '../AnalyticsHome';
import { AnalyticsResponse } from '../AnalyticsResponse';
import { isLastLevel } from '../sharedUtil';
import { EmptyContainer } from '../Skeleton';
import { usePaymentPartner } from './usePaymentPartner';

interface PaymentPartnerProps {
  highcharts: typeof Highcharts;
  data: AnalyticsResponse['payment_partner'];
  filters: {
    currency: string;
    summaryView: SummaryViewType;
  };
}

export const PaymentPartner = (props: PaymentPartnerProps) => {
  const { highcharts, data, filters } = props;

  const { paymentData, options, selectedCell, setSelectedCell } =
    usePaymentPartner({
      filters,
      paymentPartners: data,
    });

  const isEmpty = Object.keys(data).length === 0;

  return (
    <>
      <h2 className='mt-5 text-lg font-bold'>Payment Partner</h2>
      <p className='text-sm mb-3 text-[#94A3B8]'>
        Click on each payment partner to view more details
      </p>
      {isEmpty ? (
        <EmptyContainer message={emptyMessage.partner} />
      ) : (
        <>
          <Breadcrumbs className='px-3'>
            <p
              role='button'
              onClick={() => setSelectedCell('')}
              {...(selectedCell.length > 0 && { style: { color: '#0352c9' } })}
              className='text-sm'>
              All Payment Partners
            </p>
            {selectedCell &&
              selectedCell.split('_').map((methodName, index, array) => (
                <p
                  {...(index !== array.length - 1 && {
                    role: 'button',
                    onClick: () => setSelectedCell(methodName),
                    style: { color: '#0352c9' },
                  })}
                  key={methodName}
                  className='text-sm'>
                  {methodName}
                </p>
              ))}
          </Breadcrumbs>
          <div className='flex'>
            <div className='w-3/4'>
              <HighchartsReact
                containerProps={{
                  style: {
                    width: '100%',
                  },
                }}
                highcharts={highcharts}
                options={options}
              />
            </div>
            <div className='w-1/4 px-2 py-2 mt-[10px] mb-4 border border-[#7a7a7a] rounded'>
              <p className='text-base font-semibold text-[#7a7a7a] text-center capitalize'>
                {selectedCell
                  ? selectedCell.split('_').at(-1)
                  : 'Payment Partners'}
              </p>
              <ul
                style={{ maxHeight: 314 }}
                className='px-2 mt-2 overflow-y-auto customScrollbar'>
                {paymentData.map(({ name, value }) => (
                  <li
                    key={name}
                    {...(!isLastLevel(String(name)) && {
                      role: 'button',
                      onClick: () => setSelectedCell(String(name)),
                    })}
                    className={classnames([
                      'flex justify-between my-2 text-sm font-normal',
                      {
                        'hover:text-[#0352c9] hover:font-semibold':
                          !isLastLevel(String(name)),
                      },
                    ])}>
                    <span>{String(name)}</span>
                    <span className='min-w-[50px] text-right'>
                      {value?.toFixed(2)} %
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};
