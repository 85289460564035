// @ts-nocheck

import React, { ReactNode } from 'react';
import { ComboBox } from './ComboBox';
import { MultiSelect } from './MultiSelect';
import { Select } from './Select';

export type Option = Record<string, any> & {
  label: ReactNode;
  value: string | number;
  groupName?: string;
};

export interface SelectProps<T extends Option> {
  multiple?: false;
  searchable?: false;
  value: T | null;
  onChange: (value: T) => void;
}

export interface ComboBoxProps<T extends Option> {
  multiple?: false;
  searchable: true;
  value: T | null;
  onChange: (value: T) => void;
}

export interface MultiSelectProps<T extends Option> {
  multiple: true;
  searchable?: false;
  value: T[];
  onInputChange?: (value: string) => void;
  onChange: (value: T[]) => void;
  groupBy?: (option: T) => string;
}

export type DropdownProps<T extends Option> = {
  placeHolder?: string;
  id: string;
  options: T[];
} & (SelectProps<T> | ComboBoxProps<T> | MultiSelectProps<T>);

export const Dropdown = <T extends Option>(props: DropdownProps<T>) => {
  const {
    multiple,
    searchable,
    id,
    onChange,
    options,
    value,
    placeHolder,
    ...rest
  } = props;

  if (searchable && !multiple) {
    return (
      <ComboBox
        id={id}
        placeHolder={placeHolder}
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }

  if (multiple && !searchable) {
    return (
      <MultiSelect
        id={id}
        placeHolder={placeHolder}
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }

  return (
    <Select
      id={id}
      options={options}
      value={value}
      onChange={onChange}
      placeHolder={placeHolder}
      {...rest}
    />
  );
};
