import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';
import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useState,
} from 'react';
import { IconBaseProps, IconType } from 'react-icons';
import { FcExpand } from 'react-icons/fc';

type ParagraphProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export interface AccordionProps {
  // title
  titleText: string;
  titleProps?: ParagraphProps;

  // caption
  captionText?: string;
  captionProps?: ParagraphProps;

  // icon
  expandIcon?: IconType;
  iconProps?: IconBaseProps;

  subElement?: ReactNode;
  defaultExpanded?: boolean;
  handleRemove?: () => void;
}

const useStyles = makeStyles({
  accordion: {
    paddingBottom: 8,
    // marginBottom: 8,
    borderBottom: '1px solid #CBD4E1',

    '& > div:first-child': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  expanded: {
    margin: '0 !important',
    minHeight: '0',
  },
  details: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'block',
  },
  summaryRoot: {
    marginTop: 8,
  },
  summaryContent: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
});

export const Accordion: FC<AccordionProps> = (props) => {
  const {
    titleText,
    titleProps,
    captionProps,
    captionText,
    subElement,
    iconProps,
    expandIcon: ExpandIcon = FcExpand,
    children,
    defaultExpanded,
    handleRemove,
  } = props;

  const [expand, setExpand] = useState(false);
  const classes = useStyles();

  const toggleExpand = () => setExpand((prev) => !prev);

  return (
    <>
      <MuiAccordion
        defaultExpanded={defaultExpanded}
        elevation={0}
        classes={{ root: classes.accordion, expanded: classes.expanded }}
        square>
        <AccordionSummary
          onClick={toggleExpand}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
          }}>
          <div className='flex items-center justify-between'>
            <div>
              <p className='text-base font-semibold' {...titleProps}>
                {titleText}
              </p>
              <p className='text-sm font-normal' {...captionProps}>
                {captionText}
              </p>
            </div>
            <div className='flex '>
              {handleRemove && (
                <button
                  type='button'
                  className='text-sm text-[#045CE0] mr-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove();
                  }}>
                  Remove
                </button>
              )}
              <ExpandIcon
                style={{
                  transform: `rotate(${expand ? 180 : 0}deg)`,
                  transition:
                    'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }}
                {...iconProps}
              />
            </div>
          </div>
          {subElement}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {children}
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
};
