const getLastTwoDigits = (number: number) => ('0' + number).slice(-2);

export class FileUtil {
  static download(data: Blob, fileName: string) {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  static getExportName(page: string) {
    const newDate = new Date();
    const date = getLastTwoDigits(newDate.getDate());
    const month = getLastTwoDigits(newDate.getMonth() + 1);
    const year = getLastTwoDigits(newDate.getFullYear());
    const hours = getLastTwoDigits(newDate.getHours());
    const minutes = getLastTwoDigits(newDate.getMinutes());
    const seconds = getLastTwoDigits(newDate.getSeconds());

    const dateTime = `${date}_${month}_${year}_${hours}_${minutes}_${seconds}`;

    return `Nimbbl_${page}_${dateTime}`;
  }
}
