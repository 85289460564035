import { SummaryViewType } from '../AnalyticsHome';
import { AnalyticsResponse } from '../AnalyticsResponse';
import { formatTransactionData } from '../sharedUtil';
import { TransactionSummaryUtil } from './TransactionSummaryUtil';

interface TransactionSummaryHookProps {
  filters: {
    [key: string]: any;
    currency: string;
    summaryView: SummaryViewType;
  };
  transactionSummary: AnalyticsResponse['transaction_summary'];
}

export const useTransactionSummary = (props: TransactionSummaryHookProps) => {
  const { filters, transactionSummary } = props;

  const { xAxisCategories, rawData } =
    TransactionSummaryUtil.getData(transactionSummary);

  return {
    options: TransactionSummaryUtil.getOptions({
      categories: xAxisCategories,
      filters,
      series: formatTransactionData(rawData, filters),
    }),
  };
};
