import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object } from 'yup';

import { useCurrentSession, useTracker } from '../../Hooks';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterOrderUtils from '../FilterPopup/utils/FilterOrderUtils';
import {
  FilterCommonFields,
  MetaData,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';

export type FilterOrderFields = Pick<
  FilterCommonFields,
  | 'status'
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'orderId'
  | 'invoiceId'
  | 'mobile'
  | 'subMerchantId'
  | 'singleSubMerchantId'
  | 'email'
  | 'currency'
  | 'minAmount'
  | 'maxAmount'
  | 'customAttributes'
>;

export const defaultOrderFilters: FilterOrderFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
};

const orderSchema = object().shape({ ...commonSchema });

const useFilterOrders = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterOrderFields>({
    defaultValues: defaultOrderFilters,
    resolver: yupResolver(orderSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { orders } =
      (await fetchOrders({ ...form.getValues(), page: page + 1 })) ?? {};

    setOrders((prev) => [...prev, ...orders]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterOrderUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const handleFormSubmit = async (
    formData: FilterOrderFields & { page?: number }
  ) => {
    setLoading(true);
    const { filterSection, orders, metaData } =
      (await fetchOrders(formData)) ?? {};

    setOrders(orders);
    setMetaData(metaData);
    setFilters(filterSection);
    setLoading(false);
    setFilterModalShown(false);
  };

  const fetchOrders = async (
    formData: FilterOrderFields & { page?: number }
  ) => {
    const { page, ...restFormData } = formData;
    const { filters, filterSection } = FilterOrderUtils.generateParams(
      restFormData,
      page ? { page } : undefined,
      form.setValue
    );

    const response = await FilterOrderUtils.getOrder(filters);

    // check for user token
    if (checkSessionError(response)) return;

    const orders = response.data?.result?.result ?? [];
    const metaData = response.data?.result?.meta ?? new MetaData();

    const error = response.data?.result?.error?.message;
    const rows = metaData?.total_records;
    const pages = metaData?.total_pages;

    track({
      name: 'Orders' + TrackEventNames.searchClicked,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Orders',
        // @ts-ignore
        filters: filterSection,

        ...(error
          ? { failure_error_message: error }
          : {
              number_of_rows: rows,
              number_of_pages: pages,
            }),
      },
    });

    return { orders, filterSection, filters, metaData };
  };

  return {
    filters,
    form,
    loading,
    metaData,
    orders,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  };
};

export default useFilterOrders;
