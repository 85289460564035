import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { Button } from 'nimbbl-quark';
import { useEffect, useMemo, useState } from 'react';
import {
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { MdArrowBack } from 'react-icons/md';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { useGlobalStore } from '../../../Context/GlobalStore';
import { ActionTypes } from '../../../Context/reducer';
import {
  LinkCustomerDetails,
  LinkDetails,
} from '../../../interfaces/PaymentLinks';
import { PriceUtil } from '../../../Utils';
import paymentLinkIcon from './../../../images/paymentLinkIcon.svg';
import {
  LinkCustomerValidation,
  LinkDetailsValidation,
} from './PaymentLinkValidation';
import { formatResponse, getPaymentLinkById } from './utils';
import { getAllowedSubmerchant } from '../../../Apis';

const tabs = {
  'link-details': { tab: 'Link Details', step: 1 },
  'customer-details': { tab: 'Customer Details', step: 2 },
  'product-details': { tab: 'Product Details', step: 3 },
};

interface CreateLinkContext {
  linkDetailsForm: UseFormReturn<LinkDetails, any>;
  customerDetailsForm: UseFormReturn<LinkCustomerDetails, any>;
  productFieldArray: UseFieldArrayReturn<LinkDetails, 'product_details', 'id'>;
  isEdit: boolean;
  removeProduct: (index: number) => void;
}
export const useCreateLinkContext = () => useOutletContext<CreateLinkContext>();

export const CreatePaymentLink = () => {
  const { dispatch } = useGlobalStore();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const linkDetailsForm = useForm<LinkDetails>({
    mode: 'onChange',
    resolver: yupResolver(LinkDetailsValidation),
    defaultValues: new LinkDetails(),
  });
  const { reset } = linkDetailsForm;

  useEffect(() => {
    // get default value for sub merchant
    const fetch = async () => {
      const submerchants = await getAllowedSubmerchant();

      let sub_merchant_id = { label: '', value: '' };

      submerchants?.data?.result?.result?.some((item: any) => {
        const { id, description, is_sandbox } = item || {};

        if (!is_sandbox && id && description) {
          sub_merchant_id = { label: description, value: id };

          return true;
        }

        return false;
      });

      reset({
        ...new LinkDetails(),
        sub_merchant_id,
      });
    };

    fetch();
  }, [reset]);

  const customerDetailsForm = useForm<LinkCustomerDetails>({
    mode: 'onChange',
    resolver: yupResolver(LinkCustomerValidation),
    defaultValues: new LinkCustomerDetails(),
  });

  const productFieldArray = useFieldArray({
    control: linkDetailsForm.control,
    name: 'product_details',
  });

  const { fields, remove } = productFieldArray;
  const removeProduct = (index: number) => {
    dispatch({
      type: ActionTypes.setConfirmDialog,
      payload: {
        show: true,
        type: 'warning',
        title: 'Remove item',
        description: 'Are you sure you want to remove the item?',
        confirmText: 'Yes remove',
        cancelText: "No, don't",
        onConfirm: () => {
          console.log('index', index);
          remove(index);

          //  when removing the last product, make total_amount zero
          if (index === 0 && fields.length === 1) {
            linkDetailsForm.setValue('total_amount', '00.00');
          }

          dispatch({
            type: ActionTypes.setErrorMessage,
            payload: { type: 'success', message: 'Item removed successfully.' },
          });
        },
      },
    });
  };

  const contextValue: CreateLinkContext = {
    linkDetailsForm,
    customerDetailsForm,
    productFieldArray,
    removeProduct,
    isEdit,
  };

  useEffect(() => {
    const linkId = searchParams.get('id');
    if (linkId) {
      setLoading(true);
      setIsEdit(true);
      getPaymentLinkById(linkId).then((res) => {
        const data = Array.isArray(res?.result?.result)
          ? res?.result?.result[0]
          : null;
        const { customerDetails, linkDetails } = formatResponse(data);
        linkDetailsForm.reset({ ...linkDetails });
        customerDetailsForm.reset({ ...customerDetails });
        setLoading(false);
      });
    } else {
      setIsEdit(false);
    }
  }, [searchParams, linkDetailsForm, customerDetailsForm]);

  const { step: currentStep, tab: currentTab } = useMemo(() => {
    const tab = pathname.split('/')[3] as keyof typeof tabs | undefined;

    if (!tab) return tabs['link-details'];

    return tabs[tab];
  }, [pathname]);

  const handleBackBtn = () => {
    navigate(-1);
  };

  const [currency, products] = linkDetailsForm.watch([
    'currency',
    'product_details',
  ]);

  const isProduct = currentTab === tabs['product-details'].tab;
  const pageHeading = isProduct
    ? 'Add products'
    : (isEdit ? 'Edit' : 'Create a new') + ' payment link';
  const cartValue = products.reduce(
    (prev, next) =>
      prev +
      next.quantity * (parseFloat(next.amount_before_tax) || 0) +
      (parseFloat(next.tax) || 0),
    0
  );

  return (
    <div className='container flex items-start pt-8 mx-auto'>
      <Button
        IconComponent={MdArrowBack}
        iconPosition='center'
        variant='link'
        size='sm'
        attributes={{
          onClick: handleBackBtn,
        }}
        style={{ fontSize: 24, color: '#6C7F9A' }}
      />
      <div style={{ maxWidth: 640 }} className='mx-auto grow'>
        <div className='flex justify-between'>
          <h1 className='flex items-center text-lg font-semibold'>
            <img src={paymentLinkIcon} alt={pageHeading} />
            <span className='ml-5'>{pageHeading}</span>
          </h1>
          {isProduct && (
            <p className='self-center text-lg font-semibold'>
              Total cart value:{' '}
              {PriceUtil.formatCurrency(cartValue, currency?.value as any)}
            </p>
          )}
        </div>

        <hr style={{ color: '#CBD4E1' }} className='mt-4' />

        {!isProduct && (
          <div className='mt-6 text-lg font-semibold'>
            Step {currentStep}/2: {currentTab}
          </div>
        )}

        <div className={`py-2 my-${isProduct ? '2' : '6'}`}>
          {loading ? (
            <div className='flex justify-center'>
              <CircularProgress size={30} style={{ color: '#0352c9' }} />
            </div>
          ) : (
            <Outlet context={contextValue} />
          )}
        </div>
      </div>
    </div>
  );
};
