import axios from "axios";

import {
  IOrdersRetrieveRequest,
  ISubMerchantsRetrieveRequest,
  IRefundsRetrieveRequest,
  IApiKeysRetrieveRequest,
  ITransactionRetrieveRequest,
  ISettlementsRetrieveRequest,
  ISettlementsDetailsRetrieveRequest,
} from "./interface";

import constants from "../constants";
import moment from "moment";
import { PAYMENT_LINK } from "../constants/api";

const api_map = {
  order: constants.API.ORDER_API,
  orderCSV: constants.API.ORDER_API,
  submerchant: constants.API.SUBMERCHANT_API,
  apiKey: constants.API.API_KEYS_API,
  transaction: constants.API.TRANSACTION_API,
  refunds: constants.API.REFUNDS_API,
  settlement: constants.API.SETTLEMENT_API,
  initRefund: constants.API.INITIATE_REFUND_API,
  settlementSummary: constants.API.SETTLEMENT_SUMMARY_API,
  transactionGroupBy: constants.API.GROUPBY_API,
  paymentModeChart: constants.API.PAYMENT_MODE,
  paymentLink: constants.API.PAYMENT_LINK,
};

const getApiEndPoint = (name: keyof typeof api_map) =>
  `${api_map[name]}?session_id=${window.localStorage.getItem(
    "nimbbl_session_id"
  )}`;

/** sub merchant related stuffs */

export const getAllowedSubmerchant = async () => {
  const query = "{id,display_name,is_sandbox, description,sub_merchant_id}";

  let data = {
    params: {
      query: query,
      // order_by: 'is_sandbox',
    },
  };

  return axios.post(getApiEndPoint("submerchant"), data);
};

export const getSubmerchantById = async (rr: ISubMerchantsRetrieveRequest) => {
  const filters: any = [["description", "ilike", rr.search]];
  if (rr.hasOwnProperty("is_sandbox")) {
    filters.push(["is_sandbox", "=", rr.is_sandbox]);
  }
  const filter = JSON.stringify(filters);

  const query = "{id,display_name,is_sandbox, description,sub_merchant_id}";

  let data = {
    params: {
      filter: filter,
      query: query,
      // order_by: 'is_sandbox',
    },
  };

  return axios.post(getApiEndPoint("submerchant"), data);
};

const getStringFromDate = (date: any) =>
  date.getFullYear() +
  "/" +
  ("0" + (date.getMonth() + 1)).slice(-2) +
  "/" +
  ("0" + date.getDate()).slice(-2) +
  " " +
  date.getHours() +
  ":" +
  date.getMinutes();

/** Order related code start here */
export const generateOrderParams = (rr: IOrdersRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;

  if (rr.order_id) filterParams.push(["order_id", "=", rr.order_id.trim()]);

  if (rr.sub_merchant_id && rr.sub_merchant_id?.length > 0)
    filterParams.push(["sub_merchant_id", "=", rr.sub_merchant_id]);

  if (rr.invoice_id)
    filterParams.push(["invoice_id", "=", rr.invoice_id.trim()]);

  if (rr.mobile_no)
    filterParams.push(["user_id.mobile_number", "=", rr.mobile_no.trim()]);

  if (rr.email) filterParams.push(["user_id.email", "=", rr.email.trim()]);

  if (rr.status && rr.status?.length > 0 && rr.status.find((x) => x !== "All"))
    filterParams.push(["status", "in", rr.status]);

  if (
    rr.order_transac_type &&
    rr.order_transac_type?.length > 0 &&
    rr.order_transac_type.find((x) => x !== "All")
  ) {
    filterParams.push(["order_transac_type", "in", rr.order_transac_type]);
  }

  if (rr.custom_attributes)
    filterParams.push(
      "|",
      ["order_custom_attributes.key", "like", "%" + rr.custom_attributes + "%"],
      [
        "order_custom_attributes.value",
        "like",
        "%" + rr.custom_attributes + "%",
      ]
    );

  if (rr.start_date) {
    //UTC date conversion starts here
    //Remove this code if server is handling IST date
    rr.start_date = getStringFromDate(new Date(rr.start_date));
    //UTC date conversion ends here
    filterParams.push(["order_date", ">=", rr.start_date]);
  }

  if (rr.end_date) filterParams.push(["order_date", "<=", rr.end_date]);

  if (rr.amount_min)
    filterParams.push(["total_amount", ">=", Number(rr.amount_min)]);

  if (rr.amount_max)
    filterParams.push(["total_amount", "<=", Number(rr.amount_max)]);

  if (rr.currency) {
    filterParams.push(["currency", "=", rr.currency]);
  }

  query =
    "{browser_name,device_name,os_name, merchant_shopfront_domain, amount_before_tax , offer_discount,grand_total_amount,shipping_address_id{address_type, address_1, street, landmark,area, city, state,country,pincode}, referrer_platform_version, referrer_platform, additional_charges, id,order_date,order_id,total_amount,currency,invoice_id,status,sub_merchant_id{display_name, id, sub_merchant_id, description},order_transac_type,attempts,order_custom_attributes{key,value},user_id{mobile_number,email,country_code,first_name,last_name}, transaction_items{id,payment_mode,status,transaction_id, create_date,transaction_type, refund_done,refund_amount,grand_total_amount, settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount}}}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        // order_id: rr ? rr.order_id : '',
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

// api
export const getOrder = async (rr: IOrdersRetrieveRequest) => {
  const data = generateOrderParams(rr);

  return axios.post(getApiEndPoint("order"), data);
};

export const getOrderCSV = async () => {
  const data = {
    filter: "",
    next: null,
    order_by: "id",
    page: 1,
    per_page: 1000,
    prev: null,
    query:
      "{browser_name,device_name,os_name,id,order_date,order_id, additional_charges,grand_total_amount,total_amount,currency,invoice_id,status,attempts,sub_merchant_id{id,display_name,sub_merchant_id, description},total_amount,amount_before_tax,tax,order_custom_attributes{key,value},user_id{email,mobile_number,user_devices{browser,device_name,os}},transaction_items{id,payment_mode,status,transaction_id, create_date,transaction_type,refund_amount, settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount}},device_user_agent,shipping_address_id{area,city,state,pincode,address_1,street,landmark,address_type}}",
    type: "csv",
  };
  return await axios.post(getApiEndPoint("orderCSV"), data);
};

/** Order related code end here */

/** Transaction related stuff */

export const generateTransactionParams = (rr: ITransactionRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;

  filterParams.push(["transaction_type", "in", ["payment"]]);

  const { transaction_id = null, start_date, end_date } = rr;

  if (transaction_id)
    filterParams.push(["transaction_id", "=", transaction_id.trim()]);

  if (rr.status && rr.status?.length > 0 && rr.status.find((x) => x !== "All"))
    filterParams.push(["status", "in", rr.status]);

  if (rr.order_id)
    filterParams.push(["order_id.order_id", "=", rr.order_id.trim()]);

  if (rr.invoice_id)
    filterParams.push(["order_id.invoice_id", "=", rr.invoice_id.trim()]);

  if (rr.mobile_no)
    filterParams.push(["user_id.mobile_number", "=", rr.mobile_no.trim()]);

  if (rr.custom_attributes)
    filterParams.push(
      "|",
      [
        "order_id.order_custom_attributes.key",
        "like",
        "%" + rr.custom_attributes + "%",
      ],
      [
        "order_id.order_custom_attributes.value",
        "like",
        "%" + rr.custom_attributes + "%",
      ]
    );

  if (rr.email) filterParams.push(["user_id.email", "=", rr.email.trim()]);

  if (
    rr.payment_mode &&
    rr.payment_mode?.length > 0 &&
    rr.payment_mode.find((x) => x !== "All")
  )
    filterParams.push(["payment_mode", "in", rr.payment_mode]);

  if (
    rr.payment_partner?.length > 0 &&
    rr.payment_partner.find((x) => x !== "Select")
  )
    filterParams.push(["payment_partner", "in", rr.payment_partner]);

  if (!transaction_id && start_date)
    filterParams.push(["create_date", ">=", start_date]);

  if (!transaction_id && end_date)
    filterParams.push(["create_date", "<=", end_date]);

  if (rr.amount_min)
    filterParams.push(["total_amount", ">=", Number(rr.amount_min)]);

  if (rr.amount_max)
    filterParams.push(["total_amount", "<=", Number(rr.amount_max)]);

  if (rr.utr_no)
    filterParams.push(["settlement_log.utr_no", "=", rr.utr_no.trim()]);

  if (
    rr.wallet_name &&
    rr.wallet_name?.length > 0 &&
    rr.wallet_name.find((x) => x !== "All")
  )
    filterParams.push(["wallet_name", "in", rr.wallet_name]);

  if (
    rr.bank_name &&
    rr.bank_name?.length > 0 &&
    rr.bank_name.find((x) => x !== "All")
  )
    filterParams.push(["bank_name", "in", rr.bank_name]);

  if (rr.card_no) filterParams.push(["masked_card", "like", rr.card_no.trim()]);

  if (rr.vpa_id) filterParams.push(["vpa_id", "=", rr.vpa_id.trim()]);

  if (rr.sub_merchant_id && rr.sub_merchant_id?.length > 0)
    filterParams.push(["sub_merchant_id", "=", rr.sub_merchant_id]);

  if (rr.settlement_id)
    filterParams.push(["partner_settement_id", "=", rr.settlement_id.trim()]);

  if (rr.currency) {
    filterParams.push(["currency", "=", rr.currency]);
  }

  // if (rr.settlement_id)
  //   filterParams.push(["payment_settlement_logs.id", "=", rr.settlement_id])

  query =
    "{id,transaction_id,payment_mode,psp_generated_txn_id,status,masked_card,offer_discount,create_date,order_id,sub_merchant_id{sub_merchant_id, description},card_type,card_type,issuer,refund_done,vpa_holder,expiry,network,vpa_app_name,bank_name,holder_name,wallet_name,nimbbl_error_code,wallet_name,payment_partner,nimbbl_merchant_message,nimbbl_consumer_message,settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount},order_id{original_currency,available_refundable_amount,exchange_rate_used,additional_charges,grand_total_amount,id,order_id,currency,attempts,invoice_id,total_amount,amount_before_tax,tax,order_custom_attributes{key,value},order_date,user_id{email,mobile_number}}}";

  // upi_flow add above once it is live in preprod
  // nimbbl_consumer_message

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

export const getTransaction = async (rr: ITransactionRetrieveRequest) => {
  const data = generateTransactionParams(rr);

  return axios.post(getApiEndPoint("transaction"), data);
};

/** Refunds related stuff */
export const generateRefundsParams = (rr: IRefundsRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;

  //default params
  // filterParams.push("transaction_id", "in", [rr.refund_id, rr.transaction_id].filter((ele) => !!ele))
  filterParams.push([
    "transaction_type",
    "in",
    ["full-refund", "partial-refund"],
  ]);

  if (rr.transaction_id)
    filterParams.push([
      "orignal_payment_transaction_id",
      "=",
      rr.transaction_id.trim(),
    ]);

  if (rr.refund_id)
    // filterParams.push(["refund_id", "=", rr.refund_id])
    filterParams.push(["transaction_id", "=", rr.refund_id.trim()]);

  if (rr.invoice_id)
    filterParams.push(["order_id.invoice_id", "=", rr.invoice_id.trim()]);

  if (
    rr.payment_mode &&
    rr.payment_mode?.length > 0 &&
    rr.payment_mode.find((x) => x !== "All")
  )
    filterParams.push(["payment_mode", "in", rr.payment_mode]);

  if (
    rr.payment_partner?.length > 0 &&
    rr.payment_partner.find((x) => x !== "Select")
  )
    filterParams.push(["payment_partner", "in", rr.payment_partner]);

  if (rr.custom_attributes)
    filterParams.push(
      "|",
      [
        "order_id.order_custom_attributes.key",
        "like",
        "%" + rr.custom_attributes + "%",
      ],
      [
        "order_id.order_custom_attributes.value",
        "like",
        "%" + rr.custom_attributes + "%",
      ]
    );

  if (rr.status && rr.status?.length > 0 && rr.status.find((x) => x !== "All"))
    filterParams.push(["status", "in", rr.status]);

  if (rr.start_date) filterParams.push(["create_date", ">=", rr.start_date]);

  if (rr.end_date) filterParams.push(["create_date", "<=", rr.end_date]);

  if (rr.sub_merchant_id && rr.sub_merchant_id?.length > 0)
    filterParams.push(["sub_merchant_id", "=", rr.sub_merchant_id]);

  if (rr.settlement_id)
    filterParams.push(["partner_settement_id", "=", rr.settlement_id.trim()]);

  // if (rr.settlement_id)
  //   filterParams.push(["id", "=", rr.settlement_id])
  if (rr.currency) {
    filterParams.push(["currency", "=", rr.currency]);
  }

  query =
    "{create_date,sub_merchant_id{display_name, id, sub_merchant_id, description},transaction_id,is_settled,user_id{email,mobile_number},refund_arn,settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount,settlement_currency{id,name}},orignal_payment_transaction_id{id,transaction_id},transaction_type,id,status,payment_mode,payment_partner,refund_done,refund_amount,vpa_id,masked_card,holder_name,expiry,issuer,network,order_id{additional_charges,grand_total_amount,id,order_id,currency,attempts,total_amount,amount_before_tax,order_custom_attributes{key,value},tax,invoice_id}}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

export const getRefunds = async (rr: IRefundsRetrieveRequest) => {
  const data = generateRefundsParams(rr);

  return await axios.post(getApiEndPoint("refunds"), data);
};

/** settlements related stuffs */

export const generateSettlementsParams = (rr: ISettlementsRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;

  //default params
  if (rr.utr_no) filterParams.push(["utr_ref_no", "=", rr.utr_no]);

  // if (rr.total_amount)
  //   filterParams.push(["total_amount", "=", rr.total_amount]);
  if (rr.amount_min)
    filterParams.push(["settled_amount", ">=", Number(rr.amount_min)]);

  if (rr.amount_max)
    filterParams.push(["settled_amount", "<=", Number(rr.amount_max)]);

  if (
    rr.payment_partner?.length > 0 &&
    rr.payment_partner.find((x) => x !== "Select")
  )
    filterParams.push(["payment_partner", "in", rr.payment_partner]);

  if (rr.start_date)
    filterParams.push(["settlement_date", ">=", rr.start_date]);

  if (rr.end_date) filterParams.push(["settlement_date", "<=", rr.end_date]);

  if (rr.sub_merchant_id && rr.sub_merchant_id?.length > 0)
    filterParams.push(["sub_merchant_id", "=", rr.sub_merchant_id]);

  if (rr.settlement_id)
    filterParams.push(["settlement_id", "=", rr.settlement_id.trim()]);

  if (rr.psp_settlement_log_id) {
    filterParams.push(["psp_settlement_log_id", "=", rr.psp_settlement_log_id]);
  }

  // filterParams.push(['total_txn_count', '>', 0]);
  // number of nimbbl entries above 0
  filterParams.push(["txn_count", ">", 0]);

  query =
    "{id, settlement_date, sub_merchant_id{id, description}, payment_partner, settlement_id, settlement_currency{name}, utr_ref_no, settled_amount,txn_count}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: "settlement_date",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: "settlement_date",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

export const generateSettlementDetailsParams = (
  rr: ISettlementsDetailsRetrieveRequest
) => {
  let filterParams = [];
  let data: any = null;
  let query;

  //default params
  if (rr.utr_no) filterParams.push(["utr_no", "=", rr.utr_no.trim()]);

  if (rr.total_amount)
    filterParams.push(["total_amount", "=", rr.total_amount]);

  if (
    rr.payment_partner?.length > 0 &&
    rr.payment_partner.find((x) => x !== "Select")
  )
    filterParams.push(["payment_partner", "in", rr.payment_partner]);

  if (rr.start_date)
    filterParams.push(["settlement_date", ">=", rr.start_date]);

  if (rr.end_date) filterParams.push(["settlement_date", "<=", rr.end_date]);

  if (rr.sub_merchant_id && rr.sub_merchant_id?.length > 0)
    filterParams.push(["sub_merchant_id", "=", rr.sub_merchant_id]);

  if (rr.settlement_id)
    filterParams.push(["partner_settement_id", "=", rr.settlement_id.trim()]);

  if (rr.transaction_id)
    filterParams.push(["transaction_id", "=", rr.transaction_id.trim()]);

  if (
    rr.payment_mode &&
    rr.payment_mode?.length > 0 &&
    rr.payment_mode.find((x) => x !== "All")
  )
    filterParams.push(["transaction_payment_mode", "in", rr.payment_mode]);

  if (rr.invoice_id)
    filterParams.push(["order_invoice_id", "=", rr.invoice_id.trim()]);

  if (
    rr.transaction_type &&
    rr.transaction_type?.length > 0 &&
    rr.transaction_type.find((x) => x !== "All")
  ) {
    filterParams.push(["transaction_type", "in", rr.transaction_type]);
  }

  if (rr.order_id) filterParams.push(["order_id", "=", rr.order_id]);
  if (rr.mobile_no)
    filterParams.push(["payment_mobile_number", "=", rr.mobile_no]);
  if (rr.email)
    filterParams.push(["payment_mobile_number.email", "=", rr.email]);
  if (rr.currency) filterParams.push(["settlement_currency", "=", rr.currency]);
  if (rr.amount_min) filterParams.push(["settled_amount", ">=", rr.amount_min]);
  if (rr.amount_max) filterParams.push(["settled_amount", "<=", rr.amount_max]);
  if (rr.settlement_entry_type) {
    const entryType = rr.settlement_entry_type;
    if (entryType && entryType.value && entryType.value !== "All") {
      filterParams.push(["settlement_entry_type", "=", entryType.value]);
    }
  }
  if (rr.custom_attributes)
    filterParams.push(
      "|",
      [
        "order_id.order_custom_attributes.key",
        "like",
        "%" + rr.custom_attributes + "%",
      ],
      [
        "order_id.order_custom_attributes.value",
        "like",
        "%" + rr.custom_attributes + "%",
      ]
    );

  // if (rr.partner_settement_id)
  //   filterParams.push(["partner_settement_id", "=", rr.partner_settement_id])

  // filterParams.push(["transaction_type","in","full-refund","partial-refund"])

  // [["partner_settement_id","=","setl_JCpDN805ys9o3h"],["transaction_type","=","payment"]]

  // [["partner_settement_id","=","setl_JCpDN805ys9o3h"],["transaction_type","=",["payment"]]]

  // query = "{transaction_id{transaction_id,status, refund_amount},create_date,total_amount,fee_amount,tax_amount}";
  query =
    "{id,converted_transaction_amount,transaction_currency{id,name},settlement_currency{id,name},psp_currency_conversion_rate,transaction_amount,settlement_date,settlement_entry_type,create_date,partner_settement_id,total_order_amount, transaction_id{transaction_id,payment_mode,refund_amount},transaction_type,transaction_date,utr_no,order_id{order_id,grand_total_amount,transaction_items{status,refund_amount, refund_done, transaction_type},order_custom_attributes{key,value}},order_invoice_id,sub_merchant_id{id,display_name, description},payment_partner,payment_mode,settled_amount,fee_amount,tax_amount,customer_id,payment_mobile_number{mobile_number}}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: "settlement_date",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
        type: "csv",
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: "settlement_date",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
        type: "csv",
      },
    };
  }

  return data;
};

export const getSettlements = async (rr: ISettlementsRetrieveRequest) => {
  const data = generateSettlementsParams(rr);

  return axios.post(getApiEndPoint("settlementSummary"), data);
};

export const getSettlementDetails = async (
  rr: ISettlementsDetailsRetrieveRequest
) => {
  const data = generateSettlementDetailsParams(rr);

  return axios.post(getApiEndPoint("settlement"), data);
};
/** api keys related stuffs */

export const generateApiKeysParams = (rr: IApiKeysRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;
  // if(!rr.sub_merchant_id) {
  //   rr.merchant_id = 530
  // }

  if (rr.sub_merchant_id) {
    filterParams.push(["sub_merchant_id.id", "=", rr.sub_merchant_id]);
  } else if (rr.merchant_id) {
    filterParams.push(["sub_merchant_id.merchant_id", "=", rr.merchant_id]);
  }

  query =
    "{access_key,access_secret,last_accessed_on,create_date,sub_merchant_id{is_sandbox,description}}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        // order_id: rr ? rr.order_id : '',
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

export const getApiKeys = async (rr: IApiKeysRetrieveRequest) => {
  const data = generateApiKeysParams(rr);

  return await axios.post(getApiEndPoint("apiKey"), data);
};

export const formatDate = (data: string) => {
  if (data) {
    return data.replace(",", ", ");
  }
};

export const formatAmount = (data: number) => {
  return data && data.toLocaleString(undefined, { minimumFractionDigits: 2 });
  // return data && data.toLocaleString();
};

export const getRevealKeys = async (rr: IApiKeysRetrieveRequest) => {
  const data = generateRevealParams(rr);

  return await axios.post(getApiEndPoint("apiKey"), data);
};

export const generateRevealParams = (rr: IApiKeysRetrieveRequest) => {
  let filterParams = [];
  let data: any = null;
  let query;

  if (rr.access_key && rr.description) {
    filterParams.push(["sub_merchant_id.description", "=", rr.description]);
  }

  query =
    "{access_key,access_secret,last_accessed_on,create_date,sub_merchant_id{is_sandbox,description}}";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  } else {
    data = {
      params: {
        query: query,
        page: rr ? rr.page : 1,
        per_page: rr ? rr.per_page : 5,
        order_by: rr ? rr.order_by : "id",
        sort_by: rr ? rr.sort_by : "desc",
        // order_id: rr ? rr.order_id : '',
        next: rr ? rr.next : null,
        prev: rr ? rr.prev : null,
      },
    };
  }

  return data;
};

export const formateDateToDDMonYYYY = (date: any) => {
  if (date) {
    // moment(date, 'DD/MM/YYYY').format("DD MMM YYYY")
    // let d = date.replaceAll('-', '/');
    // console.log(date);
    return moment(date).format("DD MMM YYYY");
  }
};

export const getTransactionAnalytics = async (
  rr: ITransactionRetrieveRequest,
  groupBy: string
) => {
  const data = generateTransactionAnalyticsParams(rr, groupBy);

  return await axios.post(getApiEndPoint("transactionGroupBy"), data);
};

export const generateTransactionAnalyticsParams = (
  rr: ITransactionRetrieveRequest,
  groupBy: string
) => {
  let filterParams = [];
  let data: any = null;
  let query;

  filterParams.push(["transaction_type", "in", ["payment"]]);
  // filterParams.push(["create_date", ">=", "2021/07/26 18:30"],["create_date","<=","2022/08/02 16:35"]);
  if (rr.status && rr.status?.length > 0 && rr.status.find((x) => x !== "All"))
    filterParams.push(["status", "in", rr.status]);

  if (
    rr.payment_mode &&
    rr.payment_mode?.length > 0 &&
    rr.payment_mode.find((x) => x !== "All")
  )
    filterParams.push(["payment_mode", "in", rr.payment_mode]);

  if (
    rr.payment_partner?.length > 0 &&
    rr.payment_partner.find((x) => x !== "Select")
  )
    filterParams.push(["payment_partner", "in", rr.payment_partner]);

  if (rr.start_date) filterParams.push(["create_date", ">=", rr.start_date]);

  if (rr.end_date) filterParams.push(["create_date", "<=", rr.end_date]);

  query =
    "payment_mode,payment_partner,sum_amount:sum(grand_total_amount),create_date";

  if (filterParams?.length > 0) {
    let filter = JSON.stringify(filterParams);
    data = {
      params: {
        filter: filter,
        fields: query,
        page: 1,
        per_page: 30,
        groupby: groupBy,
        sort_by: "desc",
      },
    };
  } else {
    data = {
      params: {
        fields: query,
        page: 1,
        per_page: 30,
        groupby: "create_date:week",
        sort_by: "desc",
      },
    };
  }

  return data;
};

export const getPaymentModeAnalytics = async (filter: { rr: {} }) => {
  const data = generatePaymentModeAnalyticsParams(filter.rr);

  return await axios.post(getApiEndPoint("paymentModeChart"), data);
};

export const generatePaymentModeAnalyticsParams = (data: any) => {
  // console.log(data.status);
  if (data.status) {
    if (data.status === "all") {
      delete data.status;
    } else {
      data.status = data.status.toString();
    }
  }
  if (data.payment_mode) {
    data.payment_mode = data.payment_mode.toString();
  }
  if (data.payment_partner) {
    data.payment_partner = data.payment_partner.toString();
  }
  if (data.sub_merchant_id) {
    data.sub_merchant_id = data.sub_merchant_id.toString();
  }

  delete data.count_type;
  // delete data.sub_merchant_id;
  delete data.sub_merchant;
  delete data.days;
  // console.log(data);
  return data;
};

export const getPaymentLinks = async (rr: Record<string, any>) => {
  return axios.post(getApiEndPoint("paymentLink"), rr);
};
