const routerPaths = {
  home: '/',
  orders: '/orders',
  transactions: '/transactions',
  settlements: '/settlements',
  settlementDetails: '/settlements-details',
  apiKeys: '/developer/api-keys',
  refunds: '/refunds',
  bulkRefund: '/bulk-refunds',
  forgetPassword: '/forget-password',
  // offers: '/offers',
  analytics: '/analytics',
  paymentLink: '/payment-links',
};

export default routerPaths;
