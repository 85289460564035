import { Options, PointOptionsObject } from 'highcharts';
import { BlueColors, Colors, PurpleColors } from '../../../constants/color';
import {
  AnalyticsResponse,
  SubPaymentModeResponse,
} from '../AnalyticsResponse';
import { formatNumber, RawData } from '../sharedUtil';

const ignorePaymentModes = [
  'NETBANKING',
  'UPI',
  'WALLET',
  'PREPAID CARD',
  'DEBIT CARD',
  'CREDIT CARD',
];

export class PaymentModeUtil {
  static getAllModes = (
    inputData: AnalyticsResponse['payment_partner'],
    paymentMethod: string
  ) => {
    const data: Record<string, RawData[]> = {};

    data[''] = [];
    Object.entries(inputData).forEach(([paymentMethod, values], index) => {
      const {
        amount,
        count,
        amount_percentage,
        count_percentage,
        failed_amount,
        failed_count,
        pending_amount,
        pending_count,
        succeeded_amount,
        succeeded_count,
      } = values;

      data[''].push({
        id: paymentMethod,
        name: paymentMethod,
        colorValue: index,
        amount,
        count,
        amount_percentage,
        count_percentage,
      });

      if (ignorePaymentModes.includes(paymentMethod)) {
        return;
      }
      data[paymentMethod] = [
        {
          id: 'Pending',
          name: 'Pending',
          color: Colors.pending.light,
          amount: pending_amount,
          count: pending_count,
          amount_percentage: (pending_amount / amount) * 100,
          count_percentage: (pending_count / count) * 100,
        },
        {
          id: 'Failed',
          name: 'Failed',
          color: Colors.failed.light,
          amount: failed_amount,
          count: failed_count,
          amount_percentage: (failed_amount / amount) * 100,
          count_percentage: (failed_count / count) * 100,
        },
        {
          id: 'Succeeded',
          name: 'Succeeded',
          color: Colors.success.light,
          amount: succeeded_amount,
          count: succeeded_count,
          amount_percentage: (succeeded_amount / amount) * 100,
          count_percentage: (succeeded_count / count) * 100,
        },
      ];
    });

    if (Array.isArray(data[paymentMethod])) {
      return data[paymentMethod];
    }
    return [];
  };

  static getSubModes(
    inputData: SubPaymentModeResponse['result'],
    paymentMethod: string
  ) {
    const result: Record<string, RawData[]> = {};
    result[paymentMethod] = [];

    Object.entries(inputData).forEach(([subMethod, values], index) => {
      const {
        amount,
        amount_percentage,
        count,
        count_percentage,
        failed_amount,
        failed_count,
        pending_amount,
        pending_count,
        succeeded_amount,
        succeeded_count,
        failed_amount_percentage,
        failed_count_percentage,
        pending_amount_percentage,
        pending_count_percentage,
        succeeded_amount_percentage,
        succeeded_count_percentage,
      } = values;

      let id = paymentMethod + '_' + subMethod;

      result[paymentMethod].push({
        id,
        name: subMethod,
        colorValue: index,
        amount,
        count,
        amount_percentage,
        count_percentage,
      });

      if (Object.keys(inputData).length === 1 && /others/i.test(subMethod)) {
        id = paymentMethod;
      }

      result[id] = [
        {
          id: 'Pending',
          name: 'Pending',
          color: Colors.pending.light,
          amount: pending_amount,
          count: pending_count,
          amount_percentage: pending_amount_percentage,
          count_percentage: pending_count_percentage,
        },
        {
          id: 'Failed',
          name: 'Failed',
          color: Colors.failed.light,
          amount: failed_amount,
          count: failed_count,
          amount_percentage: failed_amount_percentage,
          count_percentage: failed_count_percentage,
        },
        {
          id: 'Succeeded',
          name: 'Succeeded',
          color: Colors.success.light,
          amount: succeeded_amount,
          count: succeeded_count,
          amount_percentage: succeeded_amount_percentage,
          count_percentage: succeeded_count_percentage,
        },
      ];
    });

    return result;
  }

  static getOptions(data: PointOptionsObject[], isBlue: boolean): Options {
    const colorAxis: Options['colorAxis'] = isBlue
      ? { minColor: BlueColors.at(-1), maxColor: BlueColors[0] }
      : { minColor: PurpleColors.at(-1), maxColor: PurpleColors[0] };

    return {
      colorAxis,
      legend: { enabled: false },
      series: [
        {
          cursor: 'pointer',
          opacity: 1,
          states: { hover: { enabled: false } },
          name: 'Payment Modes',
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          layoutStartingDirection: 'horizontal',
          dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'middle',
            style: {
              fontSize: '12px',
              textOutline: 'none',
            },
          },
          data,
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        useHTML: true,
        borderRadius: 4,
        followPointer: true,
        // pointFormat:
        //   '{point.name}: <strong>{point.count:,0.2f} {point.currency}</strong>',
        pointFormatter: function () {
          return `${this.name}: <strong>${formatNumber(this.count)} ${
            this.currency
          }</strong>`;
        },
      },
    };
  }
}
