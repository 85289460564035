import { AlertProps, Button, ModalV2 } from 'nimbbl-quark';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { useGlobalStore } from '../../Context/GlobalStore';
import { ConfirmDialog as DefaultConfirmDialog } from '../../Context/initialState';
import { ActionTypes } from '../../Context/reducer';
import { AlertCircle } from '../SessionExpiredModal/SessionExpiredModal';

const Icon = ({ type = 'danger' }: { type?: AlertProps['variant'] }) => {
  const {
    color,
    inner,
    outer,
    Icon,
  }: { outer: string; inner: string; color: string; Icon: IconType } =
    useMemo(() => {
      switch (type) {
        case 'warning':
          return {
            outer: '#FEF0C7',
            inner: '#FEF0C7',
            color: '#DC6803',
            Icon: FiAlertTriangle,
          };
        case 'danger':
        default:
          return {
            outer: '#FEF3F2',
            inner: '#FEE4E2',
            color: '#D92D20',
            Icon: FiAlertCircle,
          };
      }
    }, [type]);

  return (
    <AlertCircle backgroundColor={outer}>
      <AlertCircle backgroundColor={inner}>
        <Icon size={20} color={color} />
      </AlertCircle>
    </AlertCircle>
  );
};

export const ConfirmDialog = () => {
  const { confirmDialog, dispatch } = useGlobalStore();
  const {
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    description,
    onCancel,
    onConfirm,
    title,
    type,
    show,
  } = confirmDialog;

  const handleClose = () => {
    dispatch({
      type: ActionTypes.setConfirmDialog,
      payload: new DefaultConfirmDialog(),
    });

    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    handleClose();
  };

  return (
    <ModalV2
      isShown={show}
      isBackdrop
      position='center'
      size='sm'
      style={{ maxWidth: '420px', maxHeight: '260px' }}
      modalContent={
        <>
          <Icon type={type} />
          <p className='mt-4 text-base font-medium text-[#0F1A2A]'>{title}</p>
          <p className='mt-2 text-sm'>{description}</p>
          <div className='flex justify-end mt-6 mb-[-24px]'>
            <Button
              style={{ paddingLeft: 24, paddingRight: 24 }}
              variant='outline'
              size='sm'
              text={cancelText}
              attributes={{
                onClick: handleClose,
              }}
            />
            <Button
              style={{ marginLeft: 12, paddingLeft: 24, paddingRight: 24 }}
              size='sm'
              text={confirmText}
              attributes={{
                onClick: handleConfirm,
              }}
            />
          </div>
        </>
      }
    />
  );
};
