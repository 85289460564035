import constants from '../../../constants';

const api_map = {
  order: constants.API.ORDER_API,
  orderCSV: constants.API.ORDER_API,
  submerchant: constants.API.SUBMERCHANT_API,
  apiKey: constants.API.API_KEYS_API,
  transaction: constants.API.TRANSACTION_API,
  refunds: constants.API.REFUNDS_API,
  bulkRefunds: constants.API.BULK_REFUNDS_API,
  settlement: constants.API.SETTLEMENT_API,
  initRefund: constants.API.INITIATE_REFUND_API,
  settlementSummary: constants.API.SETTLEMENT_SUMMARY_API,
  transactionGroupBy: constants.API.GROUPBY_API,
  paymentModeChart: constants.API.PAYMENT_MODE,
  paymentLink: constants.API.PAYMENT_LINK,
  bulkRefundUpload: constants.API.BULK_REFUNDS_UPLOAD_API,
};

const getApiEndPoint = (name: keyof typeof api_map) =>
  `${api_map[name]}?session_id=${window.localStorage.getItem(
    'nimbbl_session_id'
  )}`;

export default getApiEndPoint;
