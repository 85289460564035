import { Dispatch, FC, MutableRefObject, useEffect, useState } from 'react';
import { BulkRefundTransaction } from './useFilterBulkRefund';
import {
  Badge,
  BadgeProps,
  Button,
  Collapsible,
  CollapsibleButton,
  Drawer,
  KeyValue,
  Table,
  TableColumnType,
} from 'nimbbl-quark';
import { MetaData } from '../../FilterPopup/interfaces/CommonFields';
import { formatDate } from '../../Orders/Orders';
import {
  FiDownload,
  FiExternalLink,
  FiInbox,
  FiRotateCw,
} from 'react-icons/fi';
import { AiOutlineAppstore } from 'react-icons/ai';
import { useCommonTracker } from '../../../Hooks';
import FieldNames from '../../../constants/FieldNames';

const icons = [<FiInbox key={0} />, <AiOutlineAppstore key={1} />];

const getStatusBadge = (
  status: BulkRefundTransaction['status']
): { type: BadgeProps['type']; text: BadgeProps['text'] } => {
  switch (status) {
    case 'failed':
      return { type: 'Failed', text: status };
    case 'processed':
      return { type: 'Succeeded', text: status };
    case 'created':
    case 'queued':
      return { type: 'Pending', text: status };
    default:
      return { type: 'reversed', text: status };
  }
};

interface BulkRefundsTableProps {
  transactions: BulkRefundTransaction[];
  metaData: MetaData;
  fetchNextPage: () => Promise<void>;
  tableRef: MutableRefObject<any>;
  page: number;
  setCsvData: Dispatch<any>;
  getSelectedRow: (data: [], currentPage: number) => void;
}

const BulkRefundsTable: FC<BulkRefundsTableProps> = (props) => {
  const {
    metaData,
    transactions,
    fetchNextPage,
    tableRef,
    page,
    setCsvData,
    getSelectedRow,
  } = props;
  const [selectedTransaction, setSelectedTransaction] =
    useState<BulkRefundTransaction>(new BulkRefundTransaction());
  const [showRefundDetails, setShowRefundDetails] = useState(false);
  const [animateRefundDetails, setAnimateRefundDetails] = useState(false);

  const { trackRowSelected } = useCommonTracker();

  console.log('transactions', transactions);

  useEffect(() => {
    if (!showRefundDetails) {
      document
        .querySelector('tr.selected-row')
        ?.classList.remove('selected-row');
    }
  }, [showRefundDetails]);

  useEffect(() => {
    if (animateRefundDetails) setShowRefundDetails(true);
  }, [animateRefundDetails]);

  const closeRefundDetails = () => {
    setAnimateRefundDetails(false);
  };

  const openRelatedRefunds = () => {
    const id = selectedTransaction.bulk_refund_id;

    if (id) {
      window.open('/refunds?bulk_refund_id=' + id, '_blank');
    }
  };

  const columns: TableColumnType<BulkRefundTransaction>[] = [
    {
      title: FieldNames.bulkRefundId,
      sorting: false,
      cellStyle: {
        // maxWidth: 180,
        color: '#0352C9',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
      },
      render: (rowData) => (
        <button
          onClick={(e) => {
            setSelectedTransaction(rowData);
            setAnimateRefundDetails(true);
          }}>
          {rowData.bulk_refund_id}
        </button>
      ),
    },
    {
      title: 'Submerchant Name',
      sorting: false,
      cellStyle: {
        minWidth: 200,
      },
      render: (rowData) => rowData.sub_merchant_id.description,
    },
    {
      title: 'count',
      sorting: false,
      cellStyle: { width: 'min-content' },
      render: (rowData) => rowData.total_count,
    },
    {
      title: 'created at',
      sorting: false,
      render: (rowData) => formatDate(rowData.create_date),
    },
    {
      title: FieldNames.status,
      sorting: false,

      render: (rowData) => (
        <div
          className='flex justify-between'
          style={{ ...(rowData.report_file_url && { minWidth: 250 }) }}>
          <Badge
            variant='accent'
            containerProps={{ style: { width: 'fit-content' } }}
            {...getStatusBadge(rowData.status)}
          />
          {rowData.report_file_url && (
            <a
              href={rowData.report_file_url}
              className='flex items-center text-sm font-medium'>
              <FiDownload size={20} style={{ marginRight: 8 }} />
              Failed Reports
            </a>
          )}
        </div>
      ),
    },
  ];

  const showSummary = /(processed)|(failed)/i.test(selectedTransaction.status);
  const isQueued = /(queued)|(processing)/i.test(selectedTransaction.status);

  const refundDetails = {
    'Bulk Refund Details': {
      [FieldNames.bulkRefundId]: selectedTransaction.bulk_refund_id,
      Count: selectedTransaction.total_count,
      'Creation Date': formatDate(selectedTransaction.create_date) ?? '',
      'Processed At': formatDate(selectedTransaction.processed_time) ?? '',
      SubMerchant: selectedTransaction?.sub_merchant_id?.description ?? '',
      [FieldNames.singleSubMerchantId]:
        selectedTransaction?.sub_merchant_id?.sub_merchant_id ?? '',
    },
    ...(showSummary && {
      'Bulk Refund Summary': {
        'Processed Refunds': selectedTransaction.success_count,
        'Unprocessed Refunds': selectedTransaction.failed_count,
      },
    }),
  };

  return (
    <div style={{ overflow: 'auto' }} className='mt-6'>
      <Table
        data={transactions}
        columns={columns}
        search={false}
        handleSearchChange={() => {}}
        selection={true}
        attributes={{
          onChangePage: async (value: number) => {
            const totalPages = Math.ceil(transactions.length / 20);
            if (
              // when we are at last page
              value + 1 === totalPages &&
              // when total transactions is still less than total records
              transactions.length < (metaData.total_records ?? 0)
            ) {
              await fetchNextPage();
            }
          },
          onSelectionChange: (rows: any) => {
            const { per_page } = metaData || {};

            getSelectedRow(rows, page + 1);
            setCsvData(rows);
            if (rows.length > 0) {
              const select_all = rows.length === per_page;

              trackRowSelected('Bulk Refunds', {
                number_of_rows: rows.length,
                select_all,
              });
            }
          },
          tableRef: tableRef,
        }}
        optionsAttributes={{
          pageSize: 20,
          draggable: false,
        }}
        localizationAttributes={{
          body: {
            emptyDataSourceMessage:
              'No records fetched for your search criteria, please change the filters and search again',
          },
        }}
      />

      <Drawer
        position='right'
        hide={closeRefundDetails}
        isShown={showRefundDetails}
        style={{ width: '400px' }}
        className={
          animateRefundDetails
            ? 'animation drawerAnimationRightShow'
            : 'animation drawerAnimationRightHide'
        }
        headerStyle={{ background: '#FAFAFC', borderBottom: '1px solid #CCC' }}
        headerElement={
          <div>
            <p className='mb-2 text-base'>
              {selectedTransaction.bulk_refund_id}
            </p>
            <Badge
              variant='accent'
              {...getStatusBadge(selectedTransaction.status)}
            />
          </div>
        }
        bodyElement={
          <div
            className='relative p-4 no-scroll icon-font'
            style={{
              paddingBottom: 80,
              overflowY: 'scroll',
              maxHeight: 'calc(100vh - 100px)',
            }}>
            {Object.keys(refundDetails).map((key, index) => {
              return (
                <Collapsible
                  key={key}
                  open={index !== 0}
                  iconColor='#0352C9'
                  isBorder={true}
                  icon={icons[index]}
                  style={{ borderBottom: '1px solid #ccc' }}
                  title={
                    <h6 className='capitalize font-weight-bold'>{key}</h6>
                  }>
                  <div
                    className='p-4 rounded-lg bg-blue-10'
                    style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {/* @ts-ignore */}
                    {Object.entries(refundDetails[key]).map(
                      ([label, value]) => (
                        <KeyValue
                          key={label}
                          styleContainer={{
                            width: '47%',
                            marginBottom: 16,
                            marginLeft: index % 2 === 0 ? '3%' : 0,
                          }}
                          label={label}
                          labelStyle={{ textTransform: 'capitalize' }}
                          value={value as string | number}
                          valueStyle={{ overflowWrap: 'break-word' }}
                        />
                      )
                    )}
                  </div>
                </Collapsible>
              );
            })}

            {!isQueued && selectedTransaction.success_count > 0 && (
              <div className='mt-4'>
                <CollapsibleButton
                  text='Related Refunds'
                  iconRight={<FiExternalLink />}
                  iconLeft={<FiRotateCw />}
                  style={{ width: '100%', background: '#F1F4F9' }}
                  iconLeftStyle={{ color: '#6C7F9A', fontSize: '18px' }}
                  iconRightStyle={{ color: '#045CE0', fontSize: '18px' }}
                  onClick={openRelatedRefunds}
                />
              </div>
            )}

            {selectedTransaction.report_file_url && (
              <div
                className='fixed right-0 bottom-0 p-4 w-[400px]'
                style={{
                  backgroundColor: '#fff',
                }}>
                <Button
                  variant='primary'
                  text='Download Unprocessed Report'
                  style={{ width: '100%' }}
                  attributes={{
                    onClick: () => {
                      window.open(selectedTransaction.report_file_url);
                    },
                  }}
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default BulkRefundsTable;
