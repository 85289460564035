import { UseFormReturn } from 'react-hook-form';
import { FilterSettlementFields } from '../Settlements/useFilterSettlements';
import { FC, useEffect } from 'react';
import { useTracker } from '../../Hooks';
import { TrackEventNames } from '../../constants/trackEventNames';
import DateUtils from './utils/DateUtils';
import {
  daysOptions,
  defaultFilterWidth,
  paymentPartnerOptions,
} from './constants';
import FilterHeading from './Fields/Heading';
import { Drawer } from 'nimbbl-quark';
import FilterContainer from './Fields/Container';
import SubMerchantField from './Fields/SubMerchantField';
import TextField from './Fields/TextField';
import DateRangeField from './Fields/DateRangeField';
import DurationField from './Fields/DurationField';
import MultipleSelectField from './Fields/MultiSelectField';
import AmountField from './Fields/AmountField';
import FilterSubmitButton from './Fields/SubmitButton';
import FieldNames from '../../constants/FieldNames';

interface FilterSettlementsProps {
  form: UseFormReturn<FilterSettlementFields, object>;
  handleFormSubmit: (formData: FilterSettlementFields) => Promise<void>;
  width?: string | number;
  isActive: boolean;
  onHide: () => void;
  removeFilter: (filterName?: string) => Promise<void>;
}

const FilterSettlements: FC<FilterSettlementsProps> = (props) => {
  const {
    isActive,
    onHide,
    width = defaultFilterWidth,
    form,
    handleFormSubmit,
    removeFilter,
  } = props;

  const { track } = useTracker();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = form;

  const duration = watch('duration');

  useEffect(() => {
    // useEffect to keep duration and dates in sync
    const { value } = duration;
    if (!value) return;

    setValue('startDate', DateUtils.subtractDays(value));

    const customOption = daysOptions[daysOptions.length - 1];
    if (value === customOption.value) return;

    setValue('endDate', new Date());
  }, [duration, setValue]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    setValue('startDate', startDate);
    setValue('endDate', endDate);
    setValue('duration', daysOptions[daysOptions.length - 1]);
  };

  const handleClearAll = async () => {
    await removeFilter('default');
    track({
      name: 'Settlements Summary' + TrackEventNames.filterFieldsCleared,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Orders',
        filters: getValues(),
      },
    });
  };

  console.log('filter settlements errors', errors);

  return (
    <Drawer
      position='right'
      isShown={isActive}
      style={{ width }}
      hide={onHide}
      headerElement={<FilterHeading title='Filter Orders' />}
      bodyElement={
        <FilterContainer>
          <form
            className='relative filter-form pb-[156px]'
            onSubmit={handleSubmit(handleFormSubmit)}
            noValidate>
            <span
              className='absolute right-0 text-sm cursor-pointer clear-all'
              style={{ color: '#D83232' }}
              onClick={handleClearAll}>
              Clear All
            </span>

            <DurationField
              label='Settlement Summary Duration'
              value={watch('duration')}
              setValue={(newValue) => setValue('duration', newValue)}
            />

            <DateRangeField
              label='Settlement Summary Date Range'
              value={[watch('startDate'), watch('endDate')]}
              onChange={handleDateChange}
            />

            <MultipleSelectField
              label={FieldNames.paymentPartner}
              options={paymentPartnerOptions}
              value={watch('paymentPartner') ?? null}
              onChange={(value) => setValue('paymentPartner', value)}
            />

            <SubMerchantField
              isMulti
              subMerchantId
              value={watch('subMerchantId') ?? []}
              onChange={(value) => setValue('subMerchantId', value)}
            />

            <TextField
              label={FieldNames.singleSubMerchantId}
              id='singleMerchant'
              type='text'
              placeHolder={`Enter ${FieldNames.singleSubMerchantId}`}
              value={watch('singleSubMerchantId') ?? ''}
              onChange={(singleSubMerchantId) =>
                setValue('singleSubMerchantId', singleSubMerchantId)
              }
            />

            <AmountField
              maxValue={watch('maxAmount') ?? ''}
              minValue={watch('minAmount') ?? ''}
              onMaxChange={(value) => setValue('maxAmount', value)}
              onMinChange={(value) => setValue('minAmount', value)}
              error={
                errors.minAmount?.message ?? errors.maxAmount?.message ?? ''
              }
            />

            <TextField
              label={FieldNames.settlementId}
              id='settlement_id'
              type='text'
              placeHolder={`Enter ${FieldNames.settlementId}`}
              value={watch('settlementId') ?? ''}
              onChange={(settlementId) =>
                setValue('settlementId', settlementId)
              }
            />

            <TextField
              label={FieldNames.utr}
              id='utr'
              type='text'
              placeHolder={`Enter ${FieldNames.utr}`}
              value={watch('utr') ?? ''}
              onChange={(utr) => setValue('utr', utr)}
            />

            <FilterSubmitButton />
          </form>
        </FilterContainer>
      }
    />
  );
};

export default FilterSettlements;
