export const OrderModel = 'nimbbl.order';

// Fields to included in the excel sheet for the Orders Page when "Export" button is clicked

// Columns in downloaded excel sheet will be in same order as fields below
export const OrderFields = [
  // order details
  { name: 'order_date', label: 'Order Date', store: true },
  { name: 'order_id', label: 'Order ID', store: true },
  { name: 'grand_total_amount', label: 'Total Amount', store: true },
  { name: 'status', label: 'Status', store: true },
  { name: 'currency', label: 'Currency', store: true },
  { name: 'invoice_id', label: 'Invoice', store: true },
  { name: 'attempts', label: 'Attempts', store: true },
  { name: 'user_id', label: 'Mobile', store: true },
  { name: 'total_amount', label: 'Order Amount', store: true },
  { name: 'merchant_id', label: 'Submerchant', store: true },
  {
    name: 'sub_merchant_description',
    label: 'Submerchant Description',
    store: true,
  },
  { name: 'sub_merchant_id', label: 'Submerchant Id', store: true },
  { name: 'user_email', label: 'Email', store: false },
  { name: 'amount_before_tax', label: 'Amount Before Tax', store: true },
  { name: 'tax', label: 'Tax', store: true },
  // { name: '', label: '', store: true },
  { name: 'additional_charges', label: 'Convenience Fee', store: true },
  { name: 'grand_total_amount', label: 'Transaction Amount', store: true },

  { name: 'referrer_platform', label: 'Referrer Platform', store: true },
  {
    name: 'referrer_platform_version',
    label: 'Referrer Platform Version',
    store: true,
  },
  { name: 'browser_name', label: 'Browser', store: true },
  { name: 'device_name', label: 'Device', store: true },
  { name: 'os_name', label: 'Operating System', store: true },
  { name: 'shipping_address_id', label: 'Address', store: false },
  {
    name: 'order_custom_attributes',
    label: 'Custom Attributes',
    store: true,
  },
  { name: 'original_currency', label: 'Original Order Currency', store: true },
  {
    name: 'original_total_amount',
    label: 'Original Order Amount',
    store: true,
  },
  { name: 'currency', label: 'Order Currency', store: true },
  { name: 'offer_discount', label: 'Offer Discount', store: true },
  { name: 'exchange_rate_used', label: 'Exchange Rate', store: true },

  // { name: 'create_date', label: 'Order Create Time', store: true },
  // amount details
  // { name: 'offer', label: 'Offer', store: true },
  // customer details
  // { name: 'user_name', label: 'User Name', store: false },
  // { name: 'shipping_address_id', label: 'Address', store: true },
  // { name: 'user_unique_id', label: 'User Id ', store: false },
  // { name: 'orignal_user_id', label: 'Original User', store: true },
  // platform details
  // {
  //   name: 'merchant_shopfront_domain',
  //   label: 'Domain',
  //   store: true,
  // },
  // {
  //   name: 'order_transac_type',
  //   label: 'Order Transaction Type',
  //   store: true,
  // },
];
