import { Routes as BrowserRoutes, Route } from 'react-router-dom';
import Login from '../Login/Login';
import Orders from '../Orders/Orders';
import ApiKey from '../ApiKeys';
import Settlements from '../Settlements/Settlements';
import Transactions from '../Transactions/Transactions';
import { ForgetPassword } from '../Login/ForgetPassword';

import constants from '../../constants';
import ResetPassword from '../Login/ResetPassword';
import ResetPasswordSuccess from '../Login/ResetPasswordSuccess';
import SettlementDetails from '../SettlementDetails/SettlementDetails';
import AnalyticsHome from '../Analytics/AnalyticsHome';
import { CreatePaymentLink } from '../PaymentLinks/Create';
import {
  CustomerDetails,
  LinkDetails,
  ProductDetails,
} from '../PaymentLinks/Create/Steps';
import PaymentLink from '../PaymentLinks/PaymentLink';
import SingleRefund from '../Refunds/SingleRefund/SingleRefund';
import BulkRefunds from '../Refunds/BulkRefunds/BulkRefunds';

interface IRouteMap {
  path: string;
  component: any;
  props?: object;
}

const routeMap: IRouteMap[] = [
  {
    path: constants.ROUTES.LOGIN,
    component: Login,
  },
  {
    path: constants.ROUTES.ORDERS,
    component: Orders,
  },
  {
    path: constants.ROUTES.API_KEY,
    component: ApiKey,
  },
  {
    path: constants.ROUTES.TRANSACTION,
    component: Transactions,
  },
  {
    path: constants.ROUTES.SETTLEMENTS,
    component: Settlements,
  },
  {
    path: `${constants.ROUTES.SETTLEMENTSDETAILS}`,
    component: SettlementDetails,
  },
  {
    path: constants.ROUTES.FORGETPASS,
    component: ForgetPassword,
  },
  {
    path: constants.ROUTES.RESETPASS,
    component: ResetPassword,
  },
  {
    path: constants.ROUTES.RESETPASSUCESS,
    component: ResetPasswordSuccess,
  },
  {
    path: constants.ROUTES.ANALYTICS,
    component: AnalyticsHome,
  },
  {
    path: constants.ROUTES.PAYMENTLINKS,
    component: PaymentLink,
  },
  {
    path: constants.ROUTES.CREATEPASS,
    component: ResetPassword,
  },
];

function Routes() {
  return (
    <>
      <BrowserRoutes>
        {routeMap.map(({ path, component: Component, props }) => (
          <Route path={path} key={path} element={<Component {...props} />} />
        ))}
        <Route path={constants.ROUTES.BULKDREFUND} element={<BulkRefunds />} />
        <Route path={constants.ROUTES.REFUNDS} element={<SingleRefund />} />

        {/* <Route path='/offers/create' element={<CreateOffer />}>
        <Route index element={<OfferDetails />} />
        <Route path='offer-details' element={<OfferDetails />} />
        <Route path='payment-method' element={<PaymentMethod />} />
        <Route path='offer-rules' element={<OfferRules />} />
        <Route path='product-customers' element={<ProductCustomers />} />
        <Route path='summary' element={<Summary />} />
      </Route> */}
        <Route path='/payment-links/create' element={<CreatePaymentLink />}>
          <Route index element={<LinkDetails />} />
          <Route path='customer-details' element={<CustomerDetails />} />
          <Route path='product-details' element={<ProductDetails />} />
        </Route>
      </BrowserRoutes>
    </>
  );
}

export default Routes;
