import React, { Suspense } from 'react';
import 'nimbbl-quark/dist/css/dashboard.tailwind.dist.css';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Components/Routes/Routes';
import { GlobalStore } from './Context/GlobalStore';
import { SessionExpiredModal } from './Components/SessionExpiredModal/SessionExpiredModal';
import { Alert } from './Components/Alert/Alert';
import { ConfirmDialog } from './Components/ConfirmDialog/ConfirmDialog';

function App() {
  return (
    <Router>
      <GlobalStore>
        <Suspense fallback={<div>Loading...</div>}>
          <Alert />
          <Routes />
          <ConfirmDialog />
          <SessionExpiredModal />
        </Suspense>
      </GlobalStore>
    </Router>
  );
}

export default App;
