import { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsTreeChart from 'highcharts/modules/treemap';
import HighchartsColorAxis from 'highcharts/modules/coloraxis';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsHeatMap from 'highcharts/modules/heatmap';
import Sidebar from '../Sidebar/Sidebar';
import HeaderComponent from '../Header/Header';
import FilterPopup from '../FilterPopup/FilterPopup';
import FilterSection from '../FilterSection/FilterSection';
import { AnalyticsSkeleton, EmptyContainer } from './Skeleton';
import { AnalyticsResponse } from './AnalyticsResponse';
import './Analytics.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'nimbbl-quark';
import { PaymentMode } from './PaymentMode/PaymentMode';
import { PaymentPartner } from './PaymentPartner/PaymentPartner';
import { TransactionSummary } from './TransactionSummary/TransactionSummary';
import useFilterAnalytics, {
  defaultAnalyticsFilters,
} from './useFilterAnalytics';
import FilterAnalyticUtils from '../FilterPopup/utils/FilterAnalyticUtils';
import isEqual from 'lodash.isequal';
import FilterSection2 from '../FilterSection/FilterSection2';
import FilterAnalytics from '../FilterPopup/FilterAnalytics';

HighchartsTreeChart(Highcharts);
HighchartsHeatMap(Highcharts);
HighchartsColorAxis(Highcharts);
HighchartsAccessibility(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export const emptyMessage = {
  all: 'No transaction data fetched for your search criteria, please change the filters and search again',
  partner:
    'No data for payment partners fetched for your search criteria, please change the filters and search again',
  mode: 'No data for payment modes fetched for your search criteria, please change the filters and search again',
};

export type SummaryViewType = 'amount' | 'count';

const AnalyticsHome = () => {
  const [summaryView, setSummaryView] = useState<SummaryViewType>('amount');

  // filter v2
  const {
    filters,
    form,
    loading,
    apiData,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    removeFilter,
  } = useFilterAnalytics();
  const { getValues } = form;

  useEffect(() => {
    handleFormSubmit(defaultAnalyticsFilters);
  }, []);

  const filterData = useMemo(
    () => ({
      ...getValues(),
      currency: getValues('currency')?.value ?? 'USD',
      summaryView,
      count_type: summaryView,
    }),
    [getValues, summaryView]
  );

  const isAmount = summaryView === 'amount';

  const { total_transaction_amount, total_transaction_count } = apiData;
  const isEmpty =
    total_transaction_amount === 0 && total_transaction_count === 0;

  return (
    <>
      <Sidebar />
      <div className='p-4 mb-8 body-container ml-60'>
        <HeaderComponent
          page='Analytics'
          title='Analytics'
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          type='analytics'
        />

        <FilterSection2
          defaultFilters={defaultAnalyticsFilters}
          filters={filters}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterAnalyticUtils.generateParams(defaultAnalyticsFilters)
                .filterSection,
              filters
            )
          }
        />

        {loading ? (
          <AnalyticsSkeleton />
        ) : isEmpty ? (
          <EmptyContainer message={emptyMessage.all} />
        ) : (
          <>
            <div className='my-6'>
              <div className='bg-[#e6effc] inline-block rounded'>
                <Button
                  classNames='mr-1'
                  text='Amount'
                  variant={isAmount ? 'primary' : 'text'}
                  style={{
                    width: '174px',
                    height: '40px',
                    ...(!isAmount && { color: '#94A3B8' }),
                  }}
                  attributes={{
                    onClick: () => setSummaryView('amount'),
                    title: 'Switch analytics view to amount',
                  }}></Button>
                <Button
                  text='Count'
                  variant={!isAmount ? 'primary' : 'text'}
                  style={{
                    width: '174px',
                    height: '40px',
                    ...(isAmount && { color: '#94A3B8' }),
                  }}
                  attributes={{
                    onClick: () => setSummaryView('count'),
                    title: 'Switch analytics view to count',
                  }}></Button>
              </div>
              <p className='mt-2 text-sm text-[#94A3B8]'>
                Click to toggle between Amount and Count
              </p>
            </div>

            <TransactionSummary
              highcharts={Highcharts}
              filterData={filterData}
              transactionSummaryData={apiData.transaction_summary}
            />

            <PaymentMode
              highcharts={Highcharts}
              paymentModeData={apiData.payment_mode}
              filterData={filterData}
            />

            <PaymentPartner
              highcharts={Highcharts}
              data={apiData.payment_partner}
              filters={filterData}
            />
          </>
        )}

        <FilterAnalytics
          form={form}
          handleFormSubmit={handleFormSubmit}
          removeFilter={removeFilter}
          isActive={isFilterModalShown}
          onHide={() => {
            setFilterModalShown(false);
          }}
        />
      </div>
    </>
  );
};

export default AnalyticsHome;
