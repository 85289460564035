import {
  ButtonBase,
  ButtonBaseProps,
  Popper,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { CSSProperties } from 'react';

const textStyle: CSSProperties = {
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 400,
};

export const ValueContainer = styled(ButtonBase)(() => ({
  border: '1px solid #033d94',
  borderRadius: '4px',
  padding: '12px',
  fontWeight: '500',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  ...textStyle,

  '&:focus': {
    outline: '1px solid #2563eb',
    borderColor: '#2563eb',
  },

  '& > input': { padding: 0 },
}));

export const PopperContainer = styled(Popper)(() => ({
  borderWidth: '1px 1px 0 1px',
  borderStyle: 'solid',
  borderColor: '#CBD4E1',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  borderRadius: 4,
  zIndex: 1300,
}));
