import { Button } from 'nimbbl-quark';
import { defaultFilterWidth } from '../constants';

const FilterSubmitButton = () => {
  return (
    <div
      className={`fixed right-0 bottom-0 p-4`}
      style={{
        width: defaultFilterWidth,
        backgroundColor: '#fff',
        borderTop: '1px solid #CBD4E1',
      }}>
      <Button
        variant='primary'
        text='Search'
        type='submit'
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default FilterSubmitButton;
