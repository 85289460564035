import { BadgeType } from 'nimbbl-quark';
import { Option } from '../Components/Dropdown';

// type ReactSelectValue = { label: string; value: string } | null;

export class LinkDetails {
  sub_merchant_id: Option = { label: '', value: '' };
  total_amount: string = '00.00';
  currency: Option = { label: 'INR', value: 'INR' };
  expires_at?: Date | null = null;
  description?: string = '';
  callback_url?: string = '';
  invoice_id?: string = '';
  terms_and_conditions?: string = '';
  show_scheduled_at: boolean = false;
  notification_scheduled_at?: Date = undefined;
  product_details: LinkProductDetails[] = [];
}

export type SendLink = 'sendMessage' | 'sendEmail';
export class LinkCustomerDetails {
  payment_link_id?: number = 0;
  payer_first_name?: string = '';
  payer_last_name?: string = '';
  payer_mobile_number?: string = '';
  payer_email_address?: string = '';
  notification_channel_email = false;
  notification_channel_sms = false;
}

export class LinkProductDetails {
  title = '';
  description?: string = '';
  amount_before_tax = '00.00';
  tax = '00.00';
  quantity = 1;
  image?: string = '';
  isImageUrl = true;
  image_url?: string = '';
  defaultExpanded = true;
}

export interface PaymentLinkTable {
  payment_link_id: string;
  amount: string;
  payer_mobile: string;
  payer_name: string;
  expires_on: string;
  status: BadgeType;
  payment_link: string;
}
