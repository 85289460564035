import axios from "axios";

import { MetaData } from "../interfaces/CommonFields";
import {
  FilterTransactionFields,
  defaultTransactionFilters,
} from "../../Transactions/useFilterTransaction";
import getApiEndPoint from "./getApiEndpoint";
import DateUtils from "./DateUtils";
import { daysOptions } from "../constants";
import FieldNames from "../../../constants/FieldNames";
import { UseFormSetValue } from "react-hook-form";

const query =
  "{id,transaction_id,payment_mode,psp_generated_txn_id,status,masked_card,offer_discount,create_date,order_id,sub_merchant_id{sub_merchant_id, description},card_type,card_type,issuer,refund_done,vpa_holder,expiry,network,vpa_app_name,bank_name,holder_name,wallet_name,nimbbl_error_code,wallet_name,payment_partner,nimbbl_merchant_message,nimbbl_consumer_message,settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount},order_id{original_currency,exchange_rate_used, additional_charges,available_refundable_amount,grand_total_amount,id,order_id,currency,attempts,invoice_id,total_amount,amount_before_tax,tax,order_custom_attributes{key,value},order_date,user_id{email,mobile_number}}}";

class FilterTransactionUtils {
  static generateParams(
    formData: FilterTransactionFields,
    metaData?: Partial<MetaData>,
    updateFormData: UseFormSetValue<FilterTransactionFields> = (..._args) => {}
  ) {
    // for filter api
    const filterParams: any[] = [];
    // for filter section
    const filterSection: Record<string, string> = {};

    // default filter for transaction
    filterParams.push(["transaction_type", "in", ["payment"]]);

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case "status": {
          const values = formData[filterName].map((item) => item.value);
          // if all is selected then don't send/show status filter
          if (/all/i.test(values.join(","))) break;
          if (values.length === 0) break;

          filterParams.push(["status", "in", values]);

          const value = formData[filterName]
            .map((item) => item.label)
            .join(", ");
          filterSection[FieldNames.status] = value;
          break;
        }
        case "startDate": {
          const startDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams.push([
            "create_date",
            ">=",
            DateUtils.getUTCStringFromDate(startDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case "endDate": {
          let endDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
            updateFormData("endDate", endDate);
          }

          filterParams.push([
            "create_date",
            "<=",
            DateUtils.getUTCStringFromDate(endDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case "transactionId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["transaction_id", "=", value?.trim()]);
          filterSection[FieldNames.transactionId] = value ?? "";
          break;
        }
        case "orderId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["order_id.order_id", "=", value?.trim()]);
          filterSection[FieldNames.orderId] = value ?? "";
          break;
        }
        case "paymentMode": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["payment_mode", "in", values]);
          filterSection[FieldNames.paymentMode] = labels;

          break;
        }
        case "paymentPartner": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["payment_partner", "in", values]);
          filterSection[FieldNames.paymentPartner] = labels;

          break;
        }
        case "invoiceId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["order_id.invoice_id", "=", value?.trim()]);
          filterSection[FieldNames.invoiceId] = value ?? "";
          break;
        }
        case "mobile": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["user_id.mobile_number", "=", value?.trim()]);
          filterSection[FieldNames.mobile] = value ?? "";
          break;
        }
        case "subMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["sub_merchant_id", "in", values]);
          filterSection[FieldNames.submerchantId] = labels;

          break;
        }
        case "singleSubMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          if (value) {
            filterParams.push(["sub_merchant_id", "=", [value.trim()]]);
            filterSection[FieldNames.singleSubMerchantId] = value;
          }

          break;
        }
        case "email": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["user_id.email", "=", value?.trim()]);
          filterSection[FieldNames.email] = value ?? "";
          break;
        }
        case "currency": {
          const value = formData[filterName];
          if (!value) break;

          const { value: code } = value;
          if (!code) break;

          filterParams.push(["currency", "=", code]);
          filterSection[FieldNames.currency] = code;
          break;
        }
        case "cardNo": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["masked_card", "like", value?.trim()]);
          filterSection[FieldNames.cardNumber] = value ?? "";
          break;
        }
        case "bankName": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["bank_name", "in", values]);
          filterSection[FieldNames.bankName] = labels;

          break;
        }
        case "walletName": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["wallet_name", "in", values]);
          filterSection[FieldNames.walletName] = labels;

          break;
        }
        case "upiId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["vpa_id", "=", value?.trim()]);
          filterSection[FieldNames.upiId] = value ?? "";
          break;
        }
        case "minAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["total_amount", ">=", Number(value)]);
          filterSection[FieldNames.minAmount] = value ?? "";
          break;
        }
        case "maxAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["total_amount", "<=", Number(value)]);
          filterSection[FieldNames.maxAmount] = value ?? "";
          break;
        }
        case "settlementId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["partner_settement_id", "=", value?.trim()]);
          filterSection[FieldNames.settlementId] = value ?? "";
          break;
        }
        case "customAttributes": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(
            "|",
            ["order_id.order_custom_attributes.key", "like", "%" + value + "%"],
            ["order_id.order_custom_attributes.key", "like", "%" + value + "%"]
          );
          filterSection[FieldNames.customAttributes] = value ?? "";
          break;
        }
        case "utr": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["settlement_log.utr_no", "=", value?.trim()]);
          filterSection[FieldNames.utr] = value ?? "";
          break;
        }
      }
    });

    const { per_page, next, prev, page } = metaData ?? {};

    const filters = {
      params: {
        ...(filterParams.length > 0 && {
          filter: JSON.stringify(filterParams),
        }),
        query: query,
        page: page ?? 1,
        per_page: per_page ?? 60,
        next: next ?? null,
        prev: prev ?? null,
      },
    };

    return {
      filters,
      filterSection,
    };
  }

  static removeParams(
    formData: FilterTransactionFields,
    filterName: string
  ): FilterTransactionFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case FieldNames.status:
        return { ...result, status: defaultTransactionFilters.status };
      case "date":
        return {
          ...result,
          startDate: defaultTransactionFilters["startDate"],
          endDate: defaultTransactionFilters["endDate"],
        };
      case FieldNames.transactionId:
        return { ...result, transactionId: undefined };
      case FieldNames.orderId:
        return { ...result, orderId: undefined };
      case FieldNames.paymentMode:
        return { ...result, paymentMode: null };
      case FieldNames.paymentPartner:
        return { ...result, paymentPartner: null };
      case FieldNames.invoiceId:
        return { ...result, invoiceId: undefined };
      case FieldNames.mobile:
        return { ...result, mobile: undefined };
      case FieldNames.submerchantId:
        return { ...result, subMerchantId: [] };
      case FieldNames.singleSubMerchantId:
        return {
          ...result,
          singleSubMerchantId: undefined,
          subMerchantId: [],
        };
      case FieldNames.email:
        return { ...result, email: undefined };
      case FieldNames.currency:
        return { ...result, currency: null };
      case FieldNames.cardNumber:
        return { ...result, cardNo: undefined };
      case FieldNames.bankName:
        return { ...result, bankName: null };
      case FieldNames.walletName:
        return { ...result, walletName: null };
      case FieldNames.upiId:
        return { ...result, upiId: undefined };
      case FieldNames.minAmount:
        return { ...result, minAmount: undefined };
      case FieldNames.maxAmount:
        return { ...result, maxAmount: undefined };
      case FieldNames.settlementId:
        return { ...result, settlementId: undefined };
      case FieldNames.customAttributes:
        return { ...result, customAttributes: undefined };
      case FieldNames.utr:
        return { ...result, utr: undefined };
      default:
        return { ...defaultTransactionFilters };
    }
  }

  static getTransactions = (body: object) =>
    axios.post(getApiEndPoint("transaction"), body);
}

export default FilterTransactionUtils;
