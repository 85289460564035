import { Button, Divider, InputField } from 'nimbbl-quark';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useCreateLinkContext } from '..';
import { LinkProductDetails } from '../../../../interfaces/PaymentLinks';
import { FieldUtil, PriceUtil } from '../../../../Utils';
import { DragDrop } from './DragDrop/DragDrop';
import { FormRow } from './FormRow';
import { Accordion } from './Accordion';
import { useCallback, useEffect } from 'react';

const nameMaxLength = 40;
const descriptionMaxLength = 80;

export const ProductDetails = () => {
  const navigate = useNavigate();
  const { linkDetailsForm, productFieldArray, removeProduct } =
    useCreateLinkContext();

  const {
    register,
    // resetField,

    watch,
    trigger,
    setValue,
    formState: { errors },
  } = linkDetailsForm;
  const { fields, append } = productFieldArray;

  const { value: currency } = watch('currency') || { value: 'INR' };
  const getProductValues = (index: number) =>
    watch([
      `product_details.${index}.defaultExpanded`,
      `product_details.${index}.title`,
      `product_details.${index}.description`,
      `product_details.${index}.amount_before_tax`,
      `product_details.${index}.tax`,
      `product_details.${index}.image`,
      `product_details.${index}.isImageUrl`,
      `product_details.${index}.image_url`,
      `product_details.${index}.quantity`,
    ]);

  console.log('product errors', errors);

  const handleAddProduct = useCallback(() => {
    append(new LinkProductDetails(), { shouldFocus: true });
    // setExpandedIndex(() => (fields.length > 0 ? fields.length - 1 : 0));
  }, []);

  useEffect(() => {
    if (fields.length === 0) {
      handleAddProduct();
    }
  }, []);

  const handleCancel = async () => {
    console.log('reset');
    console.log(await trigger('product_details'));
    if (await trigger('product_details')) {
      console.log('hello');
      // resetField('product_details');
      navigate(-1);
    }
  };

  const submitProducts = async (e: any) => {
    e.preventDefault();
    const result = await trigger('product_details', { shouldFocus: true });
    if (result) {
      fields.map((field, index) =>
        setValue(`product_details.${index}.defaultExpanded`, false)
      );

      navigate(-1);
    }
  };

  const clearImage = (index: number) =>
    setValue(`product_details.${index}.image`, '');

  const acceptImage = (files: File[], index: number) => {
    const file = files[0];
    console.log('accepted file', file);
    setValue(`product_details.${index}.image`, URL.createObjectURL(file));
  };
  const rejectImage = (files: any, index: number) => {
    setValue(`product_details.${index}.image`, '');
  };

  const isEmpty = fields.length === 0;

  return (
    <form onSubmit={submitProducts}>
      {fields.map((field, index) => {
        const [
          defaultExpanded,
          name,
          description,
          price,
          tax,
          image,
          isImageUrl,
          image_url,
          quantity,
        ] = getProductValues(index);
        const titleText =
          `${index + 1}. ` + (name.length > 0 ? name : `Product ${index + 1}`);

        const productError =
          errors?.product_details && errors?.product_details[index];
        const totalProductPrice =
          quantity * (parseFloat(price) || 0) + (parseFloat(tax) || 0);

        return (
          <Accordion
            defaultExpanded={defaultExpanded}
            handleRemove={() => removeProduct(index)}
            titleProps={{ style: { marginTop: 8 } }}
            key={field.id}
            titleText={titleText}
            subElement={
              <>
                <div className='my-2 bg-[#FFF9EA] py-2 px-4 rounded text-sm font-semibold flex justify-between'>
                  <span>Total product value</span>
                  <span>
                    {PriceUtil.formatCurrency(
                      totalProductPrice,
                      currency as any
                    )}
                  </span>
                </div>
              </>
            }>
            <>
              <FormRow
                required
                id={`product_details.${index}.name`}
                label='Product Name'
                value={
                  <InputField
                    id={`product_details.${index}.name`}
                    style={{ marginTop: -4 }}
                    placeholder='Enter product name'
                    caption={
                      productError?.title?.message ||
                      `${nameMaxLength - name.length} characters left`
                    }
                    captionType={
                      productError?.title?.message ? 'error' : 'text'
                    }
                    attributes={{
                      ...register(`product_details.${index}.title`),
                    }}
                  />
                }
              />
              <FormRow
                id={`product_details.${index}.description`}
                label='Item description'
                value={
                  <>
                    <textarea
                      id={`product_details.${index}.description`}
                      placeholder='Enter link description...'
                      className='w-full px-3 py-3 font-normal border rounded border-primary-80 text-black-primary'
                      rows={3}
                      {...register(`product_details.${index}.description`)}
                    />
                    <p
                      style={{
                        fontWeight: 300,
                        fontSize: 14,
                        color: '#6C7F9A',
                        ...(productError?.description?.message && {
                          color: 'rgb(216,50,50)',
                        }),
                      }}>
                      {productError?.description?.message ||
                        `${
                          descriptionMaxLength - (description?.length || 0)
                        } characters left`}
                    </p>
                  </>
                }
              />
              <FormRow
                required
                id={`product_details.${index}.quantity`}
                label='Quantity'
                value={
                  <InputField
                    id={`product_details.${index}.quantity`}
                    style={{ marginTop: -4 }}
                    caption={productError?.quantity?.message}
                    captionType='error'
                    attributes={{
                      ...register(`product_details.${index}.quantity`, {
                        valueAsNumber: true,
                      }),
                    }}
                  />
                }
              />
              <FormRow
                required
                id={`product_details.${index}.price`}
                label={`Price per item (in ${currency})`}
                value={
                  <InputField
                    id={`product_details.${index}.price`}
                    style={{ marginTop: -4 }}
                    caption={productError?.amount_before_tax?.message}
                    captionType='error'
                    attributes={{
                      ...register(
                        `product_details.${index}.amount_before_tax`,
                        {
                          onBlur: (e) =>
                            setValue(
                              `product_details.${index}.amount_before_tax`,
                              FieldUtil.formatDecimalNumber(e.target.value),
                              { shouldValidate: true }
                            ),
                        }
                      ),
                    }}
                  />
                }
              />
              <FormRow
                required
                id={`product_details.${index}.tax`}
                label={`Tax (in ${currency})`}
                value={
                  <InputField
                    id={`product_details.${index}.tax`}
                    style={{ marginTop: -4 }}
                    caption={productError?.tax?.message}
                    captionType='error'
                    placeholder={'Enter amount in ' + currency}
                    attributes={{
                      ...register(`product_details.${index}.tax`, {
                        onBlur: (e) =>
                          setValue(
                            `product_details.${index}.tax`,
                            FieldUtil.formatDecimalNumber(e.target.value),
                            { shouldValidate: true }
                          ),
                      }),
                    }}
                  />
                }
              />
              <FormRow
                label='Image'
                id={`product_details.${index}.image_url`}
                value={
                  <>
                    {/* <DragDrop
                      img={image ? image : image_url}
                      onClear={() => clearImage(index)}
                      handleAcceptedFiles={(files) => acceptImage(files, index)}
                      handleRejectedFiles={(files) => rejectImage(files, index)}
                    /> */}
                    {/* <div className='mt-6 mb-4'>
                      <input
                        {...register(`product_details.${index}.isImageUrl`)}
                        type='checkbox'
                        id='isImageUrl'
                        className='mr-2 rounded'
                      />
                      <label htmlFor='isImageUrl'>Add image url</label>
                    </div> */}
                    {/* {isImageUrl && ( */}
                    <InputField
                      id={`product_details.${index}.image_url`}
                      style={{ marginTop: -4 }}
                      caption={productError?.image_url?.message}
                      captionType='error'
                      placeholder='Enter image url'
                      attributes={{
                        ...register(`product_details.${index}.image_url`),
                      }}
                    />
                    {/* )} */}
                  </>
                }
              />
            </>
          </Accordion>
        );
      })}

      <Button
        variant='text'
        text='Add new product'
        style={{
          marginTop: 8,
          padding: 0,
          fontSize: 14,
          lineHeight: '20px',
          fontWeight: 500,
        }}
        iconPosition='left'
        IconComponent={() => <FiPlus size={16} className='mr-3' />}
        attributes={{
          onClick: handleAddProduct,
        }}
      />

      <Divider className='mt-2 mb-4' />

      <div className='flex justify-end'>
        <Button
          iconPosition='left'
          variant='text'
          text=' Cancel'
          classNames='mr-3'
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            backgroundColor: '#E6EFFC',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
          }}
          attributes={{
            onClick: handleCancel,
          }}
        />
        <Button
          type='submit'
          variant='primary'
          text='Save and Add Products'
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            ...(isEmpty && {
              color: '#00000042',
              backgroundColor: '#0000001f',
            }),
          }}
          attributes={{
            disabled: isEmpty,
          }}
        />
      </div>
    </form>
  );
};
