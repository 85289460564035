/* eslint-disable array-callback-return */
import moment from "moment";
import { RiCloseLine } from "react-icons/ri";
import { getPage } from "../../Apis/segment";
import { TrackEventNames } from "../../constants/trackEventNames";
import { scanFilters } from "../../Hooks";
import { useTracker } from "../../Hooks/useTracker";
import "./FilterSection.css";
import { HandlePaymentPartner } from '../../Utils/PaymentPartnerHandling';

interface ITag {
  text: string | any;
  hasClose?: boolean;
  onCloseClick?: (text: string) => void;
  className?: string;
  onClick?: (text: string) => void;
}

const Tag = ({
  text,
  hasClose = true,
  onCloseClick,
  className,
  onClick,
}: ITag) => {
  return (
    <span
      className={`tag px-2 py-1 border rounded inline-flex text-sm items-center ${className}`}
      onClick={() => {
        onClick && onClick(text);
      }}
    >
      {" "}
      {/* #TODO: need to confirm the color */}
      {text}
      {hasClose ? (
        <RiCloseLine
          className="w-4 h-4 ml-1 cursor-pointer tag-close"
          onClick={() => {
            onCloseClick && onCloseClick(text);
          }}
        />
      ) : null}
    </span>
  );
};

interface Dic {
  [key: string]: any;
}

interface IProps {
  filters?: Dic;
  totalCount?: number | null;
  className?: string;
  onClear?: (data: string) => void;
  type?: string;
  onDefaultFilterClicked?: (data: any) => void;
}

let isArray = function (a: any) {
  return !!a && a.constructor === Array;
};

const excludeList = [
  'order_by',
  'sort_by',
  'page',
  'per_page',
  'total_pages',
  'next',
  'prev',
  'days',
  'page_size',
];

const filterDisplayMap: Dic = {
  count_type: "Summary View",
  order_by: "Order By",
  sort_by: "Sort By",
  order_id: "Order ID",
  sub_merchant_id: "Submerchant ID",
  sub_merchant: "Submerchant Name",
  invoice_id: "Invoice ID",
  mobile_no: "Mobile No",
  status: "Status",
  amount_min: "Min Amount",
  amount_max: "Max Amount",
  days: "Days",
  start_date: "Start Date",
  from_date: "Start Date",
  end_date: "End Date",
  to_date: "End Date",
  order_transac_type: "Order Transaction Type",
  transaction_id: "Transaction ID",
  refund_id: "Refund ID",
  email: "Email",
  utr_no: "UTR No",
  payment_partner: "Payment Partner",
  // payment_psp: 'Payment Partner',
  payment_mode: 'Payment Mode',
  custom_attributes: 'Custom Attributes',
  total_amount: 'Total Amount',
  settlement_id: 'Settlement ID',
  bank_name: 'NetBanking Name',
  wallet_name: 'Wallet Name',
  transaction_type: 'Transaction Type',
  psp_settlement_log_id: 'PSP Settlement Id',
  settlement_entry_type: 'Settlement Entry Type',
  currency: 'Currency',
  sub_merchant_type: 'Submerchant Type',
  link_hash: 'Link hash',
  mobile_number: 'Mobile Number',
};

const removeFromList = (obj: any, exclude: string[]) => {
  const arr = isArray(obj) ? obj : Object.keys(obj);
  const newList: string[] = [];
  const extractedList: string[] = [];

  arr.map((key: string) => {
    if (!exclude.includes(key)) {
      newList.push(key);
    } else {
      extractedList.push(key);
    }
  });

  return {
    data: newList,
    extractedList,
  };
};

interface ITagText {
  text: string;
  value: string;
}

const TagText = ({ text, value }: ITagText) => (
  <span>
    <span className="mr-1 tag-key">{text}:</span>
    <span className="capitalize tag-value">{value}</span>
  </span>
);
const generateFilters = (obj: any, type?: string) => {
  let list = removeFromList(obj, excludeList).data;
  let newList: any[] = [];
  let newListWithId: any = {};
  const date: any = {};

  list.length &&
    list.map((key: any) => {
      let value = obj[key];
      let displayName: string = filterDisplayMap[key] || key;
      let isStatusOrTransac_type =
        ["status", "order_transac_type", "transaction_type"].includes(key) &&
        value.includes("All");
      if (
        !value ||
        key === "single_sub_merchant_id" ||
        isStatusOrTransac_type ||
        key === "submerchant_id" ||
        key === "payment_psp"
      )
        return;
      // if(key === 'sub_merchant_id') return;
      // if(['status', 'order_transac_type'].includes(key) && value.includes('All')) {
      //     return;
      // }
      else if (
        (key === "sub_merchant" ||
          key === "wallet_name" ||
          key === "bank_name") &&
        value &&
        value.length &&
        typeof value === "object"
      ) {
        let newValue: any = [];

        value.forEach((element: any) => {
          newValue.push(element.label);
        });
        // value = newValue
        value =
          newValue.length > 1
            ? `${newValue[0]}, +${value.length - 1}`
            : newValue[0];

        // value = value.reduce((acc:string[], item:any) => {console.log('acc1', acc); return acc.push(item.label) }, ['']).join(', ')
      } else if (
        key === "start_date" ||
        key === "from_date" ||
        key === "end_date" ||
        key === "to_date"
      ) {
        const newDate = new Date(value + " UTC");
        const momentDate = moment(value, "YYYY-MM-DD HH:mm");

        const filterDate = ("0" + newDate.getDate()).slice(-2);
        const filterMonth = ("0" + (1 + newDate.getMonth())).slice(-2);
        const filterYear = newDate.getFullYear();

        const momentFormattedDate = momentDate.format("DD/MM/YYYY");
        const isUTC = /(orders|transactions|refunds)/i.test(getPage() || "");

        if (key === "start_date" || key === "from_date") {
          date["start_date"] = isUTC
            ? `${filterDate}/${filterMonth}/${filterYear}`
            : momentFormattedDate;
        } else {
          date["end_date"] = isUTC
            ? `${filterDate}/${filterMonth}/${filterYear}`
            : momentFormattedDate;
        }

        if (Object.keys(date).length === 2) {
          const { start_date, end_date } = date;
          if (start_date === end_date) {
            value = start_date;
          } else {
            value = `${start_date} - ${end_date}`;
          }
          displayName = "Date";
        } else {
          return;
        }
      } else if (typeof value === "object") {
        if (isArray(value) && typeof value[0] !== "object") {
          value = value && value.length ? value.join(", ") : "";
        } else if (Array.isArray(value)) {
          let newValue: any = [];
          value.forEach((element: any) => {
            newValue.push(element?.label?.toLowerCase());
          });
          value = newValue;
        }
      } else if (key === 'sub_merchant_type' && value === 'live') {
        return;
      }
      // else if (key === 'sub_merchant_type' && value === 'live') {
      //   return;
      // }

      if (key === "settlement_entry_type" && value.value) {
        if (value.value === "All") return;
        value = value.value;
      }
      // console.log(value);
      if (value) {
        let val = HandlePaymentPartner.getPaymentPartner(value)
        newList.push(`${displayName}: ${value}`);
        newListWithId[`${displayName}: ${value}`] = {
          key,
          val,
          displayName,
          originalValue: obj[key],
          text: <TagText text={displayName} value={val} />,
        };
      }
    });

  // if(Object.keys(date).length) {
  //     newList.push(`date: ${date.start_date} - ${date.end_date}`)
  //     newListWithId[`date: ${date.start_date} - ${date.end_date}`] = {
  //         'end_date',
  //         `${date.start_date} - ${date.end_date}`,
  //         'date',
  //         originalValue: obj[key]
  //     }
  // }
  return {
    list: newList,
    obj: newListWithId,
  };
};

const FilterSection = ({
  filters = {},
  totalCount = 0,
  className,
  onClear,
  type = "order",
  onDefaultFilterClicked,
}: IProps) => {
  const { track } = useTracker();
  if (
    typeof filters !== "object" ||
    (typeof filters === "object" && !Object.keys(filters).length)
  ) {
    return null;
  }

  let newFilters = { ...filters };
  if (
    filters.single_sub_merchant_id &&
    filters.single_sub_merchant_id?.length > 0 &&
    Array.isArray(filters.sub_merchant) &&
    filters.sub_merchant.length > 0
  ) {
    const { sub_merchant, ...restFilters } = filters;
    newFilters = restFilters;
  } else if (
    Array.isArray(filters.sub_merchant) &&
    filters.sub_merchant.length
  ) {
    const { sub_merchant_id, ...restFilters } = filters;
    newFilters = restFilters;
  }

  const { list: filter, obj: filterMap } = generateFilters(newFilters, type);

  const handleCloseClick = (data: any) => {
    // let key = data['key'];
    // if(type === 'settlments' && key == 'payment_partner') {
    //     key = 'payment_provider'
    // }
    onClear && onClear(data["key"]);
  };

  let count = 0;

  return (
    <div>
      {filter && filter.length ? (
        <>
          <div className={`filterSection-head ${className}`}>
            <h5 className="inline-flex text-base font-semibold">
              Filter applied
            </h5>
            {totalCount ? (
              <span className="ml-0.5 text-xs">
                {Object.keys(filters).includes("count_type") ? (
                  ""
                ) : (
                  <span>
                    (Displaying {totalCount.toLocaleString("en-IN")} {getPage()}
                    )
                  </span>
                )}
              </span>
            ) : totalCount === 0 ? (
              <span className="ml-0.5 text-xs">
                {Object.keys(filters).includes("count_type") ? (
                  ""
                ) : (
                  <span> (Displaying 0 {getPage()}) </span>
                )}
              </span>
            ) : null}
          </div>
          <div className="flex flex-wrap gap-3 mt-2 FilterSection-body">
            {filter.map((item, index) => {
              if (
                ((filterMap[item].key === "start_date" ||
                  filterMap[item].key === "from_date" ||
                  filterMap[item].key === "to_date" ||
                  filterMap[item].key === "end_date") &&
                  filters["days"] &&
                  (filters["days"].value === 7 || filters["days"] === 7)) ||
                ((filterMap[item].key === "status" ||
                  filterMap[item].key === "order_transac_type") &&
                  filterMap[item]?.originalValue?.length === 1 &&
                  filterMap[item]?.originalValue[0] &&
                  filterMap[item]?.originalValue[0]?.toLowerCase() === "all")
              ) {
                count += 1;
                return (
                  <Tag
                    text={filterMap[item].text}
                    key={`${item}-${index}`}
                    className="cursor-pointer"
                    hasClose={false}
                    onClick={() => {
                      onDefaultFilterClicked && onDefaultFilterClicked(item);
                    }}
                  />
                );
              } else {
                return (
                  <Tag
                    text={filterMap[item].text}
                    key={`${item}-${index}`}
                    onCloseClick={() => handleCloseClick(filterMap[item])}
                  />
                );
              }
            })}

            {count === filter.length ? null : (
              <span
                className="flex items-center text-sm cursor-pointer clear-all"
                style={{ color: "#D83232" }}
                onClick={() => {
                  onClear && onClear("all");
                  track({
                    name: TrackEventNames.clearFilterClicked,

                    inBuiltOptions: ["merchantId", "merchantName"],
                    additionalOptions: {
                      menu_item_name: getPage()!,
                      ...scanFilters({ ...filters }),
                    },
                  });
                }}
              >
                Clear all
              </span>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default FilterSection;
