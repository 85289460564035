import {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useUnAuthenticatedRoutes } from '../Components/SessionExpiredModal/SessionExpiredModal';
import { useCurrentLocation } from '../Hooks';
import { GlobalState } from './initialState';
import { reducer, Action, ActionTypes } from './reducer';

type GlobalContextType = GlobalState & {
  dispatch: Dispatch<Action>;
};

const GlobalContext = createContext<GlobalContextType>({
  ...new GlobalState(),
  dispatch: () => ({}),
});

export const useGlobalStore = () => useContext(GlobalContext);

export const GlobalStore: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, new GlobalState());
  const pathname = useCurrentLocation();
  const isUnAuthenticatedRoute = useUnAuthenticatedRoutes();

  useEffect(() => {
    const sessionId = localStorage.getItem('nimbbl_session_id');

    if (sessionId) {
      dispatch({ type: ActionTypes.setSession, payload: true });
    } else if (!isUnAuthenticatedRoute) {
      console.log('setting callback', pathname);
      dispatch({ type: ActionTypes.setCallbackUrl, payload: pathname });
    }
  }, [dispatch, pathname, isUnAuthenticatedRoute]);

  const value = { ...state, dispatch };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
