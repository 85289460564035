import { CSSProperties, FC } from 'react';
import { Dropdown } from '../../Dropdown';
import { Option } from '../interfaces/CommonFields';

interface MultipleSelectFieldProps {
  placeholder?: string;
  options: Option[] | null;
  label: string;
  value: Option[] | null;
  onChange: (newValue: MultipleSelectFieldProps['value']) => void;
  containerProps?: {
    style?: CSSProperties;
  };
}

// this field is similar to mutli-select dropdown but is used in filters
const MultipleSelectField: FC<MultipleSelectFieldProps> = (props) => {
  const { label, onChange, options, value, containerProps, placeholder } =
    props;
  const { style } = containerProps ?? {};

  return (
    <div className='mt-6 form-input' style={style}>
      <label
        htmlFor={label}
        className='inline-block mb-1 text-sm font-semibold text-black-primary'>
        {label}
      </label>
      <Dropdown
        multiple
        id={label}
        options={options ?? []}
        value={value ?? []}
        onChange={onChange}
        placeHolder={`Select ${label}`}
      />
    </div>
  );
};

export default MultipleSelectField;
