import { FC } from 'react';
import { Dropdown } from '../../Dropdown';
import {
  statusOptions,
  refundStatusOptions,
  bulkRefundStatusOptions,
  transactionStatusOptions,
  paymentLinkStatusOptions,
} from '../constants';
import FieldNames from '../../../constants/FieldNames';

type Option = { label: string; value: string };

type StatusFieldProps = {
  isOrderType?: boolean;
  isRefundType?: boolean;
  isBulkRefundType?: boolean;
  isPaymentLinkType?: boolean;
  value: Option[];
  setValue: (newValue: Option[]) => void;
};

const StatusField: FC<StatusFieldProps> = (props) => {
  const {
    isOrderType,
    isRefundType,
    isBulkRefundType,
    isPaymentLinkType,
    setValue,
    value,
  } = props;

  const options = isOrderType
    ? statusOptions
    : isRefundType
    ? refundStatusOptions
    : isBulkRefundType
    ? bulkRefundStatusOptions
    : isPaymentLinkType
    ? paymentLinkStatusOptions
    : transactionStatusOptions;

  const handleStatusChange = (selectedStatuses: any) => {
    const isAllPresent = selectedStatuses.some(
      (status: any) => status.value === 'all'
    );
    const onlyAllIsPresent =
      isAllPresent && value.length === 1 && value[0].value === 'all';

    // when all is present remove other statuses
    if (isAllPresent && !onlyAllIsPresent) {
      const allOption = selectedStatuses.filter(
        (status: any) => status.value === 'all'
      );
      setValue(allOption);
      return;
    }

    // keep all statuses except all
    const statusWithoutAll = selectedStatuses.filter(
      (status: any) => status.value !== 'all'
    );
    setValue(statusWithoutAll);
  };

  return (
    <div className='form-input'>
      <div className='mb-1'>
        <label
          htmlFor='status'
          className='text-sm font-semibold text-black-primary'>
          {FieldNames.status}
        </label>
      </div>

      <Dropdown
        id='status'
        multiple
        options={options}
        value={value}
        onChange={handleStatusChange}
      />
    </div>
  );
};

export default StatusField;
