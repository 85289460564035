import React, { useState } from 'react';
import { segmentApiCopy } from '../../Apis/segment';
import { useTracker } from '../../Hooks';
import { TrackEventNames } from '../../constants/trackEventNames';

interface ICopyTextContainer {
  text: string;
  copyText?: string;
  copiedText?: string;
  merchantname?: string;
  section: string;
  submerchant_id?: string;
}

const CopyTextContainer = ({
  text,
  copyText = 'Copy',
  copiedText = 'Copied',
  merchantname,
  submerchant_id,
  section,
}: ICopyTextContainer) => {
  const { track } = useTracker();
  const [isHover, setIsHover] = useState(false);
  const [hoverText, setHoverText] = useState(copyText);

  return (
    <span
      className='relative flex cursor-pointer'
      onClick={() => {}}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setHoverText(copyText);
      }}>
      {text}

      {isHover ? (
        <span
          className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center font-semibold copy-text'
          style={{
            color: '#045CE1',
          }}
          onClick={() => {
            navigator.clipboard.writeText(text);
            setHoverText(copiedText);
            segmentApiCopy(merchantname, section);
            track({
              name: TrackEventNames.apiAccesskeyCopied,
              inBuiltOptions: ['merchantId', 'merchantName'],
              additionalOptions: {
                developer_section_name: 'API_Keys',
                developer_section_action: 'select',
                // @ts-ignore
                filters: {
                  sub_merchant_id: submerchant_id,
                },
              },
            });
          }}>
          {hoverText}
        </span>
      ) : null}
    </span>
  );
};

export default CopyTextContainer;
