import { Button, ModalV2 } from 'nimbbl-quark';
import { FC, useCallback, useEffect, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  CREATEPASS,
  FORGETPASS,
  LOGIN,
  RESETPASS,
  RESETPASSUCESS,
} from '../../constants/routes';
import { useGlobalStore } from '../../Context/GlobalStore';
import { useCurrentSession } from '../../Hooks';
import routerPaths from '../../router/router';

interface AlertCircleProps {
  backgroundColor?: string;
}

// time to wait till navigation triggers (in seconds)
const timerValue = 5;

export const AlertCircle: FC<AlertCircleProps> = ({
  backgroundColor,
  children,
}) => (
  <div
    style={{
      padding: 8,
      width: 'fit-content',
      borderRadius: '50%',
      backgroundColor,
    }}
    // className='border '
  >
    {children}
  </div>
);

export const useUnAuthenticatedRoutes = () => {
  const { pathname } = useLocation();

  // do not show session expired modal for the following routes
  const unAuthenticatedRoutes = [
    '/sign-up',
    LOGIN,
    FORGETPASS,
    RESETPASS,
    RESETPASSUCESS,
    CREATEPASS,
  ];
  return unAuthenticatedRoutes.some((route) => pathname === route);
};

export const SessionExpiredModal = () => {
  const { isSessionValid, callbackUrl } = useGlobalStore();
  const navigate = useNavigate();
  const { setSessionExpired, hasSession } = useCurrentSession();
  const [count, setCount] = useState(timerValue);
  const [searchParams] = useSearchParams();
  const isUnAuthenticatedRoute = useUnAuthenticatedRoutes();

  const navigateToLogin = useCallback(() => {
    const redirectUrl = routerPaths.home;
    const redirect = searchParams.get('redirect');

    if (redirect) {
      console.log('redirect already present');
      return;
    }
    navigate({
      pathname: redirectUrl,
      ...(callbackUrl && { search: 'redirect=' + callbackUrl }),
    });
  }, [navigate, callbackUrl, searchParams]);

  const handleTabFocus = useCallback(() => {
    // console.log('window in focus');
    const session = hasSession();
    if (!session) {
      setSessionExpired();
    }
  }, [hasSession, setSessionExpired]);

  // when tab gains focus checking for session id
  useEffect(() => {
    window.addEventListener('focus', handleTabFocus);

    return () => {
      window.removeEventListener('focus', handleTabFocus);
    };
  }, [handleTabFocus]);

  // resetting counter value after login
  useEffect(() => {
    if (isSessionValid) {
      // console.log('session is valid');
      setCount(timerValue);
    }
  }, [isSessionValid]);

  // decrementing counter every second until it reaches 0
  useEffect(() => {
    let timeoutId: NodeJS.Timer | null = null;
    if (!isSessionValid && !isUnAuthenticatedRoute) {
      timeoutId = setInterval(() => {
        setCount((prev) => {
          if (prev === 0) {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            return timerValue;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSessionValid, isUnAuthenticatedRoute]);

  // navigating to login page when counter reaches 0
  useEffect(() => {
    if (count === 0) {
      navigateToLogin();
    }
  }, [count, navigateToLogin]);

  return (
    <ModalV2
      isShown={!isUnAuthenticatedRoute && !isSessionValid}
      isBackdrop
      position='center'
      size='sm'
      style={{ maxWidth: '392px', maxHeight: '260px' }}
      modalContent={
        <>
          <AlertCircle backgroundColor='#FEF3F2'>
            <AlertCircle backgroundColor='#FEE4E2'>
              <FiAlertCircle size={20} color='#D92D20' />
            </AlertCircle>
          </AlertCircle>
          <p
            style={{ fontWeight: 500, marginTop: 16 }}
            className='mb-2 text-base text-black'>
            Session Expired
          </p>
          <p className='text-sm'>
            Your Session has expired. You will be redirected to the login page
            in {count} second{count !== 1 && 's'}.
          </p>
          <Button
            style={{
              marginTop: 32,
              backgroundColor: '#D83232',
              paddingLeft: 56,
              paddingRight: 56,
              lineHeight: '20px',
              fontWeight: 500,
            }}
            size='sm'
            text='Login'
            attributes={{
              onClick: navigateToLogin,
            }}
          />
        </>
      }
    />
  );
};
