import { useCallback, useState } from 'react';
import { array, object, string } from 'yup';

import {
  FilterCommonFields,
  MetaData,
  commonSchema,
  defaultCommonFilters,
} from '../FilterPopup/interfaces/CommonFields';
import { useCurrentSession, useTracker } from '../../Hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FilterTransactionUtils from '../FilterPopup/utils/FilterTransactionUtils';
import { TrackEventNames } from '../../constants/trackEventNames';

export type FilterTransactionFields = Pick<
  FilterCommonFields,
  | 'status'
  | 'duration'
  | 'startDate'
  | 'endDate'
  | 'transactionId'
  | 'orderId'
  | 'paymentMode'
  | 'paymentPartner'
  | 'invoiceId'
  | 'mobile'
  | 'subMerchantId'
  | 'singleSubMerchantId'
  | 'email'
  | 'currency'
  | 'cardNo'
  | 'bankName'
  | 'walletName'
  | 'upiId'
  | 'minAmount'
  | 'maxAmount'
  | 'settlementId'
  | 'customAttributes'
  | 'utr'
>;

export const defaultTransactionFilters: FilterTransactionFields = {
  // @ts-ignore
  ...structuredClone(defaultCommonFilters),
};

const transactionSchema = object().shape({
  ...commonSchema,
  transactionId: string(),
  paymentMode: array().of(object().shape({ label: string(), value: string() })),
  paymentPartner: array().of(
    object().shape({ label: string(), value: string() })
  ),
  cardNo: string(),
  bankName: array().of(object().shape({ label: string(), value: string() })),
  walletName: array().of(object().shape({ label: string(), value: string() })),
  upiId: string(),
  settlementId: string(),
  utr: string(),
});

const useFilterTransaction = () => {
  const { track } = useTracker();
  const { checkSessionError } = useCurrentSession();

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [metaData, setMetaData] = useState<MetaData>(new MetaData());
  const [page, setPage] = useState(1);

  const form = useForm<FilterTransactionFields>({
    defaultValues: defaultTransactionFilters,
    resolver: yupResolver(transactionSchema),
  });

  const fetchNextPage = async () => {
    if (page >= (metaData?.total_pages ?? 1)) {
      console.log('max number of pages reached');
      return;
    }

    const { transactions } =
      (await fetchTransactions({ ...form.getValues(), page: page + 1 })) ?? {};

    setTransactions((prev) => [...prev, ...transactions]);
    setPage(page + 1);
  };

  const removeFilter = async (filterName = '') => {
    const formData = form.getValues();
    const filters = FilterTransactionUtils.removeParams(formData, filterName);
    form.reset(filters);

    await handleFormSubmit(filters);
  };

  const fetchTransactions = useCallback(
    async (formData: FilterTransactionFields & { page?: number }) => {
      const { page, ...restFormData } = formData;
      const { filters, filterSection } = FilterTransactionUtils.generateParams(
        restFormData,
        page ? { page } : undefined,
        form.setValue
      );

      const response = await FilterTransactionUtils.getTransactions(filters);

      // check for user token
      if (checkSessionError(response)) return;

      const transactions = response.data?.result?.result ?? [];
      const metaData = response.data?.result?.meta ?? new MetaData();

      const error = response.data?.result?.error?.message;
      const rows = metaData?.total_records;
      const pages = metaData?.total_pages;

      track({
        name: 'Transactions' + TrackEventNames.searchClicked,
        inBuiltOptions: ['merchantId', 'merchantName'],
        additionalOptions: {
          menu_item_name: 'Transactions',
          // @ts-ignore
          filters: filterSection,

          ...(error
            ? { failure_error_message: error }
            : {
                number_of_rows: rows,
                number_of_pages: pages,
              }),
        },
      });

      return { transactions, filterSection, filters, metaData };
    },
    [checkSessionError, track]
  );

  const handleFormSubmit = useCallback(
    async (formData: FilterTransactionFields & { page?: number }) => {
      setLoading(true);
      const { filterSection, transactions, metaData } =
        (await fetchTransactions(formData)) ?? {};

      setTransactions(transactions);
      setMetaData(metaData);
      setFilters(filterSection);
      setLoading(false);
      setFilterModalShown(false);
    },
    [fetchTransactions]
  );

  return {
    filters,
    form,
    loading,
    metaData,
    transactions,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    setLoading,
    removeFilter,
  };
};

export default useFilterTransaction;
