import { Link, useNavigate } from 'react-router-dom';
import logo from './../../images/logo.svg';
import mailIcon from './../../images/passwordSuccess.svg';
import LoginBanner from './../../images/loginBanner.png';
import { Button } from 'nimbbl-quark';

export const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/');
  };
  return (
    <div className='flex justify-between'>
      <div className='relative flex justify-center flex-1 mt-8'>
        <div className='flex-col justify-start'>
          <img src={logo} alt='logo' className='mb-16' />

          <img src={mailIcon} alt='mail' className='mb-4' />
          <h3 className='flex mb-2 font-bold text-left justify-items-start'>
            Password Reset
          </h3>
          <p className='mb-6 text-md text-secondary-10'>
            Your password has been successfully reset, <br />
            Click below to login
          </p>

          <Button
            text='Continue to login'
            type='submit'
            attributes={{
              style: {
                width: '160px',
                marginTop: '20px',
                background: '#045CE0',
              },
              onClick: () => handleBackToLogin(),
            }}
          />
        </div>
        <div className='w-full text-center sm:absolute bottom-12 text-tertiary-10'>
          <span className='mr-4'>@Nimbbl</span>
          <Link className='mr-4' to='/contact'>
            Contact
          </Link>
          <Link className='mr-4' to='/contact'>
            Privacy Terms
          </Link>
        </div>
      </div>
      <div className='flex items-center justify-center flex-1'>
        <img src={LoginBanner} alt='banner' />
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
