/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import {
  Badge,
  Button,
  Collapsible,
  CollapsibleButton,
  Drawer,
  KeyValue,
  Table,
  TableColumnType,
} from "nimbbl-quark";
import { useEffect, useMemo, useRef, useState } from "react";
import { getIssuerBadgeType } from "../../Configs/confgs";
import {
  APIResponse,
  OrderRes,
  TransactionTable,
} from "../../interfaces/ApiResponse";
import { IStateData } from "../../interfaces/StateData";
import HeaderComponent from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import {
  FiColumns,
  FiCreditCard,
  FiInfo,
  FiRepeat,
  FiRotateCw,
} from "react-icons/fi";
import { BiMessageRoundedError, BiRupee } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";

import constants from "../../constants";
import TransactionCardModule from "./TransactionCardModule";
import FilterSection from "../FilterSection/FilterSection";

import { RiFileCopy2Line } from "react-icons/ri";
import { Skeleton, TableCell, TableRow } from "@mui/material";
import { DrawerTableRow, formatDate, TableDivider } from "../Orders/Orders";
import { RefundModal } from "../Refunds/RefundModal";
import {
  useCommonTracker,
  useCsvExport,
  useCurrentSession,
  useTracker,
} from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";
import { PriceUtil } from "../../Utils";
import { formateDateToDDMonYYYY } from "../../Apis";
import { HandlePaymentPartner } from "../../Utils/PaymentPartnerHandling";
import useFilterTransaction, {
  defaultTransactionFilters,
} from "./useFilterTransaction";
import FilterSection2 from "../FilterSection/FilterSection2";
import FilterTransactionUtils from "../FilterPopup/utils/FilterTransactionUtils";
import isEqual from "lodash.isequal";
import FilterTransactions from "../FilterPopup/FilterTransactions";
import { useSearchParams } from "react-router-dom";
import FieldNames from "../../constants/FieldNames";
import InfoIcon from "../../images/infoIcon.svg";
const rowsPerPage = 60;

interface AmountDetailsProps {
  data: { [key: string]: string }[];
  original_currency: string;
}

const defaultDetails = {
  convenience_fee: "0 INR",
  order_amount: "0 INR",
  transaction_amount: "0 INR",
  offer_discount: "0 INR",
  currency: undefined,
  amount_before_tax: 0,
  tax: 0,
};
const AmountDetails = (props: AmountDetailsProps) => {
  const { data } = props;
  const [details, setDetails] = useState(defaultDetails);

  useEffect(() => {
    let temp = { ...defaultDetails };
    data.forEach((item) => {
      temp = {
        ...temp,
        ...item,
        ...{ original_currency: props.original_currency },
      };
    });
    setDetails(temp);
  }, [data]);

  const {
    currency,
    convenience_fee,
    order_amount,
    transaction_amount,
    offer_discount,
    amount_before_tax,
    tax,
  } = details;
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <DrawerTableRow
          label="Amount before tax"
          value={PriceUtil.formatCurrency(amount_before_tax, currency as any)}
        />
        <DrawerTableRow
          label="Tax"
          value={"+ " + PriceUtil.formatCurrency(tax, currency as any)}
        />
        <tr>
          <td colSpan={2}>
            <TableDivider />
          </td>
        </tr>
        <DrawerTableRow label="Order Amount" value={order_amount} />
        <DrawerTableRow label="Discount" value={"- " + offer_discount} />
        <DrawerTableRow
          label="Convenience Fee"
          value={"+ " + convenience_fee}
        />

        <tr>
          <td colSpan={2}>
            <TableDivider />
          </td>
        </tr>

        {/* use net-order-amount key name */}
        <DrawerTableRow
          label="Net Order Amount"
          value={transaction_amount}
          // info='This is Transaction Amount before Conversion'
        />

        <tr>
          <td colSpan={2}>
            <TableDivider />
          </td>
        </tr>

        <DrawerTableRow
          // insert actual conversion rate here
          // info='Amount after Conversion. Exchange rate: {get_exchange_rate_from_backend}'
          label="Transaction Amount"
          value={transaction_amount}
        />
      </tbody>
    </table>
  );
};

const Transactions = () => {
  const { track } = useTracker();
  const {
    trackItemClicked,
    trackPageRendered,
    trackPopupRendered,
    trackRowSelected,
  } = useCommonTracker();
  const [singleTransactionData, setTransactionData] = useState<any>({});
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>([]);
  const [copyText, setCopyText] = useState<boolean>(false);
  // Filter related stuffs
  const [animatedRight, setAnimateRight] = useState<boolean>(false);
  const [animatedRightRefund, setAnimateRightRefund] = useState<boolean>(false);
  const [isRefundModal, setIsRefundModal] = useState<boolean>(false);
  const is_admin = window.localStorage.getItem("nimbbl_admin");
  const [searchParams] = useSearchParams();

  // filter v2
  const {
    filters,
    form,
    loading,
    metaData,
    transactions,
    page,
    isFilterModalShown,
    setFilterModalShown,
    handleFormSubmit,
    fetchNextPage,
    removeFilter,
  } = useFilterTransaction();

  useEffect(() => {
    const tid = searchParams.get("tid");

    if (tid) {
      form.setValue("transactionId", tid);
      handleFormSubmit(form.getValues());
    } else {
      handleFormSubmit(defaultTransactionFilters);
    }
  }, [searchParams, form]);

  const toggle = () => {
    setAnimateRightRefund(true);
  };

  const { checkSessionError, hasSession, setSessionExpired } =
    useCurrentSession();

  const toggleClose = () => {
    setAnimateRightRefund(false);
  };

  useEffect(() => {
    trackPageRendered("Transactions", {
      ...filters,
      ...metaData,
    });

    return () => {
      trackPageRendered.cancel();
    };
  }, [metaData, filters]);

  useEffect(() => {
    if (!animatedRight) setIsShown(true);
  }, [animatedRight]);

  useEffect(() => {
    if (animatedRightRefund) setIsModalShown(true);
  }, [animatedRightRefund]);

  const getSingleTransaction = async (id: number) => {
    try {
      const token = window.localStorage.getItem("nimbbl_session_id");

      if (!hasSession()) {
        return setSessionExpired();
      }

      const res = await axios.post<APIResponse<OrderRes>>(
        `${constants.API.TRANSACTION_API}?session_id=${token}`,
        {
          params: {
            filter: `[[\"id\",\"=\",\"${id}\"]]`,
            query:
              "{wallet_name,total_amount,bank_name,upi_flow,is_settled,psp_generated_txn_id,direct_payment_mode,sub_merchant_id{sub_merchant_id, description},settlement_log{settlement_date,utr_no,partner_settement_id,payment_partner,settled_amount,settlement_currency{id,name}},status,create_date,transaction_id,card_type,nimbbl_consumer_message,nimbbl_merchant_message,nimbbl_error_code,vpa_holder,network,vpa_app_name,payment_mode,transaction_type,payment_partner,refund_done,refund_amount,vpa_id,masked_card,holder_name,expiry,issuer,network,order_id{additional_charges,available_refundable_amount,                original_currency,original_total_amount,currency,grand_total_amount,id,order_id,order_date,offer_discount,total_amount,amount_before_tax,transaction_items{transaction_id, status,refund_amount, refund_done, transaction_type,payment_mode, orignal_payment_transaction_id{transaction_id}},order_custom_attributes{key,value},tax,invoice_id,user_id{email,mobile_number,country_code}}}",
          },
        }
      );

      if (checkSessionError(res)) {
        return;
      }

      if (res.data) {
        setTransactionData(res.data.result.result[0]);
      } else {
        setTransactionData([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedRow = (data: [], currentPage: number) => {
    const sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];
    setCsvData(sliceData);
  };

  const getSelectedExport = () => {
    const transactionIds: string[] = csvData.map(
      (item: any) => item.transaction_id
    );
    exportAll(transactionIds, "transaction_id");
  };

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector("tr.selected-row")
        ?.classList.remove("selected-row");
    }
  }, [isShown]);

  const renderRowData = (e: any, data: any) => {
    trackItemClicked("Transactions", { transaction_id: data?.transaction_id });
    setAnimateRight(true);
    getSingleTransaction(data.id);
    document.querySelector("tr.selected-row")?.classList.remove("selected-row");
    e.currentTarget.closest("tr").classList.add("selected-row");
    setFilterModalShown(false);
    trackPopupRendered("Transaction", {
      transaction_id: data?.transaction_id,
    });
  };

  const onClose = (transactionId: string) => {
    setAnimateRight(false);
    setAnimateRightRefund(false);
    // trackTransactionPopupClosed(transactionId);
    track({
      name: TrackEventNames.transactionPopupClosed,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { transaction_id: transactionId },
    });
  };

  const columns: TableColumnType<TransactionTable>[] = [
    {
      title: FieldNames.transactionId,
      field: "transaction_id",
      cellStyle: {
        color: "#0352C9",
        // fontWeight: 'bold',
        textDecoration: "none",
      },
      sorting: false,
      render: (rowData) => (
        <a
          className="flex"
          style={{ cursor: "pointer" }}
          onClick={(e) => renderRowData(e, rowData)}
        >
          {rowData.transaction_id}{" "}
          {rowData?.refund_done && (
            <span className="ml-4">
              <FiRepeat />
            </span>
          )}
        </a>
      ),
    },
    {
      title: "Transaction date",
      field: "create_date",
      sorting: false,
      render: (rowData) => (
        <span style={{ width: "110px" }}>
          {formatDate(rowData.create_date)}
        </span>
      ),
    },
    {
      title: "Transaction Amount",
      field: "amount",
      sorting: false,
      render: (rowData) => {
        return PriceUtil.formatCurrency(
          rowData?.order_id?.grand_total_amount,
          rowData?.order_id?.currency as any
        );
      },
    },
    {
      title: FieldNames.mobile,
      field: "mobile",
      sorting: false,
      render: (rowData) => rowData?.order_id?.user_id?.mobile_number,
    },
    {
      cellStyle: {
        maxWidth: 180,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      title: "invoice",
      field: "invoice",
      sorting: false,
      render: (rowData) => rowData?.order_id?.invoice_id,
    },
    {
      title: "Payment mode",
      field: "payment_mode",
      sorting: false,
      render: (rowData) => rowData.payment_mode,
    },
    {
      title: FieldNames.status,
      field: "status",
      sorting: false,
      render: (rowData) => (
        <span className="capitalize">
          <Badge
            variant="accent"
            {...getIssuerBadgeType(rowData.status)}
          ></Badge>
        </span>
      ),
    },
  ];

  const transactionObj: any = {
    transaction_details: [
      {
        transaction_amount: PriceUtil.formatCurrency(
          singleTransactionData?.order_id?.grand_total_amount,
          singleTransactionData?.order_id?.currency
        ),
      },
      {
        original_order_amount:
          singleTransactionData?.order_id?.original_total_amount !== 0
            ? PriceUtil.formatCurrency(
                singleTransactionData?.order_id?.original_total_amount,
                singleTransactionData?.order_id?.original_currency
              )
            : "",
      },
      { transaction_date: formatDate(singleTransactionData?.create_date) },
      { invoice_id: singleTransactionData?.order_id?.invoice_id },
      {
        mobile: `${singleTransactionData?.order_id?.user_id?.country_code}-${singleTransactionData?.order_id?.user_id?.mobile_number}`,
      },
      { payment_mode: singleTransactionData?.payment_mode },
      {
        payment_partner: HandlePaymentPartner.getPaymentPartner(
          singleTransactionData?.payment_partner
        ),
      },
      { submerchant: singleTransactionData?.sub_merchant_id?.description },
      {
        submerchant_id: singleTransactionData?.sub_merchant_id?.sub_merchant_id,
      },
      { psp_transaction_id: singleTransactionData?.psp_generated_txn_id },
    ],
    payment_details: [
      { card_issuer: singleTransactionData?.issuer },
      { card_network: singleTransactionData?.network },
      { card_type: singleTransactionData?.card_type },
      { card_number: singleTransactionData?.masked_card },
      { card_expiry: singleTransactionData?.expiry },
      { card_holder: singleTransactionData?.holder_name },
      { wallet_name: singleTransactionData?.wallet_name },
      { upi_id: singleTransactionData?.vpa_id },
      { account_holder: singleTransactionData?.vpa_holder },
      { upi_app: singleTransactionData?.vpa_app_name },
      { payment_mode: singleTransactionData?.payment_mode },
      { upi_flow: singleTransactionData?.upi_flow },
      { bank_name: singleTransactionData?.bank_name },
      { sub_payment_mode: singleTransactionData?.direct_payment_mode },
    ],
    amount_details: [
      {
        order_amount: PriceUtil.formatCurrency(
          singleTransactionData?.order_id?.total_amount || 0,
          singleTransactionData?.order_id?.currency
        ),
      },
      { original_currency: singleTransactionData?.order_id?.original_currency },
      { amount_before_tax: singleTransactionData?.order_id?.amount_before_tax },
      {
        available_refundable_amount:
          singleTransactionData?.order_id?.available_refundable_amount,
      },
      { tax: singleTransactionData?.order_id?.tax },
      {
        offer_discount: PriceUtil.formatCurrency(
          singleTransactionData?.order_id?.offer_discount || 0,
          singleTransactionData?.order_id?.currency
        ),
      },
      {
        convenience_fee: PriceUtil.formatCurrency(
          singleTransactionData?.order_id?.additional_charges || 0,
          singleTransactionData?.order_id?.currency
        ),
      },
      {
        transaction_amount: PriceUtil.formatCurrency(
          singleTransactionData?.order_id?.grand_total_amount || 0,
          singleTransactionData?.order_id?.currency
        ),
      },
    ],
    settlement_details: [
      {
        psp_settlement_id:
          singleTransactionData?.settlement_log?.partner_settement_id,
      },
      {
        settlement_date: formateDateToDDMonYYYY(
          singleTransactionData?.settlement_log?.settlement_date
        ),
      },
      {
        settlement_amount: PriceUtil.formatCurrency(
          singleTransactionData?.is_settled
            ? singleTransactionData?.settlement_log?.settled_amount
            : 0,
          singleTransactionData?.settlement_log?.settlement_currency?.name
        ),
      },
      { utr_number: singleTransactionData?.settlement_log?.utr_no },
    ],

    // only render custom attributes when it is present for the current transaction
    ...(singleTransactionData?.order_id?.order_custom_attributes.length > 0 && {
      custom_attributes: [
        ...singleTransactionData?.order_id?.order_custom_attributes?.map(
          ({ key, value }: { key: string; value: string }) => ({ [key]: value })
        ),
      ],
    }),
  };

  let failDetails: any = [];

  interface IMerchantErrorMsg {
    error_reason?: string;
  }

  interface ICustomerErrorMsg {
    customer_message?: string;
  }

  interface IErrorCode {
    error_code: string;
  }

  if (singleTransactionData?.nimbbl_merchant_message) {
    let obj: IMerchantErrorMsg = {
      error_reason: singleTransactionData?.nimbbl_merchant_message,
    };
    failDetails.push(obj);
  }

  if (
    singleTransactionData?.nimbbl_consumer_message &&
    singleTransactionData?.status !== "succeeded" &&
    singleTransactionData?.status !== "pending"
  ) {
    let obj: ICustomerErrorMsg = {
      customer_message: singleTransactionData?.nimbbl_consumer_message,
    };
    failDetails.push(obj);
  }

  if (singleTransactionData?.nimbbl_error_code) {
    let obj: IErrorCode = {
      error_code: singleTransactionData?.nimbbl_error_code,
    };
    failDetails.push(obj);
  }

  if (failDetails.length > 0) {
    Object.assign(transactionObj, { failure_details: failDetails });
  }

  let icon = [
    <FiRotateCw key={1} />,
    <FiCreditCard key={2} />,
    <BiRupee key={3} />,
    <BsCheck2Circle key={4} />,
    <FiInfo key={5} />,
    <BiMessageRoundedError key={6} />,
    // <FiInfo />,
  ];

  const getKeyValueObjMap = (dataArray: [], data: string) => {
    let msgNull = [];
    if (data === "settlement_details" && !singleTransactionData?.is_settled) {
      msgNull.push(<span className="text-sm">Transaction is not settled</span>);
      return msgNull;
    }

    return dataArray.map((item: any, index: number) => {
      let pointer = index + 1;
      let val: any = (Object.values(item) && Object.values(item)[0]) || "";
      let lab: any =
        Object.keys(item) && Object.keys(item)[0].replace(/\_/g, " ");

      if (val === "") return <div className="clear-both"></div>;

      if (lab.includes("upi")) {
        lab = lab.replace("upi", "UPI");
      }
      if (lab.includes("utr")) {
        lab = lab.replace("utr", "UTR");
      }
      if (lab.includes("psp")) {
        lab = lab.replace("psp", "PSP");
      }
      if (lab.includes("id")) {
        lab = lab.replace("id", "Id");
      }
      let msg = [];

      const isLastElement = index === data.length - 1;
      const isSecondLastElement = index === data.length - 2;

      msg.push(
        <KeyValue
          className={
            pointer % 2 === 0 ? "keyContainerWoMargin" : "keyContainer"
          }
          styleContainer={{
            width: "48%",
            marginBottom: isLastElement || isSecondLastElement ? 0 : 16,
          }}
          label={lab}
          labelStyle={{ textTransform: "capitalize" }}
          value={val}
          valueStyle={{
            overflowWrap: "break-word",
            textTransform:
              lab.includes("card type") || lab.includes("card holder")
                ? "capitalize"
                : "inherit",
          }}
        />
      );
      if (pointer % 2 === 0) {
        msg.push(<div className="clear-both"></div>);
      }
      if (dataArray.length === pointer) {
        msg.push(<div className="clear-both"></div>);
      }

      return msg;
    });
  };

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  const copyTextClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopyText(true);
    track({
      name: TrackEventNames.transactionIdCopied,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { transaction_id: data },
    });
  };

  const getRefundsCard = (data: any) => (
    <TransactionCardModule
      transaction_data={data}
      order_amount={singleTransactionData?.order_id?.grand_total_amount}
      currency={singleTransactionData?.order_id?.currency}
      transactionCardType="transactions"
    />
  );

  const skeletonArray = Array(10).fill("");

  const tableRef = useRef<any>();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllExportExport = () => {
    exportAll();
    clearSelection();
  };
  const getCollapsible = (data: any, index: number) => {
    if (
      data === "payment_details" &&
      (singleTransactionData?.payment_mode === "Simpl" ||
        singleTransactionData?.payment_mode === "Olamoney" ||
        singleTransactionData?.payment_mode === "Lazypay" ||
        singleTransactionData?.payment_mode === "ICICI PayLater" ||
        singleTransactionData?.payment_mode === "Freecharge")
    ) {
      return;
    }

    if (
      data === "payment_details" &&
      singleTransactionData?.payment_mode === "PayTM" &&
      !singleTransactionData?.direct_payment_mode
    ) {
      return;
    }

    return (
      <Collapsible
        open={index === 0 ? false : true}
        title={
          <h6 className="capitalize font-weight-bold">
            {data.replace(/\_/g, " ")}
          </h6>
        }
        iconColor="#0352C9"
        isBorder={true}
        icon={icon[index]}
        style={{ borderBottom: "1px solid #ccc" }}
      >
        <div
          className="p-4 rounded-lg bg-blue-10"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {data === "amount_details" ? (
            <AmountDetails
              data={transactionObj[data]}
              original_currency={
                singleTransactionData?.order_id?.original_currency
              }
            />
          ) : (
            getKeyValueObjMap(transactionObj[data], data)
          )}
        </div>
        {singleTransactionData?.order_id?.original_currency &&
          data === "amount_details" && (
            <div className="p-4 rounded-lg bg-blue-10 mt-4">
              <div className="flex items-start">
                <img src={InfoIcon} alt="" />
                <p className="text-[#6C7F9A] text-sm ml-4">
                  An exchange rate of&nbsp;
                  <span className="text-black">
                    1.00 {singleTransactionData?.order_id?.original_currency}{" "}
                  </span>
                  ={" "}
                  <span className="text-black">
                    {singleTransactionData?.order_id?.exchange_rate_used}{" "}
                    {singleTransactionData?.order_id?.currency}
                  </span>
                  &nbsp; was applied when changing the currency from&nbsp;
                  <span className="text-black">
                    {singleTransactionData?.order_id?.original_currency}
                  </span>{" "}
                  to&nbsp;
                  <span className="text-black">
                    {singleTransactionData?.order_id?.currency}
                  </span>
                </p>
              </div>
            </div>
          )}
      </Collapsible>
    );
  };

  const { exportAll, loading: csvLoading } = useCsvExport(
    "Transactions",
    form.watch()
  );

  const refundTransactions = useMemo(() => {
    const id = singleTransactionData.transaction_id;

    return singleTransactionData?.order_id?.transaction_items?.filter(
      (item: any) =>
        item.transaction_id !== id &&
        item.transaction_type !== "payment" &&
        item.status === "succeeded"
    );
  }, [singleTransactionData]);

  return (
    <div>
      <Sidebar />
      <div className="p-4 mb-8 body-container ml-60">
        <HeaderComponent
          loading={csvLoading}
          page="transactions"
          title="Transactions"
          total={metaData?.total_records}
          handleExport={getSelectedExport}
          handleExportAll={getAllExportExport}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          type="transaction"
          selectedRows={csvData?.length || 0}
        />

        <FilterSection2
          defaultFilters={defaultTransactionFilters}
          filters={filters}
          pageName="transaction"
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterTransactionUtils.generateParams(defaultTransactionFilters)
                .filterSection,
              filters
            )
          }
        />

        {loading ? (
          <div className="w-full">
            <table className="w-full">
              {skeletonArray.map((_item, index) => (
                <TableRow key={index} style={{ width: "100%" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: "20%" }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}{" "}
            </table>
          </div>
        ) : (
          <div style={{ overflow: "auto" }} className="mt-6">
            <Table
              data={transactions}
              columns={columns}
              // filtering
              search={false}
              handleSearchChange={() => ({})}
              selection={true}
              // handleSelectionChange={(data) => getSelectedRow(data)}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    "No records fetched for your search criteria, please change the filters and search again",
                },
              }}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(transactions.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total transactions is still less than total records
                    transactions.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  getSelectedRow(rows, page + 1);
                  setCsvData(rows);
                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected("Transactions", {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: 20,
                draggable: false,
              }}
            />
          </div>
        )}
      </div>

      <Drawer
        position="right"
        hide={() => onClose(singleTransactionData.transaction_id)}
        isShown={isShown}
        style={{ width: "400px" }}
        className={
          animatedRight
            ? "animation drawerAnimationRightShow"
            : "animation drawerAnimationRightHide"
        }
        headerElement={
          <div>
            <div className="flex items-center justify-start mb-2 text-base">
              {singleTransactionData?.transaction_id}{" "}
              {!copyText ? (
                <span
                  className="ml-4 text-lg cursor-pointer text-blue-40"
                  title={`Copy ${FieldNames.transactionId}`}
                  onClick={() =>
                    copyTextClipboard(singleTransactionData.transaction_id)
                  }
                >
                  {" "}
                  <RiFileCopy2Line />
                </span>
              ) : (
                <p className="copyToClipboard">copied to clipboard</p>
              )}
            </div>
            <span className="capitalize">
              <Badge
                variant="accent"
                {...getIssuerBadgeType(singleTransactionData?.status)}
              ></Badge>
            </span>
          </div>
        }
        headerStyle={{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <>
            <div
              className="p-4 no-scroll icon-font"
              style={{ overflowY: "scroll", maxHeight: "calc(100vh - 100px)" }}
            >
              {Object.keys(transactionObj).map((data: any, index: number) => {
                return getCollapsible(data, index);
              })}
              {/* singleTransactionData?.refund_done && */}
              {Array.isArray(refundTransactions) &&
                // @ts-ignore
                refundTransactions.length > 0 &&
                /(succeeded)|(reversed)/.test(
                  singleTransactionData?.status?.toLowerCase()
                ) && (
                  <div className="mt-4">
                    <CollapsibleButton
                      text={`Refunds (${refundTransactions.length})`}
                      iconRight={<FiColumns />}
                      iconLeft={<FiRepeat />}
                      style={{ width: "100%", background: "#F1F4F9" }}
                      iconLeftStyle={{ color: "#6C7F9A", fontSize: "18px" }}
                      iconRightStyle={{ color: "#045CE0", fontSize: "18px" }}
                      onClick={toggle}
                    ></CollapsibleButton>
                  </div>
                )}
              {is_admin &&
                /(succeeded)|(reversed)/.test(
                  singleTransactionData?.status?.toLowerCase()
                ) &&
                !singleTransactionData?.refund_done &&
                singleTransactionData?.transaction_type !== "full-refund" && (
                  // Array.isArray(relatedTransactions) &&
                  // relatedTransactions.length > 0 &&
                  <>
                    <div className="mt-4">
                      <Button
                        variant="primary"
                        text="Issue Refund"
                        attributes={{
                          onClick: () => setIsRefundModal(true),
                        }}
                      ></Button>
                    </div>
                  </>
                )}
              {isRefundModal && (
                <RefundModal
                  transaction_data={singleTransactionData}
                  order_amount={singleTransactionData?.order_id?.order_amount}
                  transactionCardType="transactions"
                  hideModal={() => setIsRefundModal(false)}
                />
              )}
            </div>
          </>
        }
      />

      <FilterTransactions
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />

      <Drawer
        position="right"
        hide={toggleClose}
        isShown={isModalShown}
        style={{ width: "400px" }}
        className={
          animatedRightRefund
            ? "animation drawer2AnimationRightShow"
            : "animation drawerAnimationRightHide"
        }
        headerElement={
          <div>
            <h1 className="mb-2 text-base">Transactions</h1>
            {/* <Badge type={getIssuerBadgeType(orderData.status)} ></Badge> */}
          </div>
        }
        headerStyle={{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <div
            className="p-4 no-scroll icon-font"
            style={{ overflowY: "scroll", maxHeight: "calc(100vh - 100px)" }}
          >
            {Array.isArray(refundTransactions) &&
              refundTransactions.length > 0 &&
              refundTransactions.map((data: any) => {
                return getRefundsCard(data);
              })}
          </div>
        }
      />
    </div>
  );
};

export default Transactions;
