import { Skeleton, TableCell, TableCellProps, TableRow } from '@mui/material';

const skeletonArray = Array.from(Array(10).keys());
const columnCount = Array.from(Array(5).keys());

interface CellProps {
  component?: TableCellProps['component'];
}

const Cell = (props: CellProps) => {
  const { component = 'td' } = props;

  return (
    <TableCell component={component} scope='row' style={{ width: '20%' }}>
      <Skeleton />
    </TableCell>
  );
};

const TableSkeleton = () => {
  return (
    <div className='w-full mt-2'>
      <table className='w-full'>
        {skeletonArray.map((item) => (
          <TableRow key={item} style={{ width: '100%' }}>
            <Cell component='th' />

            {columnCount.map((item) => (
              <Cell key={item} />
            ))}
          </TableRow>
        ))}
      </table>
    </div>
  );
};

export default TableSkeleton;
