import { Zoom } from '@mui/material';
import { Alert as QuarkAlert } from 'nimbbl-quark';
import { useCallback, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { useGlobalStore } from '../../Context/GlobalStore';
import { ActionTypes } from '../../Context/reducer';

const defaultErrorMessage = 'Something went wrong';

export const Alert = () => {
  const { error, errorType, dispatch } = useGlobalStore();

  const handleClose = useCallback(
    () =>
      dispatch({
        type: ActionTypes.setErrorMessage,
        payload: { message: '', type: 'success' },
      }),
    [dispatch]
  );

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (error) {
      timerId = setTimeout(() => handleClose(), 5000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [error, dispatch, handleClose]);

  return (
    <Zoom in={!!error}>
      <div
        style={{
          position: 'absolute',
          top: 32,
          left: '50%',
          translate: '-50%',
          zIndex: 2000,
        }}>
        <QuarkAlert
          variant={errorType || 'danger'}
          onClose={handleClose}
          bannerWithCta={false}
          description={error || defaultErrorMessage}
          size='md'
          icon={<IoAlertCircleOutline size={20} />}
          closeIcon={<FiX size={20} />}
          closable={true}
        />
      </div>
    </Zoom>
  );
};
