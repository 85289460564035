import { InputField } from 'nimbbl-quark';
import { FC } from 'react';

interface TextFieldProps {
  label: string;
  value: string;
  type?: 'text' | 'number' | 'email' | 'tel';
  id?: string;
  placeHolder?: string;
  onChange: (value: string) => void;
  error?: string;
}

const TextField: FC<TextFieldProps> = (props) => {
  const { label, value, placeHolder, id = '', onChange, type, error } = props;

  return (
    <div className='mt-6 form-input filterField'>
      <InputField
        type='text'
        id={id}
        attributes={{
          ...(type && { type }),
          value,
          onChange: (e) => onChange(e.currentTarget.value),
        }}
        label={label}
        placeholder={placeHolder}
        {...(error && {
          caption: error,
          captionType: 'error',
        })}
      />
    </div>
  );
};

export default TextField;
