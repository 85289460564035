import { Options, SeriesOptionsType } from 'highcharts';
import moment from 'moment';
import { PriceUtil } from '../../../Utils';
import { AnalyticsResponse } from '../AnalyticsResponse';

const sortDate = (date1: string, date2: string) => {
  let date1iso: string = date1;
  let date2iso: string = date2;
  const weekRegex = /week/i;
  const dateStringRegex = /\d{2}[a-z]{3} \d{2}/i;
  const yearStringRegex = /[a-z]{3}\d{2}/i;

  if (weekRegex.test(date1)) {
    const [dateString] = date1.match(dateStringRegex) || [];
    if (dateString) date1iso = moment(dateString).toISOString();
  } else if (yearStringRegex.test(date1)) {
    const [yearString] = date1.match(yearStringRegex) || [];
    if (yearString) date1iso = moment('01' + yearString).toISOString();
  }

  if (weekRegex.test(date2)) {
    const [dateString] = date2.match(dateStringRegex) || [];
    if (dateString) date2iso = moment(dateString).toISOString();
  } else if (yearStringRegex.test(date2)) {
    const [yearString] = date2.match(yearStringRegex) || [];
    if (yearString) date2iso = moment('01' + yearString).toISOString();
  }

  const value1 = moment(date1iso, 'DD MMM YYYY');
  const value2 = moment(date2iso, 'DD MMM YYYY');

  return value1.diff(value2, 'days');
};

const formatNumber = (inputNumber: number) =>
  Intl.NumberFormat('en-IN', {
    notation: 'compact',
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(inputNumber);
// .replace(/\D00$/, '');

interface OptionProps {
  categories: string[];
  filters: Record<'currency' | 'summaryView', string>;
  series: SeriesOptionsType[];
}

export class TransactionSummaryUtil {
  static getData(inputData: AnalyticsResponse['transaction_summary']) {
    const { count, amount, ...restTransactionSummary } = inputData || {};

    const xAxisCategories = Object.keys(restTransactionSummary).sort(sortDate);

    const rawData = xAxisCategories.map((key) => {
      const {
        'auto-reversed_amount': reversed_amount = 0,
        'auto-reversed_count': reversed_count = 0,
        failed_amount = 0,
        failed_count = 0,
        pending_amount = 0,
        pending_count = 0,
        succeeded_amount = 0,
        succeeded_count = 0,
        amount,
        count,
      } = restTransactionSummary[key];

      return {
        amount,
        count,
        reversed_amount,
        reversed_count,
        failed_amount,
        failed_count,
        pending_amount,
        pending_count,
        succeeded_amount,
        succeeded_count,
      };
    });

    return { xAxisCategories, rawData };
  }

  static getOptions(props: OptionProps): Options {
    const { categories, filters, series } = props;
    const { currency, summaryView } = filters;
    const isAmount = summaryView === 'amount';

    return {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories,
        title: { text: 'Date Range' },
      },
      yAxis: {
        title: {
          text: isAmount ? 'Amount' : 'Count',
        },
        labels: {
          enabled: true,
          formatter: function () {
            return formatNumber(+this.value);
          },
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'gray',
            textOutline: 'none',
          },
          formatter: function () {
            return formatNumber(this.total);
          },
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: 0,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
        title: {
          style: { fontWeight: 'normal' },
          text: 'Click to exclude status from the view',
        },
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            headerFormat: '<em><strong>{point.x}</strong></em><br/>',
            pointFormatter: function () {
              console.log(this);
              const value = isAmount
                ? PriceUtil.formatCurrency(this.y || 0, currency as any)
                : this.y;
              return `${this.series.name}: <strong>${value}</strong>`;
            },
          },
        },
      },
      series,
    };
  }
}
