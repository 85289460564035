import { useCallback, useEffect, useMemo } from 'react';
import {
  track as AmplitudeTrack,
  setUserId,
} from '@amplitude/analytics-browser';

type inBuiltOptions = 'merchantName' | 'merchantId' | 'merchantEmail';
type Trackers = 'Google' | 'New Relic' | 'Internal' | 'Samunnaya';

interface SelectedTrackers {
  google?: boolean;
  internal?: boolean;
  newRelic?: boolean;
  samunnaya?: boolean;
}

interface TrackParameters {
  name: string;
  inBuiltOptions?: inBuiltOptions[];
  additionalOptions?: { [key: string]: any };
  selectTrackers?: Trackers[];
  type?: string;
}

// const sendToGoogle = (eventName: string, eventData: Object) => {
//   // google analytics
//   window.analytics && window.analytics.track(eventName, eventData);
// };

const sendToNewRelic = (eventName: string, eventData: Object) => {
  // new relic event tracker
  window.newrelic && window.newrelic.addPageAction(eventName, eventData);
};

const sendToAmplitude = (eventName: string, eventData: Object) => {
  // amplitude tracker
  AmplitudeTrack(eventName, eventData);
};

const sendToSamunnaya = (
  eventName: string,
  type: string,
  eventData: Object
) => {
  window?.$ns && window.$ns({ name: eventName, data: { eventData } });
};

const checkValue = (value: string | null) => {
  if (!value) return null;
  return ['', 'undefined', 'null', 'false'].includes(value) ? null : value;
};

export const setAmplitudeId = () => {
  const merchantEmail = checkValue(localStorage.getItem('merchant_email'));
  const userEmail = checkValue(localStorage.getItem('nimbbl_user_email'));
  const id = merchantEmail || userEmail;

  if (id) {
    setUserId(id);
  } else {
    console.warn('Cannot set Amplitude UserId');
  }
};

export const useTracker = () => {
  const localData = useMemo(
    () => ({
      merchantName: localStorage.getItem('merchant_name') || '',
      merchantId: localStorage.getItem('merchant_id') || '',
      merchantEmail: localStorage.getItem('merchant_email') || '',
      userId: localStorage.getItem('nimbbl_user_id') || '',
      userName: localStorage.getItem('nimbbl_user_name') || '',
    }),
    []
  );

  useEffect(() => {
    setAmplitudeId();
  }, []);

  const getInBuiltOptions = useCallback(
    (options: inBuiltOptions[] | undefined) => {
      const result = {} as { [key: string]: string };

      if (!options) return {};

      options.forEach((option) => {
        switch (option) {
          case 'merchantId':
            result.merchant_id = localData.merchantId;
            break;
          case 'merchantName':
            result.merchant_name = localData.merchantName;
            break;
          case 'merchantEmail':
            result.merchant_email = localData.merchantEmail;
            break;
        }
      });

      return result;
    },
    [localData.merchantEmail, localData.merchantId, localData.merchantName]
  );

  const getSelectedTrackers = (
    trackers: Trackers[] | undefined
  ): SelectedTrackers => {
    const selectedTrackers: SelectedTrackers = {
      google: true,
      newRelic: true,
      internal: true,
      samunnaya: true,
    };

    if (trackers && trackers.length > 0) {
      return trackers.reduce((prev, cur) => {
        switch (cur) {
          case 'Google':
            prev['google'] = true;
            break;
          case 'Internal':
            prev['internal'] = true;
            break;
          case 'New Relic':
            prev['newRelic'] = true;
            break;
          case 'Samunnaya':
            prev['samunnaya'] = true;
        }
        return prev;
      }, {} as SelectedTrackers);
    }
    return selectedTrackers;
  };

  const track = useCallback(
    (props: TrackParameters) => {
      const {
        additionalOptions = {},
        inBuiltOptions = undefined,
        name,
        selectTrackers = undefined,
        type = 'click',
      } = props;

      // generate data to be sent
      const eventData = {
        ...getInBuiltOptions(inBuiltOptions),
        ...additionalOptions,
      };

      // get selected trackers
      const trackers = getSelectedTrackers(selectTrackers);
      const { google, internal, newRelic, samunnaya } = trackers;

      // console.log(name, eventData);
      // sending data to through trackers
      // if (google) {
      //   sendToGoogle(name, eventData);
      // }
      if (newRelic) {
        sendToNewRelic(name, eventData);
      }
      if (internal) {
        sendToAmplitude(name, eventData);
      }
      if (samunnaya) {
        sendToSamunnaya(name, type, eventData);
      }
    },
    [getInBuiltOptions]
  );

  return { track };
};
