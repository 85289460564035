import { BadgeType } from 'nimbbl-quark';

export enum IssuerStatus {
  INPROGRESS = 'pending',
  DONE = 'complete',
  NEW = 'new',
  FAILED = 'failed',
  SUCCESS = 'success',
}
export const getIssuerBadgeType = (
  status: string
): { type: BadgeType; text: string } => {
  switch (status) {
    case 'completed':
      return { type: 'Completed', text: 'Completed' };
    case 'failed':
      return { type: 'Failed', text: 'Failed' };
    case 'pending':
      return { type: 'Pending', text: 'Pending' };
    case 'succeeded':
      return { type: 'Succeeded', text: 'Succeeded' };
    case 'reversed':
      return { type: 'reversed', text: 'Reversed' };
    case 'reversing':
      return { type: 'Pending', text: 'Reversing' };
    case 'new':
    default:
      return { type: 'New', text: 'New' };
  }
};
