export class HandlePaymentPartner {
  static getPaymentPartner(val: string) {
    switch (val) {
      case "HdfcMpgs":
        return "HDFC MPGS";
      default:
        return val;
    }
  }
}
