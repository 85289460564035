import axios from 'axios';
import constants from '../../constants';
import { ApiResponse } from './apiKeysResponse';

interface FetchKeysProps {
  isProduction: boolean;
  filterParams?: { subMerchantId?: string };
  paramsOptions?: { page?: number };
}

export const fetchKeys = (params: FetchKeysProps) => {
  const apiUrl =
    constants.API.API_KEYS_API +
    '?session_id=' +
    localStorage.getItem('nimbbl_session_id');

  const { filterParams, isProduction, paramsOptions } = params;
  const { subMerchantId } = filterParams || {};

  const filters: any[] = [];

  if (subMerchantId) {
    filters.push(['sub_merchant_id.sub_merchant_id', '=', subMerchantId]);
  } else {
    const merchantId = localStorage.getItem('merchant_index_id');

    if (merchantId && merchantId !== 'undefined') {
      filters.push(['sub_merchant_id.merchant_id', '=', Number(merchantId)]);
    }
  }

  filters.push(['sub_merchant_id.is_sandbox', '=', !isProduction]);

  const query =
    '{access_key,access_secret,create_date,sub_merchant_id{sub_merchant_id,is_sandbox,description}}';

  const data = {
    params: {
      filter: JSON.stringify(filters),
      query,
      per_page: 30,
      ...(paramsOptions && { ...paramsOptions }),
    },
  };

  return axios.post<ApiResponse>(apiUrl, data);
};
