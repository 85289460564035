import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as amplitude from '@amplitude/analytics-browser';

const { NODE_ENV, REACT_APP_AMPLITUDE_DEV_KEY, REACT_APP_AMPLITUDE_PROD_KEY } =
  process.env;
const devEnvironment = NODE_ENV === 'development';

// initialize amplitude
const amplitudeKey = devEnvironment
  ? REACT_APP_AMPLITUDE_DEV_KEY
  : REACT_APP_AMPLITUDE_PROD_KEY;

if (amplitudeKey) {
  amplitude.init(amplitudeKey);
} else {
  console.log('Amplitude Key not defined');
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
