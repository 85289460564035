import React, { CSSProperties, FC, forwardRef, useMemo, useRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoCalendarClearOutline } from 'react-icons/io5';

type CaptionType = 'default' | 'error';

export interface DateFieldProps extends ReactDatePickerProps {
  containerStyles?: CSSProperties;
  containerClassName?: string;

  caption?: string;
  captionType?: CaptionType;
  captionStyles?: CSSProperties;
  captionClassName?: string;
}

const CustomInput = forwardRef((props: any, ref) => {
  const { className, disabled, style, inputRef, ...restProps } = props;

  return (
    <div style={{ position: 'relative' }}>
      <input
        disabled={disabled}
        className={`w-full p-3 font-normal border rounded border-primary-80 text-black-primary disabledInput ${className}`}
        {...restProps}
        ref={ref}
      />
      {!disabled && (
        <span
          style={{
            position: 'absolute',
            right: 12,
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          <IoCalendarClearOutline
            onClick={() => inputRef?.current?.setOpen(true)}
            color={disabled ? '#CBD4E1' : '#6C7F9A'}
            size={18}
          />
        </span>
      )}
    </div>
  );
});

export const DateField: FC<DateFieldProps> = (props) => {
  const {
    id,

    containerClassName,
    containerStyles,

    caption,
    captionStyles,
    captionType = 'default',

    ...restProps
  } = props;
  const inputRef = useRef<DatePicker | null>(null);

  const color = useMemo(() => {
    // change 'if' statement to 'switch' statement if conditions
    if (captionType === 'error') return 'rgb(216,50,50)';
    return '#6C7F9A';
  }, [captionType]);

  return (
    <div style={containerStyles} className={containerClassName}>
      <DatePicker
        ref={inputRef}
        {...restProps}
        customInput={<CustomInput inputRef={inputRef} />}
      />

      <p
        style={{
          marginTop: 4,
          fontWeight: captionType === 'error' ? 300 : 400,
          fontSize: 14,
          color,
          ...captionStyles,
        }}>
        {caption}
      </p>
    </div>
  );
};
