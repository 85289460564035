/* eslint-disable array-callback-return */

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import {
  Alert,
  Badge,
  Drawer,
  Collapsible,
  Table,
  TableColumnType,
  BadgeType,
  KeyValue,
  CollapsibleButton,
  OrderLineCard,
} from "nimbbl-quark";
import axios from "axios";
import {
  FiColumns,
  FiShoppingCart,
  FiX,
  FiList,
  FiUser,
  FiMonitor,
  FiInfo,
  FiRotateCw,
} from "react-icons/fi";
import {
  APIResponse,
  OrderRes,
  OrderConfig,
} from "../../interfaces/ApiResponse";
import { IStateData } from "../../interfaces/StateData";
import { IoAlertCircleOutline } from "react-icons/io5";
import { RiFileCopy2Line } from "react-icons/ri";
import constants from "../../constants";

import "./Orders.css";
import { BsCheck2Circle } from "react-icons/bs";
import "../Login/Styles.css";
import TransactionCardModule from "../Transactions/TransactionCardModule";
import { BiRupee } from "react-icons/bi";
import {
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import HeaderComponent from "../Header/Header";
import { PriceUtil } from "../../Utils";
import { useCommonTracker, useCsvExport, useTracker } from "../../Hooks";
import { TrackEventNames } from "../../constants/trackEventNames";
import FilterOrders from "../FilterPopup/FilterOrders";
import FilterSection2 from "../FilterSection/FilterSection2";
import useFilterOrders, { defaultOrderFilters } from "./useFilterOrders";
import isEqual from "lodash.isequal";
import FilterOrderUtils from "../FilterPopup/utils/FilterOrderUtils";
import FieldNames from "../../constants/FieldNames";
import CurrencyUpdateIcon from "../../images/currencyUpdateIcon.svg";
import InfoIcon from "../../images/infoIcon.svg";
export const formatDate = (data: string) => {
  if (data) {
    const fdata = data.split(",");
    const fdate = fdata[0];
    const ftime = fdata[1];
    return (
      <div>
        <span>
          {fdate}
          {ftime ? ", " : ""}
        </span>{" "}
        <span style={{ color: "#94A3B8" }}>{ftime}</span>
      </div>
    );
  }
  return null;
};

const getIssuerBadgeType = (
  status: string
): { type: BadgeType; text: string } => {
  switch (status) {
    case "completed":
      return { type: "Completed", text: status };
    case "failed":
      return { type: "Failed", text: status };
    case "pending":
      return { type: "Pending", text: status };
    case "lapsed":
      return { type: "reversed", text: "Lapsed" };
    case "new":
    default:
      return { type: "New", text: "New" };
  }
};

const rowsPerPage = 60;

export const DrawerTableRow = (props: {
  label: string;
  value: string;
  info?: string;
}) => {
  const { label, value, info = "" } = props;

  return (
    <tr>
      <td
        className="py-1 text-sm font-normal text-text-40"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className={info ? "mr-2" : ""}>{label}</span>
        {Boolean(info) && (
          <Tooltip placement="top" title={info} arrow>
            <IconButton size="small">
              <FiInfo size={16} />
            </IconButton>
          </Tooltip>
        )}
      </td>
      <td
        align="right"
        className="py-1 text-sm"
        style={{ overflowWrap: "break-word", textTransform: "initial" }}
      >
        {value || PriceUtil.defaultCurrencyValue}
      </td>
    </tr>
  );
};

export const TableDivider = () => (
  <hr
    className="my-2"
    style={{
      width: "100%",
      color: "#CBD4E1",
    }}
  />
);

interface AmountDetailsProps {
  data: { [key: string]: string }[];
  original_currency: string;
  exchange_rate: string;
}

const defaultDetails = {
  amount_before_tax: PriceUtil.defaultCurrencyValue,
  convenience_fee: PriceUtil.defaultCurrencyValue,
  order_amount: PriceUtil.defaultCurrencyValue,
  tax: PriceUtil.defaultCurrencyValue,
  transaction_amount: PriceUtil.defaultCurrencyValue,
  discount: PriceUtil.defaultCurrencyValue,
  currency: undefined,
};
const AmountDetails = (props: AmountDetailsProps) => {
  const { data } = props;

  const [details, setDetails] = useState(defaultDetails);

  useEffect(() => {
    let temp = { ...defaultDetails };
    data.forEach((item) => {
      temp = {
        ...temp,
        ...item,
        ...{ original_currency: props.original_currency },
      };
    });
    setDetails(temp);
  }, [data]);

  const {
    amount_before_tax,
    convenience_fee,
    discount,
    order_amount,
    tax,
    transaction_amount,
    currency,
  } = details;

  return (
    <>
      <table style={{ width: "100%" }}>
        <tbody>
          <DrawerTableRow
            label="Amount Before Tax"
            value={PriceUtil.formatCurrency(amount_before_tax, currency as any)}
          />
          <DrawerTableRow
            label="Tax"
            value={"+ " + PriceUtil.formatCurrency(tax, currency as any)}
          />

          <tr>
            <td colSpan={2}>
              <TableDivider />
            </td>
          </tr>

          <DrawerTableRow
            label="Order Amount"
            value={PriceUtil.formatCurrency(order_amount, currency as any)}
          />

          {/* <DrawerTableRow
          label="Discount"
          // use discount key name here
          value={"- " + PriceUtil.formatCurrency(discount, currency as any)}
        /> */}
          {/* <DrawerTableRow
          label="Convenience Fee"
          value={
            "+ " + PriceUtil.formatCurrency(convenience_fee, currency as any)
          }
        /> */}

          {/* <tr>
          <td colSpan={2}>
            <TableDivider />
          </td>
        </tr> */}

          {/* use net-order-amount key name */}
          {/* <DrawerTableRow
          label="Net Order Amount"
          // info='This is Transaction Amount before Conversion'
          value={PriceUtil.formatCurrency(transaction_amount, currency as any)}
        /> */}

          {/* <tr>
          <td colSpan={2}>
            <TableDivider />
          </td>
        </tr> */}

          {/* <DrawerTableRow
          // insert actual conversion rate here
          // info='Amount after Conversion. Exchange rate: {get_exchange_rate_from_backend}'
          label="Transaction Amount"
          value={PriceUtil.formatCurrency(transaction_amount, currency as any)}
        /> */}
        </tbody>
      </table>
    </>
  );
};

const Orders = () => {
  const { track } = useTracker();
  const {
    trackItemClicked,
    trackPageRendered,
    trackPopupRendered,
    trackRowSelected,
  } = useCommonTracker();
  const [orderLineData, setOrderLineData] = useState<IStateData<OrderRes[]>>({
    result: [],
    loading: true,
  });
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(true);

  const [orderData, setOrderData] = useState<any>({});
  const [orderTransactionData, setOrderTransactionData] = useState<any>([]);
  const [isOrderItemModalShown, setIsOrderItemModalShown] =
    useState<boolean>(true);
  const [refundResData] = useState<any>({});

  const [clearedData, setClearedData] = useState("");
  const [csvData, setCsvData] = useState<any>([]);
  const [showRefundAlert, setShowRefundAlert] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<boolean>(false);
  const [animatedRight, setAnimateRight] = useState<boolean>(false);
  const [animatedRightOrderLine, setAnimateRightOrderLine] =
    useState<boolean>(false);
  const [animatedRightTransaction, setAnimateRightTransaction] =
    useState<boolean>(false);
  // filter v2
  const {
    filters,
    form,
    loading,
    metaData,
    orders,
    page,
    isFilterModalShown,
    handleFormSubmit,
    setFilterModalShown,
    removeFilter,
    fetchNextPage,
  } = useFilterOrders();

  useEffect(() => {
    handleFormSubmit(defaultOrderFilters);
  }, []);

  const handleAlert = () => setShowRefundAlert(false);

  const toggle = () => {
    setAnimateRightTransaction(true);
    setAnimateRightOrderLine(false);
  };

  const toggleClose = () => setAnimateRightTransaction(false);

  const toggleOrderModal = () => {
    setAnimateRightOrderLine(true);
    setAnimateRightTransaction(false);
    track({
      name: TrackEventNames.orderLineItemsOpened,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { order_id: orderData.order_id },
    });
  };

  const toggleOrderModalClose = () => setAnimateRightOrderLine(false);

  useEffect(() => {
    trackPageRendered("Orders", { ...filters, ...metaData });

    return () => {
      trackPageRendered.cancel();
    };
  }, [filters, metaData]);

  useEffect(() => {
    if (animatedRightOrderLine) setIsOrderItemModalShown(true);
  }, [animatedRightOrderLine]);

  useEffect(() => {
    if (animatedRightTransaction) setIsModalShown(true);
  }, [animatedRightTransaction]);

  useEffect(() => {
    if (!animatedRight) {
      setIsShown(true);
    }
  }, [animatedRight]);

  useEffect(() => {
    if (clearedData) setClearedData("");
  }, [clearedData]);

  const getSelectedRow = (data: [], currentPage: number) => {
    const sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];
    setCsvData(sliceData);
  };

  const columns: TableColumnType<OrderConfig>[] = [
    {
      title: FieldNames.orderId,
      field: "order_id",
      sorting: false,
      cellStyle: {
        color: "#0352C9",
        textDecoration: "none",
      },
      render: (rowData) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            renderRowData(e, rowData);
            trackPopupRendered("Order", { order_id: rowData?.order_id });
          }}
        >
          {rowData.order_id}
        </a>
      ),
    },
    {
      title: "Order Date",
      field: "order_date",
      sorting: false,
      render: (rowData) => (
        <span style={{ width: "110px" }}>{formatDate(rowData.order_date)}</span>
      ),
    },
    {
      title: "Total Amount",
      field: "total_amount",
      sorting: false,
      render: (rowData) => (
        <span className="flex flex-row">
          {PriceUtil.formatCurrency(
            rowData.total_amount || 0,
            rowData?.currency as any
          )}

          {rowData?.original_total_amount ? (
            <Tooltip
              placement="top"
              title={
                <div className="p-3">
                  <p className="text-xs font-medium">
                    Currency has been changed from {rowData?.original_currency}{" "}
                    to {rowData?.currency}.
                  </p>
                  <p className="text-xs">
                    Exchange rate : 1.00 {rowData?.original_currency} ={" "}
                    {rowData?.exchange_rate_used} {rowData?.currency}
                  </p>
                </div>
              }
              arrow
            >
              <img src={CurrencyUpdateIcon} alt="" className="ml-2" />
            </Tooltip>
          ) : (
            ""
          )}
        </span>
      ),
      // old method for formatting price
    },
    {
      cellStyle: {
        maxWidth: 180,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      title: FieldNames.invoiceId,
      field: "invoice_id",
      sorting: false,
      render: (rowData) => rowData.invoice_id,
    },
    {
      title: FieldNames.mobile,
      field: "user_id",
      sorting: false,
      render: (rowData) => rowData.user_id.mobile_number,
    },
    {
      title: FieldNames.status,
      field: "status",
      sorting: false,
      render: (rowData) => (
        <Badge variant="accent" {...getIssuerBadgeType(rowData.status)}></Badge>
      ),
    },
  ];

  const onClose = () => {
    setAnimateRight(false);
    setAnimateRightTransaction(false);
    setAnimateRightOrderLine(false);
    setIsModalShown(false);
    setIsOrderItemModalShown(false);
    track({
      name: TrackEventNames.orderClosed,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { order_id: orderData.order_id },
    });
  };

  useEffect(() => {
    if (!isShown) {
      document
        .querySelector("tr.selected-row")
        ?.classList.remove("selected-row");
    }
  }, [isShown]);

  const renderRowData = (e: any, data: any) => {
    //Segment
    trackItemClicked("Order", { order_id: data?.order_id });
    setAnimateRight(true);
    setOrderData(data);
    setOrderTransactionData(data?.transaction_items);
    getOrderLineItems(data?.id);
    document.querySelector("tr.selected-row")?.classList.remove("selected-row");
    e.currentTarget.closest("tr").classList.add("selected-row");
    setFilterModalShown(false);
  };

  const getOrderLineItems = async (id: number) => {
    const token = window.localStorage.getItem("nimbbl_session_id");
    const res = await axios.post<APIResponse<OrderRes>>(
      `${constants.API.ORDER_LINE_ITEM_API}?session_id=${token}`,
      {
        params: {
          filter: `[["order_id","=",${id}]]`,
          query:
            "{title,description,image_url,quantity,total_amount,rate,currency, sku_id}",
        },
      }
    );

    if (res.data) {
      setOrderLineData({ loading: false, result: res.data.result.result });
    } else {
      setOrderLineData({ loading: false, result: [] });
    }
  };

  let orderObj: any = {
    order_details: [
      {
        order_amount: PriceUtil.formatCurrency(
          orderData?.total_amount || 0,
          orderData?.currency
        ),
      },
      {
        original_order_amount: orderData?.original_total_amount
          ? PriceUtil.formatCurrency(
              orderData?.original_total_amount,
              orderData?.original_currency
            )
          : null,
      },
      { order_date: formatDate(orderData?.order_date) },
      { invoice_id: orderData?.invoice_id },
      {
        submerchant: orderData?.sub_merchant_id?.description
          ? orderData?.sub_merchant_id?.description
          : "--",
      },
      {
        submerchant_id: orderData?.sub_merchant_id?.sub_merchant_id
          ? orderData?.sub_merchant_id?.sub_merchant_id
          : "--",
      },
      { attempts: orderData?.attempts ? orderData?.attempts : "0" },
    ],
    amount_details: [
      { currency: orderData?.currency },
      {
        amount_before_tax: orderData?.amount_before_tax || 0,
      },
      {
        tax: orderData?.tax || 0,
      },
      {
        order_amount: orderData?.total_amount || 0,
      },
      {
        convenience_fee: orderData?.additional_charges || 0,
      },
      {
        transaction_amount: orderData?.grand_total_amount || 0,
      },
      {
        discount: orderData?.offer_discount || 0,
      },
    ],
    customer_details: [
      { email: orderData?.user_id?.email || "--" },
      {
        mobile: orderData.user_id?.mobile_number
          ? `${orderData.user_id?.country_code}-${orderData.user_id?.mobile_number}`
          : "--",
      },
      {
        name:
          `${orderData.user_id?.first_name} ${
            orderData.user_id?.last_name || ""
          }` || "--",
      },
      { address_type: orderData?.shipping_address_id?.address_type },
      {
        address: `${orderData?.shipping_address_id?.address_1 || "-"}${
          orderData?.shipping_address_id?.street
            ? ", " + orderData?.shipping_address_id?.street
            : "-"
        }`,
      },
      { area: orderData?.shipping_address_id?.area },
      { city: orderData?.shipping_address_id?.city },
      { state: orderData?.shipping_address_id?.state },
      { pincode: orderData?.shipping_address_id?.pincode },
    ],
    platform_details: [
      {
        referrer_platform: orderData.referrer_platform || "",
      },
      {
        referrer_platform_version: orderData.referrer_platform_version || "",
      },
      { browser: orderData.browser_name || "--" },
      { device: orderData.device_name || "--" },
      { operating_system: orderData.os_name || "--" },
      { domain: orderData.merchant_shopfront_domain },
      //
      // {referrer_platform_version: orderData.referrer_platform_version ? orderData.referrer_platform_version : ""},
    ],
  };

  const customAttributesArray: any = [];

  orderData?.order_custom_attributes?.map((item: any) => {
    let keyName = item.key;
    let obj: { [key: string]: string } = {};
    obj[keyName] = item.value;
    customAttributesArray.push(obj);
  });

  if (customAttributesArray.length > 0) {
    Object.assign(orderObj, { custom_attributes: customAttributesArray });
  }

  let icon = [
    <FiList key={1} />,
    <BiRupee key={2} />,
    <FiUser key={3} />,
    <FiMonitor key={4} />,
    <FiInfo key={5} />,
  ];

  useEffect(() => {
    if (showRefundAlert) {
      setTimeout(() => {
        handleAlert();
      }, 5000);
    }
  }, [showRefundAlert]);

  useEffect(() => {
    if (copyText) {
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    }
  }, [copyText]);

  const copyTextClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    setCopyText(true);
    track({
      name: TrackEventNames.orderIdCopied,
      inBuiltOptions: ["merchantId", "merchantName"],
      additionalOptions: { order_id: orderData.order_id },
    });
  };

  const getKeyValueObjMap = (dataArray: [], data: string) => {
    return dataArray.map((item: any, index: number) => {
      let pointer = index + 1;
      let val: any = (Object.values(item) && Object.values(item)[0]) || "";
      let lab: any =
        Object.keys(item) && Object.keys(item)[0].replace(/\_/g, " ");

      if (val === "") return <div className="clear-both"></div>;

      if (lab.includes("upi")) {
        lab = lab.replace("upi", "UPI");
      }
      if (lab.includes("utr")) {
        lab = lab.replace("utr", "UTR");
      }
      if (lab.includes("id")) {
        lab = lab.replace("id", "Id");
      }

      let msg = [];
      msg.push(
        <KeyValue
          className={
            pointer % 2 === 0 ? "keyContainerWoMargin" : "keyContainer"
          }
          label={lab}
          labelStyle={{ textTransform: "capitalize" }}
          value={val}
          valueStyle={{
            overflowWrap: "break-word",
            textTransform:
              lab.includes("name") ||
              lab.includes("address type") ||
              lab.includes("transaction type")
                ? "capitalize"
                : "initial",
          }}
        />
      );
      if (pointer % 2 === 0) {
        msg.push(<div className="clear-both"></div>);
      }
      if (orderObj[data].length === pointer) {
        msg.push(<div className="clear-both"></div>);
      }

      return msg;
    });
  };

  const skeletonArray = Array(10).fill("");

  const tableRef = useRef<any>();

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const { exportAll, loading: csvLoading } = useCsvExport(
    "Orders",
    form.watch()
  );
  const getOrderExport = () => {
    exportAll();
    clearSelection();
  };

  const getSelectedExport = async () => {
    // get order_ids to be exported
    const orderIds: string[] = csvData.map((item: any) => item.order_id);
    await exportAll(orderIds, "order_id");
  };

  return (
    <>
      <Sidebar />
      {showRefundAlert && (
        <div className="alertPosition" style={{ zIndex: "999" }}>
          <Alert
            variant={
              refundResData?.result?.result?.message ? "success" : "danger"
            }
            onClose={() => handleAlert()}
            bannerWithCta={false}
            description={
              refundResData?.result?.result?.message
                ? refundResData?.result?.result?.message
                : refundResData?.result?.error?.message
            }
            size="md"
            icon={
              refundResData?.result?.result?.message ? (
                <BsCheck2Circle />
              ) : (
                <IoAlertCircleOutline />
              )
            }
            closeIcon={<FiX />}
            closable={true}
          ></Alert>
        </div>
      )}
      <div className="p-4 mb-8 body-container ml-60">
        <HeaderComponent
          loading={csvLoading}
          title="Orders"
          page="orders"
          dataArray={csvData}
          total={metaData?.total_records}
          handleExport={getSelectedExport}
          handleExportAll={getOrderExport}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          selectedRows={csvData?.length || 0}
        />

        <FilterSection2
          defaultFilters={defaultOrderFilters}
          filters={filters}
          pageName="order"
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterOrderUtils.generateParams(defaultOrderFilters)
                .filterSection,
              filters
            )
          }
        />

        {loading ? (
          <div className="w-full">
            <table className="w-full">
              {skeletonArray.map((_item, index) => (
                <TableRow key={index} style={{ width: "100%" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: "20%" }}
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            </table>
          </div>
        ) : (
          <div style={{ overflow: "auto" }} className="mt-6">
            <Table
              data={orders}
              columns={columns}
              // filtering
              search={false}
              handleSearchChange={() => ({})}
              attributes={{
                onChangePage: async (value: number) => {
                  const totalPages = Math.ceil(orders.length / 20);
                  if (
                    // when we are at last page
                    value + 1 === totalPages &&
                    // when total orders is still less than total records
                    orders.length < (metaData.total_records ?? 0)
                  ) {
                    await fetchNextPage();
                  }
                },
                onSelectionChange: (rows: any) => {
                  const { per_page } = metaData || {};

                  getSelectedRow(rows, page + 1);
                  setCsvData(rows);
                  if (rows.length > 0) {
                    const select_all = rows.length === per_page;

                    trackRowSelected("Orders", {
                      number_of_rows: rows.length,
                      select_all,
                    });
                  }
                },
                tableRef: tableRef,
              }}
              optionsAttributes={{
                pageSize: 20,
                draggable: false,
              }}
              localizationAttributes={{
                body: {
                  emptyDataSourceMessage:
                    "No records fetched for your search criteria, please change the filters and search again",
                },
              }}
              selection={true}
              handleSelectionChange={(data) => getSelectedRow(data, page + 1)}
            />
          </div>
        )}
      </div>

      <Drawer
        position="right"
        hide={onClose}
        isShown={isShown}
        style={{ width: "400px" }}
        className={
          animatedRight
            ? "animation drawerAnimationRightShow"
            : "animation drawerAnimationRightHide"
        }
        headerElement={
          <div>
            <div className="flex items-center justify-start mb-2 text-base">
              {orderData.order_id}{" "}
              {!copyText ? (
                <span
                  className="ml-4 text-lg cursor-pointer text-blue-40"
                  title="Copy Order Id"
                  onClick={() => copyTextClipboard(orderData.order_id)}
                >
                  {" "}
                  <RiFileCopy2Line />
                </span>
              ) : (
                <p className="copyToClipboard">copied to clipboard</p>
              )}
            </div>
            <Badge
              variant="accent"
              {...getIssuerBadgeType(orderData.status)}
            ></Badge>
          </div>
        }
        headerStyle={{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <React.Fragment key={orderData.order_id}>
            <div
              className="p-4 no-scroll icon-font"
              style={{ overflowY: "scroll", maxHeight: "calc(100vh - 100px)" }}
            >
              {Object.keys(orderObj).map((data: any, index: number) => {
                return (
                  <Collapsible
                    open={index === 0 ? false : true} // false === open
                    key={data}
                    title={
                      <h6 className="capitalize font-weight-bold">
                        {data.replace(/\_/g, " ")}
                      </h6>
                    }
                    iconColor="#045CE1"
                    isBorder={true}
                    icon={icon[index]}
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    <div className="p-4 rounded-lg bg-blue-10">
                      {data === "amount_details" ? (
                        <AmountDetails
                          data={orderObj[data]}
                          original_currency={orderData?.original_currency}
                          exchange_rate={orderData?.exchange_rate_used}
                        />
                      ) : (
                        getKeyValueObjMap(orderObj[data], data)
                      )}
                    </div>

                    {orderData.original_currency &&
                      data === "amount_details" && (
                        <div className="p-4 rounded-lg bg-blue-10 mt-4">
                          <div className="flex items-start">
                            <img src={InfoIcon} alt="" />
                            <p className="text-[#6C7F9A] text-sm ml-4">
                              An exchange rate of&nbsp;
                              <span className="text-black">
                                1.00 {orderData.original_currency}{" "}
                              </span>
                              ={" "}
                              <span className="text-black">
                                {orderData.exchange_rate_used}{" "}
                                {orderData.currency}
                              </span>
                              &nbsp; was applied when changing the currency
                              from&nbsp;
                              <span className="text-black">
                                {orderData.original_currency}
                              </span>{" "}
                              to&nbsp;
                              <span className="text-black">
                                {orderData.currency}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                  </Collapsible>
                );
              })}
              {orderTransactionData && orderTransactionData.length > 0 && (
                <div className="mt-4">
                  <CollapsibleButton
                    text={`Transactions (${orderData?.transaction_items.length})`}
                    iconRight={<FiColumns />}
                    iconLeft={<FiRotateCw />}
                    style={{ width: "100%", background: "#F1F4F9" }}
                    iconLeftStyle={{ color: "#6C7F9A", fontSize: "18px" }}
                    iconRightStyle={{ color: "#045CE0", fontSize: "18px" }}
                    onClick={toggle}
                  ></CollapsibleButton>
                </div>
              )}
              {orderLineData && orderLineData?.result?.length > 0 && (
                <div className="mt-4">
                  <CollapsibleButton
                    text="Order Line Items"
                    iconRight={<FiColumns />}
                    iconLeft={<FiShoppingCart />}
                    style={{ width: "100%", background: "#F1F4F9" }}
                    iconLeftStyle={{ color: "#6C7F9A", fontSize: "18px" }}
                    iconRightStyle={{ color: "#045CE0", fontSize: "18px" }}
                    onClick={toggleOrderModal}
                  ></CollapsibleButton>
                </div>
              )}
            </div>
          </React.Fragment>
        }
      />

      <Drawer
        position="right"
        hide={toggleOrderModalClose}
        isShown={isOrderItemModalShown}
        style={{ width: "400px" }}
        className={
          animatedRightOrderLine
            ? "animation drawer2AnimationRightShow"
            : "animation drawerAnimationRightHide"
        }
        headerElement={
          <div>
            <h1 className="mb-2 text-base">Order Line Items</h1>
            {/* <Badge type={getIssuerBadgeType(orderData.status)} ></Badge> */}
          </div>
        }
        headerStyle={{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <div
            className="p-4 no-scroll"
            style={{ overflowY: "scroll", maxHeight: "calc(100vh - 100px)" }}
          >
            {isOrderItemModalShown &&
              orderLineData &&
              orderLineData?.result?.length > 0 &&
              orderLineData.result.map((data: any) => {
                return (
                  <OrderLineCard
                    key={data?.title + data?.amount}
                    title={data.title}
                    description={data.description}
                    imgUrl={data.image_url}
                    style={{ marginBottom: "16px" }}
                  >
                    <div className="flex flex-col w-full gap-4">
                      <KeyValue label={"SKU ID"} value={data.sku_id}></KeyValue>

                      <div className="flex flex-row justify-around">
                        <div className="flex-1">
                          <KeyValue
                            label={"Quantity"}
                            value={data.quantity}
                          ></KeyValue>
                        </div>
                        <div className="flex-1">
                          <KeyValue
                            label={"Rate"}
                            value={PriceUtil.formatCurrency(
                              data.rate,
                              orderData?.currency
                            )}
                          ></KeyValue>
                        </div>
                        <div className="flex-1">
                          <KeyValue
                            label={"Total Amount"}
                            value={PriceUtil.formatCurrency(
                              data.total_amount,
                              orderData?.currency
                            )}
                          ></KeyValue>
                        </div>
                      </div>
                    </div>
                  </OrderLineCard>
                );
              })}
          </div>
        }
      />

      <Drawer
        position="right"
        hide={toggleClose}
        isShown={isModalShown}
        style={{ width: "400px" }}
        className={
          animatedRightTransaction
            ? "animation drawer2AnimationRightShow"
            : "animation drawerAnimationRightHide"
        }
        headerElement={
          <div>
            <h1 className="mb-2 text-base">Transactions</h1>
            {/* <Badge type={getIssuerBadgeType(orderData.status)} ></Badge> */}
          </div>
        }
        headerStyle={{ background: "#FAFAFC", borderBottom: "1px solid #CCC" }}
        bodyElement={
          <div
            className="p-4 no-scroll"
            style={{ overflowY: "scroll", maxHeight: "calc(100vh - 100px)" }}
          >
            {orderTransactionData.length > 0 &&
              orderTransactionData.map((data: any) => {
                return (
                  <TransactionCardModule
                    key={data?.transaction_data?.id}
                    currency={orderData?.currency}
                    original_currency={orderData?.original_currency}
                    transaction_data={data}
                    order_amount={orderData.grand_total_amount}
                    transactionCardType="orders"
                    isRefundType={orderData?.order_transac_type}
                    available_refundable_amount={
                      orderData?.available_refundable_amount
                    }
                  />
                );
              })}
          </div>
        }
      />

      <FilterOrders
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />
    </>
  );
};

export default Orders;
