export const getPage = () => {
  let url = window.location.href;
  let page = url.split('/');

  if (url.includes('?')) {
    let finalPageUrl = url.split('?');
    finalPageUrl = finalPageUrl[0].split('/');

    return finalPageUrl.slice(-1).pop();
  }
  return page.slice(-1).pop()?.replace('-', ' ');
};

const track = (eventName: string, eventData: Object) => {
  console.log('track', eventName);
  // google analytics
  window?.analytics && window?.analytics?.track(eventName, eventData);

  // new relic event tracker
  window?.newrelic && window?.newrelic?.addPageAction(eventName, eventData);
};

export const segmentPageRender = (data: any, filter: string) => {
  const eventName = `${getPage()} Rendered`;
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    number_of_rows: data.per_page,
    number_of_pages: data.total_pages,
    default_duration: '',
    search_fields: '',
    filter_fields: filter,
  };

  track(eventName, eventData);
};

export const segmentOrderClicked = (data: any) => {
  const eventName = 'Order Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    order_id: data.order_id,
  };

  track(eventName, eventData);
};

export const segmentOrderRender = (data: any) => {
  const eventName = 'Order Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    order_id: data.order_id,
  };
  track(eventName, eventData);
};

export const segmentOrderClosed = (data: any) => {
  const eventName = 'Order Closed';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    order_id: data.order_id,
  };
  track(eventName, eventData);
};

export const segmentOrdersExport = (filter: any) => {
  const eventName = 'Orders Exported';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    filter_fields: filter,
  };
  track(eventName, eventData);
};

export const segmentSelected = (
  rowLength: number,
  selectAll: boolean,
  page: string
) => {
  const eventName = `${page} Exported`;
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    number_of_rows: rowLength,
    select_all: selectAll,
  };
  track(eventName, eventData);
};

export const segmentClearFilter = () => {
  const eventName = 'Clear Filter Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    menu_item_name: getPage(),
  };
  track(eventName, eventData);
};

export const segmentSearchBtn = (filter: any) => {
  const eventName = `${getPage()} Search Selected`;
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    menu_item_name: () => getPage(),
    filter_values: filter,
  };
  track(eventName, eventData);
};

// Transaction
export const segmentTransactionClicked = (data: any) => {
  const eventName = 'Transaction Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: data.transaction_id,
  };
  track(eventName, eventData);
};

export const segmentTransactionRender = (data: any) => {
  const eventName = 'Transaction Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: data.transaction_id,
  };
  track(eventName, eventData);
};

export const segmentTransactionsExport = (filter: any) => {
  const eventName = 'Transactions Exported';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    filter_fields: filter,
  };
  track(eventName, eventData);
};

export const segmentTransactionsSelected = (
  rowLength: number,
  selectAll: boolean
) => {
  const eventName = 'Transactions Selected';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    number_of_rows: rowLength,
    select_all: selectAll,
  };
  track(eventName, eventData);
};

// Refunds
export const segmentRefundClicked = (data: any) => {
  const eventName = 'Refund Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: data.orignal_payment_transaction_id.transaction_id,
    refund_id: data.transaction_id,
  };
  track(eventName, eventData);
};

export const segmentRefundRender = (data: any) => {
  const eventName = 'Refund Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: data.orignal_payment_transaction_id.transaction_id,
    refund_id: data.transaction_id,
  };
  track(eventName, eventData);
};

export const segmentRefundsExport = (filter: any) => {
  const eventName = 'Refunds Exported';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    filter_fields: filter,
  };
  track(eventName, eventData);
};

export const segmentRefundsSelected = (
  rowLength: number,
  selectAll: boolean
) => {
  const eventName = 'Refunds Selected';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    number_of_rows: rowLength,
    select_all: selectAll,
  };
  track(eventName, eventData);
};

export const segmentLogout = () => {
  const eventName = 'Log Out Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    merchant_email: window.localStorage.getItem('merchant_email'),
  };
  track(eventName, eventData);
};

// Issue Refund

export const segmentIssueRefundRender = (data: any) => {
  const eventName = 'Issue Refund Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: data.settlement_log.transaction_id,
  };
  track(eventName, eventData);
};

export const segmentConfirmationRefundRender = (
  _data: any,
  transaction_id: string
) => {
  const eventName = 'Confirmation Refund Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: transaction_id,
  };
  track(eventName, eventData);
};

export const segmentConfirmationRefundCancelRender = (
  transaction_id: string
) => {
  const eventName = 'Cancel Refund Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id: transaction_id,
  };

  track(eventName, eventData);
};

// Settlement

export const segmentSettlementClicked = (data: any) => {
  const eventName = 'Settlement Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    settlement_id: data.partner_settement_id,
  };
  track(eventName, eventData);
};

export const segmentSettlementRender = (data: any) => {
  const eventName = 'Settlement Detail Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    settlement_id: data.partner_settement_id,
  };
  track(eventName, eventData);
};

export const segmentSettlementsExport = (filter: any) => {
  const eventName = 'Settlements Exported';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    filter_fields: filter,
  };
  track(eventName, eventData);
};

export const segmentSettlementsSelected = (
  rowLength: number,
  selectAll: boolean
) => {
  const eventName = 'Settlement Details Exported';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    number_of_rows: rowLength,
    select_all: selectAll,
  };
  track(eventName, eventData);
};

// Api Keys

export const segmentApiRender = (data: any) => {
  const eventName = 'API Keys Rendered';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    sub_merchant_id: !!data && data,
  };
  track(eventName, eventData);
};

export const segmentApiCopy = (data: any, section: string) => {
  const eventName = 'API Keys Copied';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    sub_merchant_id: !!data && data,
    developer_section_name: section,
  };
  track(eventName, eventData);
};

export const segmentAccessKeyReveal = (data: any, section: string) => {
  const eventName = 'API Secret Key Revealed';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    sub_merchant_id: !!data && data,
    developer_section_name: section,
  };
  track(eventName, eventData);
};

export const segmentSecretKeyCopy = (
  data: string | null,
  section: string | null
) => {
  const eventName = 'API Secret Key Copied';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    sub_merchant_id: !!data && data,
    developer_section_name:
      section === 'true' ? 'Production Keys' : 'Test Keys',
  };
  track(eventName, eventData);
};

export const segmentSubmerchantFliter = (data: string | null) => {
  const eventName = 'API Submerchant Filter Applied';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    sub_merchant_id: !!data && data,
  };
  track(eventName, eventData);
};

export const segmentMenuItemClicked = () => {
  const eventName = 'Menu Item Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    menu_item_name: getPage(),
  };
  track(eventName, eventData);
};

export const trackPasswordResetLink = (
  email: string,
  errorMessage: string = ''
) => {
  if (errorMessage) {
    // When the Merchant clicks on the "Send Password Reset Link" CTA and it fails
    track('Send Password Reset Link Failed', {
      merchant_login_email: email,
      failure_error_message: errorMessage,
    });
    return;
  }

  // When the Merchant clicks on the "Send Password Reset Link" CTA
  track('Send Password Reset Link', { merchant_login_email: email });
};

export const trackResendPasswordResetEmail = (email: string) => {
  // When the Merchant clicks on the 'Resend Link' CTA
  track('Resend Password Reset Email', { merchant_login_email: email });
};

export const trackBackToLogin = () => {
  // When the Merchant clicks on the 'Back to Login' CTA

  track('Back to Login', {});
};

export const trackOrderIDCopied = (order_id: string) => {
  const eventName = 'Order ID Copied';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    order_id,
  };

  track(eventName, eventData);
};

type PageName = 'Order' | 'Transaction';

export const trackHelpClicked = (pageName: PageName) => {
  const eventName = pageName + ' Help Clicked';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
  };

  track(eventName, eventData);
};

export const trackTransactionIDCopied = (transaction_id: string) => {
  const eventName = 'Transaction ID Copied';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id,
  };

  track(eventName, eventData);
};

export const trackTransactionPopupClosed = (transaction_id: string) => {
  const eventName = 'Transaction Closed';
  const eventData = {
    merchant_name: window.localStorage.getItem('merchant_name'),
    merchant_id: window.localStorage.getItem('merchant_id'),
    transaction_id,
  };

  track(eventName, eventData);
};
