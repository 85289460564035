import axios from 'axios';

import { MetaData } from '../interfaces/CommonFields';
import {
  FilterSettlementDetailFields,
  defaultSettlementDetailFilters,
} from '../../SettlementDetails/useFilterSettlementDetails';
import getApiEndPoint from './getApiEndpoint';
import DateUtils from './DateUtils';
import {
  daysOptions,
  settlementEntryTypeOptions,
  transactionOptions,
} from '../constants';
import FieldNames from '../../../constants/FieldNames';
import { UseFormSetValue } from 'react-hook-form';

const query =
  '{id,converted_transaction_amount,transaction_currency{id,name},settlement_currency{id,name},psp_currency_conversion_rate,transaction_amount,settlement_date,settlement_entry_type,create_date,partner_settement_id,total_order_amount, transaction_id{transaction_id,payment_mode,refund_amount},transaction_type,transaction_date,utr_no,order_id{order_id,grand_total_amount,transaction_items{status,refund_amount, refund_done, transaction_type},order_custom_attributes{key,value}},order_invoice_id,sub_merchant_id{id,display_name, description},payment_partner,payment_mode,settled_amount,fee_amount,tax_amount,customer_id,payment_mobile_number{mobile_number}}';

class FilterSettlementDetailUtils {
  static generateParams(
    formData: FilterSettlementDetailFields,
    metaData?: Partial<MetaData>,
    updateFormData: UseFormSetValue<FilterSettlementDetailFields> = (
      ..._args
    ) => {}
  ) {
    // for filter api
    const filterParams: any[] = [];
    // for filter section
    const filterSection: Record<string, string> = {};

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case 'startDate': {
          const startDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams.push([
            'settlement_date',
            '>=',
            DateUtils.getStringFromDate(startDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case 'endDate': {
          let endDate = formData[filterName];
          const duration = formData['duration'];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
            updateFormData('endDate', endDate);
          }

          filterParams.push([
            'settlement_date',
            '<=',
            DateUtils.getStringFromDate(endDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case 'transactionId': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['transaction_id', '=', value?.trim()]);
          filterSection[FieldNames.transactionId] = value ?? '';
          break;
        }
        case 'orderId': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['order_id', '=', value?.trim()]);
          filterSection[FieldNames.orderId] = value ?? '';
          break;
        }
        case 'settlementType': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const values = formValue.map((item) => item.value);
          // if all is selected then don't send/show status filter
          if (/all/i.test(values.join(','))) break;
          if (values.length === 0) break;

          filterParams.push(['transaction_type', 'in', values]);

          const value = formValue.map((item) => item.label).join(', ');
          filterSection['Settlement Type'] = value;
          break;
        }
        case 'settlementEntryType': {
          const formValue = formData[filterName];
          if (!formValue) break;

          const { value } = formValue;
          if (!value || value === 'all') break;

          filterParams.push(['settlement_entry_type', '=', value]);
          filterSection['Settlement Entry Type'] = value;
          break;
        }
        case 'paymentMode': {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(', ');

          if (values.length === 0) break;

          filterParams.push(['transaction_payment_mode', 'in', values]);
          filterSection[FieldNames.paymentMode] = labels;
          break;
        }
        case 'paymentPartner': {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(', ');

          if (values.length === 0) break;

          filterParams.push(['payment_partner', 'in', values]);
          filterSection[FieldNames.paymentPartner] = labels;
          break;
        }
        case 'invoiceId': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['order_invoice_id', '=', value?.trim()]);
          filterSection[FieldNames.invoiceId] = value ?? '';
          break;
        }
        case 'mobile': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['payment_mobile_number', '=', value?.trim()]);
          filterSection[FieldNames.mobile] = value ?? '';
          break;
        }
        case 'subMerchantId': {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(', ');

          if (values.length === 0) break;

          filterParams.push(['sub_merchant_id', 'in', values]);
          filterSection[FieldNames.submerchantId] = labels;
          break;
        }
        case 'singleSubMerchantId': {
          const value = formData[filterName];
          if (!value) break;

          if (value) {
            filterParams.push(['sub_merchant_id', '=', [value.trim()]]);
            filterSection[FieldNames.singleSubMerchantId] = value;
          }

          break;
        }
        case 'email': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push([
            'payment_mobile_number.email',
            '=',
            value?.trim(),
          ]);
          filterSection[FieldNames.email] = value ?? '';
          break;
        }
        case 'currency': {
          const value = formData[filterName];
          if (!value) break;

          const { value: code } = value;
          if (!code) break;

          filterParams.push(['settlement_currency', '=', code]);
          filterSection[FieldNames.currency] = code;
          break;
        }
        case 'minAmount': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['settled_amount', '>=', Number(value)]);
          filterSection[FieldNames.minAmount] = value ?? '';
          break;
        }
        case 'maxAmount': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['settled_amount', '<=', Number(value)]);
          filterSection[FieldNames.maxAmount] = value ?? '';
          break;
        }
        case 'settlementId': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['partner_settement_id', '=', value?.trim()]);
          filterSection[FieldNames.settlementId] = value ?? '';
          break;
        }
        case 'customAttributes': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(
            '|',
            ['order_id.order_custom_attributes.key', 'like', '%' + value + '%'],
            ['order_id.order_custom_attributes.key', 'like', '%' + value + '%']
          );
          filterSection[FieldNames.customAttributes] = value ?? '';
          break;
        }
        case 'utr': {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(['utr_no', '=', value?.trim()]);
          filterSection[FieldNames.utr] = value ?? '';
          break;
        }
      }
    });

    const { per_page, next, prev, page } = metaData ?? {};

    const filters = {
      params: {
        ...(filterParams.length > 0 && {
          filter: JSON.stringify(filterParams),
        }),
        query: query,
        page: page ?? 1,
        per_page: per_page ?? 60,
        next: next ?? null,
        prev: prev ?? null,
      },
    };

    return {
      filters,
      filterSection,
    };
  }

  static removeParams(
    formData: FilterSettlementDetailFields,
    filterName: string
  ): FilterSettlementDetailFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case 'date':
        return {
          ...result,
          startDate: defaultSettlementDetailFilters['startDate'],
          endDate: defaultSettlementDetailFilters['endDate'],
        };
      case 'Transaction ID':
        return { ...result, transactionId: undefined };
      case 'Order ID':
        return { ...result, orderId: undefined };
      case 'Settlement Type':
        return {
          ...result,
          settlementType: [transactionOptions[0]],
        };
      case 'Settlement Entry Type':
        return {
          ...result,
          settlementEntryType: settlementEntryTypeOptions[0],
        };
      case 'Payment Mode':
        return { ...result, paymentMode: null };
      case 'Payment Partner':
        return { ...result, paymentPartner: null };
      case 'Invoice ID':
        return { ...result, invoiceId: undefined };
      case 'Mobile No':
        return { ...result, mobile: undefined };
      case 'SubMerchant':
        return { ...result, subMerchantId: [] };
      case 'Submerchant ID':
        return {
          ...result,
          singleSubMerchantId: undefined,
          subMerchantId: [],
        };
      case 'Email':
        return { ...result, email: undefined };
      case 'Currency':
        return { ...result, currency: null };
      case 'Min Amount':
        return { ...result, minAmount: undefined };
      case 'Max Amount':
        return { ...result, maxAmount: undefined };
      case 'Settlement Id':
        return { ...result, settlementId: undefined };
      case 'Custom Attributes':
        return { ...result, customAttributes: undefined };
      case 'UTR':
        return { ...result, utr: undefined };
      default:
        return { ...defaultSettlementDetailFilters };
    }
  }

  static getSettlements = (body: object) =>
    axios.post(getApiEndPoint('settlement'), body);
}

export default FilterSettlementDetailUtils;
