import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AnalyticsResponse } from '../AnalyticsResponse';
import { useTransactionSummary } from './useTransactionSummary';

interface TransactionSummaryProps {
  highcharts: typeof Highcharts;
  filterData: any;
  transactionSummaryData: AnalyticsResponse['transaction_summary'];
}

export const TransactionSummary = (props: TransactionSummaryProps) => {
  const { filterData, highcharts, transactionSummaryData } = props;

  const { options } = useTransactionSummary({
    filters: filterData,
    transactionSummary: transactionSummaryData,
  });

  return (
    <>
      <p className='mt-5 text-lg font-bold'>
        {filterData.count_type === 'count'
          ? 'Transaction Count'
          : 'Transaction Amount'}
      </p>
      <div style={{ overflow: 'auto' }} className=''>
        <div className='flex'>
          <HighchartsReact
            containerProps={{ style: { width: '100%' } }}
            highcharts={highcharts}
            options={options}
          />
        </div>
      </div>
    </>
  );
};
