export class FieldUtil {
  static ENTER_KEY_CODE: string = 'Enter';
  static BACKSPACE_KEY_CODE: string = 'Backspace';

  static checkIsNumeric(input: string): boolean {
    return /^\d*$/.test(input);
  }

  static checkIsValidName(input: string): boolean {
    return /^[a-z ,.'-]+$/i.test(input);
  }

  static formatDecimalNumber(input: string): string {
    return parseFloat(input || '0').toFixed(2);
  }
}
