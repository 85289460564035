import { ReactNode } from 'react';
import { AlertProps } from 'nimbbl-quark';

export class ConfirmDialog {
  show = false;
  type?: AlertProps['variant'] = 'warning';
  title?: ReactNode = 'Are you sure ?';
  description?: ReactNode = '';
  confirmText?: string = 'Confirm';
  cancelText?: string = 'Cancel';
  onConfirm?: () => void;
  onCancel?: () => void;
}

export class GlobalState {
  isSessionValid: boolean = false;
  callbackUrl: string | null = null;
  error: ReactNode | null = null;
  errorType: AlertProps['variant'] = 'danger';
  confirmDialog: ConfirmDialog = new ConfirmDialog();
}
