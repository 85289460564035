import axios from "axios";

import { MetaData } from "../interfaces/CommonFields";
import {
  FilterSettlementFields,
  defaultSettlementFilters,
} from "../../Settlements/useFilterSettlements";
import getApiEndPoint from "./getApiEndpoint";
import DateUtils from "./DateUtils";
import { daysOptions } from "../constants";
import FieldNames from "../../../constants/FieldNames";
import { UseFormSetValue } from "react-hook-form";

const query =
  "{id, settlement_date, sub_merchant_id{id, description}, payment_partner, settlement_id, settlement_currency{name}, utr_ref_no, settled_amount,txn_count}";

class FilterSettlementUtils {
  static generateParams(
    formData: FilterSettlementFields,
    metaData?: Partial<MetaData>,
    updateFormData: UseFormSetValue<FilterSettlementFields> = (..._args) => {}
  ) {
    // for filter api
    const filterParams: any[] = [];
    // for filter section
    const filterSection: Record<string, string> = {};

    // number of nimbbl entries above 0
    filterParams.push(["txn_count", ">", 0]);

    Object.keys(formData).forEach((filterName) => {
      switch (filterName) {
        case "startDate": {
          const startDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            startDate?.setHours(0, 0, 0);
          }

          filterParams.push([
            "settlement_date",
            ">=",
            DateUtils.getStringFromDate(startDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(startDate);
          break;
        }
        case "endDate": {
          let endDate = formData[filterName];
          const duration = formData["duration"];

          if (duration.value !== daysOptions[daysOptions.length - 1].value) {
            endDate = new Date();
            updateFormData("endDate", endDate);
          }

          filterParams.push([
            "settlement_date",
            "<=",
            DateUtils.getStringFromDate(endDate),
          ]);
          filterSection[filterName] = DateUtils.formatDate(endDate);
          break;
        }
        case "paymentPartner": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value).join(",");
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["payment_partner", "=", values]);
          filterSection[FieldNames.paymentPartner] = labels;

          break;
        }
        case "subMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          const values = value.map((item) => item.value);
          const labels = value.map((item) => item.label).join(", ");

          if (values.length === 0) break;

          filterParams.push(["sub_merchant_id", "in", values]);
          filterSection[FieldNames.submerchantId] = labels;

          break;
        }
        case "singleSubMerchantId": {
          const value = formData[filterName];
          if (!value) break;

          if (value) {
            filterParams.push(["sub_merchant_id", "=", [value.trim()]]);
            filterSection[FieldNames.singleSubMerchantId] = value;
          }

          break;
        }
        case "minAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["settled_amount", ">=", Number(value)]);
          filterSection[FieldNames.minAmount] = value ?? "";
          break;
        }
        case "maxAmount": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["settled_amount", "<=", Number(value)]);
          filterSection[FieldNames.maxAmount] = value ?? "";
          break;
        }
        case "settlementId": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["settlement_id", "=", value?.trim()]);
          filterSection[FieldNames.settlementId] = value ?? "";
          break;
        }
        case "utr": {
          const value = formData[filterName];
          if (!value) break;

          filterParams.push(["utr_ref_no", "=", value?.trim()]);
          filterSection[FieldNames.utr] = value ?? "";
          break;
        }
      }
    });

    const { per_page, next, prev, page } = metaData ?? {};

    const filters = {
      params: {
        ...(filterParams.length > 0 && {
          filter: JSON.stringify(filterParams),
        }),
        query: query,
        page: page ?? 1,
        per_page: per_page ?? 60,
        next: next ?? null,
        prev: prev ?? null,
      },
    };

    return {
      filters,
      filterSection,
    };
  }

  static removeParams(
    formData: FilterSettlementFields,
    filterName: string
  ): FilterSettlementFields {
    // @ts-ignore
    const result = structuredClone(formData);

    switch (filterName) {
      case "date":
        return {
          ...result,
          startDate: defaultSettlementFilters["startDate"],
          endDate: defaultSettlementFilters["endDate"],
        };
      case FieldNames.paymentPartner:
        return { ...result, paymentPartner: null };
      case FieldNames.submerchantId:
        return { ...result, subMerchantId: [] };
      case FieldNames.singleSubMerchantId:
        return {
          ...result,
          singleSubMerchantId: undefined,
          subMerchantId: [],
        };
      case FieldNames.minAmount:
        return { ...result, minAmount: undefined };
      case FieldNames.maxAmount:
        return { ...result, maxAmount: undefined };
      case FieldNames.settlementId:
        return { ...result, settlementId: undefined };
      case FieldNames.utr:
        return { ...result, utr: undefined };
      default:
        return { ...defaultSettlementFilters };
    }
  }

  static getSettlements = (body: object) =>
    axios.post(getApiEndPoint("settlementSummary"), body);
}

export default FilterSettlementUtils;
