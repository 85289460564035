import { Button, Header, ModalV2, Table, TableColumnType } from 'nimbbl-quark';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { getRevealKeys } from '../../Apis';
import { TrackEventNames } from '../../constants/trackEventNames';
import { useCommonTracker, useTracker } from '../../Hooks';
import CopyTextContainer from '../CopyTextContainer';
import Sidebar from '../Sidebar/Sidebar';
import SubMerchantDropdown from '../SubMerchantDropdown';
import { ISubmerchantItem } from '../SubMerchantDropdown/SubMerchantDropdown';
import { ApiKeyResponse, ResponseResult } from './apiKeysResponse';
import { fetchKeys } from './fetchApiKeys';
import { Loader } from './Loader';

import './ApiKeys.css';
import { LockContainer } from './LockContainer';
import FilterSection from '../FilterSection/FilterSection';

// no of rows in table shown per page
const perPage = 10;

interface FetchMoreKeysParams {
  options: { isProduction: boolean };
  state: ResponseResult<ApiKeyResponse>;
  setState: Dispatch<SetStateAction<ResponseResult<ApiKeyResponse>>>;
}

const fetchMoreKeys = (params: FetchMoreKeysParams) => {
  const { options, state, setState } = params;
  const { isProduction } = options;

  const { meta } = state;
  const { next } = meta || {};

  if (!next) return;

  fetchKeys({ isProduction, paramsOptions: { page: next } }).then((res) => {
    const {
      data: { result },
    } = res;

    if (result) {
      setState((prev) => ({
        meta: result.meta,
        result: [
          ...(prev.result ? prev.result : []),
          ...(result.result ? result.result : []),
        ],
      }));
    } else {
      setState(new ResponseResult());
    }
  });
};

const ApiKeys = () => {
  const { track } = useTracker();
  const { trackPageRendered } = useCommonTracker();

  const [loading, setLoading] = useState(true);
  const [subMerchant, setSubMerchant] = useState<ISubmerchantItem | null>(null);
  const [productionKeys, setProductionKeys] = useState<
    ResponseResult<ApiKeyResponse>
  >(new ResponseResult());
  const [testKeys, setTestKeys] = useState<ResponseResult<ApiKeyResponse>>(
    new ResponseResult()
  );
  const [showModal, setShowModal] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [copyText, setCopyText] = useState(false);

  const clearFilters = () => {
    setSubMerchant(null);
  };

  const hideModal = () => {
    setShowModal(false);
    setSecretKey('');
  };

  const onCopy = () => {
    navigator.clipboard.writeText(secretKey);
    track({
      name: TrackEventNames.apiSubMerchantFilter,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        developer_section_name: 'API_Keys',
        developer_section_action: 'copy',
        sub_merchant_id: localStorage.getItem('sub_merchant_id') || '',
      },
    });
    setCopyText(true);
  };

  const getSecretAccessKey = async (
    access_key: string,
    description: string
  ) => {
    const result = await getRevealKeys({
      access_key: access_key,
      description: description,
    });
    setSecretKey(result.data.result.result[0].access_secret);
  };

  useEffect(() => {
    const subMerchantId = localStorage.getItem('merchant_index_id');

    trackPageRendered('API Keys', {
      developer_section_name: 'API_Keys',
      sub_merchant_id: subMerchantId,
    });

    return () => {
      trackPageRendered.cancel();
    };
  }, []);

  useEffect(() => {
    const initialFetch = async () => {
      const { value } = subMerchant || {};

      const [productionResult, testResult] = await Promise.allSettled([
        fetchKeys({
          isProduction: true,
          filterParams: { subMerchantId: value as unknown as string },
        }),
        fetchKeys({
          isProduction: false,
          filterParams: { subMerchantId: value as unknown as string },
        }),
      ]);

      if (productionResult.status === 'fulfilled') {
        const {
          value: {
            data: { result: productionKeys },
          },
        } = productionResult;

        const { error } = productionKeys || {};

        if (error || !productionKeys) {
          setProductionKeys(new ResponseResult());
        } else {
          setProductionKeys(productionKeys);
        }
      }

      if (testResult.status === 'fulfilled') {
        const {
          value: {
            data: { result: testKeys },
          },
        } = testResult;

        const { error } = testKeys || {};

        if (error || !testKeys) {
          setTestKeys(new ResponseResult());
        } else {
          setTestKeys(testKeys);
        }
      }
      setLoading(false);
    };

    initialFetch();
  }, [subMerchant]);

  const columns: TableColumnType<ApiKeyResponse>[] = [
    {
      title: 'Submerchant name',
      field: '',
      render: (rowData) => rowData.sub_merchant_id.description,
    },
    {
      title: 'Access key',
      field: 'access_key',
      render: (rowData) => (
        <CopyTextContainer
          submerchant_id={rowData.sub_merchant_id.sub_merchant_id}
          text={rowData.access_key}
          merchantname={rowData.sub_merchant_id.description}
          section={
            !rowData.sub_merchant_id.is_sandbox
              ? 'Production keys'
              : 'Test Keys'
          }
        />
      ),
    },
    {
      title: 'Access Secret',
      field: 'access_secret',
      cellStyle: {
        color: '#045CE1',
        fontWeight: 'bold',
        textDecoration: 'none',
        cursor: 'pointer',
      },
      render: (rowData) => (
        <span
          onClick={() => {
            setShowModal(true);
            getSecretAccessKey(
              rowData.access_key,
              rowData.sub_merchant_id.description
            );
            track({
              name: TrackEventNames.apiSecretKeyRevealed,
              inBuiltOptions: ['merchantId', 'merchantName'],
              additionalOptions: {
                developer_section_name: 'API_Keys',
                developer_section_action: 'view',
                sub_merchant_id: rowData.sub_merchant_id.description,
              },
            });
            localStorage.setItem(
              'sub_merchant_id',
              rowData.sub_merchant_id.description
            );
            localStorage.setItem(
              'production_keys',
              rowData.sub_merchant_id.is_sandbox ? 'true' : 'false'
            );
          }}>
          Reveal
        </span>
      ),
    },
    {
      title: 'Created on',
      field: 'create_date',
      render: (rowData) => rowData.create_date,
    },
  ];

  const productionKeysCount = productionKeys?.meta?.total_records || 0;
  const testKeysCount = testKeys?.meta?.total_records || 0;

  return (
    <>
      <Sidebar />
      <div className='p-4 mb-8 body-container ml-60'>
        <Header
          style={{}}
          leftHeaderContent={<>API Keys</>}
          leftBodyContent={<></>}
          rightContent={
            <div className='flex div-right'>
              <SubMerchantDropdown
                isMulti={false}
                className='z-20'
                value={subMerchant}
                onChange={(selectedValue) => {
                  const { label, value } = selectedValue;
                  setSubMerchant(selectedValue);
                  track({
                    name: TrackEventNames.apiSubMerchantFilter,
                    inBuiltOptions: ['merchantId', 'merchantName'],
                    additionalOptions: {
                      developer_section_name: 'API_Keys',
                      developer_section_action: 'select',
                      // @ts-ignore
                      filters: {
                        sub_merchant_name: label,
                        sub_merchant_id: value,
                      },
                    },
                  });
                }}
              />
            </div>
          }
        />

        <FilterSection
          filters={{ sub_merchant: subMerchant?.label || '' }}
          className='mt-4'
          totalCount={null}
          onClear={clearFilters}
        />

        {loading ? (
          <Loader />
        ) : (
          <div>
            {productionKeysCount === 0 && testKeysCount === 0 ? (
              <LockContainer />
            ) : (
              <>
                <h2 className='pt-6 pb-4 font-semibold'>
                  Production Keys{' '}
                  {productionKeysCount > perPage && (
                    <span className='text-sm font-normal'>
                      &#40;Displaying {productionKeysCount} production keys&#41;
                    </span>
                  )}
                </h2>
                <Table
                  data={productionKeys?.result || []}
                  columns={columns}
                  attributes={{
                    onChangePage: (value: number) => {
                      const currentLength = productionKeys?.result?.length || 0;
                      const lastPage = Math.ceil(currentLength / perPage);

                      if (value + 1 === lastPage) {
                        fetchMoreKeys({
                          options: { isProduction: true },
                          state: productionKeys,
                          setState: setProductionKeys,
                        });
                      }
                    },
                  }}
                  optionsAttributes={{
                    sorting: false,
                    draggable: false,
                    ...(productionKeysCount <= perPage && {
                      paging: false,
                    }),
                  }}
                  localizationAttributes={{
                    body: {
                      emptyDataSourceMessage:
                        'No production keys fetched for the selected submerchant, please change the filters and search again',
                    },
                  }}
                />

                <h2 className='pt-6 pb-4 font-semibold'>
                  Test Keys{' '}
                  {testKeysCount > perPage && (
                    <span className='text-sm font-normal'>
                      &#40;Displaying {testKeysCount} test keys&#41;
                    </span>
                  )}
                </h2>
                <Table
                  data={testKeys?.result || []}
                  columns={columns}
                  attributes={{
                    onChangePage: (value: number) => {
                      const currentLength = testKeys?.result?.length || 0;
                      const lastPage = Math.ceil(currentLength / perPage);

                      if (value + 1 === lastPage) {
                        fetchMoreKeys({
                          options: { isProduction: false },
                          state: testKeys,
                          setState: setTestKeys,
                        });
                      }
                    },
                  }}
                  optionsAttributes={{
                    sorting: false,
                    draggable: false,
                    ...(testKeysCount <= perPage && { paging: false }),
                  }}
                  localizationAttributes={{
                    body: {
                      emptyDataSourceMessage:
                        'No test keys fetched for the selected submerchant, please change the filters and search again',
                    },
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>

      <ModalV2
        isShown={showModal}
        isBackdrop
        position='center'
        hide={hideModal}
        size='sm'
        style={{ maxWidth: '392px', maxHeight: '220px' }}
        modalContent={
          <>
            <div
              className='flex items-center justify-between text-lg'
              style={{ color: '#333333', maxHeight: '220px' }}>
              <h5 className='text-lg font-bold'>Secret key</h5>
              <RiCloseFill
                className='w-6 h-6 cursor-pointer'
                onClick={hideModal}
              />
            </div>
            <div className='w-full p-3 mt-6 font-normal border rounded border-info-80 text-black-primary'>
              {secretKey}
            </div>
            <div className='flex items-center justify-end mt-6 text-black'>
              {copyText ? (
                'Key copied!'
              ) : (
                <Button
                  text='Copy key'
                  variant='primary'
                  classNames=''
                  attributes={{
                    onClick: onCopy,
                  }}></Button>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default ApiKeys;
