import { IconButton, Tooltip, TooltipProps } from '@material-ui/core';
import React, { CSSProperties, FC, ReactNode } from 'react';
import { IconBaseProps } from 'react-icons';
import { MdInfoOutline } from 'react-icons/md';

export interface FormRowProps {
  required?: boolean;
  label: string;
  value: ReactNode;
  id?: string;
  captionText?: string;
  valueContainerStyles?: CSSProperties;
  labelStyles?: CSSProperties;
  captionStyles?: CSSProperties;
  containerClassName?: string;
  labelContainerClassName?: string;
  valueContainerClassName?: string;
  info?: {
    text: string;
    iconProps?: IconBaseProps;
    tooltipProps?: TooltipProps;
  };
}

export const FormRow: FC<FormRowProps> = (props) => {
  const {
    required,
    label,
    captionText,
    value,
    id,
    labelStyles,
    captionStyles,
    valueContainerStyles,
    containerClassName,
    labelContainerClassName,
    valueContainerClassName,
    info,
  } = props;

  const { text = '', iconProps, tooltipProps } = info || {};

  return (
    <div className={`grid grid-cols-8 mb-6 ${containerClassName}`}>
      <div className={`col-start-1 col-end-4 ${labelContainerClassName}`}>
        <div className='flex items-center'>
          <label
            htmlFor={id}
            className='text-sm font-medium'
            style={{ ...labelStyles }}>
            {label}
            {required && (
              <span
                style={{
                  marginLeft: 4,
                  color: '#FF5247',
                }}>
                *
              </span>
            )}
          </label>
          {text && (
            <Tooltip title={text} arrow {...tooltipProps}>
              <IconButton size='small' style={{ marginLeft: 8 }}>
                <MdInfoOutline size={16} {...iconProps} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {captionText && (
          <p
            style={{ lineHeight: '18px', ...captionStyles }}
            className='text-xs text-[#6C7F9A] font-normal'>
            {captionText}
          </p>
        )}
      </div>
      <div
        className={`col-start-4 col-end-9 ${valueContainerClassName}`}
        style={{ ...valueContainerStyles }}>
        {value}
      </div>
    </div>
  );
};
