import { Button } from 'nimbbl-quark';
import { FiPlus } from 'react-icons/fi';

import EmptyBulkRefunds from '../../../images/EmptyBulkRefunds.svg';

const AddIcon = () => <FiPlus size={24} style={{ marginRight: 12 }} />;

interface EmptyBulkRefundProps {
  openUpload: () => void;
}

const EmptyBulkRefund = (props: EmptyBulkRefundProps) => {
  const { openUpload } = props;

  return (
    <div
      style={{
        height: 'calc(100vh - (50px + 85px + 72px))',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img
        style={{ marginTop: 40 }}
        src={EmptyBulkRefunds}
        alt='empty bulk refunds'
      />

      <p className='mt-4 text-base font-semibold'>No Bulk Refund Found</p>

      <Button
        style={{ paddingLeft: 24, paddingRight: 24, marginTop: 40 }}
        text='Create Bulk Refund'
        iconPosition='left'
        IconComponent={AddIcon}
        attributes={{
          onClick: openUpload,
        }}
      />
    </div>
  );
};

export default EmptyBulkRefund;
