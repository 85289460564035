import {
  BulkRefundFields,
  BulkRefundModel,
  OrderFields,
  OrderModel,
  RefundFields,
  RefundModel,
  SettlementFields,
  SettlementModel,
  TransactionFields,
  TransactionModel,
} from "../constants/exportFields";
import {
  SettlementDetailFields,
  SettlementDetailModel,
} from "../constants/exportFields/settlementDetailsFields";
import { Page } from "../interfaces/StateData";
import { paymentPartnerOptions } from "../Components/FilterPopup/constants";
import DateUtils from "../Components/FilterPopup/utils/DateUtils";

type AnyObject = { [key: string]: any };
const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const path = "web/export/custom/xlsx";
const queryParams = {
  sessionId: "session_id=",
};

// values not allowed in array
const notAllowedValues = ["All", "Select"];

// Used in 'useCsvExport' hook
export class CsvExportUtil {
  // Creating URL
  static url = `${baseUrl}/${path}?${queryParams.sessionId}`;

  static getToken() {
    return localStorage.getItem("nimbbl_session_id") ?? "";
  }

  static getFields(page: Page) {
    switch (page) {
      case "Orders":
        return { model: OrderModel, fields: OrderFields };
      case "Transactions":
        return { model: TransactionModel, fields: TransactionFields };
      case "Refunds":
        return { model: RefundModel, fields: RefundFields };
      case "Bulk Refunds":
        return { model: BulkRefundModel, fields: BulkRefundFields };
      case "Settlement Summary":
        return { model: SettlementModel, fields: SettlementFields };
      case "Settlement Details":
        return { model: SettlementDetailModel, fields: SettlementDetailFields };
    }
  }

  static setSpecialFilters(page: Page, body: AnyObject) {
    if (page === "Transactions") {
      body.push(["transaction_type", "in", ["payment"]]);
    }

    if (page === "Refunds") {
      body.push(["transaction_type", "in", ["full-refund", "partial-refund"]]);
    }

    if (page === "Settlement Summary") {
      body.push(["txn_count", ">", 0]);
    }
  }

  static generateFilterParams(filter: AnyObject, page: Page) {
    const result: any[] = [];

    Object.entries(filter).forEach(([key, value]) => {
      if (!value) return;

      // necessary checks for array
      const checkForArray =
        // is array
        Array.isArray(value) &&
        // is not empty
        (value.length === 0 ||
          // does not contains some special values
          notAllowedValues.some((item) =>
            new RegExp(item, "i").test(
              value.map((item: any) => item.value).join(" | ")
            )
          ));

      if (checkForArray) return;

      if (typeof value === "string") value = value.trim();

      switch (key) {
        case "minAmount":
          switch (page) {
            case "Settlement Summary":
              return result.push(["settled_amount", ">=", Number(value)]);
            case "Refunds":
              return result.push(["refund_amount", ">=", Number(value)]);
            case "Orders":
            case "Transactions":
            default:
              return result.push(["total_amount", ">=", Number(value)]);
          }

        case "maxAmount":
          switch (page) {
            case "Settlement Summary":
              return result.push(["settlementId", "<=", value]);
            case "Refunds":
              return result.push(["refund_amount", "<=", Number(value)]);
            case "Orders":
            case "Transactions":
            default:
              return result.push(["total_amount", "<=", Number(value)]);
          }
        case "settlementId":
          return result.push(["settlement_id", "=", value?.trim()]);

        case "cardNo":
          return result.push(["masked_card", "like", value?.trim()]);

        case "total_amount":
          return result.push([key, "=", value]);
        case "currency":
          return result.push([key, "=", value?.value]);
        case "upiId":
          return result.push(["vpa_id", "=", value?.trim()]);
        case "settlementId":
          switch (page) {
            case "Transactions":
            case "Refunds":
            case "Settlement Details":
              return result.push(["partner_settement_id", "=", value?.trim()]);

            default:
              return result.push([key, "=", value]);
          }

        case "customAttributes":
          switch (page) {
            case "Orders":
              return result.push(
                "|",
                ["order_custom_attributes.key", "like", "%" + value + "%"],
                ["order_custom_attributes.value", "like", "%" + value + "%"]
              );
            case "Refunds":
            case "Transactions":
              return result.push(
                "|",
                [
                  "order_id.order_custom_attributes.key",
                  "like",
                  "%" + value + "%",
                ],
                [
                  "order_id.order_custom_attributes.value",
                  "like",
                  "%" + value + "%",
                ]
              );
          }
          break;

        case "email":
          return result.push(["user_id.email", "=", value.trim()]);

        case "endDate":
          switch (page) {
            case "Orders":
              return result.push([
                "order_date",
                "<=",
                DateUtils.getUTCStringFromDate(value),
              ]);
            case "Bulk Refunds":
            case "Refunds":
            case "Transactions":
              return result.push([
                "create_date",
                "<=",
                DateUtils.getUTCStringFromDate(value),
              ]);
            case "Settlement Summary":
            case "Settlement Details":
              return result.push([
                "settlement_date",
                "<=",
                DateUtils.getStringFromDate(value),
              ]);
          }
          break;

        case "invoiceId":
          switch (page) {
            case "Orders":
              return result.push(["invoice_id", "=", value?.trim()]);
            case "Refunds":
            case "Transactions":
              return result.push(["order_id.invoice_id", "=", value?.trim()]);
            case "Settlement Summary":
            case "Settlement Details":
              return result.push(["order_invoice_id", "=", value]);
          }
          break;

        case "mobile":
          return result.push(["user_id.mobile_number", "=", value?.trim()]);

        case "refundId":
          return result.push(["transaction_id", "=", value?.trim()]);

        case "bulkRefundId":
          return result.push(["bulk_refund_id", "=", value?.trim()]);

        case "orderId":
          switch (page) {
            case "Orders":
              return result.push(["order_id", "=", value?.trim()]);
            case "Transactions":
            default:
              return result.push(["order_id.order_id", "=", value?.trim()]);
          }

        case "order_transac_type":
          return result.push([key, "in", value]);

        case "paymentPartner":
          result.push([
            "payment_partner",
            "in",
            value.map((item: any) => item.value),
          ]);

          return;

        case "transaction_type":
          if (page === "Settlement Details") {
            return result.push([key, "in", value]);
          }
          break;

        case "status":
          return result.push([
            "status",
            "in",
            value.map((item: any) => item.value),
          ]);
        case "bankName":
          return result.push([
            "bank_name",
            "in",
            value.map((item: any) => item.value),
          ]);
        case "walletName":
          return result.push([
            "wallet_name",
            "in",
            value.map((item: any) => item.value),
          ]);

        case "subMerchantId":
          return result.push([
            "sub_merchant_id",
            "in",
            value.map((item: any) => item.value),
          ]);

        case "singleSubMerchantId":
          return result.push(["sub_merchant_id", "=", value.trim()]);

        case "paymentMode": {
          switch (page) {
            case "Settlement Details":
              return result.push(["transaction_payment_mode", "in", value]);

            default:
              return result.push([
                "payment_mode",
                "in",
                value.map((item: any) => item.value),
              ]);
          }
        }

        case "startDate": {
          switch (page) {
            case "Orders":
              return result.push([
                "order_date",
                ">=",
                DateUtils.getUTCStringFromDate(value),
              ]);
            case "Bulk Refunds":
            case "Refunds":
            case "Transactions":
              return result.push([
                "create_date",
                ">=",
                DateUtils.getUTCStringFromDate(value),
              ]);
            case "Settlement Summary":
            case "Settlement Details":
              return result.push([
                "settlement_date",
                ">=",
                DateUtils.getStringFromDate(value),
              ]);
          }
          break;
        }

        case "refund_id":
          return result.push(["transaction_id", "=", value]);

        case "transactionId":
          switch (page) {
            case "Refunds":
              return result.push([
                "orignal_payment_transaction_id.transaction_id",
                "=",
                value?.trim(),
              ]);
            case "Transactions":
            case "Settlement Summary":
            case "Settlement Details":
              return result.push(["transaction_id", "=", value?.trim()]);
          }
          break;

        case "utr":
          switch (page) {
            case "Settlement Summary":
              return result.push(["utr_ref_no", "=", value?.trim()]);
            case "Transactions":
              return result.push(["settlement_log.utr_no", "=", value?.trim()]);
            case "Settlement Details":
              return result.push(["utr_no", "=", value?.trim()]);
          }
          break;
      }
    });

    return result;
  }
}
