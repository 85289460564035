import { FC } from 'react';
import SubMerchantDropdown from '../../SubMerchantDropdown';
import FieldNames from '../../../constants/FieldNames';

type Option = { label: string; value: string };

type SubMerchantFieldProps =
  | {
      isMulti: true;
      value: Option[];
      onChange: (newValue: Option[]) => void;
      subMerchantId?: boolean;
      subMerchantType?: string;
    }
  | {
      isMulti: false;
      value: Option | null;
      onChange: (newValue: Option) => void;
      subMerchantId?: boolean;
      subMerchantType?: string;
    };

const SubMerchantField: FC<SubMerchantFieldProps> = (props) => {
  const {
    isMulti = false,
    subMerchantId = false,
    value,
    onChange,
    subMerchantType,
  } = props;

  return (
    <div className='mt-6 form-input'>
      <div className='mb-1'>
        <label
          htmlFor='submerchant-dropdown'
          className='text-sm font-semibold text-black-primary'>
          {FieldNames.submerchantId}
        </label>
      </div>
      <SubMerchantDropdown
        className='z-20'
        isMulti={isMulti}
        subMerchantId={subMerchantId}
        value={value}
        {...(subMerchantType && {
          subMerchantType,
        })}
        onChange={onChange}
      />
    </div>
  );
};

export default SubMerchantField;
