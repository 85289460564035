import { useState, useRef, useEffect } from 'react';
import { Route, Router, Routes } from 'react-router-dom';

import Sidebar from '../../Sidebar/Sidebar';
import HeaderComponent from '../../Header/Header';
import RefundTabs from '../RefundTabs';
import EmptyBulkRefund from './EmptyBulkRefund';
import useFilterBulkRefund, {
  defaultBulkRefundFilters,
} from './useFilterBulkRefund';
import TableSkeleton from '../../TableSkeleton/TableSkeleton';
import FilterSection2 from '../../FilterSection/FilterSection2';
import isEqual from 'lodash.isequal';
import FilterBulkRefund from '../../FilterPopup/FilterBulkRefund';
import FilterBulkRefundUtils from '../../FilterPopup/utils/FilterBulkRefundUtils';
import BulkRefundsTable from './BulkRefundsTable';
import { useCsvExport } from '../../../Hooks';
import BulkUploadModal from './BulkUploadModal';

const BulkRefunds = () => {
  const tableRef = useRef<any>();
  const [csvData, setCsvData] = useState<any>([]);
  const [showUpload, setShowUpload] = useState(false);

  const {
    filters,
    form,
    handleFormSubmit,
    isFilterModalShown,
    loading,
    removeFilter,
    setFilterModalShown,
    transactions,
    metaData,
    fetchNextPage,
    page,
  } = useFilterBulkRefund();

  const { exportAll, loading: csvLoading } = useCsvExport(
    'Bulk Refunds',
    form.watch()
  );

  useEffect(() => {
    handleFormSubmit({ ...defaultBulkRefundFilters });
  }, []);

  const clearSelection = () => {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false);
    }
  };

  const getAllExport = () => {
    exportAll();
    clearSelection();
  };

  const getSelectedRow = (data: [], currentPage: number) => {
    let sliceData: any[] = [];
    sliceData = [...data.slice(currentPage * 20 - 20, currentPage * 20)];

    setCsvData(sliceData);
  };

  const getSelectedExport = () => {
    const transactionIds: string[] = csvData.map(
      (item: any) => item.bulk_refund_id
    );

    exportAll(transactionIds, 'bulk_refund_id');
  };

  const isFiltersApplied = !isEqual(
    FilterBulkRefundUtils.generateParams(defaultBulkRefundFilters)
      .filterSection,
    filters
  );

  return (
    <>
      <Sidebar />
      <div className='p-4 mb-8 body-container ml-60'>
        <HeaderComponent
          defaultBulkRefundFilters
          loading={csvLoading}
          page='refunds'
          title='Bulk Refund'
          total={metaData.total_records ?? 0}
          handleExport={getSelectedExport}
          handleExportAll={getAllExport}
          handleShowConfigFormModal={() => {
            setFilterModalShown(true);
          }}
          selectedRows={csvData.length}
          showBulkRefund={
            (metaData.total_records ?? 0) > 0 ||
            (isFiltersApplied && (metaData.total_records ?? 0) === 0)
          }
          openUpload={() => setShowUpload(true)}
        />

        <FilterSection2
          defaultFilters={defaultBulkRefundFilters}
          filters={filters}
          pageName='bulk refund'
          totalCount={metaData?.total_records ?? 0}
          removeFilter={removeFilter}
          showClearAll={
            !isEqual(
              FilterBulkRefundUtils.generateParams(defaultBulkRefundFilters)
                .filterSection,
              filters
            )
          }
        />

        {loading ? (
          <TableSkeleton />
        ) : (metaData.total_records ?? 0) > 0 ? (
          <BulkRefundsTable
            {...{
              page,
              tableRef,
              metaData,
              setCsvData,
              transactions,
              fetchNextPage,
              getSelectedRow,
            }}
          />
        ) : (
          <EmptyBulkRefund openUpload={() => setShowUpload(true)} />
        )}
      </div>

      <FilterBulkRefund
        form={form}
        handleFormSubmit={handleFormSubmit}
        removeFilter={removeFilter}
        isActive={isFilterModalShown}
        onHide={() => {
          setFilterModalShown(false);
        }}
      />

      <BulkUploadModal
        open={showUpload}
        handleFormSubmit={handleFormSubmit}
        openUpload={() => setShowUpload(true)}
        onClose={() => {
          setShowUpload(false);
        }}
      />
    </>
  );
};

export default BulkRefunds;
