import { Drawer } from 'nimbbl-quark';
import { FC, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { FilterAnalyticsFields } from '../Analytics/useFilterAnalytics';
import { useTracker } from '../../Hooks';
import DateUtils from './utils/DateUtils';
import {
  daysOptions,
  defaultFilterWidth,
  paymentModeOptions,
  paymentPartnerOptions,
} from './constants';
import { TrackEventNames } from '../../constants/trackEventNames';
import FilterHeading from './Fields/Heading';
import FilterContainer from './Fields/Container';
import StatusField from './Fields/StatusField';
import DurationField from './Fields/DurationField';
import DateRangeField from './Fields/DateRangeField';
import MultipleSelectField from './Fields/MultiSelectField';
import SubMerchantField from './Fields/SubMerchantField';
import CurrencyField from './Fields/CurrencyField';
import FilterSubmitButton from './Fields/SubmitButton';
import FieldNames from '../../constants/FieldNames';

interface FilterAnalyticsProps {
  form: UseFormReturn<FilterAnalyticsFields, object>;
  handleFormSubmit: (formData: FilterAnalyticsFields) => Promise<void>;
  width?: string | number;
  isActive: boolean;
  onHide: () => void;
  removeFilter: (filterName?: string) => Promise<void>;
}

const FilterAnalytics: FC<FilterAnalyticsProps> = (props) => {
  const {
    isActive,
    onHide,
    width = defaultFilterWidth,
    form,
    handleFormSubmit,
    removeFilter,
  } = props;

  const { track } = useTracker();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = form;

  const duration = watch('duration');

  useEffect(() => {
    // useEffect to keep duration and dates in sync
    const { value } = duration;
    if (!value) return;

    setValue('startDate', DateUtils.subtractDays(value));

    const customOption = daysOptions[daysOptions.length - 1];
    if (value === customOption.value) return;

    setValue('endDate', new Date());
  }, [duration, setValue]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    setValue('startDate', startDate);
    setValue('endDate', endDate);
    setValue('duration', daysOptions[daysOptions.length - 1]);
  };

  const handleClearAll = async () => {
    await removeFilter('default');
    track({
      name: 'Order ' + TrackEventNames.filterFieldsCleared,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Orders',
        filters: getValues(),
      },
    });
  };

  console.log('filter analytics errors', errors);

  return (
    <Drawer
      position='right'
      isShown={isActive}
      style={{ width }}
      hide={onHide}
      headerElement={<FilterHeading title='Filter Transaction Summary' />}
      bodyElement={
        <FilterContainer>
          <form
            className='relative filter-form pb-[156px]'
            onSubmit={handleSubmit(handleFormSubmit)}
            noValidate>
            <span
              className='absolute right-0 text-sm cursor-pointer clear-all'
              style={{ color: '#D83232' }}
              onClick={handleClearAll}>
              Clear All
            </span>

            <StatusField
              isRefundType
              value={watch('status')}
              setValue={(statuses) => setValue('status', statuses)}
            />

            <DurationField
              label='Duration'
              value={watch('duration')}
              setValue={(newValue) => setValue('duration', newValue)}
            />

            <DateRangeField
              label='Date Range'
              value={[watch('startDate'), watch('endDate')]}
              onChange={handleDateChange}
            />

            <MultipleSelectField
              label={FieldNames.paymentMode}
              options={paymentModeOptions}
              value={watch('paymentMode') ?? null}
              onChange={(value) => setValue('paymentMode', value)}
            />

            <MultipleSelectField
              label={FieldNames.paymentPartner}
              options={paymentPartnerOptions}
              value={watch('paymentPartner') ?? null}
              onChange={(value) => setValue('paymentPartner', value)}
            />

            <SubMerchantField
              isMulti={false}
              subMerchantId={false}
              value={watch('subMerchantId') ?? null}
              onChange={(value) => setValue('subMerchantId', value)}
            />

            <CurrencyField
              containerProps={{ className: 'mt-6 form-input' }}
              label='Transaction Currency'
              value={watch('currency') ?? null}
              onChange={(value) => setValue('currency', value)}
            />

            <FilterSubmitButton />
          </form>
        </FilterContainer>
      }
    />
  );
};

export default FilterAnalytics;
