import { UseFormReturn } from 'react-hook-form';
import { FilterBulkRefundFields } from '../Refunds/BulkRefunds/useFilterBulkRefund';
import { FC, useEffect } from 'react';
import { useTracker } from '../../Hooks';
import DateUtils from './utils/DateUtils';
import { daysOptions, defaultFilterWidth } from './constants';
import { TrackEventNames } from '../../constants/trackEventNames';
import { Button, Drawer } from 'nimbbl-quark';
import FilterHeading from './Fields/Heading';
import FilterContainer from './Fields/Container';
import StatusField from './Fields/StatusField';
import DateRangeField from './Fields/DateRangeField';
import DurationField from './Fields/DurationField';
import TextField from './Fields/TextField';
import SubMerchantField from './Fields/SubMerchantField';
import FieldNames from '../../constants/FieldNames';
import FilterSubmitButton from './Fields/SubmitButton';

interface FilterBulkRefundProps {
  form: UseFormReturn<FilterBulkRefundFields, object>;
  handleFormSubmit: (formData: FilterBulkRefundFields) => Promise<void>;
  width?: string | number;
  isActive: boolean;
  onHide: () => void;
  removeFilter: (filterName?: string) => Promise<void>;
}

const FilterBulkRefund: FC<FilterBulkRefundProps> = (props) => {
  const {
    form,
    handleFormSubmit,
    isActive,
    onHide,
    removeFilter,
    width = defaultFilterWidth,
  } = props;

  const { track } = useTracker();

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = form;

  const duration = watch('duration');

  useEffect(() => {
    // useEffect to keep duration and dates in sync
    const { value } = duration;
    if (!value) return;

    setValue('startDate', DateUtils.subtractDays(value));

    const customOption = daysOptions[daysOptions.length - 1];
    if (value === customOption.value) return;

    setValue('endDate', new Date());
  }, [duration, setValue]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;

    setValue('startDate', startDate);
    setValue('endDate', endDate);
    setValue('duration', daysOptions[daysOptions.length - 1]);
  };

  const handleClearAll = async () => {
    await removeFilter('default');
    track({
      name: 'Bulk Refunds ' + TrackEventNames.filterFieldsCleared,
      inBuiltOptions: ['merchantId', 'merchantName'],
      additionalOptions: {
        menu_item_name: 'Orders',
        filters: getValues(),
      },
    });
  };

  console.log('filter bulk refunds errors', errors);

  return (
    <Drawer
      position='right'
      isShown={isActive}
      style={{ width }}
      hide={onHide}
      headerElement={<FilterHeading title='Filter Bulk Refunds' />}
      bodyElement={
        <FilterContainer>
          <form
            className='relative filter-form pb-[156px]'
            onSubmit={handleSubmit(handleFormSubmit)}
            noValidate>
            <span
              className='absolute right-0 text-sm cursor-pointer clear-all'
              style={{ color: '#D83232' }}
              onClick={handleClearAll}>
              Clear all
            </span>

            <StatusField
              isBulkRefundType
              value={watch('status')}
              setValue={(statuses) => setValue('status', statuses)}
            />

            <DurationField
              label='Bulk Refund Duration'
              value={watch('duration')}
              setValue={(newValue) => setValue('duration', newValue)}
            />

            <DateRangeField
              label='Bulk Refund Date Range'
              value={[watch('startDate'), watch('endDate')]}
              onChange={handleDateChange}
            />

            <TextField
              label={FieldNames.bulkRefundId}
              id='bulk_refund_id'
              placeHolder={`Enter ${FieldNames.bulkRefundId}`}
              value={watch('bulkRefundId') ?? ''}
              onChange={(bulkRefundId) =>
                setValue('bulkRefundId', bulkRefundId)
              }
            />

            <SubMerchantField
              isMulti
              subMerchantId
              value={watch('subMerchantId') ?? []}
              onChange={(value) => setValue('subMerchantId', value)}
            />

            <TextField
              label={FieldNames.singleSubMerchantId}
              id='singleMerchant'
              type='text'
              placeHolder={`Enter ${FieldNames.singleSubMerchantId}`}
              value={watch('singleSubMerchantId') ?? ''}
              onChange={(singleSubMerchantId) =>
                setValue('singleSubMerchantId', singleSubMerchantId)
              }
            />

            <FilterSubmitButton />
          </form>
        </FilterContainer>
      }
    />
  );
};

export default FilterBulkRefund;
