// This file contains the names for all the tracking events

// The name can be matched with the Tracking Plan xlsx:
// https://docs.google.com/spreadsheets/d/121MpPV3r1ZbvjIgk-O9F7ZQ4m5T2FlUqxfRwTcvlIEs

export enum TrackEventNames {
  // common
  clearFilterClicked = 'Clear Filter Clicked',
  itemClicked = 'Clicked',
  popupRendered = ' Rendered',
  dashboardRendered = 'Dashboard Rendered',
  exported = 'Exported',
  filterClicked = 'Filter Clicked',
  filterFieldsCleared = 'Filter Fields Cleared',
  helpClicked = 'Help Clicked',
  menuItemClicked = 'Menu Item Clicked',
  pageSelected = 'Selected',
  pageRendered = 'Rendered',
  logout = 'Log Out Rendered',
  filterFieldsEntered = ' Filter Fields Entered',

  // login
  signInForm = 'Sign In Form', // when use types something in the login form
  signedIn = 'Signed In',
  failedSignedIn = 'Sign In Failed',
  forgotPassword = 'Forgot Password Clicked',
  backToLogin = 'Back To Login',
  resendPasswordResetEmail = 'Resend Password Reset Email',
  searchClicked = 'Search Clicked',
  sendPasswordResetLink = 'Send Password Reset Link',
  sendPasswordResetLinkFailed = 'Send Password Reset Link Failed',
  sendPasswordResetLinkSuccess = 'Password Reset Link Successful',

  // orders
  orderClosed = 'Order Closed',
  orderIdCopied = 'Order ID Copied',
  orderLineItemsOpened = 'Order Line Items Opened',
  orderPopupRendered = 'Order Rendered',

  // transactions
  transactionClicked = 'Transaction Clicked',
  transactionExported = 'Transactions Exported',
  transactionIdCopied = 'Transaction ID Copied',
  transactionPopupClosed = 'Transaction Closed',
  transactionRendered = 'Transaction Rendered',
  issueRefundRendered = 'Issue Refund Rendered',
  issueRefundSelected = 'Issue Refund Selected',
  confirmationRendered = 'Confirmation Rendered',
  confirmationClicked = 'Confirmation Clicked',

  // refunds
  refundIDCopied = 'Refund ID Copied',
  refundClosed = 'Refund Closed',
  refundRelatedTransactionOpened = 'Refund Related Transaction Opened',

  // settlements
  // settlement details
  // api keys
  apiSubMerchantFilter = 'API Submerchant Filter Applied',
  apiSecretKeyRevealed = 'API Secret Key Revealed',
  apiSecretKeyCopied = 'API Secret Key Copied',
  apiAccesskeyCopied = 'API Keys Copied',
}
